<template>
    <div class="fixed-inner-header">
        <div class="container-fluid">
            <div class="row inner-header">
                <div class="col-12">
                    <div class="row">
                        <div class="col-sm-8 col-lg-3 pr-0 mr-0 my-auto">
                            <h4 class="pl-2 mb-0 fs-16">Events / Meetings</h4>
                        </div>
                        <div class="col-6 col-sm-4 col-lg-2 pl-0 mb-1 ml-0 text-left">
                            <div class="row no-gutters">
                                <div class="col-11">
                                    <select v-model="ermObject" :title="ermObject.value" @change="filterEventData(ermObject)" class="form-control" id="requirementId">
                                        <option value="all" >All Event Groups</option>
                                        <option v-for="(info, index) in ermLookup" :value="{id: info.id, value:info.value}" :title="info.value">{{info.value}}</option>
                                    </select>
                                </div>
                                <div class="col">
                                    <a class="setting-icon-custom action-link" href="javascript:" v-b-modal.addErmMasterModal title="Add Event Group"><i class="fa fa-plus-circle" aria-hidden="true"></i></a>
                                    <a href="javascript:" title="Manage Event Group" class="setting-icon-custom2 action-link" @click="routeToMasterPage"><i class="fa fa-list" aria-hidden="true"></i></a>
                                    <!-- <a class="setting-icon-custom2" href="javascript:" @click="routeToMasterPage" title="Manage Event Group"><i class="fa fa-cog" aria-hidden="true"></i></a> -->
                                </div>
                            </div>
                        </div>
                        <div class="col-6 col-sm-4 col-lg-3 mt-0 mb-1">
                            <b-button class="addnew-tassk-btn float-right" variant="outline-info" @click="openCalEventPopup(null, false)" size="sm"><i class="fa fa-plus pr-1"></i>Add Event / Meeting</b-button>
                        </div>
                        <div class="col-sm-8 col-lg-4 my-auto">
                            <nav class="breadcrumb-container d-sm-block d-lg-inline-block float-right" aria-label="breadcrumb">
                                <ol class="breadcrumb pt-0 my-0">
                                    <li class="breadcrumb-item">
                                        <a href="javascript:" @click="getPrevUrl()"><i class="fa fa-reply pr-2 action-link" aria-hidden="true"></i>Back</a>
                                    </li>
                                    <li v-if="projId" class="breadcrumb-item">
                                        <h4 class="pl-2 mb-0 fs-14 text-right">#{{this.projId}} - {{this.projectName}}</h4>
                                    </li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                    <div class="separator mb-2"></div>
                </div>
            </div>
            <b-overlay :show="showOverlay" opacity="0.4">
                <div class="row">
                    <div class="col-xl-12">
                        <div class="card mb-4">
                            <div class="card-body">
                                <fullcalendar ref="evtCalendar" :key="calendarEventKey" :calendarEvents="filteredEvents" @date-click="handleDateClick" @event-selected="eventSelected" @mouse-hover-event="mouseHoverEvent" />
                            </div>
                        </div>
                    </div>
                </div>
                <template #overlay>
                    <div class="text-center">
                        <LoadingSpinner />
                    </div>
                </template>
            </b-overlay>
        </div>
        <AddErmMasterPopup ref="addErmMasterModal" :ermMasterInfo="ermMasterInfo" @update-parent="lookupData" />
        <AddCalEventPopup v-if="showDialog" ref="addCalEventRef" @refreshData="getEvents" @masterLookupRefresh="lookupData" :calendarEventData="calendarEvent" @closeDialog="closeDialog" />
        <MeetingPrepartionDetailPopup v-if="showDialog" ref="meetingPrepartionDetailPopupRef" @closeDialog="closeDialog" />
    </div>
</template>
<script>
import iziToast from 'izitoast';
import Multiselect from 'vue-multiselect';
import moment from 'moment';
import dayList from '@/json/daysofweek.json';
import { required, email, minLength, maxLength, sameAs } from "vuelidate/lib/validators";
import AddErmMasterPopup from '@/components/popup/action/AddErmMasterPopup.vue';
import AddCalEventPopup from '@/components/popup/action/AddCalendarEventPopup.vue';
import MeetingPrepartionDetailPopup from '@/components/popup/itemdetail/MeetingPrepartionDetailPopup.vue';
import event from '@/json/event.json'
export default {
    name: 'app',
    components: {
        AddErmMasterPopup,
        Multiselect,
        AddCalEventPopup,
        MeetingPrepartionDetailPopup
    },
    data() {
        return {
            // elements: {
            //     childDataLoaded: false,
            // },
            showOverlay: false,
            showDialog: false,
            projId: localStorage.getItem("projId"),
            projectName: localStorage.getItem("projectName"),
            vhextAccountId: localStorage.getItem("vhextAccountId"),
            projectId: localStorage.getItem("projectId"),
            userId: localStorage.getItem("userId"),
            entityRecordMasterId: '', 
            ermMasterInfo: {
                title: "Event Preparation",
                entity: 'meeting_minute_master',
                refEntity: 'calendar_event',
                tooltip: 'New Event Group',
                titleLabelName: 'Event Group Title',
            },

            calendarEventKey: 0,
            calendarEvent: {
                editMode: false,
                viewMode: null,
                showMOM: false,
                title: '',
                eventDate: this.getDateValue(new Date()),
                startDate: '',
                eventStartDate: this.getDateValue(new Date()),
                eventStartTime: this.getTimeValue(new Date()),
                endDate: '',
                eventEndDate: this.getDateValue(new Date()),
                eventEndTime: this.getTimeValue(new Date()),
                link: '',
                location: '',
                eventTypeId: '',
                calendarTypeId: '',
                calendarCategoryId: '',
                eventParticipantTypeId: '',
                eventFrequency: 0,
                statusId: '',
                isAllDayEvent: false,
                daysOfWeek: [],
                hostedById: '',
                description: '',
                createdById: localStorage.getItem("userId"),
                projectId: localStorage.getItem("projectId"),
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                participantId: localStorage.getItem("userId"),
                entityRecordMasterId: '',
                eventParticipant: [],
                selectedDate: '',
                isMOMRecorder: false,
            },
            days: dayList,
            allEvents: [],
            filteredEvents: [],

    // {
    //   daysOfWeek: [0,6], //Sundays and saturdays
    //   rendering:"background",
    //   color: "#ff9f89",
    //   overLap: false,
    //   allDay: true
    // }

            ermLookup: [],

            ermObject: 'all',
        }
    },
    computed: {
        events() {
            return this.allEvents.map((event)=> {
                if (!event.allDay) {
                    if(event.start!= null)
                        event.start = moment(event.start.replace("T00:00:00.0", "")).format("YYYY-MM-DD HH:mm:ss");
                    if(event.end!= null)
                        event.end = moment(event.end.replace("T00:00:00.0", "")).format("YYYY-MM-DD HH:mm:ss");
                }
                if (event.eventFrequency == 1) {

                    if (!event.allDay) {
                        event['startTime'] = moment(String(event.start)).format('HH:mm');
                        event['endTime'] = moment(String(event.end)).format('HH:mm');
                    }

                    event['startRecur'] = event['start'];
                    delete event['start'];
                    event['endRecur'] = event['end'];
                    delete event['end'];
                    event.daysOfWeek = event.daysOfWeek ? event.daysOfWeek.split(',') : [];
                    
                    return event;
                }
                else {
                    return event;
                }
            })
        },
        isHostOrCreator() {
            console.log(this.calendarEvent.hostedById,this.userId, this.calendarEvent.createdById)
            return this.calendarEvent.hostedById == this.userId || this.calendarEvent.createdById == this.userId;
        }
    },
    mounted() {
        this.getEvents();
        this.lookupData();
        // this.refreshCalendar();
    },
    methods: {
        refreshCalendar() {
            // this.$refs.evtCalendar.forceRerender();
        },
        getEvents: function() {
            // this.elements.childDataLoaded = false;
            this.showOverlay = true;
            let data = {
                participantId: this.calendarEvent.participantId
            }
            console.log(data);
            axios.post(this.$store.getters.getAPIBasePath + '/communication/eventparticipant/list', data)
                .then((response) => {
                    this.showOverlay = false;
                    let result = response.data;
                    this.allEvents = result.eventParticipantList;
                    console.log(this.allEvents);
                    this.calendarEventKey++;
                    this.$nextTick(() => {
                        this.filterEventData(this.ermObject);
                    })
                })
                .catch(error => {
                    this.showOverlay = false;
                    iziToast.error({
                       title: 'Error',
                       message: 'Error',
                       position: 'topRight'
                   });
                })
        },
        lookupData: function() {
            let data = {
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                projectId: localStorage.getItem("projectId"),
                userId: localStorage.getItem("userId"),
                entity: 'meeting_minute_master'
            }
            axios.post(this.$store.getters.getAPIBasePath + '/lookup/entityerm', data)
                .then((response) => {
                    let result = response.data;
                    this.ermLookup = result.entityERMLookup;
                    console.log(result);
                })
        },
        getParticipantList: function(eventId) {
            let data = {
                eventId: eventId
            }
            axios.post(this.$store.getters.getAPIBasePath + '/communication/eventparticipant/lookup/all', data)
                .then((response) => {
                    let result = response.data;
                    this.calendarEvent.eventParticipant = result.eventParticipantLookup;
                    console.log(result);
                })
        },
        eventSelected: function(event) {
            let calendarProps = event.extendedProps;
            // this.getParticipantList(calendarProps.eventId);
            this.openCalEventPopup(event, true);
        },
        openCalEventPopup: function(event, flag) {
            console.log(event, flag)
            if (flag == true) {
                let calendarProps = event.extendedProps;
                // this.$refs.addCalEventRef.getParticipantList(calendarProps.eventId);
                this.calendarEvent.id = calendarProps.eventId;
                this.calendarEvent.title = event.title;
                this.calendarEvent.isAllDayEvent = event.allDay;
                this.calendarEvent.selectedDate = event._instance.range.start;

                if (event._def.recurringDef) {
                    let recurData = event._def.recurringDef.typeData;
                    console.log(recurData)
                    this.calendarEvent.daysToRepeat = [];
                    recurData.daysOfWeek.forEach((day) => {
                        this.calendarEvent.daysToRepeat.push(this.days[day]);
                    })
                    this.calendarEvent.eventStartDate = this.getDateValue(recurData.startRecur);
                    this.calendarEvent.eventEndDate = this.getDateValue(recurData.endRecur);
                    this.events.forEach((event)=> {
                        if (event.eventId == calendarProps.eventId) {
                            console.log(event.startTime, event.endTime)
                            this.calendarEvent.eventStartTime = event.startTime ? event.startTime.toString().substr(0, 5) : null;
                            this.calendarEvent.eventEndTime = event.endTime ? event.endTime.toString().substr(0, 5) : null;                        
                        }
                    });
                } else {
                    this.calendarEvent.daysToRepeat = [];
                    this.calendarEvent.eventStartDate = event.startStr;
                    this.calendarEvent.eventEndDate = event.endStr;
                    this.calendarEvent.eventStartTime = this.getTimeValue(event.startStr);
                    this.calendarEvent.eventEndTime = this.getTimeValue(event.endStr);
                }
                this.calendarEvent.eventDate = calendarProps.eventDate;
                this.calendarEvent.eventFrequency = calendarProps.eventFrequency ? calendarProps.eventFrequency : 0;
                this.calendarEvent.type = calendarProps.type;
                this.calendarEvent.link = calendarProps.link;
                this.calendarEvent.calendarTypeId = calendarProps.calendarTypeId;
                this.calendarEvent.calendarCategoryId = calendarProps.calendarCategoryId;
                this.calendarEvent.eventTypeId = calendarProps.eventTypeId;
                this.calendarEvent.description = calendarProps.description;
                this.calendarEvent.status = calendarProps.status;
                this.calendarEvent.statusId = calendarProps.statusId;
                this.calendarEvent.projectId = calendarProps.projectId;
                this.calendarEvent.vhextAccountId = calendarProps.vhextAccountId;
                this.calendarEvent.entityRecordMasterId = calendarProps.entityRecordMasterId;
                this.calendarEvent.createdBy = calendarProps.createdBy;
                this.calendarEvent.hostedBy = calendarProps.hostedBy;
                this.calendarEvent.createdById = calendarProps.createdById;
                this.calendarEvent.hostedById = calendarProps.hostedById;
                this.calendarEvent.eventId = calendarProps.eventId;
                this.calendarEvent.location = calendarProps.location;
                this.calendarEvent.eventParticipantTypeId = calendarProps.eventParticipantTypeId;
            } else {
                this.calendarEvent.title = '';
                this.calendarEvent.isAllDayEvent = false;
                this.calendarEvent.daysOfWeek = [];
                this.calendarEvent.daysToRepeat = [];
                this.calendarEvent.eventDate = this.getDateValue(new Date());
                this.calendarEvent.startDate = '';
                this.calendarEvent.endDate = '';
                this.calendarEvent.eventStartDate = this.getDateValue(new Date());
                this.calendarEvent.eventEndDate = this.getDateValue(new Date());
                this.calendarEvent.eventStartTime = this.getTimeValue(new Date());
                this.calendarEvent.eventEndTime = this.getTimeValue(new Date());
                this.calendarEvent.entityRecordMasterId = this.entityRecordMasterId;
                this.calendarEvent.vhextAccountId = this.vhextAccountId;
                this.calendarEvent.projectId = this.projectId;
                this.calendarEvent.statusId = '';
                this.calendarEvent.hostedById = '';
                this.calendarEvent.hostedBy = '';
                this.calendarEvent.createdById = this.userId;
                this.calendarEvent.createdBy = '';
                this.calendarEvent.link = '';
                this.calendarEvent.location = '';
                this.calendarEvent.description = '';
                this.calendarEvent.calendarTypeId = '';
                this.calendarEvent.calendarCategoryId = '';
                this.calendarEvent.eventTypeId = '';
                this.calendarEvent.eventFrequency = 0;
                this.calendarEvent.eventParticipantList = '';
                this.calendarEvent.eventParticipant = [];
                this.calendarEvent.id = '';
            }
            this.calendarEvent.editMode = false;
            this.calendarEvent.showMOM = flag;
            this.calendarEvent.viewMode = flag;
            this.showDialog = true;
            console.log(this.calendarEvent)
            if (this.calendarEvent.viewMode == true && !this.isHostOrCreator && this.calendarEvent.eventParticipantTypeId != 2) {
                let eventId = moment(String(this.calendarEvent.selectedDate)).format('YYYYMMDD');
                this.$nextTick(()=> {
                    this.$refs.meetingPrepartionDetailPopupRef.getDetails(this.calendarEvent.id, 'calendar_event', this.calendarEvent.selectedDate);
                })
            }
            else if (this.calendarEvent.viewMode == true && !this.isHostOrCreator && this.calendarEvent.eventParticipantTypeId == 2) {
                this.calendarEvent.isMOMRecorder = true;
                this.$nextTick(()=> {
                    this.$bvModal.show('addCalEventPopup');
                })
            }
            else {
                this.$nextTick(()=> {
                    this.$bvModal.show('addCalEventPopup');
                })
            }
        },
        ermFilter: function(ermData) {
            console.log(ermData);
            if (ermData.id !== null && ermData.id) {
                this.entityRecordMasterId = ermData.id;
                this.filteredEvents = this.events;
                if (this.filteredEvents != null) {
                    var event = this.filteredEvents;
                    this.filteredEvents = event.filter(function(evt) {
                        return evt.entityRecordMasterId == ermData.id;
                    });
                }
                // this.setERMData(ermData.id,ermData.value);
            } else if (ermData == 'all'|| ermData.id == '') {
                this.filteredEvents = this.events;
            }
            console.log(ermData);
        },
        async filterEventData (ermData) {
            await this.ermFilter(ermData);
            this.calendarEventKey++;
        },
        handleDateClick: function(info) {
            console.log('date-click', info.dateStr);
        },
        mouseHoverEvent: function(info) {
            console.log('mouseHoverEvent', info);
        },
        routeToMasterPage() {
            let routePath = this.$route.path;
            console.log(routePath)
            if (routePath.includes('landing')) {
                this.$router.push('/landing/mmnmas/list');
            } else {
                this.$router.push('/mmnmas/list');
            }
        },
        closeDialog: function() {;
            this.showDialog = false;
        },
        combineDateAndTime: function(date, time) {
            console.log(date, time);
            if (date && date !== '') {
                if (time && time !== '') {
                    return moment(date.toString() + 'T' + time.toString());
                } else
                    return moment(date.toString() + 'T' + '00:00');
            }
        },
    }
}
</script>