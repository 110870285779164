<template>
    <div>
        <header id="header" class="fixed-top d-flex align-items-center">
            <div class="container-fluid d-flex align-items-center">
                <div class="logo ml-0">
                    <!-- <h1><a href="index.html">vhextech</a></h1> -->
                    <!-- Uncomment below if you prefer to use an image logo -->
                    <a href="javascript:" class=" text-center d-block web-logo"><img src="@/assets/images/logo.png"></a>
                </div>
                <nav class="nav-menu d-none d-lg-block">
                    <ul>
                        <li class="active"><a href="">Home</a></li>
                        <li><a href="#services">Services</a></li>
                        <li><a href="#testimonials">Testimonials</a></li>
                        <li><a href="#contact">Contact Us</a></li>
                    </ul>
                </nav>
            </div>
        </header>
        <section id="hero" class="d-flex flex-column justify-content-center align-items-center" :style="{ backgroundImage: 'url(' + require('@/assets/img/hero-bg.jpg') + ')' }">
            <div class="container text-center text-md-left" data-aos="fade-up">
                <div class="row">
                    <div class="col-md-8">
                        <div class="d-flex h-100">
                            <div class="justify-content-center align-self-center text-center">
                                <h1>Welcome to <span>vManage-X</span> </h1>
                                <h2>We are a Team of Innovators giving Logical Management Solutions</h2> 
                                <!-- <a href="javascript:" class="btn-get-started scrollto" @click="elements.showRegister=true">Get Started</a> -->
                                <a href="javascript:" class="btn-get-started scrollto disabled">Get Started</a>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="section-bg p-3 login-freezing mt-0">
                            <div class="form">
                                <div class="tab-content">
                                    <b-overlay :show="showOverlay" spinner-variant="info" opacity="0.4" rounded="sm">
                                        <div v-if="elements.showRegister" id="signup">
                                            <h6 class="p-1 mb-0 fs-16 ">User Registration</h6>
                                            <div class="separator mb-2"></div>
                                            <form autocomplete="off" @submit.prevent="saveAccount">
                                                <div class="card-body p-1">
                                                    <div class="row mb-2">
                                                        <div class="col-12">
                                                            <div class="form-group mb-2">
                                                                <label class="sr-only" for="accountNameReg">Account Name</label>
                                                                <div class="required-field-block" :class="{ 'is-invalid': submitted && $v.vhextAccount.accountName.$error }">
                                                                    <input type="text" class="form-control" v-model="vhextAccount.accountName" name="username" id="accountNameReg" autocomplete="false" :class="{ 'is-invalid': $v.vhextAccount.accountName.$error }" placeholder="Account Name">
                                                                    <div class="required-icon"></div>
                                                                </div>
                                                                <div v-if="submitted && $v.vhextAccount.accountName.$error" class="invalid-feedback">
                                                                    <span v-if="!$v.vhextAccount.accountName.required">Account Name is required</span>
                                                                    <span v-if="!$v.vhextAccount.accountName.minLength">Account Name must have at least {{$v.vhextAccount.accountName.$params.minLength.min}} letters.</span>
                                                                    <span v-if="!$v.vhextAccount.accountName.maxLength">Account Name must not exceed {{$v.vhextAccount.accountName.$params.maxLength.max}} letters.</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-12">
                                                            <div class="form-group mb-2">
                                                                <label class="sr-only" for="emailIdReg">Email Id</label>
                                                                <div class="required-field-block" :class="{ 'is-invalid': submitted && $v.vhextAccount.emailId.$error }">
                                                                    <input type="email" v-model="vhextAccount.emailId" name="email" autocomplete="false" id="emailIdReg" class="form-control" placeholder="Email Address">
                                                                    <div class="required-icon"></div>
                                                                </div>
                                                                <div v-if="submitted && $v.vhextAccount.emailId.$error" class="invalid-feedback">
                                                                    <span v-if="submitted && !$v.vhextAccount.emailId.required" class="text-danger">EmailId is required</span>
                                                                    <span v-if="submitted &&  !$v.vhextAccount.emailId.email">Email is not valid</span>
                                                                </div>
                                                                <!--  <div v-if="error" class="invalid-feedback"> -->
                                                                <label v-if="error" class="text-danger">EmailId already exists.</label>
                                                                <!-- </div> -->
                                                            </div>
                                                        </div>
                                                        <!--  <div class="col-6 pr-1">
                                                            <div class="form-group mb-2">
                                                                <div class="required-field-block" :class="{ 'is-invalid': submitted && $v.vhextAccount.password.$error, 'is-valid': !$v.vhextAccount.password.$invalid }">
                                                                    <input type="password" v-model="vhextAccount.password" class="form-control" ref="password" placeholder="Password">
                                                                    <div class="required-icon"></div>
                                                                </div>
                                                                <div v-if="submitted && $v.vhextAccount.password.$error" class="invalid-feedback">
                                                                    <span v-if="!$v.vhextAccount.password.required">Password is required</span>
                                                                    <span v-if="!$v.vhextAccount.password.minLength">Password must be at least 6 characters</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-6 pl-1">
                                                            <div class="form-group mb-2">
                                                                <div class="required-field-block" :class="{ 'is-invalid': submitted && $v.vhextAccount.confirmationPassword.$error }">
                                                                    <input type="password" v-model="vhextAccount.confirmationPassword" class="form-control" placeholder="Confirm Password">
                                                                    <div class="required-icon"></div>
                                                                </div>
                                                                <div v-if="submitted && $v.vhextAccount.confirmationPassword.$error" class="invalid-feedback">
                                                                    <span v-if="!$v.vhextAccount.confirmationPassword.required">Confirm Password is required</span>
                                                                    <span v-else-if="!$v.vhextAccount.confirmationPassword.sameAsPassword">Passwords must match</span>
                                                                </div>
                                                            </div>
                                                        </div> -->
                                                        <div class="col-6 pr-1">
                                                            <div class="form-group mb-2">
                                                                <label class="sr-only" for="vhextAccountTypeIdReg">Account Type</label>
                                                                <div class="required-field-block" :class="{ 'is-invalid': submitted && $v.vhextAccount.vhextAccountTypeId.$error }">
                                                                    <select v-model="vhextAccount.vhextAccountTypeId" class="form-control" id="vhextAccountTypeIdReg" autocomplete="false">
                                                                        <option value="">Account Type</option>
                                                                        <option v-for="(info, index) in accountTypes" :value="info.id">{{info.value}}</option>
                                                                    </select>
                                                                    <div class="required-icon"></div>
                                                                </div>
                                                                <div v-if="submitted && $v.vhextAccount.vhextAccountTypeId.$error" class="invalid-feedback">
                                                                    <span v-if="!$v.vhextAccount.vhextAccountTypeId.required">Account Type is required</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-6 pl-1">
                                                            <div class="form-group mb-2">
                                                                <label class="sr-only" for="phoneReg">Phone</label>
                                                                <input type="text" v-model="vhextAccount.phone" class="form-control" name="mobile" autocomplete="false" placeholder="Phone number" id="phoneReg">
                                                            </div>
                                                        </div>
                                                        <div class="col-6 pr-1">
                                                            <div class="form-group mb-2">
                                                                <label class="sr-only" for="companyReg">Company Name</label>
                                                                <input type="text" v-model="vhextAccount.company" name="organization" autocomplete="false" class="form-control" placeholder="Company Name" id="companyReg">
                                                            </div>
                                                        </div>
                                                        <div class="col-6 pl-1">
                                                            <div class="form-group mb-2">
                                                                <label class="sr-only" for="jobTitle">Job Title</label>
                                                                <input type="text" v-model="vhextAccount.jobTitle" class="form-control" name="organization-title" autocomplete="false" placeholder="Job Title" id="jobTitle">
                                                            </div>
                                                        </div>
                                                        <div class="col-12">
                                                            <div class="form-group mb-2">
                                                                <label class="sr-only" for="countryId">Country</label>
                                                                <select v-model="vhextAccount.countryId" class="form-control" id="countryId" autocomplete="false">
                                                                    <option value="">Country</option>
                                                                    <option v-for="(info, index) in countries" :value="info.id">{{info.value}}</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <!-- <div class="col-12">
                                                            <div class="form-group mb-2">
                                                                <textarea v-model="vhextAccount.activityId" class="form-control" rows="3" placeholder="CAPTCHA"></textarea>
                                                            </div>
                                                        </div> -->
                                                    <div class="form-group mb-2">
                                                        <b-form-checkbox id="checkbox-1" v-model="vhextAccount.terms">
                                                            I accept the <a href="#" class="action-link">Terms of Service</a>
                                                        </b-form-checkbox>
                                                        <!-- <div v-if="submitted && $v.vhextAccount.terms.$error" class="invalid-feedback">
                                                            <span v-if="submitted && !$v.vhextAccount.terms.required" class="text-danger">Please accept the terms.</span>
                                                        </div> -->
                                                    </div>
                                                    <div class="text-center">
                                                        <button type="submit" class="btn btn-primary mb-0 mr-2 pt-1 pb-1 pl-3 pr-3"><i class="fa fa-floppy-o pr-2"></i>Submit</button>
                                                        <button type="submit" @click="clearData" class="btn btn-primary mb-0 pt-1 pb-1 pl-3 pr-3"><i class="fa fa-times pr-2"></i>Cancel</button>
                                                    </div>
                                                </div>
                                             </form>
                                        </div>
                                        <div v-if="!elements.showRegister" id="login">
                                            <!-- <a href="javascript:" class="logo text-center d-block mb-4 login-logo"><img src="@/assets/images/logo.png"></a> -->
                                            <h4 class="text-center mt-2"><span class="login-header">Login</span></h4>
                                            <div class="php-email-form mx-2">
                                                <div class="row form-group">
                                                    <div class="col-12">
                                                        <div class="form-group mb-2">
                                                            <div class="required-field-block">
                                                                <input type="text" v-model="login.loginName" class="form-control" id="loginName" placeholder="Login Email Id">
                                                                <div class="required-icon"></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-12">
                                                        <div class="form-group mb-2">
                                                            <div class="required-field-block">
                                                                <input type="password" @keyup.enter="performLogin" v-model="login.password" class="form-control" id="loginPassword" placeholder="Enter Password">
                                                                <div class="required-icon"></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-12 col-sm-12 col-xs-12">
                                                        <div class="row m-t-15">
                                                            <div class="col-md-12 col-sm-12 col-xs-12">
                                                                <b-form-checkbox id="checkbox-2" value="accepted" unchecked-value="not_accepted1">
                                                                    Remember Me
                                                                </b-form-checkbox>
                                                            </div>
                                                        </div>
                                                        <div class="row m-t-15 forgot-btns mt-2">
                                                            <div class="col-md-12 col-sm-12 col-xs-12"> <a v-b-modal.forgotPasswordPopup class="fs-12 action-link" href="javascript:">Forgot Password</a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="text-center">
                                                    <button class="btn-type-1" :disabled="disableLogin" @click="performLogin()" type="submit">
                                                        Login
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <ul class="login-options mb-2">
                                            <!-- <li v-show="!elements.showRegister" class="text-center pt-2"><a href="javascript:" class="fs-13 action-link" @click.prevent="elements.showRegister=true">Register Now</a>
                                            </li> -->
                                            <li v-show="elements.showRegister" class="text-center pt-2">Already a user? Login
                                                <a href="javascript:" class="action-link" @click.prevent="elements.showRegister=false">here</a>
                                            </li>
                                        </ul>
                                    </b-overlay>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <div id="main">
            <div class="container">
                <div class="row">
                    <div class="col-md-12">
                        <section id="what-we-do" class="what-we-do">
                            <div class="section-title">
                                <h2>What vManage-X Do</h2>
                                <p>vManage Simple Task to Complex Projects</p>
                            </div>
                            <div class="row">
                                <div class="col-lg-2 col-md-6 d-flex align-items-stretch mt-4 mt-md-0">
                                    <div class="icon-box">
                                        <div class="icon"><i class="bx bxl-dribbble"></i></div>
                                        <h4><a href="">Project</a></h4>
                                        <p>Project Information,Client,Client Requirement,Project Docs</p>
                                    </div>
                                </div>
                                <div class="col-lg-2 col-md-6 d-flex align-items-stretch mt-4 mt-md-0">
                                    <div class="icon-box">
                                        <div class="icon"><i class="bx bxl-dribbble"></i></div>
                                        <h4><a href="">Plan</a></h4>
                                        <p>Planning your Project Requirement,Time,Cost</p>
                                    </div>
                                </div>
                                <div class="col-lg-2 col-md-6 d-flex align-items-stretch mt-4 mt-md-0">
                                    <div class="icon-box">
                                        <div class="icon"><i class="bx bx-file"></i></div>
                                        <h4><a href="">Develop</a></h4>
                                        <p>Requirement Study,Design,Development Planning,Task,Timesheet</p>
                                    </div>
                                </div>
                                <div class="col-lg-2 col-md-6 d-flex align-items-stretch mt-4 mt-lg-0">
                                    <div class="icon-box">
                                        <div class="icon"><i class="bx bx-tachometer"></i></div>
                                        <h4><a href="">Verify</a></h4>
                                        <p>Review,Approval,Tests</p>
                                    </div>
                                </div>
                                <div class="col-lg-2 col-md-6 d-flex align-items-stretch mt-4 mt-lg-0">
                                    <div class="icon-box">
                                        <div class="icon"><i class="bx bx-tachometer"></i></div>
                                        <h4><a href="">Control</a></h4>
                                        <p>Change Management</p>
                                    </div>
                                </div>
                                <div class="col-lg-2 col-md-6 d-flex align-items-stretch mt-4 mt-lg-0">
                                    <div class="icon-box">
                                        <div class="icon"><i class="bx bx-tachometer"></i></div>
                                        <h4><a href="">Release</a></h4>
                                        <p>Release,Client Support
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <!-- ======= About Section ======= -->
                        <section id="about" class="about  section-bg">
                            <div class="row">
                                <div class="col-lg-6"> <img src="@/assets/img/about.jpg" class="img-fluid" alt=""> </div>
                                <div class="col-lg-6 pt-4 pt-lg-0">
                                    <h3>About Us</h3>
                                    <p> Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. </p>
                                    <ul>
                                        <li><i class="bx bx-check-double"></i> Ullamco laboris nisi ut aliquip ex ea commodo consequat.</li>
                                        <li><i class="bx bx-check-double"></i> Duis aute irure dolor in reprehenderit in voluptate velit.</li>
                                    </ul>
                                    <div class="row icon-boxes">
                                        <div class="col-md-6"> <i class="bx bx-receipt"></i>
                                            <h4>Corporis voluptates sit</h4>
                                            <p>Consequuntur sunt aut quasi enim aliquam quae harum pariatur laboris nisi ut aliquip</p>
                                        </div>
                                        <div class="col-md-6 mt-4 mt-md-0"> <i class="bx bx-cube-alt"></i>
                                            <h4>Ullamco laboris nisi</h4>
                                            <p>Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <!-- End About Section -->
                        <!-- ======= Skills Section ======= -->
                        <section id="skills" class="skills">
                            <div class="row skills-content">
                                <div class="col-lg-6">
                                    <div class="progress"> <span class="skill">HTML <i class="val">100%</i></span>
                                        <div class="progress-bar-wrap">
                                            <div class="progress-bar" role="progressbar" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
                                        </div>
                                    </div>
                                    <div class="progress"> <span class="skill">CSS <i class="val">90%</i></span>
                                        <div class="progress-bar-wrap">
                                            <div class="progress-bar" role="progressbar" aria-valuenow="90" aria-valuemin="0" aria-valuemax="100"></div>
                                        </div>
                                    </div>
                                    <div class="progress"> <span class="skill">JavaScript <i class="val">75%</i></span>
                                        <div class="progress-bar-wrap">
                                            <div class="progress-bar" role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"></div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <div class="progress"> <span class="skill">PHP <i class="val">80%</i></span>
                                        <div class="progress-bar-wrap">
                                            <div class="progress-bar" role="progressbar" aria-valuenow="80" aria-valuemin="0" aria-valuemax="100"></div>
                                        </div>
                                    </div>
                                    <div class="progress"> <span class="skill">WordPress/CMS <i class="val">90%</i></span>
                                        <div class="progress-bar-wrap">
                                            <div class="progress-bar" role="progressbar" aria-valuenow="90" aria-valuemin="0" aria-valuemax="100"></div>
                                        </div>
                                    </div>
                                    <div class="progress"> <span class="skill">Photoshop <i class="val">55%</i></span>
                                        <div class="progress-bar-wrap">
                                            <div class="progress-bar" role="progressbar" aria-valuenow="55" aria-valuemin="0" aria-valuemax="100"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <!-- End Skills Section -->
                        <!-- ======= Counts Section ======= -->
                        <section id="counts" class="counts">
                            <div class="row">
                                <div class="col-lg-3 col-6">
                                    <div class="count-box"> <i class="icofont-simple-smile"></i> <span data-toggle="counter-up">232</span>
                                        <p>Happy Clients</p>
                                    </div>
                                </div>
                                <div class="col-lg-3 col-6">
                                    <div class="count-box"> <i class="icofont-document-folder"></i> <span data-toggle="counter-up">521</span>
                                        <p>Projects</p>
                                    </div>
                                </div>
                                <div class="col-lg-3 col-6 mt-5 mt-lg-0">
                                    <div class="count-box"> <i class="icofont-live-support"></i> <span data-toggle="counter-up">1,463</span>
                                        <p>Hours Of Support</p>
                                    </div>
                                </div>
                                <div class="col-lg-3 col-6 mt-5 mt-lg-0">
                                    <div class="count-box"> <i class="icofont-users-alt-5"></i> <span data-toggle="counter-up">15</span>
                                        <p>Hard Workers</p>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <!-- End Counts Section -->
                        <!-- ======= Services Section ======= -->
                        <section id="services" class="services section-bg">
                            <div class="section-title">
                                <h2>Services</h2>
                                <p>Necessitatibus eius consequatur ex aliquid fuga eum quidem</p>
                            </div>
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="icon-box"> <i class="icofont-computer"></i>
                                        <h4><a href="javascript:">Lorem Ipsum</a></h4>
                                        <p>Voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident</p>
                                    </div>
                                </div>
                                <div class="col-md-6 mt-4 mt-lg-0">
                                    <div class="icon-box"> <i class="icofont-chart-bar-graph"></i>
                                        <h4><a href="javascript:">Dolor Sitema</a></h4>
                                        <p>Minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat tarad limino ata</p>
                                    </div>
                                </div>
                                <div class="col-md-6 mt-4">
                                    <div class="icon-box"> <i class="icofont-image"></i>
                                        <h4><a href="javascript:">Sed ut perspiciatis</a></h4>
                                        <p>Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur</p>
                                    </div>
                                </div>
                                <div class="col-md-6 mt-4">
                                    <div class="icon-box"> <i class="icofont-settings"></i>
                                        <h4><a href="javascript:">Nemo Enim</a></h4>
                                        <p>Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum</p>
                                    </div>
                                </div>
                                <div class="col-md-6 mt-4">
                                    <div class="icon-box"> <i class="icofont-earth"></i>
                                        <h4><a href="javascript:">Magni Dolore</a></h4>
                                        <p>At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque</p>
                                    </div>
                                </div>
                                <div class="col-md-6 mt-4">
                                    <div class="icon-box"> <i class="icofont-tasks-alt"></i>
                                        <h4><a href="javascript:">Eiusmod Tempor</a></h4>
                                        <p>Et harum quidem rerum facilis est et expedita distinctio. Nam libero tempore, cum soluta nobis est eligendi</p>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <!-- End Services Section -->
                        <!-- ======= Contact Section ======= -->
                        <section id="contact" class="contact">
                            <div class="section-title">
                                <h2>Contact</h2>
                                <p>Magnam dolores commodi suscipit eius consequatur ex aliquid fuga</p>
                            </div>
                            <div class="row mt-5 justify-content-center">
                                <div class="col-lg-10">
                                    <div class="info-wrap">
                                        <div class="row">
                                            <div class="col-lg-4 info"> <i class="icofont-google-map"></i>
                                                <h4>Location:</h4>
                                                <p>NO. G 2, 40/28, SHAKUNTALA RAJESWARI,METHA NAGAR, AMINJIKARAI,
                                                    <br>Chennai TN 600030 India</p>
                                            </div>
                                            <div class="col-lg-4 info mt-4 mt-lg-0"> <i class="icofont-envelope"></i>
                                                <h4>Email:</h4>
                                                <p>managex@vhext.com
                                                    <br>contact@example.com</p>
                                            </div>
                                            <div class="col-lg-4 info mt-4 mt-lg-0"> <i class="icofont-phone"></i>
                                                <h4>Call:</h4>
                                                <p>+1 9999999999
                                                    <br>+1 5589 22475 14</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row mt-5 justify-content-center">
                                <div class="col-lg-10">
                                    <form role="form" class="php-email-form">
                                        <div class="form-row">
                                            <div class="col-md-6 form-group">
                                                <input type="text" name="name" class="form-control" id="name" placeholder="Your Name" data-rule="minlen:4" data-msg="Please enter at least 4 chars" />
                                                <div class="validate"></div>
                                            </div>
                                            <div class="col-md-6 form-group">
                                                <input type="email" class="form-control" name="email" id="email" placeholder="Your Email" data-rule="email" data-msg="Please enter a valid email" />
                                                <div class="validate"></div>
                                            </div>
                                        </div>
                                        <div class="form-group">
                                            <input type="text" class="form-control" name="subject" id="subject" placeholder="Subject" data-rule="minlen:4" data-msg="Please enter at least 8 chars of subject" />
                                            <div class="validate"></div>
                                        </div>
                                        <div class="form-group">
                                            <textarea class="form-control" name="message" rows="5" data-rule="required" data-msg="Please write something for us" placeholder="Message"></textarea>
                                            <div class="validate"></div>
                                        </div>
                                        <div class="mb-3">
                                            <div class="loading">Loading</div>
                                            <div class="error-message"></div>
                                            <div class="sent-message">Your message has been sent. Thank you!</div>
                                        </div>
                                        <div class="text-center">
                                            <button class="btn-type-1" type="submit">Send Message</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </section>
                        <!-- End Contact Section -->
                    </div>
                </div>
            </div>
        </div>
        <footer id="footer">
            <div class="footer-top">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-lg-3 col-md-6 footer-contact">
                            <h3>vhextech</h3>
                            <p> NO. G 2, 40/28, SHAKUNTALA RAJESWARI ST
                                <br> METHA NAGAR, AMINJIKARAI,
                                <br> CHENNAI - 600030
                                <br> India
                                <br>
                                <br> <strong>Phone:</strong> +1 9999999999
                                <br> <strong>Email:</strong> managex@vhext.com
                                <br> </p>
                        </div>
                        <div class="col-lg-2 col-md-6 footer-links">
                            <h4>Useful Links</h4>
                            <ul>
                                <li><i class="bx bx-chevron-right"></i> <a href="javascript:">Home</a></li>
                                <li><i class="bx bx-chevron-right"></i> <a href="javascript:">About us</a></li>
                                <li><i class="bx bx-chevron-right"></i> <a href="javascript:">Services</a></li>
                                <li><i class="bx bx-chevron-right"></i> <a href="javascript:">Pricing</a></li>
                                <li><i class="bx bx-chevron-right"></i> <a href="javascript:">Demo</a></li>
                                <li><i class="bx bx-chevron-right"></i> <a href="javascript:">Terms of service</a></li>
                                <li><i class="bx bx-chevron-right"></i> <a href="javascript:">Privacy policy</a></li>
                            </ul>
                        </div>
                        <div class="col-lg-3 col-md-6 footer-links">
                            <h4>Our Services</h4>
                            <ul>
                                <li><i class="bx bx-chevron-right"></i> <a href="javascript:">Web Design</a></li>
                                <li><i class="bx bx-chevron-right"></i> <a href="javascript:">Web Development</a></li>
                                <li><i class="bx bx-chevron-right"></i> <a href="javascript:">Product Management</a></li>
                                <li><i class="bx bx-chevron-right"></i> <a href="javascript:">Marketing</a></li>
                                <li><i class="bx bx-chevron-right"></i> <a href="javascript:">Graphic Design</a></li>
                            </ul>
                        </div>
                        <div class="col-lg-4 col-md-6 footer-newsletter">
                            <h4>Join Our Newsletter</h4>
                            <p>Tamen quem nulla quae legam multos aute sint culpa legam noster magna</p>
                            <form action="" method="post">
                                <input type="email" name="email">
                                <input type="submit" value="Subscribe"> </form>
                        </div>
                    </div>
                </div>
            </div>
            <div class="container-fluid d-md-flex py-4">
                <div class="mr-md-auto text-center text-md-left">
                    <div class="copyright"> &copy; Copyright <strong><span>vhextech</span></strong>. All Rights Reserved </div>
                </div>
                <div class="social-links text-center text-md-right pt-3 pt-md-0"> <a href="javascript:" class="twitter"><i class="bx bxl-twitter"></i></a> <a href="javascript:" class="facebook"><i class="bx bxl-facebook"></i></a> <a href="javascript:" class="instagram"><i class="bx bxl-instagram"></i></a> <a href="javascript:" class="google-plus"><i class="bx bxl-skype"></i></a> <a href="javascript:" class="linkedin"><i class="bx bxl-linkedin"></i></a> </div>
            </div>
        </footer>
        <ForgotPasswordPopup ref="forgotPasswordPopupRef" />
    </div>
</template>
<script>
import iziToast from 'izitoast';
import moment from 'moment';
import ForgotPasswordPopup from '@/components/popup/action/ForgotPasswordPopup.vue'
import { required, email, minLength, maxLength, sameAs } from "vuelidate/lib/validators";
export default {
    components: {
        ForgotPasswordPopup,
    },
    data() {
        return {
            elements: {
                showRegister: false,
            },
            showOverlay: false,
            disableLogin: false,
            error: false,
            entity: 'vhext_account',
            submitted: false,
            accountTypes: [],
            countries: [],
            vhextAccount: {
                accountName: '',
                vhextAccountTypeId: '',
                emailId: '',
                phone: '',
                password: '',
                confirmationPassword: '',
                terms: false,
                company: '',
                jobTitle: '',
                countryId: '',
                isMobileActivation: 0,
            },
            login: {
                loginName: '',
                password: '',
                key: this.$route.params.key,
                userType: this.$route.params.userType
            },
        }
    },
    mounted() {
        this.lookupData();
        console.log(localStorage.getItem("pageRedirectURL"));
    },
    validations: {
        vhextAccount: {
            accountName: {
                required,
                minLength: minLength(2),
                maxLength: maxLength(100)
            },
            emailId: {
                required,
                email
            },
            /*  password: {
                  required,
                  minLength: minLength(6)
              },
              confirmationPassword: {
                  required,
                  sameAsPassword: sameAs('password')
              },*/
            // terms: {
            //     checked(val) {
            //         return val
            //     }
            //     //sameAs: sameAs( () => true )
            // },
            vhextAccountTypeId: {
                required,
            }
        }
    },
    methods: {
        performLogin() {
            var container = $("#app-container");
            var body_container = $("body");
            var allMenuClassNames = "menu-hidden sub-hidden main-hidden menu-sub-hidden main-show-temporary sub-show-temporary menu-mobile";
            container.removeClass(allMenuClassNames);
            body_container.removeClass('open-note');
            this.disableLogin = true;
            axios.post(this.$store.getters.getAPIBasePath + '/user/authenticate', this.login)
                .then(response => {
                    let result = response.data;
                    if (result.result) {
                        const defaultredirects = this.$store.getters.getDefaultRedirects;
                        this.$store.dispatch("setupLogin", result).then(() => {

                            console.log(result);
                            if(result.data.defaultProjectId > 0){
                                this.getDefaultProject();
                            }
                            else{
                                this.$router.push({ path: defaultredirects.general });
                            }
                        });
                        this.disableLogin = false;
                    } else {
                        this.disableLogin = false;
                        iziToast.info({
                            message: result.message,
                            position: 'topRight'
                        });
                    }
                })
                .catch(error => {
                    this.disableLogin = false;
                    console.log(error);
                    iziToast.error({
                        title: 'Error Occurred',
                        message: 'Please try again',
                        position: 'topRight'
                    });
                });
        },
        lookupData: function() {
            let data = {
                entity: this.entity
            }
            axios.post(this.$store.getters.getAPIBasePath + '/vhext/lookup/all', data)
                .then((response) => {
                    let result = response.data;
                    this.accountTypes = result.vhextAccountTypeLookup;
                    // this.accountTypes = this.filterAccountType("Individual", "Company");
                    this.countries = result.countryLookup;
                })

        },
        saveAccount: function() {
            this.submitted = true;
            this.$v.vhextAccount.$touch();
            if (!this.$v.vhextAccount.$invalid) {
                if (!this.vhextAccount.terms) {
                    iziToast.info({
                        title: 'Please accept the Terms',
                        position: 'topRight'
                    });
                    return;
                }
                this.showOverlay = true;
                this.error = false;
                axios.post(this.$store.getters.getAPIBasePath + '/vhext/account/add', this.vhextAccount)
                    .then((response) => {
                        this.showOverlay = false;
                        let result = response.data;
                        if (result.status == "CREATED") {
                            iziToast.success({
                                title: 'Check Email',
                                message: `Activation mail has been sent to "${this.vhextAccount.emailId}"`,
                                drag: true,
                                timeout: 8000,
                                icon: 'fa fa-envelope',
                                position: 'center',
                                titleSize: '15px',
                                backgroundColor: 'white',
                                progressBar: false,
                                titleLineHeight: '40px',
                                messageSize: '15px',
                                messageLineHeight: '40px',
                            });
                            this.clearData();
                            this.elements.showRegister = false;
                        } else if (result.status == 'OK') {
                            this.error = true;
                            iziToast.info({
                                message: result.message,
                                position: 'topRight'
                            });

                        }
                    })
                    .catch(error => {
                        this.showOverlay = false;
                        iziToast.error({
                           title: 'Error',
                           message: 'Error',
                           position: 'topRight'
                       });
                    })
            }
        },
        getDefaultProject: function() {
            let data = {
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                userId: localStorage.getItem("userId"),
            }
            console.log(data);

            axios.post(this.$store.getters.getAPIBasePath + '/project/default/view', data)
                .then((response) => {
                    let result = response.data;

                    console.log(result);                    
                    if (result) {
                        this.defaultProject = result.defaultProject;

                            this.setSessionProjectId(this.defaultProject.id,this.defaultProject.projectId,this.defaultProject.projectTypeId,this.defaultProject.displayName,this.defaultProject.currencySymbol,this.defaultProject.isStandard, this.defaultProject.vmxServiceCode)

                    }

                    console.log(this.defaultProject);


                }).catch(error => {
                    console.log(error);
                });
        },

        clearData: function() {
            this.elements.showRegister = false;
            this.error = false;
            this.vhextAccount.accountName = '';
            this.vhextAccount.vhextAccountTypeId = '';
            this.vhextAccount.emailId = '';
            this.vhextAccount.phone = '';
            this.vhextAccount.password = '';
            this.vhextAccount.terms = false;
            this.vhextAccount.confirmationPassword = '';
            this.vhextAccount.company = '';
            this.vhextAccount.title = '';
            this.vhextAccount.countryId = '';
            this.$v.vhextAccount.$reset();
        },
        setSessionProjectId: function(projectId, projId, projectTypeId, projectName, currency, isStandard, vmxServiceCode) {
            localStorage.setItem('projectId', projectId);
            localStorage.setItem('projId', projId);
            localStorage.setItem('projectTypeId', projectTypeId);
            localStorage.setItem('projectName', projectName);
            localStorage.setItem('currency', currency);
            localStorage.setItem('isStandard', isStandard);
            localStorage.setItem('vmxServiceCode', vmxServiceCode);
            if (this.login.userType == 'CLI'){
                this.$router.push('/client/docshr/list');
                var container = $("#app-container");
                var allMenuClassNames = "menu-hidden sub-hidden main-hidden menu-sub-hidden main-show-temporary sub-show-temporary menu-mobile open-note";
                container.removeClass(allMenuClassNames);
            }
            else {
                this.$router.push('/actionitem');
                var container = $("#app-container");
                var allMenuClassNames = "menu-hidden sub-hidden main-hidden menu-sub-hidden main-show-temporary sub-show-temporary menu-mobile open-note";
                container.removeClass(allMenuClassNames);
            }
        },

        filterAccountType: function(individual, company) {
            return this.accountTypes.filter(function(data, key) {
                if (data.value == individual || data.value == company) {
                    return data;
                }
            });
        }

    }
}
</script>