<template>
    <div class="card h-100">
        <div class="card-body p-3">
            <b-overlay :show="showOverlay" spinner-variant="primary" opacity="0.4" rounded="sm">
                <div class="row view-filter mb-3">
                    <div class="col-3 col-lg-3 col-xl-3">
                        <div class="row">
                            <b-col xl="6" cols="6" class="pr-0 mt-1">
                                <!-- <label class="mb-0 mt-1"></label> -->
                                <label class="d-block pl-2 pt-1 mb-0 accordion-title" block href="javascript:">
                                    Attendance Date
                                </label>
                            </b-col>
                            <b-col xl="6" cols="6" class="pl-0">
                                <input type="date" class="form-control" v-model="searchDate" @keyup.enter="getAttendanceData()" id="dateWD" >
                            </b-col>
                        </div>
                    </div>
                    <div class="col-1 col-lg-1 col-xl-1 text-left">
                        <button type="submit" @click="getAttendanceData()" class="sbtn btn btn-primary btn-xs px-2 mr-1 mt-1">Go</button>
                    </div>
                </div>                
                <div class="row view-filter mb-3">
                    <div class="col-3 col-lg-3 col-xl-3">
                        <label class="d-block pl-2 pt-1 mb-0 accordion-title" block href="javascript:">Work Schedule</label>
                        <span class="mb-0 title-highlight px-2 py-1">{{ employeeAttendanceData.workShift }}</span>
                    </div>
                    <div class="col-3 col-lg-3 col-xl-3">
                        <label class="d-block pl-2 pt-1 mb-0 accordion-title" block href="javascript:">{{ workingDate | formatFullDate }}</label>
                        <span class="mb-0 title-highlight px-2 py-1">{{ employeeAttendanceData.workStartTime }} to {{ employeeAttendanceData.workEndTime }}</span>
                    </div>
                    <div class="col-3 col-lg-3 col-xl-3">
                        <label class="d-block pl-2 pt-1 mb-0 accordion-title" block href="javascript:">Work Hours</label>
                        <span v-if="employeeAttendanceData.workHour"class="mb-0 title-highlight px-2 py-1">{{ employeeAttendanceData.workHour}} Hours</span>
                        <span v-if="employeeAttendanceData.workMinute" class="mb-0 title-highlight px-2 py-1">{{ employeeAttendanceData.workMinute }} Minutes</span>
                    </div>                    
                    <div class="col-md-2 text-right">
                        <button v-if="!employeeAttendanceData.empAttendanceStatusId" type="button" @click="manageEmployeeAttendance()" class="btn sbtn w-auto btn-outline-primary mx-1">
                            Mark as Present
                        </button>
                        <button v-else type="button" disabled class="btn sbtn w-auto btn-primary mx-1">
                            <i class="fa fa-check pr-1" aria-hidden="true"></i>Present
                        </button>
                    </div>                    
                </div>
                <!-- <div class="separator mx-2"></div> -->
                <div v-if="!elements.isWorkScheduleStarted" class="card h-100 text-center mt-4">
                    <div class="card-body p-5">
                        <label class="text-info fs-16 mb-0">Daily Work Schedule not set for "{{ employeeAttendanceData.workPattern }}"</label>
                    </div>
                </div>
                <template v-if="employeeAttendanceData.empAttendanceStatusId">
                    <!-- <div class="card h-100">
                        <div class="card-body p-3"> -->
                            <!-- <label class="d-block p-2 my-0 accordion-title" block href="javascript:">
                                Work Start / Stop
                            </label>
                            <div class="separator mx-2"></div> -->
                            <div class="row text-left my-3 mt-4">
                                <div class="col-md-5">
                                    <div class="d-flex justify-content-start" role="group" aria-label="First group">
                                        <button v-if="btnControls.showStart" type="button" @click="addEmployeeAttendanceTimeline(1)" class="btn sbtn w-auto btn-primary mx-1">
                                            <i class="fa fa-play pr-1" aria-hidden="true"></i>Work Start
                                        </button>
                                        <button v-if="btnControls.showBreak" type="button" @click="addEmployeeAttendanceTimeline(2)" class="btn sbtn w-auto btn-primary mx-1">
                                            <i class="fa fa-pause pr-1" aria-hidden="true"></i>Work Break
                                        </button>
                                        <button v-if="btnControls.showResume" type="button" @click="addEmployeeAttendanceTimeline(3)" class="btn sbtn w-auto btn-primary mx-1">
                                            <i class="fa fa-play pr-1" aria-hidden="true"></i>Work Resume
                                        </button>
                                        <button v-if="btnControls.showEnd" type="button" @click="addEmployeeAttendanceTimeline(4)" class="btn sbtn w-auto btn-primary mx-1">
                                            <i class="fa fa-stop pr-1" aria-hidden="true"></i>Work End
                                        </button>
                                    </div>
                                </div>
                                <div class="col-md-2 text-center">
                                    <label class="my-2 mx-0 h6">{{ timerDuration }}</label>
                                </div>

                            </div>
                        <!-- </div>
                    </div> -->
                    <label class="d-block p-2 my-0 accordion-title" block href="javascript:">
                        Work Timeline
                    </label>
                    <div class="separator mx-2"></div>
                    <b-table show-empty small outlined stacked="md" :select-mode="selectMode"  sort-icon-left :items="attendanceTimelineList" :fields="attendanceTimelineFields" responsive="sm" :current-page="currentPageEmp" :per-page="perPage" :filter="filter" :filterIncludedFields="filterOn" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" @filtered="onFiltered">
                        <template #cell(index)="data">
                            {{ data.index + 1 }}
                        </template>
                        <template v-slot:cell(empTimelineStateEnum)="data">
                            <span v-if="data.value">{{ getTimelineState(data.value) }}</span>
                        </template>
                        <template v-slot:cell(empTimelineDt)="data">
                            <span v-if="data.value">{{ data.value | formatDateTimeAmPm }}</span>
                        </template>
                        <template v-slot:cell(nextEmpTimelineDt)="data">
                            <span v-if="data.value">{{ data.value | formatDateTimeAmPm }}</span>
                        </template>     
                        <template v-slot:cell(timeDiff)="data">
                            <span >{{ getTimeDiff(data.item.empTimelineDt,data.item.nextEmpTimelineDt, false) }}</span>
                        </template>                                                                                
                        <template v-slot:empty="scope">
                            <div class="text-center">No Record found</div>
                        </template>
                        <template v-slot:emptyfiltered="scope">
                            <div class="text-center">{{ scope.emptyFilteredText }}</div>
                        </template>
                    </b-table>
                    <span :class="'badge-secondary'" class="fs-11 badge badge-pill pointer text-center float-left">{{this.totalRowsEmp}} Items</span>
                    <b-pagination v-if="attendanceTimelineList.length!=0" pills v-model="currentPageEmp" :total-rows="totalRowsEmp" :per-page="perPage" first-number last-number align="fill" size="sm" class="my-0 pull-right"></b-pagination>
                </template>
            </b-overlay>
        </div>
    </div>
</template>
<script>
import iziToast from 'izitoast';
import moment from 'moment';
import { required, email, minLength, maxLength, sameAs } from "vuelidate/lib/validators";
import enummixin from "@/mixins/enummixin";
import { EventBus } from '@/main';
export default {
    name: 'app',
    mixins: [enummixin],
    data() {
        return {
            showOverlay: false,
            elements: {
                showDetail: false,
                isWorkScheduleStarted: true,
            },

            btnControls: {
                showStart: true,
                showBreak: false,
                showResume: false,
                showEnd: false,
            },
           
            vhextAccountId: localStorage.getItem("vhextAccountId"),
            userId: localStorage.getItem("userId"),

            searchDate: this.getDateValue(new Date()),
            workingDate: this.getDateValue(new Date()),
            startTime: '',
            timerDuration: '00:00:00',
            timerValue: 0,

            employeeAttendance: {
                id: '',
                empAttendanceTimelineId: '',
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                workPattern: '',
                employeeAttendanceId: '',
                humanResourceId: '',
                empTimelineStateEnum: '',
                empTimelineDt: '',
                nextEmpTimelineDt: '',
                createdById: localStorage.getItem("userId"),
                workingDayId: '',
                leaveTypeId: '',
                leaveTimeId: '',
                hrmLeaveTypeId: '',
                remark: '',
                empAttendanceStatusId: '',
                empStatusLookupId: '',
                statusLookupId: '',
            },

            employeeAttendanceData: [],

            attendanceTimelineList: [],
            attendanceTimelineFields: [
                { key: 'index', label: 'SN' },
                { key: 'empTimelineStateEnum', label: 'Timeline State', sortable: true, sortDirection: 'desc' },
                { key: 'task', label: 'Task', sortable: true, sortDirection: 'desc' },
                { key: 'empTimelineDt', label: 'From', sortable: true, sortDirection: 'desc' },
                { key: 'nextEmpTimelineDt', label: 'Till', sortable: true, sortDirection: 'desc' },
                { key: 'timeDiff', label: 'Time Diff (HH:MM)', sortable: true, sortDirection: 'desc' },
                // { key: 'actions', label: 'Action'},
            ],

            selectMode: 'single',
            totalRows: 1,
            totalRowsEmp: 0,
            currentPage: 1,
            currentPageEmp: 1,
            perPage: 10,
            pageOptions: [10, 15, 20],
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',
            filter: null,
            filterOn: [],
            deleteParam: {
                id: 0
            }
        }
    },
    computed: {
        // attendanceTimelineList() {
        //     return this.timelineList.sort((a, b) => a - b);
        // }
    },
    mounted() {
        this.getAttendanceData();
    },
    beforeDestroy() {
        this.clearTimerVal();
    },
    methods: {
        getAttendanceData: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
                userId: this.userId,
                workingDate: this.searchDate,
            }
            this.workingDate = this.searchDate;
            this.elements.isWorkScheduleStarted = true;
            this.showOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/company/dailyemployeeattendance/view', data)
                .then((response) => {
                    this.showOverlay = false;
                    let result = response.data;
                    this.employeeAttendanceData = result.employeeAttendance;
                    // this.$nextTick(() => {
                        // if (this.employeeAttendanceData.workingDayId) {
                            this.elements.isWorkScheduleStarted = true;
                            this.getTimelineList();
                        // }
                        // else {
                        //     this.elements.isWorkScheduleStarted = false;
                        // }
                    // })
                })
                .catch(error => {
                    this.showOverlay = false;
                    iziToast.error({
                       title: 'Error',
                       message: 'Error',
                       position: 'topRight'
                   });
                })
        },
        getTimelineList: function() {
            if (this.employeeAttendanceData.employeeAttendanceId) {
                let data = {
                    vhextAccountId: this.vhextAccountId,
                    humanResourceId: this.employeeAttendanceData.humanResourceId,
                    empTimelineDt: this.getDateValue(this.searchDate)
                }
                this.showOverlay = true;
                axios.post(this.$store.getters.getAPIBasePath + '/resource/empattendancetimeline/list', data)
                    .then((response) => {
                        this.showOverlay = false;

                        let result = response.data;

                        this.attendanceTimelineList = result.empAttendanceTimelineList;
                        this.totalRowsEmp = this.attendanceTimelineList.length;
                        this.$nextTick(() => {
                            this.setBtnControls();
                        })

                    })
                    .catch(error => {
                        this.showOverlay = false;
                        iziToast.error({
                           title: 'Errorss',
                           message: 'Error',
                           position: 'topRight'
                       });
                    })
            }
            else {
                this.attendanceTimelineList = [];
                this.setBtnControls();
            }
        },
        manageEmployeeAttendance: function() {
            this.$nextTick(() => {
                this.clearTimerVal();
            });
            this.showOverlay = true;
            // this.employeeAttendance.id = data.id;
            // this.employeeAttendance.empAttendanceTimelineId = data.empAttendanceTimelineId;
            this.employeeAttendance.vhextAccountId = localStorage.getItem("vhextAccountId");
            this.employeeAttendance.employeeAttendanceId = this.employeeAttendanceData.employeeAttendanceId;
            this.employeeAttendance.humanResourceId = this.employeeAttendanceData.humanResourceId;
            this.employeeAttendance.workingDate = this.workingDate;
            this.employeeAttendance.createdById = this.employeeAttendanceData.createdById;
            this.employeeAttendance.workingDayId = this.employeeAttendanceData.workingDayId;
            // this.employeeAttendance.leaveTypeId = this.employeeAttendanceData.leaveTypeId;
            // this.employeeAttendance.leaveTimeId = this.employeeAttendanceData.leaveTimeId;
            // this.employeeAttendance.hrmLeaveTypeId = this.employeeAttendanceData.hrmLeaveTypeId;
            // this.employeeAttendance.remark = this.employeeAttendanceData.remark;

            // statusLookupId = 4 denotes employee present status
            this.employeeAttendance.empStatusLookupId = 4;
            
            axios.post(this.$store.getters.getAPIBasePath + '/company/employeeattendance/manage', this.employeeAttendance)
                .then((response) => {
                    this.showOverlay = false;
                    let result = response.data;
                    if (result.status == "CREATED") {
                        // iziToast.success({
                        //     title: 'Success',
                        //     message: result.message,
                        //     position: 'topRight'
                        // });
                        this.getAttendanceData();
                        this.$nextTick(() => {
                            EventBus.$emit('onEmpAttTimelineUpdate');
                        })
                    }
                })
                .catch(error => {
                    this.showOverlay = false;
                    iziToast.error({
                       title: 'Error',
                       message: 'Error',
                       position: 'topRight'
                   });
                })
        },
        addEmployeeAttendanceTimeline: function(empTimelineStateEnum) {
            this.$nextTick(() => {
                this.clearTimerVal();
            });
            this.showOverlay = true;
            // this.employeeAttendance.id = data.id;
            // this.employeeAttendance.empAttendanceTimelineId = data.empAttendanceTimelineId;
            this.employeeAttendance.vhextAccountId = localStorage.getItem("vhextAccountId");
            this.employeeAttendance.employeeAttendanceId = this.employeeAttendanceData.employeeAttendanceId;
            this.employeeAttendance.humanResourceId = this.employeeAttendanceData.humanResourceId;
            this.employeeAttendance.taskId = null;
            this.employeeAttendance.empTimelineDt = new Date();
            this.employeeAttendance.nextEmpTimelineDt = new Date();
            this.employeeAttendance.workingDate = this.workingDate;
            this.employeeAttendance.createdById = this.employeeAttendanceData.createdById;
            this.employeeAttendance.workingDayId = this.employeeAttendanceData.workingDayId;
            this.employeeAttendance.leaveTypeId = this.employeeAttendanceData.leaveTypeId;
            this.employeeAttendance.leaveTimeId = this.employeeAttendanceData.leaveTimeId;
            this.employeeAttendance.hrmLeaveTypeId = this.employeeAttendanceData.hrmLeaveTypeId;
            // this.employeeAttendance.remark = this.employeeAttendanceData.remark;
            this.employeeAttendance.empTimelineStateEnum = empTimelineStateEnum;

            // statusLookupId = 4 denotes employee present status
            this.employeeAttendance.statusLookupId = 4;
            
            axios.post(this.$store.getters.getAPIBasePath + '/resource/empattendancetimeline/add', this.employeeAttendance)
                .then((response) => {
                    this.showOverlay = false;
                    let result = response.data;
                    if (result.status == "CREATED") {
                        // iziToast.success({
                        //     title: 'Success',
                        //     message: result.message,
                        //     position: 'topRight'
                        // });
                        this.getAttendanceData();
                        this.$nextTick(() => {
                            EventBus.$emit('onEmpAttTimelineUpdate');
                        })
                    }
                })
                .catch(error => {
                    this.showOverlay = false;
                    iziToast.error({
                       title: 'Error',
                       message: 'Error',
                       position: 'topRight'
                   });
                })
        },
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },
        getTimelineState(id) {
            let result = this.empTimelineStateList.find(data => data.id == id);
            return result ? result.value : '';
        },
        // getTimeDiff(startDT,endDT) {

        //     var diffTime = "";
            
        //     if(endDT == null){
        //         diffTime = "";
        //     }
        //     else{
        //         const date1 = new Date(startDT);
        //         const date2 = new Date(endDT);

        //         const diffhours = parseInt(Math.abs(date2 - date1) / (1000 * 60 * 60) % 24);
        //         const diffMins = parseInt(Math.abs(date2 - date1) / (1000 * 60) % 60);
        //         diffTime = ('0' + diffhours).slice(-2) + ":" + ('0' + diffMins).slice(-2);
        //     }
        //     return diffTime;

        // },        
        setBtnControls() {
            // var index = 0;
            // let maxIndex;
            // for (let i=0; i<=index;i++){
            //     if (this.attendanceTimelineList[i].id > index) {
            //         maxIndex = i;
            //     }
            // }
            this.$nextTick(() => {
                if(this.attendanceTimelineList.length > 0) {
                    let lastIndex = this.attendanceTimelineList.length-1;

                    if (this.attendanceTimelineList[lastIndex].empTimelineStateEnum == 1) {
                        this.btnControls.showStart = false;
                        this.btnControls.showBreak = true;
                        this.btnControls.showResume = false;
                        this.btnControls.showEnd = true;
                        this.startTime = this.attendanceTimelineList[lastIndex].empTimelineDt;
                        this.pageTitleClock();
                    }
                    else if (this.attendanceTimelineList[lastIndex].empTimelineStateEnum == 2) {
                        this.btnControls.showStart = false;
                        this.btnControls.showBreak = false;
                        this.btnControls.showResume = true;
                        this.btnControls.showEnd = true;
                        this.startTime = this.attendanceTimelineList[lastIndex].empTimelineDt;
                        this.pageTitleClock();
                    }
                    else if (this.attendanceTimelineList[lastIndex].empTimelineStateEnum == 3) {
                        this.btnControls.showStart = false;
                        this.btnControls.showBreak = true;
                        this.btnControls.showResume = false;
                        this.btnControls.showEnd = true;
                        this.startTime = this.attendanceTimelineList[lastIndex].empTimelineDt;
                        this.pageTitleClock();
                    }
                    else if (this.attendanceTimelineList[lastIndex].empTimelineStateEnum == 4) {
                        this.btnControls.showStart = true;
                        this.btnControls.showBreak = false;
                        this.btnControls.showResume = false;
                        this.btnControls.showEnd = false;                        
                    }
                }
                else {
                    this.btnControls.showStart = true;
                    this.btnControls.showBreak = false;
                    this.btnControls.showResume = false;
                    this.btnControls.showEnd = false;
                }
            })
        },
        pageTitleClock: function() {
            // console.log(this.timerDuration, this.timerValue)
            this.timerDuration = this.startTime ? this.getTimeDiff(new Date(), this.startTime, true) : '00:00:00';
            this.timerValue = setTimeout(this.pageTitleClock, 1000);
        },
        clearTimerVal() {
            clearTimeout(this.timerValue);
            this.startTime = '';
            this.timerDuration = '00:00:00';
        }
    }
}
</script>