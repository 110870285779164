export default ({
    state: {
        apibasepath: process.env.VUE_APP_BASEURL,
        local_storage_name: process.env.VUE_APP_LOCALSTORAGE_NAME  || 'userAuthData',
        userdata: {},
        authToken: localStorage.getItem(process.env.VUE_APP_LOCALSTORAGE_NAME || 'userAuthData') || null,
        companyLogo: '',
        appLoading: false,
        appLoadingContent: '',
        appReloadKey: 0,
        erm_data: {
            erm_id: '',
            erm_title: '',
        },
        userManagementData: {
            userTypeId: '',
            userRoleId: '',
        },
        erd_data: {
            erd_id: '',
        },
        meetingMinute: {
            eventId: null,
            calendarEventId: null,
            meetingDate: null,
            isMOM: false,
        },
        adminSetting: {
            moduleId: null,
            subModuleId: null,
            subModule: null,
            showComponent: false,
        },
        menuData: {
            standardMenuId: '',
            menuTitle: '',
            functionalInfo: '',
        },
        actionItemData: {
            selectedDate: null,
            daysToShow: 7,
        },
        configloaded: 0,
        default_redirects: {
            // fnm: '/fnm/landing',
            // cli: '/cli/landing',
            // cmr: '/cmr/landing',
            // crp: '/crp/landing',
            // hrm: '/hrm/landing',
            // mrp: '/mrp/landing',
            // prm: '/prm/landing',
            // shl: '/shl/landing',
            // emp: '/emp/landing',
            // tmb: '/tmb/landing',
            // vsa: '/vsa/landing',
            // vho: '/vsa/landing',
            general: '/emp/landing'
        }

    },
    getters: {
        getAPIBasePath(state) {
            return state.apibasepath;
        },
        getDefaultRedirects(state) {
            return state.default_redirects;
        },
        isConfigLoaded(state) {
            if (state.configloaded == 1) {
                return true;
            }
            return false;
        },
        currentUser(state) {
            return state.userdata;
        },
        getLocalStorageName(state) {
            return state.local_storage_name;
        },
        getAuthToken(state) {
            return state.authToken;
        },
        getERMData(state) {
            return state.erm_data;
        },
        
    },
    mutations: {
        setAuthToken(state, token) {
            state.authToken = token;
            localStorage.setItem(state.local_storage_name, token);
        },
        clearAuthToken(state) {
            state.authToken = null;
            localStorage.removeItem(state.local_storage_name);
        },
        incrementAppReloadKey(state) {
            state.appReloadKey += 1;
        },
        saveUserData(state, payload) {
            state.userdata = payload;
        },
        markAsConfigLoaded(state, flag) {
            state.configloaded = flag;
        },
        setERMData(state, ermData) {
            state.erm_data.erm_id = ermData.ermId;
            state.erm_data.erm_title = ermData.ermTitle;
        },
    },
    actions: {
        reloadApp({ commit }) {
            commit('incrementAppReloadKey');
        },
        doLogout(context) {
            context.commit("clearAuthToken", context.state);
            localStorage.clear();
            localStorage.removeItem(context.getters.getLocalStorageName);
            localStorage.removeItem("projectId");
            localStorage.removeItem("currency");
            localStorage.removeItem("vhextAccountId");
            localStorage.removeItem("parentVhextAccountId");
            localStorage.removeItem("isPartOfCorporateGroup");
            localStorage.removeItem("isParentCompany");
            localStorage.removeItem("isPrimaryRole");
            localStorage.removeItem("userId");
            localStorage.removeItem("userTypeId");
            localStorage.removeItem("userName");
            localStorage.removeItem("userImage");
            localStorage.removeItem("vmxServiceCode");
            localStorage.removeItem("userTypeCode");
            localStorage.removeItem("vcaOwnerId");
            localStorage.removeItem("isVcaOwner");
            localStorage.removeItem("logo");
            localStorage.removeItem("pageRedirectURL");
            localStorage.removeItem("effortHourDay");

            context.commit("markAsConfigLoaded", 0);
        },
        setupLogin(context, resultset) {
            return new Promise((resolve, reject) => {
                localStorage.removeItem(context.getters.getLocalStorageName);
                context.commit("saveUserData", resultset.data);
                context.commit("setAuthToken", resultset.data.token);

                localStorage.setItem(context.getters.getLocalStorageName, resultset.data.token);
                localStorage.setItem("vhextAccountId", resultset.data.vhextAccountId);
                localStorage.setItem("parentVhextAccountId", resultset.data.parentVhextAccountId ?? '');
                localStorage.setItem("isPartOfCorporateGroup", resultset.isPartOfCorporateGroup);
                localStorage.setItem("isParentCompany", resultset.data.isParentCompany);
                localStorage.setItem("isPrimaryRole", resultset.data.isPrimaryRole ?? false);
                localStorage.setItem("userId", resultset.data.userId);
                localStorage.setItem("userTypeId", resultset.data.userTypeId);
                localStorage.setItem("userTypeCode", resultset.data.userType);
                localStorage.setItem("userName", resultset.data.name);
                localStorage.setItem("userImage", resultset.data.photo);
                localStorage.setItem("logo", resultset.data.logo);
                localStorage.setItem("vcaOwnerId", resultset.data.vcaOwnerId);
                localStorage.setItem("isVcaOwner", resultset.data.vcaOwner);
                localStorage.setItem("currency", resultset.data.currencySymbol);
                localStorage.setItem("effortHourDay", resultset.data.effortHourDay);

                resolve("true");
            });
        },
        setERMData(context, ermData) {
            context.commit("setERMData", ermData);
        },
    },
    modules: {}
})