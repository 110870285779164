<template>
    <div class="fixed-inner-header">
        <div class="container-fluid">
            <div class="row inner-header">
                <div class="col-12">
                    <div class="row  pt-1">
                        <div class="col-lg-4 col-sm-4">
                            <!-- <h4 class="pl-2 mb-0 fs-16">{{this.ermTitle}}</h4> -->
                            <h4 class="pl-2 mb-0 fs-16">Time Sheet Report</h4>
                        </div>
                        <div class="col-lg-4 col-sm-4">
                            <h4 class="pl-2 mb-0 fs-14 text-center"><span class="text-muted">Time Sheet Report</span></h4>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            <nav class="breadcrumb-container d-sm-block d-lg-inline-block float-right" aria-label="breadcrumb">
                                <ol class="breadcrumb pt-0 mb-0">
                                    <li class="breadcrumb-item">
                                        <a href="javascript:" @click="getPrevUrl()"><i class="fa fa-reply pr-2" aria-hidden="true"></i>Back</a>
                                    </li>
                                    <li v-if="projId" class="breadcrumb-item">
                                        <h4 class="pl-2 mb-0 fs-14 text-right">#{{this.projId}} - {{this.projectName}}</h4>
                                    </li>
                                </ol>
                            </nav>
                        </div>

                    </div>
                    <div class="separator mb-2"></div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-12 col-xl-12">
                    <div class="card mb-4">
                        <div class="card-body p-3">
                            <div id="scope-app" class="mb-3">
                                <b-card no-body class="">
                                    <b-card-header header-tag="header" class="p-0 accordion-header" role="tab">
                                        <label class="d-block p-2 accordion-title" block href="#" v-b-toggle.accordion-main>
                                            <span><i class="fa fa-filter action-link pr-2" aria-hidden="true"></i>Filter</span>
                                        </label>
                                    </b-card-header>
                                    <b-collapse id="accordion-main" accordion="my-accordion" v-model="elements.visible" role="tabpanel">
                                        <b-card-body class="p-2">
                                            <div class="row mb-2">
                                                <div class="col-3">
                                                    <div class="form-group">
                                                        <label for="preparedBy">Prepared By</label>
                                                        <select v-model="timeSheet.preparedById" class="form-control">
                                                            <option value="">Select</option>
                                                            <option v-for="(info, index) in users" :value="info.id">{{info.value}}</option>
                                                        </select>
                                                    </div>
                                                </div>
<!--                                                 <div class="col-3">
                                                    <div class="form-group">
                                                        <label for="taskId">Task</label>
                                                        <select v-model="timeSheet.taskId" class="form-control">
                                                            <option value="">Select</option>
                                                            <option v-for="(info, index) in tasks" :value="info.id">{{info.value}}</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div class="col-2">
                                                    <div class="form-group">
                                                        <label for="activityId">Activity</label>
                                                        <select v-model="timeSheet.activityId" class="form-control">
                                                            <option value="">Select</option>
                                                            <option v-for="(info, index) in activities" :value="info.id">{{info.value}}</option>
                                                        </select>
                                                    </div>
                                                </div> -->
                                                <div class="col-2">
                                                    <div class="form-group">
                                                        <label>Start Date</label>
                                                        <input type="date" class="form-control" v-model="timeSheet.startDate">
                                                    </div>
                                                </div>
                                                <div class="col-2">
                                                    <div class="form-group">
                                                        <label>End Date</label>
                                                        <input type="date" class="form-control" v-model="timeSheet.endDate">
                                                    </div>
                                                </div>
                                            </div>
                                            <button type="submit" @click="getTimeSheetReports" class="sbtn btn btn-primary mb-0 mr-3 pt-1 pb-1 pl-3 pr-3"><i class="fa fa-floppy-o pr-2"></i>Filter</button>
                                            <a href="javascript:" title="Reset Filter" @click="clearData"><i class="fa fa-refresh" aria-hidden="true"></i></a>
                                        </b-card-body>
                                    </b-collapse>
                                </b-card>
                            </div>
                            <div class="row view-filter">
                                <div class="col-sm-12">
                                    <div class="float-right">
                                        <div class="dataTables_length">
                                            <b-form-group label="Per page" label-cols-sm="6" label-align-sm="right" label-size="sm" label-for="perPageSelect" class="mb-0">
                                                <b-form-select v-model="perPage" id="perPageSelect" size="sm" :options="pageOptions"></b-form-select>
                                            </b-form-group>
                                        </div>
                                    </div>
                                    <div class="float-left">
                                        <div class="dataTables_filter">
                                            <label>
                                                <input type="search" v-model="filter" class="form-control form-control-sm" placeholder="Search...">
                                            </label>
                                        </div>
                                    </div>
                                    <div class="clearfix"></div>
                                </div>
                            </div>
                            <b-table show-empty small stacked="md" sort-icon-left :items="timeSheets" :fields="timeSheetFields" responsive="sm" :current-page="currentPage" :per-page="perPage" :filter="filter" :filterIncludedFields="filterOn" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" @filtered="onFiltered">
                                <template v-slot:cell(title)="data">
                                    <a class="tooltiptext action-link" href="#" @click="viewItemDetails(data.item)">{{data.value}}</a>
                                </template>
                                
                                <template v-slot:cell(actEffortMins)="data">
                                    <div v-if="data.item.actEffortMins" >
                                        {{convertToHM(data.item.actEffortMins)}}
                                    </div>
                                </template>
                                <template v-slot:cell(status)="data">
                                    <div>
                                      <b-badge v-if="data.item.status == 'To Do'" variant="primary">{{data.value}}</b-badge>
                                      <b-badge v-else-if="data.item.status == 'In Progress'" variant="secondary">{{data.value}}</b-badge>
                                      <b-badge v-else-if="data.item.status == 'Completed'" variant="info">{{data.value}}</b-badge>
                                      <b-badge v-else-if="data.item.status == 'Closed'" variant="success">{{data.value}}</b-badge>
                                      <b-badge v-else-if="data.item.status == 'On Hold'" variant="warning">{{data.value}}</b-badge>
                                      <b-badge v-else-if="data.item.status == 'Cancelled'" variant="danger">{{data.value}}</b-badge>
                                    </div>
                                </template>
                                <template v-slot:empty="scope">
                                    <div class="text-center">No Time Sheets found</div>
                                </template>
                                <template v-slot:emptyfiltered="scope">
                                    <div class="text-center">{{ scope.emptyFilteredText }}</div>
                                </template>
<!--                                 <template v-slot:custom-foot="data">
                                    <div class="col-sm-12 col-md-7 pl-0 mt-1">
                                        <b-tr >
                                            <div class="text-center">{{getDuration}}</div>
                                        </b-tr>
                                    </div>
                                </template> -->
                            </b-table>
                            <b-pagination v-if="timeSheets.length!=0" pills v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="fill" size="sm" class="my-0 pull-right"></b-pagination>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <TimesheetDetailPopup v-if="showDialog" ref="timesheetDetailPopupRef" :detailViewData="detailViewData" :referenceDetailId="timesheetPopupId" @closeDialog="closeDialog" />
    </div>
</template>
<script>
import iziToast from 'izitoast';
import moment from 'moment';
import TimesheetDetailPopup from '@/components/popup/itemdetail/TimesheetDetailPopup.vue';
import { required, email, minLength, maxLength, sameAs } from "vuelidate/lib/validators";
export default {
    name: 'app',
    components: {
        TimesheetDetailPopup,
    },
    data() {
        return {
            elements: {
                visible: false,
            },
            activityPopupTitle: 'Activity Settings',
            entity:'time_sheet',
            vhextAccountId: localStorage.getItem("vhextAccountId"),
            projectId: localStorage.getItem("projectId"),
            projectTypeId: localStorage.getItem("projectTypeId"),

            projId: localStorage.getItem("projId"),
            projectName: localStorage.getItem("projectName"),
            
            entityRecordMasterId: this.$route.params.ermId,
            ermTitle: localStorage.getItem("ermTitle"),
            userId: localStorage.getItem("userId"),

            fromDate: this.getDateValue(new Date()),
            tillDate: this.getDateValue(new Date()),


            effortTypes: [
                { name: "Man Hours", id: 1 },
                { name: "Man Days", id: 2 },
                { name: "Man Months", id: 3 },
                { name: "Man Years", id: 4 },
            ],

            showDialog: false,

            timeSheet: {
                id: '',
                timeSheetId: '',
                vhextAccountId: this.vhextAccountId,
                projectId: this.projectId,
                entityRecordMasterId: this.entityRecordMasterId,
                title: '',
                taskId: '',
                statusId: '',
                status: '',
                activityId: '',
                activity: '',
                preparedById: '',
                preparedBy: '',
                startDate: this.getDateValue(new Date()),
                endDate: this.getDateValue(new Date()),
                description: '',
                createdDt: '',
                lastUpdatedDt: '',
                entity: this.entity
            },
            users: [],
            tasks: [],
            activities: [],
            timeSheetStatus: [],

            detailViewData: {},
            timesheetPopupId: 0,

            timeSheets: [],
            timeSheetFields: [
                { key: 'timeSheetId', label: 'Id', sortable: true, sortDirection: 'desc' },
                { key: 'title', label: 'Title', sortable: true, sortDirection: 'desc' },
                { key: 'activity', label: 'Activity', sortable: true, sortDirection: 'desc' },
                { key: 'taskTitle', label: 'Task', sortable: true, sortDirection: 'desc' },
                { key: 'startDate', label: 'Start Date', formatter: "formatDateTimeAmPm", sortable: true, sortDirection: 'desc' },
                { key: 'endDate', label: 'End Date', formatter: "formatDateTimeAmPm", sortable: true, sortDirection: 'desc' },
                { key: 'dateTimeDiff', label: 'Duration [DD:HH:MM]', sortable: true, sortDirection: 'desc' },
                { key: 'actEffortMins', label: 'Actual Effort', sortable: true, sortDirection: 'desc' },
                { key: 'preparedBy', label: 'Prepared By', sortable: true, sortDirection: 'desc' },
                { key: 'status', label: 'Task Status', sortable: true, sortDirection: 'desc' },
            ],

            totalRows: 1,
            currentPage: 1,
            perPage: 10,
            pageOptions: [10, 15,20,25,50],
            
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',
            filter: null,
            filterOn: [],

            infoModal: {
                id: 'info-modal',
                title: '',
                content: ''
            },
            deleteParam: {
                id: 0
            }
        }
    },
    computed: {
        getDuration() {

            return this.timeSheets.reduce((accum, item) => {

                return accum +
                parseInt(Math.abs(new Date(item.endDate).getTime() - new Date(item.startDate).getTime()) / (1000 * 60) % 60);
            }, 0)
        //     return accum + (item.endDate.getTime() - item.startDate.getTime() / 60000)
        // }, 0.00)
        }

    },    
    mounted() {
        this.initialSettings();
        this.lookupData();
        this.getTimeSheetReports();
    },
    methods: {
        initialSettings: function() {
            this.vhextAccountId = localStorage.getItem("vhextAccountId");
            this.projectId = localStorage.getItem("projectId");
            this.entityRecordMasterId = this.$route.params.ermId;
            this.entity = 'time_sheet';
            this.userId = localStorage.getItem("userId");
        },
        getTimeSheetReports: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
                projectId: this.projectId,
                // entityRecordMasterId: this.entityRecordMasterId,
                preparedById: Number(this.timeSheet.preparedById),
                taskId: Number(this.timeSheet.taskId),
                activityId: Number(this.timeSheet.activityId),
                startDate: this.timeSheet.startDate,
                endDate: this.timeSheet.endDate,
            }
            axios.post(this.$store.getters.getAPIBasePath + '/task/timesheetreport/list',data)
                .then((response) => {
                    let result = response.data;
                    if (result.timeSheetReportList) {
                        this.timeSheets = result.timeSheetReportList;
                        this.totalRows = this.timeSheets.length; 
                    }                   
                })
        },
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },
        lookupData: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
                projectId: this.projectId,
                projectTypeId: this.projectTypeId,
                entityRecordMasterId: this.entityRecordMasterId,
                entity: this.entity
            }
            axios.post(this.$store.getters.getAPIBasePath + '/task/timesheet/lookup/all', data)
                .then((response) => {
                    let result = response.data;
                    this.tasks = result.taskLookup;
                    this.activities = result.activityLookup;
                    this.timeSheetStatus = result.statusLookup;
                    this.users = result.userLookup;
                })
        },
        clearData: function() {
            this.elements.visible = false;
            this.timeSheet.taskId = 0;
            this.timeSheet.preparedById = 0;
            this.timeSheet.activityId = 0;
            this.timeSheet.startDate = this.getDateValue(new Date());
            this.timeSheet.endDate = this.getDateValue(new Date());
            this.getTimeSheetReports();
        },
        getEffortType: function(effortTypes, type) {
            let effortType = this.effortTypeFilter(effortTypes, type);
            if (effortType.length > 0) {
                return effortType[0].name;
            }
        },
        viewItemDetails: function(data) {
            console.log(data);
            this.detailViewData = data;
            this.timesheetPopupId = data.id;
            this.showDialog= true;
            this.$nextTick(()=> {
                this.$refs.timesheetDetailPopupRef.getDetails(this.timesheetPopupId, null);
            })
        },
        closeDialog: function() {
            console.log('closed');
            this.showDialog = false;
        }

    }
}
</script>