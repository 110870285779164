<template>
    <div class="fixed-inner-header">
        <div class="container-fluid">
            <div class="row inner-header">
                <div class="col-12">
                    <div class="row">
                        <div class="col-lg-8 col-sm-6">
                            <h4 class="pl-2 mb-0 fs-16">Employee Position Change</h4>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            <nav class="breadcrumb-container d-sm-block d-lg-inline-block float-right" aria-label="breadcrumb">
                                <ol class="breadcrumb pt-0 mb-0">
                                    <li class="breadcrumb-item">
                                        <a href="javascript:" @click="getPrevUrl()"><i class="fa fa-reply pr-2" aria-hidden="true"></i>Back</a>
                                    </li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                    <div class="separator mb-2"></div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-4 col-xl-4">
                    <div class="card mb-4">
                        <div class="card-body p-3">
                            <b-tabs content-class="mt-3" lazy>
                                <b-tab title="Employees">
                                    <div class="row view-filter">
                                        <div class="col-sm-12">
                                            <div class="float-right">
                                                <div class="dataTables_length">
                                                    <b-form-group label="Per page" label-cols-sm="6" label-align-sm="right" label-size="sm" label-for="perPageSelect" class="mb-0">
                                                        <b-form-select v-model="perPage" id="perPageSelect" size="sm" :options="pageOptions"></b-form-select>
                                                    </b-form-group>
                                                </div>
                                            </div>
                                            <div class="float-left">
                                                <div class="dataTables_filter">
                                                    <label>
                                                        <input type="search" v-model="filter" class="form-control form-control-sm" placeholder="Search...">
                                                    </label>
                                                </div>
                                            </div>
                                            <div class="clearfix"></div>
                                        </div>
                                    </div>
                                    <b-table ref="employeeTable" v-model="mainTableArray" show-empty small outlined head-variant=:primary sticky-header="65vh" selectable :select-mode="selectMode" sort-icon-left :items="employeeList" @row-selected="onRowSelected" :fields="employeeFields" responsive="sm" :filter="filter" :filterIncludedFields="filterOn" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" @filtered="onFiltered">

                                        <template #cell(index)="data">
                                            {{ data.index + 1 }}
                                        </template>                                                                               
                                        <template v-slot:empty="scope">
                                            <div class="text-center">No Employees found</div>
                                        </template>
                                        <template v-slot:emptyfiltered="scope">
                                            <div class="text-center">{{ scope.emptyFilteredText }}</div>
                                        </template>
                                    </b-table>
        
                                    <span :class="'badge-secondary'" class="fs-11 badge badge-pill pointer text-center float-left">{{this.totalRows}} Items</span>

<!--                                     <b-pagination v-if="employeeList.length!=0" pills v-model="currentPage" :total-rows="totalRows" :per-page="perPage" first-number last-number align="fill" size="sm" class="my-0 pull-right"></b-pagination> -->
                                </b-tab>
                            </b-tabs>

                        </div>
                    </div>
                </div>

                <div class="col-lg-8 col-xl-8 pl-0">
                    <div class="card mb-4">
                        <div class="card-body p-3">
                            <b-overlay :show="showOverlay" spinner-variant="primary" opacity="0.4" rounded="sm">
                                <!-- <b-tabs content-class="mt-3" lazy> -->
                                    <!-- <b-tab title="User Access Privileges"> -->
                                    <b-card v-if="hasWorkflowConfig==1" no-body class="mb-3">
                                        <b-card-header header-tag="header" class="p-0 accordion-header" role="tab">
                                            <label class="d-block p-2 accordion-title" block href="#" v-b-toggle.accordion-1>
                                                <i class="fa fa-random pr-2 action-link"></i>Employee Position Change <span> - {{ employeeName }}</span>
                                            </label>
                                        </b-card-header>
                                        <b-collapse id="accordion-1" accordion="my-accordion" v-model="elements.visible" role="tabpanel">
                                            <b-card-body class="py-1 px-3">
                                                <div class="row mb-0">
                                                    <div class="col-6">
                                                        <div class="form-group">
                                                            <label for="designationIdEmp">Designation</label>
                                                            <select v-model="employmentChange.designationId" class="form-control" id="designationIdEmp" >
                                                                <option value="">Select</option>
                                                                <option v-for="(info, index) in designations" :value="info.id" :key="index">{{info.value}}</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div class="col-6">
                                                        <div class="form-group">
                                                            <label for="departmentIdEmp">Department</label>
                                                            <select v-model="employmentChange.departmentId" class="form-control" id="departmentIdEmp" >
                                                                <option value="">Select</option>
                                                                <option v-for="(info, index) in departments" :value="info.id" :key="index">{{info.value}}</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div class="col-6">
                                                        <div class="form-group">
                                                            <label for="jobCategoryIdEmp">Job Category</label>
                                                            <select v-model="employmentChange.jobCategoryId" class="form-control" id="jobCategoryIdEmp" >
                                                                <option value="">Select</option>
                                                                <option v-for="(info, index) in jobCategories" :value="info.id" :key="index">{{info.value}}</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div class="col-3">
                                                        <div class="form-group">
                                                            <label>Salary (per annum)</label>
                                                            <VhextMoneyControl :value="Math.abs(Number(employmentChange.salary))" @money-input="moneyControlInput" />
                                                        </div>
                                                    </div>
                                                    <div class="col-3">
                                                        <div class="form-group">
                                                            <label>Salary (per hour)</label>
                                                            <VhextMoneyControl :value="Math.abs(Number(employmentChange.hourlySalary))" @money-input="moneyControlInputHourly" />
                                                        </div>
                                                    </div>                                                    
                                                    <div class="col-12 col-sm-6">
                                                        <div class="form-group">
                                                            <label for="wefDate">Effective From</label>
                                                            <input type="date" v-model="employmentChange.wefDate" class="form-control" id="wefDate">
                                                        </div>
                                                    </div>
                                                    <div class="col-6">
                                                        <div class="form-group">
                                                            <label for="reportingToId">Reporting To</label>
                                                            <select v-model="employmentChange.reportingToId" class="form-control">
                                                                <option value="">Select</option>
                                                                <option v-for="(info, index) in users" :value="info.id">{{info.value}}</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div class="col-6">
                                                        <div class="form-group">
                                                            <label for="employmentChangeById">Emp. Position Responsibility</label>
                                                            <select v-model="employmentChange.employmentChangeById" class="form-control">
                                                                <option value="">Select</option>
                                                                <option v-for="(info, index) in users" :value="info.id">{{info.value}}</option>
                                                            </select>
                                                        </div>
                                                    </div>      
                                                    <div class="col-6">
                                                        <div class="form-group">
                                                            <label for="statusId">Change Request Status</label>
                                                            <div class="required-field-block" :class="{ 'is-invalid': $v.employmentChange.statusId.$error }">
                                                                <select v-model="employmentChange.statusId" class="form-control" :class="{ 'is-invalid': $v.employmentChange.statusId.$error }">
                                                                    <option value="">Select</option>
                                                                    <option v-for="(info, index) in empPositionChangeSatus" :value="info.id">{{info.value}}</option>
                                                                </select>
                                                                <div class="required-icon"></div>
                                                            </div>  
                                                            <label v-if="$v.employmentChange.statusId.$error && !$v.employmentChange.statusId.required" class="text-danger">Please select status</label>
                                                        </div>
                                                    </div>                                                                                                                                                      
                                                    <div class="col-12">
                                                        <div class="form-group">
                                                            <AttachmentComponent ref="attachmentControl" :attachmentInfo="attachmentInfo" :attachments="attachments" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row mb-2">
                                                    <div class="col">
                                                        <button type="submit" @click="saveData" class="btn btn-primary mb-0 mr-2 pt-1 pb-1"><i class="fa fa-floppy-o pr-2"></i>Submit</button>
                                                        <button type="submit" @click="clearData" class="btn btn-primary mb-0 pt-1 pb-1"><i class="fa fa-times pr-2"></i>Cancel</button>
                                                    </div>
                                                </div>
                                            </b-card-body>
                                        </b-collapse>
                                    </b-card>
                                    <b-card no-body class="mb-3">
                                        <b-card-header header-tag="header" class="p-0 accordion-header" role="tab">
                                            <label class="d-block pt-2 pl-3 accordion-title m-0" block href="#" >
                                                <i class="action-link"></i>Employee Current Position<span></span>
                                            </label>
                                        </b-card-header>
                                        <div class="separator my-2"></div>
                                        <!-- <b-collapse id="accordion-2" accordion="my-accordion" v-model="elements.curEmpPositionVisible" role="tabpanel"> -->
                                        <b-card-body class="py-1 px-3">
                                            <div class="row">
                                                <div class="col">
                                                    <button v-if="hasWorkflowConfig==0" type="submit" title="Employee Position Change" @click="openEmploymentHistoryPopup" class="btn btn-outline-primary sbtn btn-xs py-1 px-2 float-left"><i class="fa fa-random pr-2"></i>Employee Position Change</button>
                                                </div>
                                                <div class="col-md-2 col-sm-2 pl-3 mr-0 ml-auto">
                                                    <div class="more-btns text-center">
                                                        <button v-if="!elements.empInfoEditMode" href="javascript:" @click="elements.empInfoEditMode = true" class="sbtn btn btn-xs action-link p-1" title="Edit">
                                                            <i class="fa fa-pencil" aria-hidden="true"></i>
                                                        </button>
                                                        <div v-if="elements.empInfoEditMode">
                                                            <button type="submit" @click="updateEmploymentInfo" title="Save" class="sbtn btn btn-xs action-link p-1"><i class="fa fa-check" aria-hidden="true"></i></button>
                                                            <button type="submit" @click="clearData();elements.empInfoEditMode=false;" title="Cancel" class="sbtn btn btn-xs action-link p-1"><i class="fa fa-times" aria-hidden="true"></i></button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-6">
                                                    <div class="form-group">
                                                        <label for="designationIdEmp">Designation</label>
                                                        <select v-model="employment.designationId" class="form-control" id="designationIdEmp" :disabled="!elements.empInfoEditMode" >
                                                            <option value="">Select</option>
                                                            <option v-for="(info, index) in designations" :value="info.id" :key="index">{{info.value}}</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div class="col-6">
                                                    <div class="form-group">
                                                        <label for="departmentIdEmp">Department</label>
                                                        <select v-model="employment.departmentId" class="form-control" id="departmentIdEmp" :disabled="!elements.empInfoEditMode" >
                                                            <option value="">Select</option>
                                                            <option v-for="(info, index) in departments" :value="info.id" :key="index">{{info.value}}</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-3">
                                                    <div class="form-group">
                                                        <label for="jobCategoryIdEmp">Job Category</label>
                                                        <select v-model="employment.jobCategoryId" class="form-control" id="jobCategoryIdEmp" :disabled="!elements.empInfoEditMode" >
                                                            <option value="">Select</option>
                                                            <option v-for="(info, index) in jobCategories" :value="info.id" :key="index">{{info.value}}</option>
                                                        </select>
                                                        <!-- <input v-else type="text" class="form-control" id="qualification" disabled v-model='employment.jobCategory'> -->
                                                    </div>
                                                </div>
                                                <div class="col-3">
                                                    <div class="form-group">
                                                        <label>Salary (per annum)</label>
                                                        <VhextMoneyControl :value="Math.abs(Number(employment.salary))" :disabled="!elements.empInfoEditMode" @money-input="moneyControlInput" />
                                                    </div>
                                                </div>
                                                <div class="col-3">
                                                    <div class="form-group">
                                                        <label>Salary (per month)</label>
                                                        <VhextMoneyControl :value="Math.abs(Number(monthlySalary))" disabled />
                                                    </div>
                                                </div>
                                                <div class="col-3">
                                                    <div class="form-group">
                                                        <label>Salary (per hour)</label>
                                                        <VhextMoneyControl :value="Math.abs(Number(employment.hourlySalary))" :disabled="!elements.empInfoEditMode" @money-input="moneyControlInputHourly" />
                                                    </div>
                                                </div>                                                    
                                                
                                            </div>
                                            <div class="row">
                                                <div class="col-12 col-sm-3">
                                                    <div class="form-group">
                                                        <label for="wefDate">Effective From</label>
                                                        <input v-if="elements.empInfoEditMode" type="date" v-model="employment.wefDate" class="form-control" id="wefDate">
                                                        <input v-else type="text" disabled class="form-control" :value="employment.wefDate | formatDate">
                                                    </div>
                                                </div>
                                                <div class="col-12 col-sm-3">
                                                    <div class="form-group">
                                                        <label for="endDate">End Date</label>
                                                        <input v-if="elements.empInfoEditMode" type="date" v-model="employment.endDate" class="form-control" id="endDate">
                                                        <input v-else type="text" disabled class="form-control" :value="employment.endDate | formatDate">
                                                    </div>
                                                </div>
                                                <div class="col-3">
                                                    <div class="form-group">
                                                        <label for="reportingToId">Reporting To</label>
                                                        <select v-model="employment.reportingToId" :disabled="!elements.empInfoEditMode" class="form-control">
                                                            <option value="">Select</option>
                                                            <option v-for="(info, index) in users" :value="info.id">{{info.value}}</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div class="col-3">
                                                    <div class="form-group">
                                                        <label for="employmentChangeById">Emp. Position Responsibility</label>
                                                        <select v-model="employment.employmentChangeById" :disabled="!elements.empInfoEditMode" class="form-control">
                                                            <option value="">Select</option>
                                                            <option v-for="(info, index) in users" :value="info.id">{{info.value}}</option>
                                                        </select>
                                                        <!-- <input v-if="!elements.empInfoEditMode" disabled v-model="employment.employmentChangeBy" type="text" class="form-control"> -->
                                                    </div>
                                                </div>                        
                                            </div>
                                        </b-card-body>
                                        <!-- </b-collapse> -->
                                    </b-card>                                    
                                    <div class="row mb-2">
                                        <div class="col-12 col-sm-12">
                                            <h6 class="fs-14 contact-title mb-3"><span>Emp. Position Change History</span></h6>
                                            <b-table show-empty small class="preview-table" stacked="md" selectable :select-mode="selectMode" sort-icon-left :items="empChangeHistory" :fields="empHistoryFields" responsive="sm" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection">
                                                <template #cell(index)="data">
                                                    {{ data.index + 1 }}
                                                </template>
                                                <template v-slot:cell(salary)="data">
                                                    <div class="text-right">{{data.value}}</div>
                                                </template>
                                                <template v-slot:cell(hourlySalary)="data">
                                                    <div class="text-right">{{data.value}}</div>
                                                </template>                                                
                                                <template v-slot:cell(actions)="data">
                                                    <a href="javascript:" title="Delete" @click="deleteEmploymentChange(data.item.id)" v-b-modal.deleteEmploymentChange><i class="fa fa-trash pl-2"></i></a>
                                                </template>
                                                <template v-slot:empty="scope">
                                                    <div class="text-center">No History found</div>
                                                </template>
                                                <template v-slot:emptyfiltered="scope">
                                                    <div class="text-center">{{ scope.emptyFilteredText }}</div>
                                                </template>
                                            </b-table>

                                            <span :class="'badge-secondary'" class="fs-11 badge badge-pill pointer text-center float-left">{{this.totalEPCHRows}} Items</span>

<!--                                             <b-pagination v-if="empChangeHistory.length!=0" pills v-model="currentEPCHPage" :total-rows="totalEPCHRows" align="fill" size="sm" class="my-0 pull-right"></b-pagination> -->
                                        </div>
                                    </div>
                            </b-overlay>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <JobPositionChangePopup id="jobPositionChangePopup" ref="jobPositionChangePopupRef" :employeeId="employeeId" :employeeData="employment" :key="jobPositionChangePopupKey" @refreshData="getEmployeeList" />
        <DeleteComponent id="deleteEmploymentChange" :onYes="onYes" :returnParams="deleteParam" title="Delete Confirmation" message="Are you sure to delete Employment History?" />
    </div>
</template>
<script>
import iziToast from 'izitoast';
import moment from 'moment';
import { required, email, minLength, maxLength, sameAs } from "vuelidate/lib/validators";
import AttachmentComponent from '@/components/common/AttachmentComponent.vue';
import JobPositionChangePopup from '@/components/popup/action/JobPositionChangePopup.vue';
import VhextMoneyControl from '@/components/usercontrols/VhextMoneyControl.vue'
export default {
    name: 'app',
    components: {
        AttachmentComponent,
        JobPositionChangePopup,
        VhextMoneyControl,
    },
    data() {
        return {
            showOverlay: false,
            elements: {
                visible: false,
                editMode: false,
                curEmpPositionVisible: true,
                empInfoEditMode: false,

            },
            hasWorkflowConfig: 0,
            isWorkflowInitiator: 0,
            vhextAccountId: localStorage.getItem("vhextAccountId"),
            userId: localStorage.getItem("userId"),
            entity: 'employment_change',

            jobPositionChangePopupKey: 100,

            employment: {
                id: '',
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                employeeId: '',
                employeeCode: '',
                departmentId: '',
                designationId: '',
                jobCategoryId: '',
                jobCategory: '',
                salary: '',
                hourlySalary: '',
                reportingToId: '',
                recruitedById: '',
                employmentChangeById: localStorage.getItem("userId"),
                wefDate: '',
                endDate: '',
                joiningDate: '',
                workTypeId: '',
                workShiftId: '',
                statusId: '',
                createdById: localStorage.getItem("userId"),
            },

            employmentChange: {
                id: '',
                employmentChangeId: '',
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                employeeId: '',
                departmentId: '',
                designationId: '',
                jobCategoryId: '',
                salary: '',
                hourlySalary: '',
                reportingToId: '',
                employmentChangeById: localStorage.getItem("userId"),
                wefDate: '',
                createdById: localStorage.getItem("userId"),
            },

            employeeId: '',
            employeeName: '',
            mainTableArray: [],

            attachmentInfo: {
                title: 'Attachment'
            },
            attachments: [],

            departments: [],
            designations: [],
            jobCategories: [],
            users: [],
            empPositionChangeSatus: [],

            employeeList: [],
            employeeFields: [
                { key: 'index',label: 'SN', thStyle: "width: 10px" },            
                { key: 'employeeCode', label: 'EID', sortable: true, sortDirection: 'desc' },
                { key: 'name', label: 'Name', sortable: true, sortDirection: 'desc' },        
                { key: 'designation', label: 'Designation', tdClass:'', sortable: true, sortDirection: 'desc' },        
                // { key: 'department', label: 'Department', sortable: true, sortDirection: 'desc' },        
            ],

            empChangeHistory: [],
            empHistoryFields: [
                { key:'index',label: 'SN' },
                { key: 'designation', label: 'Designation', sortable: true, sortDirection: 'desc' },
                { key: 'department', label: 'Department', sortable: true, sortDirection: 'desc' },
                { key: 'jobCategory', label: 'Job Category', sortable: true, sortDirection: 'desc' },
                { key: 'salary', label: 'Salary(per annum)', sortable: true, sortDirection: 'desc' },
                { key: 'hourlySalary', label: 'Salary(hourly)', sortable: true, sortDirection: 'desc' },
                { key: 'wefDate', label: 'Effective From', sortable: true, formatter: "formatDate", sortDirection: 'desc' },
                { key: 'endDate', label: 'End Date', sortable: true, formatter: "formatDate", sortDirection: 'desc' },
                // { key: 'actions', label: 'Actions' } 
            ],

            selectMode: 'single',
            totalRows: 1,
            totalEPCHRows: 1,

            currentPage: 1,
            currentEPCHPage: 1,
            
            perPage: 10,
            perEPCHPage: 5,

            pageOptions: [5,10, 15, 20],

            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',
            filter: null,
            filterOn: [],

            deleteParam: {
                id: 0
            }
        }
    },
    computed: {
        monthlySalary() {
            return this.employment.salary ? Math.trunc(this.employment.salary / 12) : 0;
        }
    },
    mounted() {
        this.getEmployeeList();
        this.lookupData();
        // this.getUserMenu(localStorage.getItem("userId"));
    },
    validations: {
        employmentChange: {
            statusId: {
                required
            }
        },
    },
    methods: {
        getEmployeeList: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
            }
            axios.post(this.$store.getters.getAPIBasePath + '/resource/employee/list', data)
                .then((response) => {
                    let result = response.data;
                    this.employeeList = result.employeeList;
                    this.totalRows = this.employeeList.length;
                    console.log(this.employeeList);
                    if (this.employeeList.length>0) {
                        this.$nextTick(() => {
                            this.selectBTableRowById(this.employeeId, this.mainTableArray, 'employeeTable');
                        })
                    }
                })
        },
        lookupData: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
                userId: this.userId,
                entity: this.entity,
            }
            this.showOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/resource/employmentchange/lookup/all', data)
                .then((response) => {
                    let result = response.data;
                    this.showOverlay = false;
                    this.departments = result.departmentLookup;
                    this.designations = result.designationLookup;
                    this.jobCategories = result.customCategoryLookup;
                    this.users = result.userLookup;
                    this.empPositionChangeSatus = result.statusLookup;
                    this.hasWorkflowConfig = result.hasWorkflowConfig ? result.hasWorkflowConfig : 0;
                    this.isWorkflowInitiator = result.isWorkflowInitiator ? result.isWorkflowInitiator : 0;

                }).catch((error)=> this.showOverlay = false);
        },
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },
        onEPCHFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalEPCHRows = filteredItems.length
            this.currentEPCHPage = 1
        },

        viewEmploymentChange: function(id) {
            axios.get(this.$store.getters.getAPIBasePath + '/resource/employmentchange/view/' + id)
                .then((response) => {
                    let result = response.data;
                    let employmentChangeDetail = result.employmentChange;
                    if (jobDetail) {                        
                        this.employmentChange.id = employmentChangeDetail;
                        this.employmentChange.employmentChangeId = employmentChangeDetail;
                        this.employmentChange.employeeId = employmentChangeDetail;
                        this.employmentChange.departmentId = employmentChangeDetail;
                        this.employmentChange.designationId = employmentChangeDetail;
                        this.employmentChange.jobCategoryId = employmentChangeDetail;
                        this.employmentChange.salary = employmentChangeDetail;
                        this.employmentChange.hourlySalary = employmentChangeDetail;
                        this.employmentChange.reportingToId = employmentChangeDetail;
                        this.employmentChange.employmentChangeById = localStorage.getItem("userId");
                        this.employmentChange.wefDate = employmentChangeDetail;

                    }
                })
                this.elements.editMode = false;
                this.elements.showDetail = true;
        },


        // getEmploymentChange: function() {
        //     if (this.employeeId) {
        //         let data = {
        //             vhextAccountId: this.vhextAccountId,
        //             employeeId: this.employeeId,
        //         }
        //         this.showOverlay = true;
        //         axios.post(this.$store.getters.getAPIBasePath + '/resource/employmentchange/list', data)
        //             .then((response) => {
        //                 this.showOverlay = false;
        //                 let result = response.data;
        //                 this.empChange = result.employmentChangeList;
        //                 console.log(this.empChangeHistory);
        //                 this.totalEPCHRows = this.empChange.length;
        //             })
        //     }
        // },
        getEmploymentHistory: function() {
            if (this.employeeId) {
                let data = {
                    vhextAccountId: this.vhextAccountId,
                    employeeId: this.employeeId,
                }
                this.showOverlay = true;
                axios.post(this.$store.getters.getAPIBasePath + '/resource/employmenthistory/list', data)
                    .then((response) => {
                        this.showOverlay = false;
                        let result = response.data;
                        this.empChangeHistory = result.employmentHistoryList;
                        console.log(this.empChangeHistory);
                        this.totalEPCHRows = this.empChangeHistory.length;
                    })
            }
        },

        async saveData() {
            this.employmentChange.employeeId = this.employeeId;
            let result;
            this.$v.employmentChange.$touch();
            if (!this.$v.employmentChange.$invalid) {
                this.showOverlay = true;
                // if (this.elements.editMode) {
                //     result = await this.updateEmploymentChange();
                //     await this.$refs.attachmentControl.saveAttachment(this.entity, this.employmentChange.id);
                // } else {
                    result = await this.saveEmploymentChange();
                    await this.$refs.attachmentControl.saveAttachment(this.entity, result.data.id);
                // }

                this.showOverlay = false;
                if (result.data.status == "CREATED") {
                    iziToast.success({
                        title: 'Success',
                        message: result.data.message,
                        position: 'topRight'
                    });
                    this.showOverlay = false;
                    this.getEmploymentHistory();
                    this.$emit('refreshData');
                    this.$bvModal.show(this.id);
                    this.clearData();
                }
            }
        },
        updateEmploymentInfo: function() {
            // this.$v.humanResourceDetails.$touch();
            console.log(this.employment);

            this.showHRDetOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/resource/employment/update', this.employment)
                .then((response) => {
                    this.showHRDetOverlay = false;
                    let result = response.data;
                    this.elements.editMode = false;

                    this.elements.empInfoEditMode = false;
                    // this.elements.empHistoryVisible = false;
                    if (result.status == "CREATED") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                    }
                    this.getEmployeeList();
                    // this.$emit('eventHRSave', this.humanResourceId);
                })
        },        
        saveEmploymentChange: function() {
            return axios.post(this.$store.getters.getAPIBasePath + '/resource/employmentchange/add', this.employmentChange)
        },
        updateEmploymentChange: function() {
            return axios.post(this.$store.getters.getAPIBasePath + '/resource/employmentchange/update', this.employmentChange)
        },
        deleteEmploymentChange: function(id) {
            this.deleteParam.id = id;
        },
        onYes: function() {
            console.log(this.deleteParam.id);
            axios.delete(this.$store.getters.getAPIBasePath + '/resource/employmentchange/delete/' + this.deleteParam.id)
                .then(response => {
                    var result = response.data;
                    if (result.status == "OK") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                    }
                })
                .catch(error => {
                    console.log(error);
                });
        },
        clearData: function() {
            this.elements.editMode = false;
            this.elements.visible = false;
            // this.elements.empHistoryVisible = true;
            this.employmentChange.id = '';
            this.employmentChange.employmentChangeId = '';
            this.employmentChange.employeeId = '';
            this.employmentChange.departmentId = '';
            this.employmentChange.designationId = '';
            this.employmentChange.jobCategoryId = '';
            this.employmentChange.salary = '';
            this.employmentChange.hourlySalary = '';
            this.employmentChange.reportingToId = '';
            this.employmentChange.employmentChangeById = localStorage.getItem("userId");
            this.employmentChange.wefDate = '';
            // this.$refs.attachmentControl.clearAttachment();
            this.attachments = [];
            // this.$v.employmentChange.$reset();
        },
        onRowSelected: function(items) {
            if (items != null && items.length > 0) {
                let data = items[0];
                this.employeeId = data.id;
                this.employeeName = data.name;
                this.employment.vhextAccountId = data.vhextAccountId;
                this.employment.id = data.id;
                this.employment.employeeId = data.employeeId;
                this.employment.departmentId = data.departmentId;
                this.employment.designationId = data.designationId;
                this.employment.jobCategoryId = data.jobCategoryId;
                this.employment.jobCategory = data.jobCategory;
                this.employment.salary = data.salary;
                this.employment.hourlySalary = data.hourlySalary;
                this.employment.reportingToId = data.reportingToId;
                this.employment.employmentChangeById = data.employmentChangeById;
                this.employment.wefDate = data.wefDate;
                this.employment.endDate = data.endDate;
                this.employment.createdById = data.userId;

                if (this.hasWorkflowConfig==1) {
                    this.employmentChange.employeeId = data.id;
                    this.employmentChange.departmentId = data.departmentId;
                    this.employmentChange.designationId = data.designationId;
                    this.employmentChange.jobCategoryId = data.jobCategoryId;
                    this.employmentChange.salary = data.salary;
                    this.employmentChange.hourlySalary = data.hourlySalary;
                    this.employmentChange.reportingToId = data.reportingToId;
                    this.employmentChange.employmentChangeById = localStorage.getItem("userId");
                    this.employmentChange.wefDate = data.wefDate;
                }

                this.getEmploymentHistory();
            }
        },
        openEmploymentHistoryPopup: function() {
            // this.employeeId = this.humanResourceDetails.empId;
            this.jobPositionChangePopupKey++;
            this.$nextTick(() => {
                this.$bvModal.show('jobPositionChangePopup');
            });
        },
        // showEdit: function(data) {
        //     this.elements.editMode = true;
        //     this.elements.visible = true;
        //     this.employmentChange.id = data.id;
        //     this.employmentChange.serviceHistoryId = data.serviceHistoryId;
        //     this.employmentChange.transactionId = data.transactionId;
        //     this.employmentChange.vhextAccountId = data.vhextAccountId;
        //     this.employmentChange.serviceUtilId = data.serviceUtilId;
        //     this.employmentChange.title = data.title;
        //     this.employmentChange.cost = data.cost;
        //     this.employmentChange.startDate = data.startDate;
        //     this.employmentChange.endDate = data.endDate;
        //     this.employmentChange.servicePeriodTypeId = data.servicePeriodTypeId;
        //     this.employmentChange.paymentDueDate = data.paymentDueDate;
        //     this.employmentChange.paymentDate = data.paymentDate;
        //     this.employmentChange.transactionById = data.transactionById;
        //     this.employmentChange.statusId = data.statusId;
        //     this.employmentChange.createdById = data.createdById;
        //     this.$nextTick(() => {
        //         this.$refs.attachmentControl.getAttachmentData(this.entity, data.id, this.vhextAccountId, null);
        //     })
        // },
        // onFiltered(filteredItems) {
        //     // Trigger pagination to update the number of buttons/pages due to filtering
        //     this.totalRows = filteredItems.length
        //     this.currentPage = 1
        // },
        moneyControlInput: function(value) {
            this.employment.salary = Math.abs(value);
            this.employmentChange.salary = Math.abs(value);
        },
        moneyControlInputHourly: function(value) {
            this.employment.hourlySalary = Math.abs(value);
            this.employmentChange.hourlySalary = Math.abs(value);

        },
    }
}
</script>