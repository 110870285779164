<template>
    <div id="app">
        <div class="container-fluid">
            <div class="row">
                <div class="col-12">
                    <div class="row task-top-menu mt-1">
                        <div class="col-lg-3 col-sm-3">
                            <h4 class="pl-2 mb-0 fs-16">{{this.ermTitle}}</h4>
                        </div>
                        <div class="col-lg-2 col-sm-2 mt-0 pr-0 mr-0">
                            <select v-model="ermObject" @change="ermFilter(ermObject)" class="form-control" id="taskId">
                                <option value="all" >All</option>
                                <option v-for="(info, index) in ermLookup" :value="{id: info.id, value:info.value}" :title="info.value">{{info.value}}</option>
                            </select>
                        </div>
                        <div class="col-lg-2 col-sm-2">
                            <h4 class="pl-2 mb-0 fs-14 text-center"><span class="text-muted">Task Gantt Chart</span></h4>
                        </div>
                        <div class="col-lg-5 col-sm-5 mt-1">
                            <nav class="breadcrumb-container d-sm-block d-lg-inline-block float-right" aria-label="breadcrumb">
                                <ol class="breadcrumb pt-0 mb-0">
                                    <li class="breadcrumb-item">
                                        <a href="javascript:" @click="getPrevUrl()"><i class="fa fa-reply pr-2" aria-hidden="true"></i>Back</a>
                                    </li>
                                    <li class="breadcrumb-item">
                                        <a href="javascript:" title="List View" class="action-link" @click="routeTaskList()"><i class="fa fa-list px-1" aria-hidden="true"></i></a>
                                    </li>                                                                        
                                    <li class="breadcrumb-item">
                                        <a href="javascript:" title="Board View" class="action-link" @click="routeTaskBoard()" >
                                            <i class="fa fa-sitemap px-1" aria-hidden="true"></i>
                                        </a>
                                    </li>                                    
                                    <li v-if="projId" class="breadcrumb-item">
                                        <h4 class="pl-2 mb-0 fs-14 text-right">#{{this.projId}} - {{this.projectName}}</h4>
                                    </li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                    <div class="separator mb-2"></div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-12 col-xl-12">
                    <div class="card mb-4 p-3">
                        <div class="row pl-2">
                            <div class="col-3">
                                <p class="chart-title text-left">Task Gantt Chart</p>
                            </div>
                            <div class="col-9 text-right">
                                <template>
                                    <b-avatar text="M" rounded="sm" variant="primary" class="align-baseline mr-1" button @click="setGanttChartScale('m')"></b-avatar>
                                    <b-avatar text="W" rounded="sm" variant="primary" class="align-baseline mr-1" button @click="setGanttChartScale('w')"></b-avatar>
                                    <b-avatar text="D" rounded="sm" variant="primary" class="align-baseline mr-1" button @click="setGanttChartScale('d')"></b-avatar>
                                </template>
                                
                            </div>
                        </div>                        
                        <!-- <p class="chart-title text-left">Task Gantt Chart</p> -->
                        <gantt v-if="elements.showChart" class="gantt-task" :tasks="tasks" :ganttChartScales="ganttChartScales" @task-updated="logTaskUpdate" @link-updated="logLinkUpdate" @task-selected="selectTask" :key="ganttChartKey"></gantt>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Gantt from "@/components/blocks/charts/GanttChart.vue"

export default {
    name: "App",
    components: {
        Gantt
    },
    data() {
        return {
            elements: {
                showChart: false,
                noReqSpecData: false,
                noScopeData: false,
                noUserReqData: false
            },
            vhextAccountId: localStorage.getItem("vhextAccountId"),
            projectId: localStorage.getItem("projectId"),
            projectTypeId: localStorage.getItem("projectTypeId"),

            projId: localStorage.getItem("projId"),
            projectName: localStorage.getItem("projectName"),
            userId: localStorage.getItem("userId"),
            entityRecordMasterId: '',
            ermTitle: localStorage.getItem("ermTitle"),

            ganttChartKey: 0,
            chartOptions: {
                responsive: true,
                maintainAspectRatio: false,
            },
            ganttChartScales:'day',

            allTasks: [],
            tasks: {
                data: [],
                links: []
            },
            selectedTask: null,

            ermLookup: [],
            ermObject: {
                id:   '',
                value:   '',
            },

            messages: [],
            entityRecordMasters: []
        }
    },
    mounted() {
        // this.lookupData();
        this.getEntityRecordMasterList()
        this.setGanttChartScale('d');
    },
    methods: {
        getEntityRecordMasterList: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
                projectId: this.projectId,
                projectTypeId: this.projectTypeId,
                userId: this.userId,
                entity: 'task'
            }
            console.log(data);
            axios.post(this.$store.getters.getAPIBasePath + '/task/lookup/all', data)
                .then((response) => {
                    let result = response.data;
                    this.entityRecordMasterId = result.entityERMLookup[0].id;
                    this.ermLookup = result.entityERMLookup;

                    console.log(this.ermLookup);
                    this.ermObject.id = this.entityRecordMasterId;
                    this.getTaskGanttChart(this.entityRecordMasterId);
                })
        },

        getTaskGanttChart: function(id) {
            let data = {
                entityRecordMasterId: id
            }
            axios.post(this.$store.getters.getAPIBasePath + '/task/ganttchart/list', data)
                .then((response) => {
                    let result = response.data;

                    this.allTasks = result.taskGanttChartList;
                    // this.tasks = result.allTasks;

                    console.log(result);
                    if (result.taskGanttChartList != null) {
                        this.tasks = {
                            data: result.taskGanttChartList,
                            links: []
                        }
                        console.log(this.tasks.data);
                    }
                    this.ganttChartKey += 1;
                    this.elements.showChart = true;
                })
        },
        selectTask(task) {
            console.log(task);
            this.selectedTask = task
        },
        ermFilter: function(ermData) {
            console.log(ermData);
            if (ermData.id !== null && ermData.id) {
                this.getTaskGanttChart(ermData.id);

            } else if (ermData == 'all') {
                this.tasks = this.allTasks;
            }
            console.log(ermData);
        },


        addMessage(message) {
            this.messages.unshift(message)
            if (this.messages.length > 40) {
                this.messages.pop()
            }
        },

        setGanttChartScale(scale) {
            if (scale == 'd') {
                // alert(scale);
                this.ganttChartScales = 'day';
                this.ganttChartKey += 1;                        
            }
            else if (scale == 'w') {
                this.ganttChartScales = 'week';
                this.ganttChartKey += 1;                        
                // alert(scale);
            }
            else if (scale == 'm') {
                this.ganttChartScales = 'month';
                this.ganttChartKey += 1;                        
                // alert(scale);
            }

        },

        logTaskUpdate(id, mode, task) {
            let text = (task && task.text ? ` (${task.text})` : '')
            let message = `Task ${mode}: ${id} ${text}`
            this.addMessage(message)
        },

        logLinkUpdate(id, mode, link) {
            let message = `Link ${mode}: ${id}`
            if (link) {
                message += ` ( source: ${link.source}, target: ${link.target} )`
            }
            this.addMessage(message)
        },
        routeTaskList() {

            if (this.ermObject.id != null && this.ermObject.id > 0) {
                this.$router.push('/task/' + this.ermObject.id);
            } else {
                this.$router.push('/dvpmas/list');
            }

        },
        routeTaskBoard() {

            if (this.ermObject.id != null && this.ermObject.id > 0) {
                this.$router.push('/tskbrd');
            } else {
                this.$router.push('/dvpmas/list');
            }

        }

    }
};
</script>