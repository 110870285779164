<template>
    <div class="fixed-inner-header">
        <div class="container-fluid">
            <div class="row inner-header">
                <div class="col-12">
                    <div class="row">
                        <div class="col-lg-3 col-sm-4">
                            <!-- <h4 v-if="showUserPrivileges" class="pl-2 mb-0 fs-16">User Privilleges</h4> -->
                            <h4 class="pl-2 mb-0 fs-16">User Management</h4>
                        </div>
                        <div class="col-lg-5 col-sm-4">
                            <b-form-group v-if="parentVhextAccountId" v-slot="{ ariaDescribedby }">
                                <b-form-radio-group v-model="showGuestUsers" :aria-describedby="ariaDescribedby" name="radio-sub-component" class="top-header-btn" buttons button-variant="outline-primary sbtn" size="xs">
                                    <b-form-radio :value="false" class="py-1 px-2"><i class="fa fa-users pr-1"></i>Users</b-form-radio>
                                    <b-form-radio :value="true" class="py-1 px-2"><i class="fa fa-user-secret pr-1"></i>Guest Users</b-form-radio>
                                </b-form-radio-group>
                            </b-form-group>
                        </div>
                        <div class="col-lg-4 col-sm-4">
                            <nav class="breadcrumb-container d-sm-block d-lg-inline-block float-right" aria-label="breadcrumb">
                                <ol class="breadcrumb pt-0 mb-0">
                                    <li class="breadcrumb-item">
                                        <!-- <a v-if="showUserPrivileges" href="javascript:" @click="showUserPrivileges=false"><i class="fa fa-reply action-link pr-2" aria-hidden="true"></i>Back</a> -->
                                        <a href="javascript:" @click="getPrevUrl()"><i class="fa fa-reply action-link pr-2" aria-hidden="true"></i>Back</a>
                                    </li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                    <div class="separator mb-2"></div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-8 col-xl-8">
                    <div class="card mb-4">
                        <div class="card-body p-3">
                            <b-overlay :show="showUserOverlay" spinner-variant="primary" opacity="0.4" rounded="sm">
                                <template v-if="!showGuestUsers">
                                    <div class="card mb-3">
                                        <b-card no-body class="">
                                            <b-card-header header-tag="header" class="p-0 accordion-header" role="tab">
                                                <label class="d-block p-2 accordion-title" block href="#" v-b-toggle.accordion-user>
                                                    <span><i class="fa fa-plus-circle pr-2 mt-2 action-link"></i>New User</span>
                                                </label>
                                            </b-card-header>
                                            <b-collapse id="accordion-user" v-model="elements.visible" accordion="my-accordion" collapsed role="tabpanel">
                                                <b-card-body class="p-3 mb-2">
                                                    <div class="row">
                                                        <div class="col-6">
                                                            <div class="form-group">
                                                                <label for="userTypeData">User Type</label>
                                                                <div class="required-field-block" :class="{ 'is-invalid': $v.userData.userTypeData.id.$error }">
                                                                    <select v-model="userData.userTypeData" id="userTypeData" :class="{ 'is-invalid': $v.userData.userTypeData.id.$error }" @change="clearUserFields;userTypeId=userData.userTypeData.id;userData.userRoleData.id='';userData.userRoleData.customUserRoleId='';userData.userRoleData.customUserRole='';" class="form-control">
                                                                        <option :value="{id: '', userType: '', userTypeCode: ''}">Select</option>
                                                                        <option v-for="(info, index) in vmxAccountUserTypes" :value="{id: info.id, userType: info.value, userTypeCode:info.userTypeCode}" >{{info.value}}</option>
                                                                    </select>
                                                                    <div class="required-icon"></div>
                                                                </div>
                                                                <span v-if="$v.userData.userTypeData.id.$error && !$v.userData.userTypeData.id.required" class="text-danger">Please select user type</span>
                                                            </div>
                                                        </div>
                                                        <div class="col-6">
                                                            <div class="form-group">
                                                                <label for="userName">Name</label>
                                                                <!-- <a href="javascript:" class="float-right button-add action-link" @click="toggleUserDropdown">
                                                                    <i v-if="!elements.showDropdownName" title="Select existing members" class="fa fa-lg fa-caret-down"></i>
                                                                    <i v-if="elements.showDropdownName" title="Add new member" class="fa fa-lg fa-plus-square-o"></i>
                                                                </a> -->
                                                                <div class="required-field-block" :class="{ 'is-invalid': $v.userData.userName.$error }">
                                                                    <select v-if="userData.userTypeData.userTypeCode === 'CLI'" v-model="userData.member" @change="getClient(userData.member.id);userData.userName=userData.member" :class="{ 'is-invalid': $v.userData.userName.$error }" class="form-control">
                                                                        <option value="">Select</option>
                                                                        <option v-for="(info, index) in clientLookup" :value="{id: info.id, text:info.value}">{{info.value}}</option>
                                                                    </select>
                                                                    <select v-else v-model="userData.member" @change="getHumanResource(userData.member.id, 'Users');userData.userName=userData.member" :class="{ 'is-invalid': $v.userData.userName.$error }" class="form-control">
                                                                        <option value="">Select</option>
                                                                        <option v-for="(info, index) in resourceLookup" :value="{id: info.id, text:info.value}">{{info.value}}</option>
                                                                    </select>
                                                                    <!-- <input v-if="!elements.showDropdownName" type="text" class="form-control" v-model="userData.userName" :class="{ 'is-invalid': $v.userData.userName.$error }" placeholder="Enter Name"> -->
                                                                    <div class="required-icon"></div>
                                                                </div>
                                                                <label v-if="$v.userData.userName.$error && !$v.userData.userName.required" class="text-danger">Please enter user name</label>
                                                            </div>
                                                        </div>
                                                        <div class="col-6">
                                                            <div class="form-group">
                                                                <label for="emailId">Login Email Id</label>
                                                                <div class="required-field-block" :class="{ 'is-invalid': $v.userData.emailId.$error }">
                                                                    <input type="email" autocomplete="nope" :class="{ 'is-invalid': $v.userData.emailId.$error }" class="form-control" v-model="userData.emailId" id="emailId" placeholder="User Account Login Email Id">
                                                                    <div class="required-icon"></div>
                                                                </div>
                                                                <div v-if="$v.userData.emailId.$error" class="invalid-feedback">
                                                                    <span v-if="!$v.userData.emailId.required" class="text-danger">EmailId is required</span>
                                                                    <span v-if="!$v.userData.emailId.email">Email is not valid</span>
                                                                </div>
                                                                <label v-if="elements.alreadyExistsPM" class="text-danger">Email already exists.</label>
                                                            </div>
                                                        </div>
                                                        <div class="col-6">
                                                            <div class="form-group">
                                                                <label for="userRoleData">User Role</label>
                                                                <div class="row no-gutters">
                                                                    <div class="col-10">
                                                                        <div class="required-field-block" :class="{ 'is-invalid': $v.userData.userRoleData.id.$error }">
                                                                            <select v-model="userData.userRoleData" id="userRoleData" :class="{ 'is-invalid': $v.userData.userRoleData.$error }" @change="clearUserFields;userRoleId=userData.userRoleData.id;" class="form-control">
                                                                                <option :value="{id: '', customUserRoleId: '', customUserRole: ''}">Select</option>
                                                                                <option v-for="(info, index) in customUserRoles" :value="{id: info.id, customUserRoleId:info.id, customUserRole:info.value}" >{{info.value}}</option>
                                                                            </select>
                                                                            <div class="required-icon"></div>
                                                                        </div>
                                                                        <span v-if="$v.userData.userRoleData.id.$error && !$v.userData.userRoleData.id.required" class="text-danger">Please select user role</span>
                                                                    </div>
                                                                    <div class="col-2 text-center form-control">
                                                                        <a title="Manage User Role" class="action-link d-inline-block" @click="$router.push({ name: 'admsetmngurr' })" href="javascript:" ><i class="fa fa-cogs" aria-hidden="true"></i></a>
                                                                        <!-- <button type="submit" title="Reset User Role - Modules and Menus" v-b-modal.userRoleResetDialog @click="" class="btn btn-outline-primary sbtn btn-md py-1 px-2">
                                                                            <i class="fa fa-cogs"></i>
                                                                        </button>  -->     
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-6">
                                                            <div class="form-group">
                                                                <label for="ownerName">Phone</label>
                                                                <input type="text" class="form-control" v-model="userData.phone" id="ownerName" placeholder="Phone">
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <button type="submit" @click="createUserAccount" class="btn btn-primary mb-0 mr-2 pt-1 pb-1 pl-3 pr-3"><i class="fa fa-floppy-o pr-2"></i>Save</button>
                                                    <button type="submit" @click="clearDataUser();elements.visible=false;" class="btn btn-primary mb-0 pt-1 pb-1 pl-3 pr-3"><i class="fa fa-times pr-2"></i>Cancel</button>
                                                </b-card-body>
                                            </b-collapse>
                                        </b-card>
                                    </div>
                                    <!-- <div class="form-row view-filter">
                                        <div class="col-sm-12">
                                            <div class="col-4 float-left">
                                                <div class="dataTables_filter">
                                                    <label class="d-block pl-1 accordion-title">Users
                                                    </label>
                                                </div>
                                            </div>                                
                                            <div class="col-3">
                                                <select v-model="userFilterData.statusId" class="form-control form-control-sm">
                                                    <option value="">Filter Account Status</option>
                                                    <option v-for="(info, index) in userStatus" :value="info.statusLookupId">{{info.value}}</option>
                                                </select>
                                            </div>

                                            <div class="col-4 float-right">
                                                <div class="dataTables_filter">
                                                    <label>
                                                        <input type="search" v-model="filter" class="form-control form-control-sm" placeholder="Search...">
                                                    </label>
                                                </div>
                                            </div>
                                            <div class="clearfix"></div>
                                        </div>
                                    </div> -->
                                    <div class="form-row view-filter dataTables_filter">
                                        <div class="col-8">
                                            <div class="dataTables_filter">
                                                <label class="d-block pl-1 accordion-title">Users
                                                </label>
                                            </div>

                                        </div>
                                        <div class="col-2 mt-0">
                                            <select v-model="userFilterData.statusId" class="form-control form-control-sm" @change="onStatusFiltered">
                                                <option value="">All Users</option>
                                                <option v-for="(info, index) in userStatus" :value="info.statusLookupId">{{info.value}}</option>
                                            </select>
                                        </div>
                                        <!-- <div class="col-2 float-right">
                                            <input type="search" v-model="filter" class="form-control form-control-sm" placeholder="Search...">
                                        </div> -->

                                        <div class="col-2 text-right">
                                            <div class="dataTables_filter">
                                                <label>
                                                    <input type="search" v-model="filter" class="form-control form-control-sm" placeholder="Search...">
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <b-table ref="userTable" v-model="mainTableArray" outlined show-empty small head-variant=:primary sticky-header="65vh" selectable :select-mode="selectMode" sort-icon-left :items="filteredUserList" :fields="userFields" @row-selected="onRowSelectedUsers" responsive="sm" 
                                    :filter="filter" :filterIncludedFields="filterOn" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" @filtered="onFilteredUsers">
                                        <template #cell(index)="data">
                                            {{ data.index + 1 }}
                                        </template>
                                        <template #cell(userName)="data">
                                            <div v-if="data.item.id == inlineEditRowId && data.field.key == inlineEditFieldName">
                                                <input type="text" class="form-control" :id="data.field.key+data.item.id" v-model="data.value" placeholder="" @keyup.enter="updateInlineTextValue('user_name', data.item.id, data.value)" @blur="cancelInlineEdit(userList, data.field.key)">
                                            </div>
                                            <div v-else class="row no-gutters align-items-center">
                                                <div class="col-auto">
                                                    <b-avatar v-if="data.item.photo" class="mr-2" :src="data.item.photo"></b-avatar>
                                                    <b-avatar v-else class="mr-2"></b-avatar>
                                                </div>
                                                <div class="col">
                                                    <div class="action-link mr-auto" @dblclick="focusInlineEdit(data.field.key+data.item.id, data.item.id, data.field.key, data.value)">{{ data.value }}</div>
                                                    <div class="mr-auto text-muted">{{ data.item.designation }}</div>
                                                </div>
                                            </div>
                                        </template>

                                        <template v-slot:cell(loginName)="data">
                                            <div v-if="data.item.id == inlineEditRowId && data.field.key == inlineEditFieldName">
                                                <input type="email" autocomplete="nope" :class="{ 'is-invalid': $v.changeRequestData.loginName.$error }" :id="data.field.key+data.item.id" v-model="data.item.loginName" @keyup.enter="userEmailChangeRequest(data.item)" class="form-control" @keyup.esc="cancelInlineEdit(userList, data.field.key)" @blur="cancelInlineEdit(userList, data.field.key)" placeholder="Login Email Id">
                                                <div v-if="$v.changeRequestData.loginName.$error" class="invalid-feedback">
                                                    <span v-if="!$v.changeRequestData.loginName.required" class="text-danger">EmailId is required</span>
                                                    <span v-if="!$v.changeRequestData.loginName.email">Email Id is not valid</span>
                                                </div>
                                                <label v-if="elements.alreadyExistsPM" class="text-danger">Email Id already exists.</label>
                                            </div>
                                            <div v-else @dblclick="focusInlineEdit(data.field.key+data.item.id, data.item.id, data.field.key, data.item.loginName)" class="action-link"> {{ data.item.loginName ? data.item.loginName : 'Edit' }}</div>
                                        </template>
                                        <template v-slot:cell(userTypeId)="data">
                                            <div v-if="data.item.id == inlineEditRowId && data.field.key == inlineEditFieldName">
                                                <select :id="data.field.key+data.item.id" v-model="data.item.userTypeId" @keyup.enter="updateUserTypeAndRole(data.item.id,data.item.userTypeId,userRoleData.customUserRoleId, 'PUR')" class="form-control" @keyup.esc="cancelUserInlineEdit(userList)">
                                                    <option v-for="(info, index) in vmxAccountUserTypes" :value="info.id"
                                                    >{{info.value}}</option>
                                                </select>
                                            </div>
                                            <div v-else @dblclick="setUserInlineEdit(data.field.key+data.item.id,data.item)" class="action-link"> {{ data.item.userType ? data.item.userType : 'Edit' }}</div>
                                        </template> 
                                        <template v-slot:cell(customUserRoleId)="data">
                                            <div v-if="data.item.id == inlineEditRowId && data.field.key == inlineEditFieldName2">
                                                <select :id="data.field.key+data.item.id" v-model="userRoleData" @change="updateUserTypeAndRole(data.item.id,data.item.userTypeId,userRoleData.customUserRoleId, 'PUR')" class="form-control" @blur="cancelUserInlineEdit(userList)">
                                                    <option v-for="(info, index) in customUserRolesDynamic(data.item.userTypeId)" :value="{customUserRoleId:info.id,customUserRole:info.value,userTypeId:info.userTypeId}"
                                                    >{{info.value}}</option>
                                                </select>
                                            </div>
                                            <div v-else @dblclick="setUserInlineEdit(data.field.key+data.item.id,data.item)" class="action-link">{{ data.item.customUserRole ? data.item.customUserRole : 'Edit' }} <i v-if="!data.item.customUserRole" class="fa fa-exclamation-circle pointer text-danger" title="Invalid User Role" aria-hidden="true"></i></div>
                                        </template>
                                        <template v-slot:cell(active)="data">
                                            <b-form-checkbox class="ml-3" v-model="data.value" disabled>
                                            </b-form-checkbox>
                                        </template>
                                        <template v-slot:cell(statusId)="data">
                                            <div v-if="data.item.id == inlineEditRowId && data.field.key == inlineEditFieldName">
                                                <select :id="data.field.key+data.item.id" v-model="data.value" @change="updateInlineNumberValue('status_id', data.item.id, data.value)" class="form-control" @blur="cancelInlineEdit(userList, data.field.key)">
                                                    <!-- <option value="">Select</option> -->
                                                    <option v-for="(info, index) in userStatus" :value="info.statusLookupId"
                                                    >{{info.value}}</option>
                                                </select>
                                            </div>
                                            <div v-else @dblclick="focusInlineEdit(data.field.key+data.item.id, data.item.id, data.field.key, data.value)" class="action-link"> {{ data.item.status ? data.item.status : 'Select' }}</div>
                                        </template>
                                        <template v-slot:cell(actions)="data">
                                            <a v-if="data.item.status==='Inactive'" href="javascript:" title="Resend Activation Link" @click="resendActivation(data.item.id,data.item.userTypeId, data.item.loginName,data.item.emailId,data.item.userName)"><i class="fa fa-share-square-o pointer action-link" aria-hidden="true"></i></a>
                                            <a v-else>N/A</a>                                        
                                        </template>
                                        <template v-slot:empty="scope">
                                            <div class="text-center">No Users found</div>
                                        </template>
                                        <template v-slot:emptyfiltered="scope">
                                            <div class="text-center">{{ scope.emptyFilteredText }}</div>
                                        </template>
                                    </b-table>
                                    <span :class="'badge-secondary'" class="fs-11 badge badge-pill pointer text-center float-left ml-3">{{this.totalRowsUsers}} Items</span>
                                    <!-- <b-pagination v-if="filteredUserList.length!=0" pills v-model="currentPage" :total-rows="totalRowsUsers" :per-page="perPageUsers" first-number last-number align="fill" size="sm" class="my-0 pull-right"></b-pagination> -->
                                </template>
                                <template v-else>
                                    <div class="card mb-3">
                                        <b-card no-body class="">
                                            <b-card-header header-tag="header" class="p-0 accordion-header" role="tab">
                                                <label class="d-block p-2 accordion-title" block href="#" v-b-toggle.accordion-user>
                                                    <span><i class="fa fa-plus-circle pr-2 mt-2 action-link"></i>New Guest User</span>
                                                </label>
                                            </b-card-header>
                                            <b-collapse id="accordion-user" v-model="elements.visible" accordion="my-accordion" collapsed role="tabpanel">
                                                <b-card-body class="p-3 mb-2">
                                                    <div class="row">
                                                        <div class="col-6">
                                                            <div class="form-group">
                                                                <label for="fromVhextAccountId">Company</label>
                                                                <div class="required-field-block" :class="{ 'is-invalid': $v.multiUserRole.fromVhextAccountId.$error }">
                                                                    <select v-model="multiUserRole.fromVhextAccountId" @change="multiUserRole.userId=''" id="fromVhextAccountId" :class="{ 'is-invalid': $v.multiUserRole.fromVhextAccountId.$error }" class="form-control">
                                                                        <option value="">Select</option>
                                                                        <option v-for="(info, index) in filteredCorpGroupCompanies" :value="info.id" >{{info.value}}</option>
                                                                    </select>
                                                                    <div class="required-icon"></div>
                                                                </div>
                                                                <span v-if="$v.multiUserRole.fromVhextAccountId.$error && !$v.multiUserRole.fromVhextAccountId.required" class="text-danger">Please select company</span>
                                                            </div>
                                                        </div>
                                                        <div class="col-6">
                                                            <div class="form-group">
                                                                <label for="userId">User</label>
                                                                <div class="required-field-block" :class="{ 'is-invalid': $v.multiUserRole.userId.$error }">
                                                                    <select v-model="multiUserRole.userId" :class="{ 'is-invalid': $v.multiUserRole.userId.$error }" class="form-control">
                                                                        <option value="">Select</option>
                                                                        <option v-for="(info, index) in filteredCorpGroupUsers" :value="info.id">{{info.value}}</option>
                                                                    </select>
                                                                    <div class="required-icon"></div>
                                                                </div>
                                                                <label v-if="$v.multiUserRole.userId.$error && !$v.multiUserRole.userId.required" class="text-danger">Please select user</label>
                                                            </div>
                                                        </div>
                                                        <div class="col-6">
                                                            <div class="form-group">
                                                                <label for="userTypeId">User Type</label>
                                                                <div class="required-field-block" :class="{ 'is-invalid': $v.multiUserRole.userTypeId.$error }">
                                                                    <select v-model="multiUserRole.userTypeId" id="userTypeId" :class="{ 'is-invalid': $v.multiUserRole.userTypeId.$error }" @change="userTypeId=multiUserRole.userTypeId;multiUserRole.customUserRoleId='';" class="form-control">
                                                                        <option value="">Select</option>
                                                                        <option v-for="(info, index) in vmxAccountUserTypes" :value="info.id" >{{info.value}}</option>
                                                                    </select>
                                                                    <div class="required-icon"></div>
                                                                </div>
                                                                <span v-if="$v.multiUserRole.userTypeId.$error && !$v.multiUserRole.userTypeId.required" class="text-danger">Please select user type</span>
                                                            </div>
                                                        </div>
                                                        <div class="col-6">
                                                            <div class="form-group">
                                                                <label for="customUserRoleId">User Role</label>
                                                                <div class="row no-gutters">
                                                                    <div class="col-10">
                                                                        <div class="required-field-block" :class="{ 'is-invalid': $v.multiUserRole.customUserRoleId.$error }">
                                                                            <select v-model="multiUserRole.customUserRoleId" id="customUserRoleId" :class="{ 'is-invalid': $v.multiUserRole.customUserRoleId.$error }" @change="userRoleId=multiUserRole.customUserRoleId;" class="form-control">
                                                                                <option value="">Select</option>
                                                                                <option v-for="(info, index) in customUserRoles" :value="info.id" >{{info.value}}</option>
                                                                            </select>
                                                                            <div class="required-icon"></div>
                                                                        </div>
                                                                        <span v-if="$v.multiUserRole.customUserRoleId.$error && !$v.multiUserRole.customUserRoleId.required" class="text-danger">Please select user role</span>
                                                                    </div>
                                                                    <div class="col-2 text-center form-control">
                                                                        <a title="Manage User Role" class="action-link d-inline-block" @click="$router.push({ name: 'admsetmngurr' })" href="javascript:" ><i class="fa fa-cogs" aria-hidden="true"></i></a>
                                                                        <!-- <button type="submit" title="Reset User Role - Modules and Menus" v-b-modal.userRoleResetDialog @click="" class="btn btn-outline-primary sbtn btn-md py-1 px-2">
                                                                            <i class="fa fa-cogs"></i>
                                                                        </button>  -->     
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <button type="submit" @click="saveGuestUser" class="btn btn-primary mb-0 mr-2 pt-1 pb-1 pl-3 pr-3"><i class="fa fa-floppy-o pr-2"></i>Save</button>
                                                    <button type="submit" @click="clearMultiUserRoleData();elements.visible=false;" class="btn btn-primary mb-0 pt-1 pb-1 pl-3 pr-3"><i class="fa fa-times pr-2"></i>Cancel</button>
                                                </b-card-body>
                                            </b-collapse>
                                        </b-card>
                                    </div>
                                    <!-- <div class="form-row view-filter">
                                        <div class="col-sm-12">
                                            <div class="col-4 float-left">
                                                <div class="dataTables_filter">
                                                    <label class="d-block pl-1 accordion-title">Users
                                                    </label>
                                                </div>
                                            </div>                                
                                            <div class="col-3">
                                                <select v-model="userFilterData.statusId" class="form-control form-control-sm">
                                                    <option value="">Filter Account Status</option>
                                                    <option v-for="(info, index) in userStatus" :value="info.statusLookupId">{{info.value}}</option>
                                                </select>
                                            </div>

                                            <div class="col-4 float-right">
                                                <div class="dataTables_filter">
                                                    <label>
                                                        <input type="search" v-model="filter" class="form-control form-control-sm" placeholder="Search...">
                                                    </label>
                                                </div>
                                            </div>
                                            <div class="clearfix"></div>
                                        </div>
                                    </div> -->

                                    <div class="form-row view-filter dataTables_filter">
                                        <div class="col-8">
                                            <div class="dataTables_filter">
                                                <label class="d-block pl-1 accordion-title">Guest Users
                                                </label>
                                            </div>

                                        </div>
                                        <div class="col-2 mt-0">
                                            <select v-model="userFilterData.statusId" class="form-control form-control-sm" @change="onStatusFiltered">
                                                <option value="">All Users</option>
                                                <option v-for="(info, index) in userStatus" :value="info.statusLookupId">{{info.value}}</option>
                                            </select>
                                        </div>
                                        <!-- <div class="col-2 float-right">
                                            <input type="search" v-model="filter" class="form-control form-control-sm" placeholder="Search...">
                                        </div> -->

                                        <div class="col-2 text-right">
                                            <div class="dataTables_filter">
                                                <label>
                                                    <input type="search" v-model="filterGuestUser" class="form-control form-control-sm" placeholder="Search...">
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <b-table ref="guestUserTable" v-model="mainTableArray" outlined show-empty small head-variant=:primary sticky-header="65vh" selectable :select-mode="selectMode" sort-icon-left :items="guestUserList" :fields="userFields" @row-selected="onRowSelectedUsers" responsive="sm" 
                                    :filter="filterGuestUser" :filterIncludedFields="filterOn" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" @filtered="onFilteredUsers">
                                        <template #cell(index)="data">
                                            {{ data.index + 1 }}
                                        </template>
                                        <template #cell(userName)="data">
                                            <div class="row no-gutters align-items-center">
                                                <div class="col-auto">
                                                    <b-avatar v-if="data.item.photo" class="mr-2" :src="data.item.photo"></b-avatar>
                                                    <b-avatar v-else class="mr-2"></b-avatar>
                                                </div>
                                                <div class="col">
                                                    <div class="mr-auto">{{ data.value }}</div>
                                                    <div class="mr-auto text-muted">{{ data.item.designation }}</div>
                                                </div>
                                            </div>
                                        </template>
                                        <template v-slot:cell(userTypeId)="data">
                                            <div v-if="data.item.id == inlineEditRowId && data.field.key == inlineEditFieldName">
                                                <select :id="data.field.key+data.item.id" v-model="data.item.userTypeId" @keyup.enter="updateUserTypeAndRole(data.item.id,data.item.userTypeId,userRoleData.customUserRoleId, 'GUR')" class="form-control" @keyup.esc="cancelUserInlineEdit(userList)">
                                                    <option v-for="(info, index) in vmxAccountUserTypes" :value="info.id"
                                                    >{{info.value}}</option>
                                                </select>
                                            </div>
                                            <div v-else @dblclick="setUserInlineEdit(data.field.key+data.item.id,data.item)" class="action-link"> {{ data.item.userType ? data.item.userType : 'Edit' }}</div>
                                        </template> 
                                        <template v-slot:cell(customUserRoleId)="data">
                                            <div v-if="data.item.id == inlineEditRowId && data.field.key == inlineEditFieldName2">
                                                <select :id="data.field.key+data.item.id" v-model="userRoleData" @change="updateUserTypeAndRole(data.item.id,data.item.userTypeId,userRoleData.customUserRoleId, 'GUR')" class="form-control" @blur="cancelUserInlineEdit(userList)">
                                                    <option v-for="(info, index) in customUserRolesDynamic(data.item.userTypeId)" :value="{customUserRoleId:info.id,customUserRole:info.value,userTypeId:info.userTypeId}"
                                                    >{{info.value}}</option>
                                                </select>
                                            </div>
                                            <div v-else @dblclick="setUserInlineEdit(data.field.key+data.item.id,data.item)" class="action-link">{{ data.item.customUserRole ? data.item.customUserRole : 'Edit' }} <i v-if="!data.item.customUserRole" class="fa fa-exclamation-circle pointer text-danger" title="Invalid User Role" aria-hidden="true"></i></div>
                                        </template>
                                        <template v-slot:cell(active)="data">
                                            <b-form-checkbox class="ml-3" v-model="data.value" disabled>
                                            </b-form-checkbox>
                                        </template>
                                        <template v-slot:cell(statusId)="data">
                                            <div> {{ data.item.status }}</div>
                                        </template>
                                        <template v-slot:cell(actions)="data">
                                            <a v-if="data.item.status==='Inactive'" href="javascript:" title="Resend Activation Link" @click="resendActivation(data.item.id,data.item.userTypeId, data.item.loginName,data.item.emailId,data.item.userName)"><i class="fa fa-share-square-o pointer action-link" aria-hidden="true"></i></a>
                                            <a v-else>N/A</a>                                        
                                        </template>
                                        <template v-slot:empty="scope">
                                            <div class="text-center">No Users found</div>
                                        </template>
                                        <template v-slot:emptyfiltered="scope">
                                            <div class="text-center">{{ scope.emptyFilteredText }}</div>
                                        </template>
                                    </b-table>
                                    <span :class="'badge-secondary'" class="fs-11 badge badge-pill pointer text-center float-left ml-3">{{this.totalRowsUsers}} Items</span>

                                    <!-- <b-pagination v-if="filteredUserList.length!=0" pills v-model="currentPage" :total-rows="totalRowsUsers" :per-page="perPageUsers" first-number last-number align="fill" size="sm" class="my-0 pull-right"></b-pagination> -->
                                </template>
                            </b-overlay>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-xl-4 pl-0">
                    <div class="card h-100">

                        <div class="card-body p-2">
                            <div class="card mb-2 mt-2">
                                <b-card no-body class="">                        
                                    <b-card-header header-tag="header" class="p-0 accordion-header" role="tab">
                                        <div v-if="userDetail.userName" class="d-block p-2 mt-2">
                                            <label class="ml-1 mr-1 title-highlight">
                                                <span>User Name :</span> {{userDetail.userName}}
                                            </label>
                                            <label class="ml-5 mr-1 title-highlight">
                                                <span>User Role :</span> {{userDetail.customUserRole}}
                                            </label>

                                            <span v-if="userDetail.active == true" title="Active User" class="float-right pointer mr-2"><i class="fa fa-user fa-lg text-success" aria-hidden="true"></i></span>
                                            <span v-if="userDetail.active == false" title="Inactive User" class="float-right pointer mr-2"><i class="fa fa-user-times fa-lg" aria-hidden="true"></i></span>
                                        </div>                                            
                                    </b-card-header>
                                </b-card>
                            </div>
                            <b-tabs v-if="!elements.visible" content-class="" lazy>
                                <b-tab title="Account Info">
                                    <div id="profile" class="px-2">
                                        <div class="profile_header">
                                            <div class="row">
                                                <div class="col-lg-12 col-xl-12">
                                                    <div class="profile_custom mb-0 card p-2">
                                                        <div class="text-left px-2 mt-2">
                                                            <div class="d-block pl-2">
                                                                <label v-if="userDetail.emailId" class="ml-0 mr-2">
                                                                    <span>Login Email ID :</span> {{userDetail.emailId}}
                                                                </label>
                                                                <span v-if="!elements.visible" class="position-absolute mt-0 ml-auto" style="right: 1rem;">
                                                                    <span v-if="userDetail.statusId == 1" class="badge green" title="Account Status">{{userDetail.status}}</span>
                                                                    <span v-else-if="userDetail.statusId == 2" class="badge grey" title="Account Status" >{{userDetail.status}}</span>
                                                                    <span v-else class="badge badge-danger" title="Account Status" >{{userDetail.status}}</span>
                                                                </span>

                                                            </div>
                                                            <div class="d-block pl-2">
                                                                <label v-if="userDetail.userId" class="ml-0 mr-2">
                                                                    <span>UID :</span> {{userDetail.userId}}
                                                                </label>
                                                            </div>
                                                            <div class="d-block pl-2">
                                                                <label v-if="userDetail.userType" class="ml-0 mr-2">
                                                                    <span>User Account Type :</span> {{userDetail.userType}}
                                                                </label>
                                                            </div>
                                                            <div class="d-block pl-2">
                                                                <label v-if="userDetail.customUserRole" class="ml-0 mr-2">
                                                                    <span>User Role :</span> {{userDetail.customUserRole}}
                                                                </label>
                                                            </div>
                                                            <div class="d-block pl-2">
                                                                <label v-if="userDetail.humanResourceType" class="ml-0 mr-2">
                                                                    <span>Resource Type :</span> {{userDetail.humanResourceType}}
                                                                </label>
                                                            </div>

                                                            <div class="d-block pl-2">
                                                                <label v-if="userDetail.position" class="ml-0 mr-2">
                                                                    <span>Position :</span> {{userDetail.position}}
                                                                </label>
                                                            </div>
                                                            <div class="d-block pl-2">
                                                                <label v-if="userDetail.mobile" class="ml-0 mr-2">
                                                                    <span>Mobile :</span> {{userDetail.mobile}}
                                                                </label>
                                                            </div>

                                                            <div class="d-block pl-2">
                                                                <label v-if="userDetail.phone" class="ml-0 mr-2">
                                                                    <span>Phone :</span> {{userDetail.phone}}
                                                                </label>
                                                            </div>
                                                            <label v-if="userDetail.loginName" class="pl-2">
                                                            Login Name : {{userDetail.loginName}}</label>
                                                        </div>
                                                    </div>
                                                    <div class="profile_custom mb-0 p-2">
                                                        <div class="text-right pl-2 mt-2">
                                                            <div class="d-block pl-2">
                                                                <label v-if="userDetail.createdBy" class="ml-0 mr-2 text-muted">
                                                                    <span>Created By :</span> {{userDetail.createdBy}}
                                                                </label>
                                                            </div>
                                                            <div class="d-block pl-2">
                                                                <label v-if="userDetail.createdDt" class="ml-0 mr-2 text-muted">
                                                                    <span>Created Date :</span> {{userDetail.createdDt | formatDateTimeAmPm}}
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </b-tab>
                                <b-tab title="Menu Privilleges">
                                    <UserPrivileges :user="userPrivilegeData" :key="userPrivilegesKey" />
                                </b-tab>
                            </b-tabs>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <DeleteComponent id="deleteUser" :onYes="onYes" :returnParams="deleteParam" title="Delete Confirmation" message="Are you sure to delete the User?" />
        <DeleteComponent id="userEmailChangeRequest" :onYes="onYesEmailChange" :returnParams="changeRequestParam" title="Email Change Confirmation" message="This will send a verification email to the new Email ID. Are you sure to continue?" />
    </div>
</template>
<script>
import iziToast from 'izitoast';
import moment from 'moment';
import month from '../../json/month.json'
import { required, email, minLength, maxLength, sameAs } from "vuelidate/lib/validators";
import UserPrivileges from '@/views/user/UserPrivileges.vue';
export default {
    name: 'app',
    components: {
        UserPrivileges,
    },
    data() {
        return {
            showUserPrivileges: false,
            showGuestUsers: false,
            parentVhextAccountId: localStorage.getItem("parentVhextAccountId"),
            vhextAccountId: localStorage.getItem("vhextAccountId"),
            user: this.$store.getters.currentUser,
            userId: localStorage.getItem("userId"),
            entity: 'user',

            humanResourceId: 0,
            userCount: '0',

            userPrivilegeData: {},
            userPrivilegesKey: 100,

            inlineEditRowId: '',
            inlineEditFieldName: '',
            inlineEditFieldValue: '',
            inlineEditFieldValue2: '',
            inlineEditFieldName2: '',

            activeTab: '',
            showUserOverlay: false,
            elements: {
                visible: false,
                showDropdownName: true,
                alreadyExistsPM: false,
            },
            error: false,
            userFilterData: {
                statusId: '',
            },

            userRoleData: {
                customUserRoleId: '',
                customUserRole: '',
                userTypeId: '',
            },

            mainTableArray: [],

            userData: {
                member: '',
                userName: '',
                userTypeId: '',
                userTypeCode:'',
                userType: '',
                userTypeData: {
                    id: '',
                    userType: '',
                    userTypeCode: '',
                },
                userRoleData: {
                    id: '',
                    customUserRoleId: '',
                    customUserRole: '',
                },
                jobTitle: '',
                emailId: '',
                phone: '',
                humanResourceId: '',
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                createdById: localStorage.getItem("userId"),
            },

            multiUserRole: {
                id: '',
                guestUserId: '',
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                fromVhextAccountId: '',
                userId: '',
                userTypeId: '',
                userTypeCode:'',
                userType: '',
                customUserRoleId: '',
                statusId: '',
                active: true,
                createdById: localStorage.getItem("userId"),
            },

            userDetail: {
                name: '',
                userName: '',
                emailId: '',
                phone: '',
                mobile: '',
                position: '',
                userType: '',
                humanResourceType: '',
                active: '',
                createdDt: '',
                lastUpdatedDt: '',
            }, 
            changeRequestData: {
                userId: '',
                loginName: '',
                userType: '',
                vhextAccountId: localStorage.getItem("vhextAccountId"),
            },            

            userStatus: [],
            humanResourceData: [],
            clientData: [],

            guestUserList: [],
            corpGroupCompanies: [],
            corpGroupUsers: [],            
            vmxAccountUserTypes: [],
            customUserRoleLookup: [],
            humanResourcesLookup: [],
            clientLookup: [],
            corporateAccounts: [],
            vhextAccountUsers: [],

            filteredUserList: [],
            userList: [],
            userFields: [
                { key: 'index', label: 'SN', tdClass: 'align-middle'},
                { key: 'userName', label: 'Name', sortable: true, sortDirection: 'desc' },
                // { key: 'userId', label: 'UID', sortable: true, sortDirection: 'desc' },
                // { key: 'name', label: 'Name', sortable: true, sortDirection: 'desc' },
                // { key: 'position', label: 'Position', sortable: true, sortDirection: 'desc' },
                { key: 'loginName', label: 'Login Name [Email Id]', tdClass: 'align-middle', sortable: true, sortDirection: 'desc' },
                { key: 'userTypeId', label: 'User Type', tdClass: 'align-middle', sortable: true, sortDirection: 'desc' },
                { key: 'customUserRoleId', label: 'User Role', tdClass: 'align-middle', sortable: true, sortDirection: 'desc' },
                // { key: 'phone', label: 'Phone', tdClass: 'align-middle', sortable: true, sortDirection: 'desc' },
                { key: 'active', label: 'Active', tdClass: 'align-middle', sortable: true, sortDirection: 'desc' },
                { key: 'statusId', label: 'Account Status', tdClass: 'align-middle', sortable: true, sortDirection: 'desc' },
                { key: 'actions', label: 'Actions', tdClass: 'align-middle', }
            ],
            selectMode: 'single',
            totalRows: 0,
            totalRowsUsers: 0,
            totalRowsGuestUsers: 0,
            currentPage: 1,
            perPage: 5,
            perPageUsers: 10,
            pageOptions: [5, 10, 15, 20, 25, 50],
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',
            filter: null,
            filterGuestUser: null,
            filterOn: [],
            deleteParam: {
                id: 0
            },
            changeRequestParam: {
                id: 0
            },
        }
    },
    computed: {
        resourceLookup() {
            let resourceLookup = this.humanResourcesLookup.filter(data => data.userId == null);
            if(this.userData.userTypeData.userTypeCode == 'EMP') {
                resourceLookup = resourceLookup.filter(data => data.typeCode == 'EMP');
            }
            else if(this.userData.userTypeData.userTypeCode == 'NEM') {
                resourceLookup = resourceLookup.filter(data => data.typeCode != 'EMP');
            }
            return resourceLookup;
        },

        customUserRoles() {
            if(this.showGuestUsers){
                return this.customUserRoleLookup.filter(data => data.userTypeId == this.multiUserRole.userTypeId);
            }else{
                return this.customUserRoleLookup.filter(data => data.userTypeId == this.userData.userTypeData.id);
            }
            
        },
        userTypeId: {
            get() {
                return this.$store.state.userManagementData.userTypeId;
            },
            set(value) {
                this.$store.state.userManagementData.userTypeId = value;
            }
        },
        userRoleId: {
            get() {
                return this.$store.state.userManagementData.userRoleId;
            },
            set(value) {
                this.$store.state.userManagementData.userRoleId = value;
            }
        },
        filteredCorpGroupCompanies(){
            return this.corpGroupCompanies.filter(data => data.id != this.vhextAccountId)
        },
        filteredCorpGroupUsers(){
            let filteredCorpGroupUsers = this.corpGroupUsers;
            if(this.corpGroupUsers.length && this.guestUserList.length) {
                filteredCorpGroupUsers = this.corpGroupUsers.filter(user => !this.guestUserList.some(guestUser => user.id == guestUser.id));
            }
            return filteredCorpGroupUsers.filter(data => data.vhextAccountId == this.multiUserRole.fromVhextAccountId)
        }
    },
    mounted() {
        this.lookupData();
        if(this.parentVhextAccountId){
            this.lookupDataCorpGroupUser();
            this.getGuestUsers();
        }
    },
    validations: {
        userData: {
            userName: {
                required
            },
            emailId: {
                required,
                email
            },
            userTypeData: {
                id: {
                    required,
                }
            },
            userRoleData: {
                id: {
                    required,
                }
            }
        },
        multiUserRole: {
            fromVhextAccountId: {
                required
            },
            vhextAccountId: {
                required
            },
            userId: {
                required
            },
            userTypeId: {
                required,
            },
            customUserRoleId: {
                required,
            }
        },
        changeRequestData: {
            loginName: {
                required,
                email
            },
        },
    },
    methods: {
        lookupData: function() {
            let data = {
                vhextAccountId: localStorage.getItem("vhextAccountId"),
            }
            axios.post(this.$store.getters.getAPIBasePath + '/user/lookup/all', data)
                .then((response) => {
                    let result = response.data;
                    console.log(result);
                    this.humanResourcesLookup = result.humanResourceLookup;
                    this.clientLookup = result.clientLookup;
                    this.vmxAccountUserTypes = result.customUserTypeLookup;
                    this.customUserRoleLookup = result.customUserRoleLookup;
                    this.userStatus = result.statusLookup;

                    this.userFilterData.statusId = this.userStatus[0].statusLookupId;

                    this.getCompanyUsers();

                })
                
        },
        lookupDataCorpGroupUser: function() {
            let data = {
                parentVhextAccountId: localStorage.getItem("parentVhextAccountId"),
                vhextAccountId: localStorage.getItem("vhextAccountId"),
            }
            console.log("DATA",data);
            axios.post(this.$store.getters.getAPIBasePath + '/user/corpgroup/lookup/all', data)
                .then((response) => {
                    let result = response.data;
                    this.corpGroupCompanies = result.corpGroupCompanyLookup;
                    this.corpGroupUsers = result.corpGroupUserLookup;
                    this.vmxAccountUserTypes = result.customUserTypeLookup;
                    this.customUserRoleLookup = result.customUserRoleLookup;
                    this.userStatus = result.statusLookup;

                })
                
        },
        getCompanyUsers: function() {
            let data = {
                vhextAccountId: localStorage.getItem("vhextAccountId"),
            }
            console.log(data);
            axios.post(this.$store.getters.getAPIBasePath + '/user/companyuser/list', data)
                .then((response) => {
                    let result = response.data;
                    console.log(result);
                    if (result) {
                        this.userList = result.userList;

                        this.onStatusFiltered();

                        this.humanResourceId = this.userList[0].humanResourceId;
                        this.$nextTick(() => {
                            this.selectBTableRowById(this.userDetail.id, this.mainTableArray, 'userTable');
                        })
                        console.log(this.userCount);
                    }
                }).catch(error => {
                    console.log(error);
                });
        },
        getGuestUsers: function() {
            let data = {
                vhextAccountId: localStorage.getItem("vhextAccountId"),
            }
            axios.post(this.$store.getters.getAPIBasePath + '/common/multiuserrole/user/list', data)
                .then((response) => {
                    let result = response.data;
                    this.guestUserList = result.multiUserRoleList;
                    // this.$nextTick(() => {
                    //     this.selectBTableRowById(this.userDetail.id, this.mainTableArray, 'userTable');
                    // })
                }).catch(error => {
                    console.log(error);
                });
        },
        createUserAccount: function() {
            // if (this.elements.showDropdownName == true) {
            this.userData.userName = this.userData.member.text;
            this.userData.humanResourceId = this.userData.member.id;
            // } else{
            //     this.userData.humanResourceId = '';
            // }      

            if(this.userData.userTypeData.id != null){
                this.userData.userTypeId = this.userData.userTypeData.id;
                this.userData.userType = this.userData.userTypeData.userType;
                // this.userData.userTypeCode = this.userData.userType == 'Client' ? 'CLI' : 'TMB';
                this.userData.userTypeCode = this.userData.userType == 'Client' ? 'CLI' : this.userData.userTypeData.userTypeCode;
            }
            this.userData.customUserRoleId = this.userData.userRoleData.id;
            console.log(this.userData);

            // return;
            this.$v.userData.$touch();
            if (!this.$v.userData.$invalid) {
                this.showUserOverlay = true;
                this.error = false;
                axios.post(this.$store.getters.getAPIBasePath + '/user/account/create', this.userData)
                    .then((response) => {
                        this.showUserOverlay = false;
                        let result = response.data;
                        if (result.status == "CREATED") {
                            this.activationMailSentToast(this.userData.emailId);
                            // this.$refs.humanResources.getHumanResource();
                            this.clearDataUser();
                            this.getCompanyUsers();
                            this.getHumanResourceLookup();
                        } else if (result.status == 'OK') {
                            this.elements.alreadyExistsPM = true;
                            this.error = true;
                            iziToast.info({
                                message: result.message,
                                position: 'topRight'
                            });

                        }
                    })
                    .catch(error => {
                        console.log(error)
                        this.showUserOverlay = false;
                        iziToast.error({
                           title: 'Error',
                           message: error.message,
                           position: 'topRight'
                       });
                    })
            } else {
                this.error = true;
            }
        },
        saveGuestUser: function() {
            this.$v.multiUserRole.$touch();
            if (!this.$v.multiUserRole.$invalid) {
                this.showUserOverlay = true;
                this.error = false;
                axios.post(this.$store.getters.getAPIBasePath + '/user/guest/manage', this.multiUserRole)
                    .then((response) => {
                        this.showUserOverlay = false;
                        let result = response.data;
                        if (result.status == "CREATED") {
                            iziToast.success({
                                title: 'Success',
                                message: result.message,
                                position: 'topRight'
                                
                            });
                            this.clearMultiUserRoleData();
                            this.getGuestUsers();
                        }
                    })
                    .catch(error => {
                        console.log(error)
                        this.showUserOverlay = false;
                        iziToast.error({
                           title: 'Error',
                           message: error.message,
                           position: 'topRight'
                       });
                    })
            }
        },
        getHumanResourceLookup: function() {
            let data = {
                vhextAccountId: localStorage.getItem("vhextAccountId"),
            }
            axios.post(this.$store.getters.getAPIBasePath + '/lookup/humanresource', data)
                .then((response) => {
                    let result = response.data;
                    console.log(result);
                    this.humanResourcesLookup = result.humanResourceLookup;

                })
        },
        deleteUser: function(id) {
            this.deleteParam.id = id;
        },
        onYes: function() {
            this.showUserOverlay = true;
            axios.delete(this.$store.getters.getAPIBasePath + '/user/delete/' + this.deleteParam.id)
                .then(response => {
                    this.showUserOverlay = false;
                    var result = response.data;
                    if (result.status == "OK") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.getCompanyUsers();
                        this.getHumanResourceLookup();
                    }
                })
                .catch(error => {
                    this.showUserOverlay = false;
                    iziToast.error({
                       title: 'Error',
                       message: 'Error',
                       position: 'topRight'
                   });
                })
        },
        customUserRolesDynamic(userTypeId) {
            // alert(userTypeId);
            console.log(this.customUserRoleLookup);
            // return this.customUserRoleLookup;
            return this.customUserRoleLookup.filter(data => data.userTypeId == userTypeId);
        },
        onRowSelectedUsers: function(items) {
            console.log(items);
            if (items != null && items.length > 0) {
                let data = items[0];
                console.log(data);
                this.userDetail.id = data.id;
                this.userDetail.userId = data.userId;
                this.userDetail.name = data.name;
                this.userDetail.userName = data.userName;
                this.userDetail.emailId = data.emailId;
                this.userDetail.phone = data.phone;
                this.userDetail.mobile = data.mobile;
                this.userDetail.position = data.position;
                this.userDetail.userType = data.userType;
                this.userDetail.userRoles = data.userRoles;
                this.userDetail.customUserRole = data.customUserRole;
                this.userDetail.humanResourceType = data.humanResourceType;
                this.userDetail.active = data.active;
                this.userDetail.statusId = data.statusId;
                this.userDetail.status = data.status;
                this.userDetail.createdDt = data.createdDt;
                this.userDetail.lastUpdatedDt = data.lastUpdatedDt;

                this.userPrivilegeData = data;
                this.userPrivilegesKey++;
            }
        },
        onStatusFiltered() {
            let statusId = this.userFilterData.statusId;

            let filteredUsers = [];

            let filterArray1 = [];
            console.log(statusId)
            if (statusId) {
                filterArray1 = this.userList.filter(data => {
                    if (data.statusId == statusId) {
                        return data;
                    }
                });
            }
            else filterArray1 = this.userList;

            filteredUsers = filterArray1;

            this.filteredUserList = filteredUsers;

            this.totalRowsUsers = filteredUsers.length;
            this.userCount = this.totalRowsUsers;
            this.$nextTick(() => {
                this.selectBTableRowById(this.userDetail.id, this.mainTableArray, 'userTable');
            })

            // return filteredUsers;
        },        
        toggleUserDropdown() {
            this.elements.showDropdownName = !this.elements.showDropdownName;
            this.userData.member = '';
            this.userData.userName = '';
            this.userData.emailId = '';
        },
        clearDataUser: function() {
            this.userData.member = '';
            this.userData.userName = '';
            this.userData.userTypeId = '';
            this.userData.userType = '';
            this.userData.userTypeData.id = '';
            this.userData.userTypeData.userType = '';
            this.userData.userTypeData.userTypeCode = '';
            this.userData.userRoleData.id = '';
            this.userData.userRoleData.customUserRoleId = '';
            this.userData.userRoleData.customUserRole = '';
            this.userData.jobTitle = '';
            this.userData.emailId = '';
            this.userData.phone = '';
            this.error = false;
            this.elements.alreadyExistsPM = false;
            this.$v.userData.$reset();

        },
        clearMultiUserRoleData: function() {
            this.multiUserRole.fromVhextAccountId = '';
            this.multiUserRole.userId = '';
            this.multiUserRole.userTypeId = '';
            this.multiUserRole.customUserRoleId = '';
            this.$v.multiUserRole.$reset();
        },

        clearUserFields: function() {
            this.userData.member = '';
            this.userData.userName = '';
            this.userData.emailId = '';
            this.userData.phone = '';
        },
        onFilteredUsers(filteredItems) {
            this.totalRowsUsers = filteredItems.length
            // this.currentPage = 1
        },
        onFilteredGuestUsers(filteredItems) {
            this.totalRowsGuestUsers = filteredItems.length
            // this.currentPage = 1
        },
        getHumanResource: function(id) {
            if (id !== undefined && id !== '') {
                this.showUserOverlay = true;
                axios.get(this.$store.getters.getAPIBasePath + '/resource/humanresource/view/' + id)
                    .then((response) => {
                        this.showUserOverlay = false;
                        let result = response.data;
                        console.log(result);
                        this.humanResourceId = id;
                        this.humanResourceData = result.humanResourceView;
                        this.userData.emailId = this.humanResourceData.emailId;
                        this.userData.jobTitle = this.humanResourceData.position;
                        this.userData.phone = this.humanResourceData.mobile;
                    })
                    .catch(error => {
                        console.log(error)
                        this.showUserOverlay = false;
                        iziToast.error({
                           title: 'Error',
                           message: error.message,
                           position: 'topRight'
                       });
                    })
            }
        },
        getClient: function(id) {
            console.log(id);
            if (id !== undefined || id !== '') {
                let data = {
                    id: id
                }
                axios.post(this.$store.getters.getAPIBasePath + '/client/view', data)
                    .then((response) => {
                        let result = response.data;
                        console.log(result);
                        this.clientData = result.client;
                        this.userData.emailId = this.clientData.emailId;
                        this.userData.jobTitle = this.clientData.position;
                        this.userData.phone = this.clientData.mobile;
                        console.log(this.clientData);
                    })
            }
        },
        userEmailChangeRequest: function(data) {
            this.changeRequestParam.id = data.id;

            this.changeRequestData.userId = data.id;
            this.changeRequestData.userType = data.userType;
            this.changeRequestData.vhextAccountId = this.vhextAccountId;
            this.changeRequestData.loginName = data.loginName;

            this.$v.changeRequestData.$touch();
            if (!this.$v.changeRequestData.$invalid) {
                this.$bvModal.show('userEmailChangeRequest');
            }
        },
        onYesEmailChange: function(flag) {
            let data = {
                userId: this.changeRequestData.userId,
                userType: this.changeRequestData.userType,
                vhextAccountId: this.vhextAccountId,
                loginName: this.changeRequestData.loginName,
                emailId: this.changeRequestData.loginName,
            }
           // this.error = false;
           this.showUserOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/user/emailchange/request', data)
                .then((response) => {
                    this.showUserOverlay = false;                        
                    let result = response.data;
                    if (result.status == 'OK') {
                        iziToast.success({
                            title: 'Check Email',
                            message: result.message,
                            drag: true,
                            icon: 'fa fa-envelope',
                            position: 'center',
                            titleSize: '15px',
                            backgroundColor: 'white',
                            progressBar: false,
                            titleLineHeight: '40px',
                            messageSize: '15px',
                            messageLineHeight: '40px',
                        });
                        this.getCompanyUsers();
                        // this.$bvModal.hide('userPopup');
                    }
                })
                .catch(error=>{
                    this.showUserOverlay = false;
                    iziToast.error({
                        title: 'Error',
                        message: error.message,
                        position: 'topRight'
                    });
                })
        },
        resendActivation: function(userId, userTypeId, loginName, emailId, name) {
            let data = {
                id: userId,
                userTypeId: userTypeId,
                vhextAccountId: this.vhextAccountId,
                loginName: loginName,
                emailId: emailId,
                userName: name
            }
            this.userDetail.id = userId;
            this.showUserOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/user/account/resend/activation', data)
                .then((response) => {
                    this.showUserOverlay = false;
                    let result = response.data;
                    if (result.status == "CREATED") {
                        this.activationMailSentToast(emailId);
                        this.clearDataUser();
                        this.getCompanyUsers();
                    } else if (result.status == 'OK') {
                        this.error = true;
                        iziToast.info({
                            message: result.message,
                            position: 'topRight'
                        });

                    }
                })
                .catch(error => {
                    this.showUserOverlay = false;
                    iziToast.error({
                       title: 'Error',
                       message: error.message,
                       position: 'topRight'
                   });
                })
        },
        updateInlineNumberValue: function(columnName,rowId,fieldValue) {
            let data = {
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                projectId: 0,
                entity: this.entity,
                columnName: columnName,
                numberValue: fieldValue,            
                id: rowId,  
            }
            console.log(data);
            this.userDetail.id = rowId;
            this.showUserOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/dynamicsql/update/numbervalue', data)
                .then(response => {
                    this.showUserOverlay = false;
                    var result = response.data;
                    if (result.status == "OK") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.inlineEditRowId='';
                        this.getCompanyUsers();
                    }
                })
                .catch(error => {
                    this.showUserOverlay = false;
                    iziToast.error({
                       title: 'Error',
                       message: 'Error',
                       position: 'topRight'
                   });
                }) 
        },
        updateUserTypeAndRole: function(userId, userTypeId, customUserRoleId, multiUserTypeCode) {
            let data = {
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                userId: userId,
                multiUserTypeCode: multiUserTypeCode,
                userTypeId: userTypeId,
                customUserRoleId: customUserRoleId,
                isPrimaryRole: this.showGuestUsers ? false : true,
                actionState: 2,
                createdById: this.userId,
            }
            
            console.log("DT",data);
            // return;
            this.showUserOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/user/updateusertypeandrole', data)
                .then(response => {
                    this.showUserOverlay = false;
                    var result = response.data;
                    if (result.status == "OK") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.inlineEditRowId='';
                        this.getCompanyUsers();
                    }
                })
                .catch(error => {
                    this.showUserOverlay = false;
                    iziToast.error({
                       title: 'Error',
                       message: 'Error',
                       position: 'topRight'
                   });
                }) 
        },
        updateInlineTextValue: function(columnName,rowId,fieldValue) {
            let data = {
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                projectId: 0,
                entity: 'user',
                columnName: columnName,
                textValue: fieldValue,            
                id: rowId,  
            }
            this.showUserOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/menu/update/textvalue', data)
                .then(response => {
                    this.showUserOverlay = false;
                    var result = response.data;
                    if (result.status == "OK") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.inlineEditRowId='';
                        this.getCompanyUsers();
                    }
                })
                .catch(error => {
                    this.showUserOverlay = false;
                    iziToast.error({
                       title: 'Error',
                       message: 'Error',
                       position: 'topRight'
                   });
                }) 
        },
        setUserInlineEdit(cellId, data) {
            this.userRoleData.userTypeId = data.userTypeId;
            this.userRoleData.customUserRoleId = data.customUserRoleId;
            this.userRoleData.customUserRole = data.customUserRole;

            this.inlineEditRowId = data.id;
            this.inlineEditFieldValue = data.userTypeId;
            this.inlineEditFieldName = 'userTypeId';

            this.inlineEditFieldValue2 = data.customUserRoleId;
            this.inlineEditFieldName2 = 'customUserRoleId';
            this.$nextTick(() => {
                document.getElementById(cellId).focus();
            })
        },
        cancelUserInlineEdit: function(items) {
            console.log(items);
            items = items.map(data => {
                if(data.id == this.inlineEditRowId) {
                    data.userTypeId = this.inlineEditFieldValue;
                    data.customUserRoleId = this.inlineEditFieldValue2;
                    return data;
                }
                else {
                    return data;
                }
            });
            this.inlineEditRowId ='';
            this.inlineEditFieldValue ='';
            this.inlineEditFieldName ='';
            this.inlineEditFieldValue2 ='';
            this.inlineEditFieldName2 ='';
        },
        focusInlineEdit(cellId, rowId, fieldName, fieldValue) {
            console.log(cellId, rowId, fieldValue)
            this.inlineEditRowId = rowId;
            this.inlineEditFieldValue = fieldValue;
            this.inlineEditFieldName = fieldName;
            this.$nextTick(() => {
                document.getElementById(cellId).focus();
            })
        },
        cancelInlineEdit: function(items,fieldName) {
            console.log(items,fieldName);
            items = items.map(data => {
                if(data.id == this.inlineEditRowId) {
                    data[fieldName] = this.inlineEditFieldValue;
                    return data;
                }
                else {
                    return data;
                }
            });
            this.inlineEditRowId ='';
            this.inlineEditFieldValue ='';
            this.inlineEditFieldName ='';
            this.inlineEditFieldValue2 ='';
            this.inlineEditFieldName2 ='';
        },
    },

}
</script>