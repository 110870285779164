<template>
    <div>
        <b-overlay :show="showOverlay" spinner-variant="info" opacity="0.5" rounded="sm">
        <div class="container-fluid mail-page">
            <div class="row ">
                <div class="col-12 p-2">
                    <div class="row">
                        <div class="col-lg-3 col-sm-3">
                            <h4 class="pl-2 mb-0 pb-0 fs-16">Manage Notification</h4>
                        </div>
                        <div class="col-lg-3 col-sm-3">
                            <button type="submit" title="Create Notification" @click="sendNotification()" class="btn btn-outline-primary sbtn btn-xs py-1 px-2 top-header-btn"><i class="fa fa-plus pr-2"></i>New Notification</button>                            
                        </div>
                        <div class="col-lg-6 col-sm-6">
                            <nav class="breadcrumb-container d-sm-block d-lg-inline-block float-right" aria-label="breadcrumb">
                                <ol class="breadcrumb pt-0  mb-0">
                                    <li class="breadcrumb-item">
                                        <a href="javascript:" @click="getPrevUrl()"><i class="fa fa-reply pr-2 action-link" aria-hidden="true"></i>Back</a>
                                    </li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                    <div class="separator"></div>
                </div>
                <div class="col-3 pr-1 pl-0">
                    <div class="dataTables_filter mb-1">
                        <input type="search" v-model="search" class="form-control form-control-lg" placeholder="Search...">
                    </div>
                    <div class="mail-page-col ">
                        <b-tabs v-model="tabIndex" lazy>
                            <b-tab title="Notifications"  @click="sendBox()" class="overflow-auto" style="height: 500px;">
                                <template #title>
                                    Notification <b-badge class="mt-0" variant="primary">{{sendNotificationLength}}</b-badge>
                                </template>
                                <div class="card-media-mail">
                                    <div>
                                        <b-list-group >
                                            <b-list-group-item v-for="notificationList in filteredSendNotification"
                                                :key="notificationList.id" @mouseover="hoveredNotification = notificationList.id" @mouseleave="hoveredNotification = null"
                                                :class="{ 'hover-brighten': hoveredNotification === notificationList.id }" @click="showSendNotification(notificationList.id)"> 
                                                <div class="row">
                                                    <div class="col-7 text-left">
                                                        <span class="font-weight-bold" :class="{ 'text-white': hoveredNotification === notificationList.id }">{{ notificationList.title }}</span>
                                                    </div>
                                                     <div class="col-5 float-right text-right">
                                                        <span :class="{ 'text-white': hoveredNotification === notificationList.id }">{{ formatDate(notificationList.createdDt) }}</span>
                                                     </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-8 float-right">
                                                        <span :class="{ 'text-white': hoveredNotification === notificationList.id }">{{ truncateText(stripHtml(notificationList.description), 35) }}</span>
                                                    </div>
                                                     <div class="col-4 float-right text-right">
                                                        <span class="fs-11" :class="{ 'text-white': hoveredNotification === notificationList.id }">Seen By : {{notificationList.readCount}} / {{notificationList.notifiedEmpCount}}</span>
                                                     </div>                                                    
                                                </div> 
                                            </b-list-group-item>
                                        </b-list-group>
                                    </div>
                                </div>
                            </b-tab>
                            <b-tab title="Draft Notifications" @click="draftBox()" class="overflow-auto" style="height: 500px;">
                                <template #title>
                                    Draft <b-badge class="mt-0" variant="primary">{{draftNotificationLength}}</b-badge>
                                </template>
                                <div class="card-media-mail">
                                    <div>
                                        <b-list-group >
                                            <b-list-group-item
                                                v-for="notificationList in filteredDraftNotification"
                                                :key="notificationList.id" 
                                                @mouseover="hoveredNotification = notificationList.id"
                                                @mouseleave="hoveredNotification = null"
                                                :class="{ 'hover-brighten': hoveredNotification === notificationList.id }"
                                                @click="showDraftNotification(notificationList.id)"
                                            > 
                                              <div class="row">
                                                    <div class="col-7 text-left">
                                                        <span class="  font-weight-bold " :class="{ 'text-white': hoveredNotification === notificationList.id }">{{ notificationList.title }}</span>
                                                    </div>
                                                     <div class="col-5 float-right text-right">
                                                    <span :class="{ 'text-white': hoveredNotification === notificationList.id }">{{ formatDate(notificationList.createdDt) }}</span>
                                                     </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-11 float-right">
                                                        <span :class="{ 'text-white': hoveredNotification === notificationList.id }">{{ truncateText(stripHtml(notificationList.description), 35) }}</span>
                                                    </div>
                                                </div> 
                                            </b-list-group-item>
                                        </b-list-group>
                                    </div>
                                </div>
                                
                            </b-tab> 
                        </b-tabs>
                    </div>
                </div>

                <div  class=" col-9 pl-1 mt-7 pr-1 mail-page-col">
                    <div class=""  v-if="showDraftNotificationDetails == 1">
                        <div class="card ">
                            <div class="row mt-2">
                                <div class="col-8 float-left font-weight-bold text-primary mb-0 pt-1 pb-1 pl-4 pr-3">
                                    <span style="font-size: 1.0rem;">{{ selectedNotification.title }}</span>
                                </div>
                                <div class="col-4 float-right font-weight-bold text-primary mb-0 pt-1 pb-1 pl-4 pr-3">
                                    <button type="submit" title="Delete Notification" @click="deleteNotification(selectedNotification.id)" v-b-modal.deleteNotification
                                            class="btn float-right mb-0 pt-1 pb-1 pl-1 pr-1">
                                        <i class="fa fa-trash pr-2"></i>
                                    </button>
                                    <button type="submit" title="Edit Notification" @click="editNotification(selectedNotification)" 
                                            class="btn float-right mb-0 pt-1 pb-1 pl-1 pr-1">
                                        <i class="fa fa-edit pr-2"></i>
                                    </button> 
                                </div>
                            </div>
                        </div>

                        <div class=" card mt-4 mx-auto" style="max-width: 900px; padding: 0.35rem;">
                            <div class="row  justify-content-center">
                                <div class="row w-100 mt-4 ">
                                    <div class="col-7 mt-2 text-left ">
                                        <span class="pt-1 pb-2 pr-3 pl-4" style="font-size: 0.75rem;" >Posted To : {{ selectedNotification.toVhextAccount }}/{{ selectedNotification.groupType }}</span>
                                    </div>
                                    <div class="col-5 mt-2 text-right">
                                        <span class="pt-1 pb-2 pr-4" style="font-size: 0.75rem;" >{{ formatDate(selectedNotification.createdDt) }}</span>
                                    </div>
                                </div>
                                <div class="row w-100 mt-3 pl-4">
                                    <div class="col-auto  pr-2">
                                        <b-avatar class="mr-0"></b-avatar>
                                    </div>
                                    <div class="col-9 text-left pl-0 pr-0">
                                        <div style="font-size: 0.75rem;">From:</div>
                                        <div style="font-size: 0.8rem; font-weight: bold;">{{ selectedNotification.createdBy }}</div>
                                    </div>
                                </div>
                                <div class="row w-100 mt-3 mb-4 pl-4 pr-4">
                                    <div class="col-12" style="text-align: justify;">
                                        <span class="" style="font-size: 1.0rem;" v-html="truncateText(selectedNotification.description, 5000)"></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class=""  v-if="showSendNotificationDetails == 1">
                        <div class="card ">
                            <div class="row mt-2">
                                <div class="col-8 float-left font-weight-bold text-primary mb-0 pt-1 pb-1 pl-4 pr-3">
                                    <span style="font-size: 1.0rem;">{{ selectedNotification.title }}</span>
                                </div>
                                <div class="col-4 float-right font-weight-bold text-primary mb-0 pt-1 pb-1 pl-4 pr-3">
                                    <button type="submit" @click="deleteNotification(selectedNotification.id)" v-b-modal.deleteNotification
                                            class="btn float-right mb-0 pt-1 pb-1 pl-1 pr-1">
                                        <i class="fa fa-trash pr-2"></i>
                                    </button>
                                     <button type="submit"  @click="editDraftNotification(selectedNotification)" :disabled="showOverlay"
                                        class="btn float-right mb-0 mr-2 pt-1 pb-1 pl-1 pr-1" title="Edit and Send"><i class="fa fa-edit pr-2"></i> 
                                    </button> 
                                </div>
                            </div>
                        </div>

                        <div class=" card mt-4 mx-auto" style="max-width: 900px; padding: 0.35rem;">
                            <div class="row  justify-content-center">
                                <div class="row w-100 mt-4">
                                    <div class="col-7 mt-2 text-left ">
                                        <span class="pt-1 pb-2 pr-3 pl-4" style="font-size: 0.75rem;" >Posted To : {{ selectedNotification.toVhextAccount }}/{{ selectedNotification.groupType }}</span>
                                    </div>
                                    <div class="col-5 text-right">
                                        <span class="pt-1 pb-2 pr-4" style="font-size: 0.75rem;" >{{ formatDate(selectedNotification.createdDt) }}</span>
                                    </div>
                                </div>
                                <div class="row w-100 mt-3 pl-4">
                                    <div class="col-auto  pr-2">
                                        <b-avatar class="mr-0"></b-avatar>
                                    </div>
                                    <div class="col-9 text-left pl-0 pr-0">
                                        <div style="font-size: 0.75rem;">From:</div>
                                        <div style="font-size: 0.8rem; font-weight: bold;">{{ selectedNotification.createdBy }}</div>
                                    </div>
                                </div>
                                <div class="row w-100 mt-3 mb-4 pl-4 pr-4">
                                    <div class="col-12" style="text-align: justify;">
                                        <span class="" style="font-size: 1.0rem;" v-html="truncateText(selectedNotification.description, 5000)"></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </b-overlay>
        <SendNotificationPopup ref="sendNotificationPopupRef" id="jobShareMailPopup" @refresh-notification="getNotifications()"/>
        <DeleteComponent id="deleteNotification" :onYes="onYes" :returnParams="deleteParam"
        title="Delete Confirmation" message="Are you sure you want to delete this notification?" />
        
        
    </div>
</template>
<script>
import iziToast from 'izitoast';
import moment from 'moment';
import Editor from '@tinymce/tinymce-vue';
import SendNotificationPopup from '@/components/popup/action/SendNotificationPopup.vue'
export default {
    name: 'app',
    components: {
        Editor,
        SendNotificationPopup,
    },
    data() {
        return {
            showOverlay: false,


            parentVhextAccountId: localStorage.getItem("parentVhextAccountId"),
            vhextAccountId: localStorage.getItem("vhextAccountId"),
            projectId: localStorage.getItem("projectId"),
            projId: localStorage.getItem("projId"),
            projectName: localStorage.getItem("projectName"),
            userId: localStorage.getItem("userId"),

            search: '',

            notifications: [],
            sendNotifications:[],
            draftNotifications:[],
            notificationDetails: {
                title: '',
                body: '',
                sentDate: '',
                subject: '',
            },
            selectedEmailId:null,
            currentDate: new Date().toString().substr(0, 10),
            tabIndex: 0,
            visibleMailContent: false,
            showSendNotificationDetails:null,
            showDraftNotificationDetails:null,
            boxSelectionId: null,
            hoveredNotification: null,
            selectedNotification: null,
            sendNotificationLength: null,
            draftNotificationLength: null,
            forDemo:null,
            
            notificationData: {
                parentVhextAccountId: localStorage.getItem("parentVhextAccountId"),
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                toVhextAccountId: 0,
                toVhextAccount: '',
                projectId: localStorage.getItem("projectId"),
                title: '',
                description: '',
                selectedEmployees:[],
                createdById: localStorage.getItem("userId"),
            },

            deleteParam: {
                id: 0
            },
           
        }
    },
   computed: {
        filteredSendNotification() {

            return this.sendNotifications.filter(data => data.title && data.title.toLowerCase().includes(this.search.toLowerCase()));

        },        
        filteredDraftNotification() {

            return this.draftNotifications.filter(data => data.title && data.title.toLowerCase().includes(this.search.toLowerCase()));
            
        },        
   },

    mounted() {
        this.getNotifications();        
    },
    methods: {
        getNotifications: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
                userId: this.userId
            }
            console.log(data);           
            axios.post(this.$store.getters.getAPIBasePath +  '/communication/notification/list', data)
                .then((response) => {
                    let result = response.data; 
                    console.log(result);
                    this.notifications = result.notificationList.reverse();  
                    console.log(this.notifications);
                    this.sendNotifications = this.notifications.filter(notification => notification.statusId === 662);
                    console.log(this.sendNotifications );
                    this.draftNotifications = this.notifications.filter(notification => notification.statusId === 661);
                    console.log(this.draftNotifications);
                    this.sendNotificationLength = this.sendNotifications.length;      
                    this.draftNotificationLength = this.draftNotifications.length; 
                   
                })
        },
        deleteNotification: function(id) {
            console.log(id);
            this.deleteParam.id = id;
        },
        onYes: function() {
            let data = {
                id: this.deleteParam.id,               
            }
            console.log(data);

            axios.post(this.$store.getters.getAPIBasePath + '/communication/notification/delete', data)
                .then(response => {
                    var result = response.data;
                    if (result.status == "OK") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.getNotifications();
                    }
                })
                .catch(error => {
                    console.log(error);
                });
        },
        backgroundImage(img) {
            return 'background: url(' + img + ') no-repeat center; background-size: cover;';
        },
        // async getNotifications(){
        //     await this.getNotifications();
        // },
        draftBox(){
            this.showSendNotificationDetails = 0;
            console.log("draft");
        },

        sendBox(){
            console.log("Send");
          this.showDraftNotificationDetails = 0;
        }, 
        
        sendNotification() {
             this.$nextTick(() => {
                this.$refs.sendNotificationPopupRef.setNotificationData();
            })
        },

        editDraftNotification(editDraftNotificationData){
            this.$nextTick(() => {
                this.$refs.sendNotificationPopupRef.setEditNotificationData(editDraftNotificationData);
            })
        
        },

        editNotification(editNotificationData){
            console.log(editNotificationData)
            this.$nextTick(() => {
                this.$refs.sendNotificationPopupRef.setEditDraftNotificationData(editNotificationData);
            }) 
        
        },
        truncateText(text, length) {
            if (text.length > length) {
                return text.substring(0, length) + '...';
            }
            return text;
        },

        stripHtml(html) {
            const tempDiv = document.createElement('div');
            tempDiv.innerHTML = html;
            return tempDiv.textContent || tempDiv.innerText || '';
        },

        formatDate(dateStr) {
            const date = new Date(dateStr);
            const now = new Date();
            const yesterday = new Date();
            yesterday.setDate(now.getDate() - 1);

            const options = {
            hour: '2-digit',
            minute: '2-digit',
            hour12: true,
            };

            if (date.toDateString() === now.toDateString()) {
                return date.toLocaleTimeString([], options);
            // } else if (date.toDateString() === yesterday.toDateString()) {
            //     return 'Yesterday';
            } else {
            return date.toLocaleDateString();
            }
        },

        showSendNotification(id){
            this.showSendNotificationDetails= 1;
            this.selectedNotification = this.sendNotifications.find(notification => notification.id === id);
            console.log(this.selectedNotification);
        },
        showDraftNotification(id){
            this.showDraftNotificationDetails= 1;
            this.showSendNotificationDetails= null;
            console.log(this.showDraftNotificationDetails);
            this.selectedNotification = this.draftNotifications.find(notification => notification.id === id);
            console.log("Draft",this.selectedNotification);
        
        }

    }
    
}
</script>