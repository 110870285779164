<template>
    <div class="container-fixed">
        <b-overlay :show="showOverlay" opacity="0.4">
            <div class="form-row">
                <div class="col-2">
                    <div class="card">
                        <div class="card-body p-3">
                            <div class="row">
                                <div class="col-sm-12 mb-1">
                                    <div class="card p-1">
                                        <div class="row no-gutters align-items-center">
                                            <div class="col-auto p-1">
                                                <b-avatar v-if="humanResourceDetails.photo" class="mr-2" :src="humanResourceDetails.photo" size="lg"></b-avatar>
                                                <b-avatar v-else class="mr-2"></b-avatar>
                                            </div>
                                            <div class="col">
                                                <div class="mr-auto">{{ humanResourceDetails.name }}</div>
                                                <div class="mr-auto text-muted fs-11">{{ humanResourceDetails.designation }}</div>
                                                <div class="mr-auto">Emp Code : {{ humanResourceDetails.employeeCode }}</div>
                                            </div>
                                        </div>                                                
                                        <div class="separator mb-1"></div>
                                    </div>
                                </div>

                                <div class="col-sm-12 mb-1">
                                    <div class="card p-1">
                                        <p class="chart-title text-left pl-1 py-1 mb-0">Attendance
                                            <!-- <span class="badge badge-light float-right pointer py-1 px-2" title="Total Users">{{  }}</span> -->
                                            <span v-if="isPrimaryRole === 'true'" class="float-right pointer py-1 px-2">
                                                <a v-if="this.empWorkState == 0 || this.empWorkState == 4" href="javascript:" title="My Attendance : Work Not Started" class="pointer emp-tl-indicator px-1 red" v-b-modal.empAttendanceTimelinePopup @click="">
                                                    <i class="fa fa-clock-o pt-1"></i>
                                                </a>
                                                <a v-if="this.empWorkState == 2 " href="javascript:" title="My Attendance : Work Break" class="pointer emp-tl-indicator px-1 grey" style="max-width: 200px; overflow: hidden; text-overflow: ellipsis; white-space: nowrap;" v-b-modal.empAttendanceTimelinePopup @click="">
                                                    <i class="fa fa-clock-o pr-1 pt-1"></i>{{ timerDuration }} {{ timelineTaskTitle }}
                                                </a> 
                                               <a v-if="this.empWorkState == 1 || this.empWorkState == 3 " href="javascript:" title="My Attendance : Work In Progress" class="pointer emp-tl-indicator px-1 green" style="max-width: 200px; overflow: hidden; text-overflow: ellipsis; white-space: nowrap;" v-b-modal.empAttendanceTimelinePopup @click="">
                                                    <i class="fa fa-clock-o pr-1 pt-1"></i>{{ timerDuration }} {{ timelineTaskTitle }}
                                                </a>
                                            </span>
                                        </p>
                                    </div>
                                </div>

<!--                                 <div class="col-sm-12 mb-1">
                                    <div class="card p-1">
                                        <p class="chart-title text-left pl-1 mb-1">Notifications
                                        </p>
                                        <div class="separator mb-1"></div>
                                        <div class="card-body p-1 text-center">
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-12 mb-1">
                                    <div class="card p-1">
                                        <p class="chart-title text-left pl-1 mb-1">Meetings
                                        </p>
                                        <div class="separator mb-1"></div>
                                        <div class="card-body p-1 text-center">
                                        </div>
                                    </div>
                                </div>    
                                <div class="col-sm-12 mb-1">
                                    <div class="card p-1">
                                        <p class="chart-title text-left pl-1 mb-1">Projects 
                                        </p>
                                        <div class="separator mb-1"></div>
                                        <div class="card-body p-1 text-center">
                                        </div>
                                    </div>
                                </div>        -->                      
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-10">
                    <div class="form-row mb-2">
                        <div class="col-12">
                            <div class="card">
                                <div class="card-body p-3">
                                    <div class="form-row">
                                        <div class="col-md-6 col-xl-6">
                                            <div class="card p-1">
                                                <p class="chart-title text-left pl-1 mb-0">
                                                    Task Assigned To Me
                                                    <span class="dataTables_filter">
                                                        <label>
                                                            <input type="search" v-model="filter" class="form-control form-control-sm" placeholder="Search...">
                                                        </label>
                                                    </span>

                                                    <span class="badge badge-light float-right pointer mt-1 px-2" title="Total Task Assigned By Me">{{ taskAssignedToMeCount }}</span>                                                    
                                                </p>
                                                <div class="separator mb-1"></div>
                                                <div class="card-body px-1 py-1">
                                                    <div class="row view-filter">
                                                        <div class="col-sm-12">
                                                            <div v-for="status in userTaskByStatusData" class="pr-1 my-1 text-center float-left">
                                                                <div class="card b-radius-5">
                                                                    <div class="card-body px-2 py-2">
                                                                        <div class="form-group mb-0">

                                                                            <label class="caption-title my-auto" for="title">{{ status.status }} 
                                                                                <b-badge class="ml-1" title="Tasks">{{status.taskCount}}</b-badge>
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div class="clearfix"></div>
                                                        </div>
                                                    </div>
                                                    <div class="data-table-rows  data-tables-hide-filter">
                                                        <b-table outlined show-empty small head-variant=:primary sticky-header="45vh" :items="taskAssignedToMe" :fields="myTaskFields" responsive="sm" :filter="filter" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection">               
                                                            <template v-slot:cell(taskId)="data">
                                                                <div class="text-center">{{ data.item.taskId }}</div>
                                                            </template>    
                                                            <template v-slot:cell(title)="data">
                                                                <a class="tooltiptext action-link" href="javascript:" @click="viewItemDetails(data.item)">{{data.value}}</a>
                                                            </template>                                          
                                                            <template v-slot:cell(status)="data">
                                                                <span v-if="data.item.status == 'To Do'" class="badge badge-pill bg-light mb-1">{{data.value}}
                                                                </span>
                                                                <span v-else-if="data.item.status == 'In Progress'" class="badge badge-pill bg-info mb-1">{{data.value}}
                                                                </span>
                                                                <span v-else-if="data.item.status == 'Completed'" class="badge badge-pill badge-success mb-1">{{data.value}}
                                                                </span>      
                                                                <span v-else-if="data.item.status == 'Verified'" class="badge badge-pill badge-primary mb-1">{{data.value}}
                                                                </span>                                               
                                                                <span v-else-if="data.item.status == 'Closed'" class="badge badge-pill badge-dark mb-1">{{data.value}}
                                                                </span>
                                                                <span v-else-if="data.item.status == 'Cancelled'" class="badge badge-pill badge-danger mb-1">{{data.value}}
                                                                </span>
                                                                <span v-else-if="data.item.status == 'On Hold'" class="badge badge-pill badge-dark mb-1">{{data.value}}</span>
                                                                <span v-else>{{data.value}}</span>
                                                            </template>
                                                            <template v-slot:cell(actions)="data">

                                                                <a type="button" class="text-center" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                    <i class="fa fa-ellipsis-v p-1"></i>
                                                                </a>    
                                                                <div class="dropdown-menu dropdown-primary" aria-labelledby="dropdownMenuLink">
                                                                    <a class="dropdown-item addnew-tassk pb-1 py-1" @click="viewItemDetails(data.item)" href="javascript:"><i class="fa fa-list-alt pr-2" aria-hidden="true"></i>Task Details</a>
                                                                    <a v-if="data.item.assignedById == userId" class="dropdown-item addnew-tassk py-1" @click="showEdit(data.item)" href="javascript:"><i class="fa fa-pencil pr-2"></i>Edit Task</a>
                                                                    <!-- <a class="dropdown-item addnew-tassk pb-1 py-1" @click="toggleDiv();duplicateTask(data.item);" href="javascript:"><i class="fa fa-copy pr-2" aria-hidden="true"></i>Duplicate Task</a> -->
                                                                    <a v-if="data.item.assignedById == userId || data.item.assignedToId == userId" class="dropdown-item addnew-tassk my-0 py-1" @click="showStatusPopup(data.item, false)" href="javascript:"><i class="fa fa-edit pr-2 mt-1 mb-0" aria-hidden="true"></i>Task Status Update</a>
                                                                    <a v-if="data.item.assignedById == userId || data.item.assignedToId == userId" class="dropdown-item addnew-tassk my-0 py-1" @click="openTimeSheet(data.item)" href="javascript:"><i class="fa fa-clock-o pr-2 mt-1 mb-0" aria-hidden="true"></i>Timesheet</a>
                                                                    <!-- <a v-if="data.item.assignedById == userId || data.item.assignedToId == userId" class="dropdown-item addnew-tassk my-0 py-1" @click="showTaskFinPopup(data.item)" href="javascript:"><i class="fa fa-money pr-2 mt-1 mb-0" aria-hidden="true"></i>Task Cost Update</a> -->
                                                                    <a v-if="data.item.assignedById == userId" class="dropdown-item py-1" @click="deleteTask(data.item.id)" v-b-modal.deleteTask href="javascript:"><i class="fa fa-trash pr-2"></i>Delete Task</a>
                                                                    <a v-if="data.item.assignedById == userId || userTypeCode == 'PRM' || userTypeCode == 'CAM' || userTypeCode == 'VSA'" class="dropdown-item addnew-tassk my-0 py-1" @click="archiveTask(data.item)" href="javascript:"><i class="fa fa-archive pr-2 mt-1 mb-0" aria-hidden="true"></i>Archive</a>
                                                                    <!-- <a v-if="data.item.assignedById == userId || userTypeCode == 'PRM' || userTypeCode == 'CAM' || userTypeCode == 'VSA'" class="dropdown-item addnew-tassk my-0 py-1" @click.prevent="memorizeTask(data.item)" href="javascript:"><span class="fs-11 pr-1">M<sup>+</sup></span>{{ data.item.isMemorized ? 'Remove Task From Memory' : 'Add Task To Memory' }}</a> -->
                                                                </div>

                                                            </template>                                                       
                                                            <template v-slot:empty="scope">
                                                                <div class="text-center">No Task found</div>
                                                            </template>
                                                            <template v-slot:emptyfiltered="scope">
                                                                <div class="text-center">{{ scope.emptyFilteredText }}</div>
                                                            </template>
                                                        </b-table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>          
                                        <div class="col-md-6 col-6">
                                            <div class="card p-1">
                                                <p class="chart-title text-left pl-1 mb-0">
                                                    Task Assigned By Me
                                                    <span class="dataTables_filter">
                                                        <label>
                                                            <input type="search" v-model="filter2" class="form-control form-control-sm" placeholder="Search...">
                                                        </label>
                                                    </span>
                                                    <span class="badge badge-light float-right pointer mt-1 px-2" title="Total Task Assigned To Me">{{ taskAssignedByMeCount }}</span>
                                                </p>

                                                <div class="separator mb-1"></div>
                                                <div class="card-body px-1 py-1">
                                                    <div class="row view-filter">
                                                        <div class="col-sm-12">
                                                            <div v-for="status in userTaskAssignedByStatusData" class="mb-0 pr-1 text-center float-left">
                                                                <div class="card b-radius-5">
                                                                    <div class="card-body px-2 py-2">
                                                                        <div class="form-group mb-0">
                                                                            <label class="caption-title my-auto" for="title">{{ status.status }} 
                                                                                <b-badge class="ml-2" title="Tasks">{{status.taskCount}}</b-badge>
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div class="clearfix"></div>
                                                        </div>
                                                    </div>
                                                    <div class="data-table-rows  data-tables-hide-filter">
                                                        <b-table outlined show-empty small head-variant=:primary sticky-header="45vh" :items="taskAssignedByMe" :fields="myTaskFields" responsive="sm" :filter="filter2" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection">                                         

                                                            <template v-slot:cell(taskId)="data">
                                                                <div class="text-center">{{ data.item.taskId }}</div>
                                                            </template>
                                                            <template v-slot:cell(title)="data">
                                                                <a class="tooltiptext action-link" href="javascript:" @click="viewItemDetails(data.item)">{{data.value}}</a>
                                                            </template>                                                                                                        
                                                            <template v-slot:cell(status)="data">
                                                                <span v-if="data.item.status == 'To Do'" class="badge badge-pill bg-light mb-1">{{data.value}}
                                                                </span>
                                                                <span v-else-if="data.item.status == 'In Progress'" class="badge badge-pill bg-info mb-1">{{data.value}}
                                                                </span>
                                                                <span v-else-if="data.item.status == 'Completed'" class="badge badge-pill badge-success mb-1">{{data.value}}
                                                                </span>      
                                                                <span v-else-if="data.item.status == 'Verified'" class="badge badge-pill badge-primary mb-1">{{data.value}}
                                                                </span>                                               
                                                                <span v-else-if="data.item.status == 'Closed'" class="badge badge-pill badge-dark mb-1">{{data.value}}
                                                                </span>
                                                                <span v-else-if="data.item.status == 'Cancelled'" class="badge badge-pill badge-danger mb-1">{{data.value}}
                                                                </span>
                                                                <span v-else-if="data.item.status == 'On Hold'" class="badge badge-pill badge-dark mb-1">{{data.value}}</span>
                                                                <span v-else>{{data.value}}</span>
                                                            </template>
                                                            <template v-slot:cell(actions)="data">

                                                                <a type="button" class="text-center" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                    <i class="fa fa-ellipsis-v p-1"></i>
                                                                </a>    
                                                                <div class="dropdown-menu dropdown-primary" aria-labelledby="dropdownMenuLink">
                                                                    <a class="dropdown-item addnew-tassk pb-1 py-1" @click="viewItemDetails(data.item)" href="javascript:"><i class="fa fa-list-alt pr-2" aria-hidden="true"></i>Task Details</a>
                                                                    <a v-if="data.item.assignedById == userId" class="dropdown-item addnew-tassk py-1" @click="showEdit(data.item)" href="javascript:"><i class="fa fa-pencil pr-2"></i>Edit Task</a>
                                                                    <!-- <a class="dropdown-item addnew-tassk pb-1 py-1" @click="toggleDiv();duplicateTask(data.item);" href="javascript:"><i class="fa fa-copy pr-2" aria-hidden="true"></i>Duplicate Task</a> -->
                                                                    <a v-if="data.item.assignedById == userId || data.item.assignedToId == userId" class="dropdown-item addnew-tassk my-0 py-1" @click="showStatusPopup(data.item, false)" href="javascript:"><i class="fa fa-edit pr-2 mt-1 mb-0" aria-hidden="true"></i>Task Status Update</a>
                                                                    <a v-if="data.item.assignedById == userId || data.item.assignedToId == userId" class="dropdown-item addnew-tassk my-0 py-1" @click="openTimeSheet(data.item)" href="javascript:"><i class="fa fa-clock-o pr-2 mt-1 mb-0" aria-hidden="true"></i>Timesheet</a>
                                                                    <!-- <a v-if="data.item.assignedById == userId || data.item.assignedToId == userId" class="dropdown-item addnew-tassk my-0 py-1" @click="showTaskFinPopup(data.item)" href="javascript:"><i class="fa fa-money pr-2 mt-1 mb-0" aria-hidden="true"></i>Task Cost Update</a> -->
                                                                    <a v-if="data.item.assignedById == userId" class="dropdown-item py-1" @click="deleteTask(data.item.id)" v-b-modal.deleteTask href="javascript:"><i class="fa fa-trash pr-2"></i>Delete Task</a>
                                                                    <a v-if="data.item.assignedById == userId || userTypeCode == 'PRM' || userTypeCode == 'CAM' || userTypeCode == 'VSA'" class="dropdown-item addnew-tassk my-0 py-1" @click="archiveTask(data.item)" href="javascript:"><i class="fa fa-archive pr-2 mt-1 mb-0" aria-hidden="true"></i>Archive</a>
                                                                    <!-- <a v-if="data.item.assignedById == userId || userTypeCode == 'PRM' || userTypeCode == 'CAM' || userTypeCode == 'VSA'" class="dropdown-item addnew-tassk my-0 py-1" @click.prevent="memorizeTask(data.item)" href="javascript:"><span class="fs-11 pr-1">M<sup>+</sup></span>{{ data.item.isMemorized ? 'Remove Task From Memory' : 'Add Task To Memory' }}</a> -->
                                                                </div>

                                                            </template>                                                       
                                                            <template v-slot:empty="scope">
                                                                <div class="text-center">No Task found</div>
                                                            </template>
                                                            <template v-slot:emptyfiltered="scope">
                                                                <div class="text-center">{{ scope.emptyFilteredText }}</div>
                                                            </template>

                                                        </b-table>
                                                    </div>

                                                </div>                                        
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <template #overlay>
                <div class="text-center">
                    <LoadingSpinner/>
                </div>
            </template>
        </b-overlay>
        <AddTaskComponent ref="addTaskRef" :elements="elements" @refresh-task="getUserTask()" />
        <DeleteComponent id="deleteTask" :onYes="onYes" :returnParams="deleteParam" title="Delete Confirmation" message="Are you sure to delete Task?" />        
        <QuickTaskPopup ref="ermAssignmentsRef" :projectTypeId="this.projectTypeId" @refresh-task="getUserTask()"/>          
        <TaskDetailPopup v-if="showDialog" ref="taskDetailPopupRef" :detailViewData="detailViewData" :attachmentData="attachmentData" :referenceDetailId="referencePopupId" @closeDialog="closeDialog" />
        <TaskFinancialPopup :key="taskFinancialPopupKey" ref="taskFinancialPopupRef" id="task-financial-popup" :taskFinancialData="taskFinancialData" @refreshData="" @closeDialog="closeDialog" />        
        <TaskStatusUpdatePopup v-if="showDialog" ref="taskSatatusUpdatePopupRef" :statusUpdate="statusUpdate" @updateParent="getUserTask();addEmployeeAttendanceTimeline(4)" @closeDialog="closeDialog" />
        <TaskTimeSheetPopup ref="timeSheetPopupRef" :taskData="timeSheetTaskData"  @closeDialog="closeDialog" />
        
    </div>
</template>
<script>

import iziToast from 'izitoast';
import moment from 'moment';

import AddTaskComponent from '@/components/common/AddTaskComponent.vue';
import QuickTaskPopup from '@/components/popup/action/QuickTaskPopup.vue';
import TaskDetailPopup from '@/components/popup/itemdetail/TaskDetailPopup.vue';
import TaskFinancialPopup from '@/components/popup/action/TaskFinancialPopup.vue';
import TaskStatusUpdatePopup from '@/components/popup/action/TaskStatusUpdatePopup.vue';
import TaskTimeSheetPopup from '@/components/popup/action/TaskTimeSheetPopup.vue';

import { EventBus } from '@/main';

export default {
    name: "App",
    data() {
        return {
            showOverlay: false,
            parentVhextAccountId: localStorage.getItem("parentVhextAccountId"),
            vhextAccountId: localStorage.getItem("vhextAccountId"),
            projectId: localStorage.getItem("projectId"),
            projectTypeId: localStorage.getItem("projectTypeId"),
            projId: localStorage.getItem("projId"),
            projectName: localStorage.getItem("projectName"),
            userId: localStorage.getItem("userId"),
            isPrimaryRole: localStorage.getItem("isPrimaryRole"),
            userTypeCode: localStorage.getItem("userTypeCode"),
            userTypeId: localStorage.getItem("userTypeId"),

            elements: {
                clientTaskPage: '',
                editMode: false,
                viewMode: false,
                viewOnlyComponent: false,
                filterMode: false,
                taskStatusUpdate: false,
                isHide: true,
                isDisable: true
            },

            startTime: '',
            timelineTaskTitle: '',
            timerDuration: '00:00:00',
            timerValue: 0,
            empWorkState:'',
            empWorkStatus: {
                empTimelineStateEnum: 0,
                empTimelineDt: '',
                task: '',
            },

            entity: 'task',

            isArchive: false,
            showDialog: false,

            taskFinancialData: {
                vhextAccountId: '',
                projectId: '',
                taskId: '',
            },
            taskFinancialPopupKey: 0,

            statusUpdate: {
                id: '',
                taskId: '',
                title: '',
                entityRecordMasterId: '',
                progressPercentage: 0,
                statusId: '',
                status: '',
                activityId: '',
                startDateValue: '',
                endDateValue: '',
                startTimeValue: '',
                endTimeValue: '',
                popupTitle: '',
            },

            humanResourceDetails: {
                id: '',
                vhextAccountId: '',
                entity: '',
                userId: '',
                userName: '',
                loginName: '',
                userType: '',
                active: '',
                humanResourceId: '',                
                humanResourceTypeId: '',
                subContractId: '',
                name: '',
                photo: '',
                position:'',
                gender: '',
                dateOfBirth:'',
                qualification: '',
                experienceYears:'',
                experienceMonths:'',
                roleResponsibility:'',
                technologySkill:'',
                recruitedById:'',
                recruitedBy:'',
                joiningDate:'',
                workShiftId: '',
                jobCategoryId: '',
                jobCategory: '',
                createdBy: '',
                createdDt: '',
                lastUpdatedDt: '',
                empId: '',
                employeeId: '',
                employeeCode: '',
                reportingToId: '',
                reportingTo: '',
                employmentChangeById: '',
                employmentChangeBy: '',
                wefDate: '',
                endDate: '',
                designationId: '',
                workTypeId: '',
                salary: null,
                hourlySalary: null,
                employeeStatusId: '',
                employeeStatus: '',
                active: true,
                isEmployee: false,

            },
            userTaskByStatusData: {},
            userTaskAssignedByStatusData: {},
            attachmentData: {},

            taskCount: 0,
            taskAssignedToMeCount: 0,
            taskAssignedByMeCount: 0,

            timeSheetTaskData: null,
            

            myTaskList: [],
            myTaskFields: [
                { key: 'taskId', label: 'Id', sortable: true, sortDirection: 'desc' },
                { key: 'title', label: 'Task Title', sortable: true, sortDirection: 'desc' },
                // { key: 'activity', label: 'Activity', sortable: true, sortDirection: 'desc' },
                // { key: 'description', label: 'Description', tdClass: 'table-cw-400', sortDirection: 'desc' },
                // { key: 'estimatedStartDt', label: 'Start Time', formatter: 'formatDateTimeAmPm', sortable: true, sortDirection: 'desc' },
                { key: 'estimatedEndDt', label: 'Due Date', formatter: 'formatDateTimeYearCheck', sortable: true, sortDirection: 'desc' },
                // { key: 'assignedBy', label: 'Assigned By', tdClass: 'table-cw-400', sortDirection: 'desc' },
                { key: 'assignedTo', label: 'Assigned To', tdClass: 'table-title-col-size', sortable: true, sortDirection: 'desc' },
                { key: 'status', label: 'Status', sortable: true, tdClass: 'table-cw-100', sortDirection: 'desc', class: 'text-center' },
                { key: 'actions', label: 'Actions', class: 'text-center'}
            ],


            selectMode: 'single',
            totalRows: 1,
            currentPage: 1,
            // perPage: 5,
            pageOptions: [5, 10, 15],
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',
            filter: null,
            filter2: null,
            filterOn: [],

            deleteParam: {
                id: 0
            }

        }
    },
    components: {
        AddTaskComponent,
        QuickTaskPopup,
        TaskDetailPopup,
        TaskFinancialPopup,
        TaskStatusUpdatePopup,
        TaskTimeSheetPopup,
    },    
    computed: {
        taskAssignedToMe() {
            let filteredTask = this.myTaskList.filter(item => item.assignedToId == this.userId);

            this.taskAssignedToMeCount = filteredTask.length;

            return filteredTask;
        },
        taskAssignedByMe() {
            let filteredTask = this.myTaskList.filter(item => item.assignedById == this.userId);

            this.taskAssignedByMeCount = filteredTask.length;

            return filteredTask;
        },

    },
   created() {
        EventBus.$on('onEmpAttTimelineUpdate', () => {
            this.getEmpWorkStatus()
        });
    },
    beforeDestroy() {
        EventBus.$off('onEmpAttTimelineUpdate');
    },

    mounted() {
        this.getDashboardData();
        this.getEmpWorkStatus();
    },
    validations: {
    },
    methods: {
        getDashboardData: function() {
            let data = {
                parentVhextAccountId: this.parentVhextAccountId,
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                userId: localStorage.getItem("userId"),
                isArchive: false,
            }
            this.showOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/dashboard/mydata/all', data)
                .then((response) => {
                    this.showOverlay = false;
                    let result = response.data;

                    this.humanResourceDetails = result.humanResourceView;
                    this.myTaskList = result.userTaskList;

                    this.userTaskByStatusData = result.userTaskByStatus;
                    this.userTaskAssignedByStatusData = result.userTaskAssignedByStatus;

                    this.taskCount = this.myTaskList.length;
                })
                .catch(error => {
                    this.showOverlay = false;
                    console.error(error);
                    iziToast.error({
                       title: 'Error',
                       message: 'Error',
                       position: 'topRight'
                   });
                })
        },
        getUserTask: function() {
            let data = {
                parentVhextAccountId: this.parentVhextAccountId,
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                userId: localStorage.getItem("userId"),
                isArchive: false,
            }
            this.showOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/dashboard/mydata/all', data)
                .then((response) => {
                    this.showOverlay = false;
                    let result = response.data;

                    this.myTaskList = result.userTaskList;
                    this.taskCount = this.myTaskList.length;
                })
                .catch(error => {
                    this.showOverlay = false;
                    console.error(error);
                    iziToast.error({
                       title: 'Error',
                       message: 'Error',
                       position: 'topRight'
                   });
                })
        },
        getEmpWorkStatus: function() {
            let data = {
                userId: this.userId,
            }
            console.log(data)
            axios.get(this.$store.getters.getAPIBasePath + '/resource/empworkstatus/view/'  + this.userId)
                .then((response) => {
                    let result = response.data;
                    this.empWorkStatus = result.empWorkStatus;
                    this.empWorkState = this.empWorkStatus?.empTimelineStateEnum ? this.empWorkStatus.empTimelineStateEnum : 0;
                    if (this.empWorkStatus) {
                        this.$nextTick(() => {
                            if (this.empWorkStatus.empTimelineStateEnum == 1) {
                                this.startTime = this.empWorkStatus.empTimelineDt;
                                this.timelineTaskTitle = this.empWorkStatus.task;
                                this.pageTitleClock();
                            }
                            else if (this.empWorkStatus.empTimelineStateEnum == 2) {
                                this.startTime = this.empWorkStatus.empTimelineDt;
                                this.timelineTaskTitle = this.empWorkStatus.task;
                                this.pageTitleClock();
                            }
                            else if (this.empWorkStatus.empTimelineStateEnum == 3) {
                                this.startTime = this.empWorkStatus.empTimelineDt;
                                this.timelineTaskTitle = this.empWorkStatus.task;
                                this.pageTitleClock();
                            }
                            else if (this.empWorkStatus.empTimelineStateEnum == 4) {
                                this.startTime = '';
                                this.timerDuration = '00:00:00';
                            }
                        })
                    }
                })
        },
        pageTitleClock: function() {
            // console.log(this.timerDuration, this.timerValue)
            this.timerDuration = this.startTime ? this.getTimeDiff(new Date(), this.startTime, true) : '00:00:00';
            this.timerValue = setTimeout(this.pageTitleClock, 1000);
        },
        clearTimerVal() {
            clearTimeout(this.timerValue);
            this.startTime = '';
            this.timerDuration = '00:00:00';
        },

        toggleDiv: function() {
            this.elements.isHide = !this.elements.isHide;
            this.elements.isDisable = !this.elements.isDisable;
        },
        memorizeTask: function(itemData) {
            let data = {
                'id': itemData.id,
                'entity': 'task',
                'columnName': 'is_memorized',
                'stateValue': itemData.isMemorized ? false : true,
            }
            var confirmMessage = '';
            if (itemData.isMemorized) {
                confirmMessage = "Are you sure to remove the task from memory?"
            } else {
                confirmMessage = "Are you sure to add the task to memory?"
            }
            this.$bvModal.msgBoxConfirm(confirmMessage, this.messageBoxData)
            .then(value => {
                if (value) {
                    this.showOverlay = true;
                    axios.post(this.$store.getters.getAPIBasePath + '/dynamicsql/recordstate/update', data)
                        .then((response) => {
                            this.showOverlay = false;
                            let result = response.data;
                            if (result.status == "OK") {
                                iziToast.success({
                                    title: 'Updated',
                                    position: 'topCenter'
                                });
                                this.getUserTask();
                                this.taskMemoryPopupKey++;
                            }
                        })
                        .catch((error) => {
                            this.showOverlay = false;
                        })
                }
            })
        },
        archiveTask: function(item) {
            let data = {
                id: item.id,
                entity: this.entity,
                columnName: 'is_archive',
                stateValue: (item.isArchive == true ? false : true)
            }
            console.log(data)
            this.showOverlay=true;
            axios.post(this.$store.getters.getAPIBasePath + '/dynamicsql/recordstate/update', data)
                .then((response) => {
                    this.showOverlay=false;
                    let result = response.data;
                    if (result.status == "OK") {
                        iziToast.success({
                            title: 'Task Archived',
                            position: 'topRight'
                        });
                        this.getUserTask();
                    }
                })
                .catch(error => {
                    this.showOverlay=false;
                });
        },

        deleteTask: function(id) {
            console.log(id)
            this.deleteParam.id = id;
        },
        onYes: function() {
            let data = {
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                projectId: localStorage.getItem("projectId"),
                entity: this.entity,
                id: this.deleteParam.id,               
            }
            console.log(data);

            axios.post(this.$store.getters.getAPIBasePath + '/task/delete', data)
                .then(response => {
                    var result = response.data;
                    if (result.status == "OK") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.getUserTask();
                    }
                })
                .catch(error => {
                    console.log(error);
                });
        },
        viewItemDetails: function(data) {
            console.log(data);
            this.detailViewData = data;
            this.referencePopupId = data.id;
            this.attachmentData.refEntity = this.entity;
            this.attachmentData.refRecordId = data.id;
            this.showDialog = true;
            this.$nextTick(()=> {
                this.$refs.taskDetailPopupRef.getDetails(this.referencePopupId, null);
            })
        },
        showEdit: function(data) {
            this.$bvModal.show('ermAssignments');
            this.$nextTick(() => {
                this.$refs.ermAssignmentsRef.showEdit(data);
            })
        },
        duplicateTask: function(data) {
            this.$nextTick(()=> {                
                this.$refs.addTaskRef.duplicateTask(data);
            });
        },
        showStatusPopup: function(data, endTimeline) {
            console.log("STP",data);
            if (data && data !== null) {
                this.statusUpdate.id = data.id;
                this.statusUpdate.taskId = data.taskId;
                this.statusUpdate.title = data.title;
                this.statusUpdate.entityRecordMasterId = data.entityRecordMasterId;
                this.statusUpdate.statusId = data.statusId;
                this.statusUpdate.status = data.status;
                this.statusUpdate.activityId = data.activityId;
                this.statusUpdate.progressPercentage = data.progressPercentage ? data.progressPercentage : 0;

                if (endTimeline) {
                    this.statusUpdate.popupTitle = null;
                    this.statusUpdate.startDateValue = this.empWorkStatus.empTimelineDt ? this.getDateValue(this.empWorkStatus.empTimelineDt) : this.getDateValue(new Date());
                    this.statusUpdate.endDateValue = this.getDateValue(new Date());
                    this.statusUpdate.startTimeValue = this.empWorkStatus.empTimelineDt ? this.getTimeValue(this.empWorkStatus.empTimelineDt) : null;
                    this.statusUpdate.endTimeValue = this.getTimeValue(new Date());
                }
                else {
                    this.statusUpdate.popupTitle = null;
                    this.statusUpdate.startDateValue = this.getDateValue(new Date());
                    this.statusUpdate.endDateValue = this.getDateValue(new Date());
                    this.statusUpdate.endTimeValue = '';
                    this.statusUpdate.startTimeValue = '';
                }

                this.showDialog = true;
                this.$nextTick(()=> {
                    this.$bvModal.show('taskSatatusUpdatePopup');
                })
            }
        },
        openTimeSheet: function(data) {
            this.$nextTick(()=> {
                this.timeSheetTaskData = data;
            });
            this.showDialog = true;
            this.$nextTick(()=> {
                this.$refs.timeSheetPopupRef.openDialog();
            })
        },
        showTaskFinPopup: function(data) {
            console.log(this.$refs)
            this.taskFinancialData.vhextAccountId = data.vhextAccountId;
            this.taskFinancialData.projectId = data.projectId;
            this.taskFinancialData.taskId = data.id;
            this.taskFinancialPopupKey++;
            this.$nextTick(()=> {
                this.$bvModal.show('task-financial-popup');
            })
        },
        closeDialog: function() {
            console.log('closed');
            this.showDialog = false;
            // this.clearData();
        },


    }
}
</script>