<template>
    <div class="type">
        <b-modal ref="ermMasterModal" size="lg" :id="id" centered modal-class="ermMasterModal" :title="ermMasterInfo.title" @hide="closeDialog" :no-close-on-backdrop="true" ok-title="Ok">
            <b-overlay :show="showOverlay" spinner-variant="info" opacity="0.4" rounded="sm">
                <div class="p-2">
                    <b-card no-body class="mb-3">
                        <b-card-header header-tag="header" class="p-0 accordion-header" role="tab">
                            <label class="d-block p-2 accordion-title" block href="#" v-b-toggle.accordion-add>
                                <span v-if="!elements.editMode"><i class="fa fa-plus-circle pr-2 action-link"></i>New {{ ermMasterInfo.title }}</span>
                                <span v-if="elements.editMode">Edit</span>
                            </label>
                        </b-card-header>
                        <b-collapse id="accordion-add" accordion="my-accordion2" v-model="elements.visible" role="tabpanel">
                            <b-card-body class="p-0">
                                <div class="row">
                                    <b-input-group class="form-group py-2">
                                        <div class="align-middle float-left my-2 ml-4 mr-0" cols="2">Title</div>
                                        <b-col cols="9" class="pr-1">
                                            <div class="required-field-block" :class="{ 'is-invalid': $v.entityRecordMaster.title.$error }">
                                                <b-form-input id="title" v-model="entityRecordMaster.title" placeholder="Enter Title"></b-form-input>
                                                <div class="required-icon"></div>
                                            </div>
                                            <label v-if="$v.entityRecordMaster.title.$error && !$v.entityRecordMaster.title.required" class="text-danger m-0">Please Enter Title</label>
                                            <!-- <div class="mt-1">
                                                <input type="checkbox" class="align-middle mr-1" title="Create as Public Document" v-model="entityRecordMaster.isPublic"><span class="align-middle d-inline-block">Public</span>
                                            </div> -->
                                        </b-col>
                                        <div class="float-right">
                                            <div class="more-btns">
                                                <button type="submit" title="Save" @click="saveERMMaster" class="sbtn btn btn-xs action-link p-1"><i class="fa fa-check" aria-hidden="true"></i></button>
                                                <button type="submit" title="Clear" @click="clearERMMasterData" class="sbtn btn btn-xs action-link p-1"><i class="fa fa-times" aria-hidden="true"></i></button>
                                            </div>
                                        </div>
                                        <!-- <div class="align-middle float-left my-auto ml-4 mr-0" cols="2">Public</div> -->
                                        <!-- <b-col cols="9" class="pr-1 ml-4">
                                            <input type="checkbox" class="align-middle mr-1" v-model="entityRecordMaster.isPublic"><span class="align-middle d-inline-block">Public</span>
                                        </b-col> -->
                                    </b-input-group>
                                </div>
                            </b-card-body>
                        </b-collapse>
                    </b-card>
                    <div class="row view-filter">
                        <div class="col-sm-12">
                            <div class="row input-group-label">
                                <div class="col-6">
                                    <label class="mb-0 mt-2 fs-14">{{ ermMasterInfo.title }} List</label>
                                </div>
                                <div class="col-3">
                                    <div v-if="!isArchive" class="float-right pt-2 pr-2">
                                        <a class="action-link" :title="'Archived Docs'" href="javascript:" @click="getERMHistory()"><i class="fa fa-history"></i> Archive</a>
                                    </div>
                                    <div v-if="isArchive" class="float-right pt-2 pr-2">
                                        <a class="action-link" :title="ermMasterInfo.tooltip" href="javascript:" @click="getERMList()"><i class="fa fa-history"></i> {{ermMasterInfo.title}} List</a>
                                    </div>                                    
                                </div>
                                <div class="col-3 dataTables_filter text-right">
                                    <label>
                                        <input type="search" v-model="filter" class="form-control form-control-sm" placeholder="Search...">
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>

<!--                     <div class="float-right">
                        <div class="dataTables_length">
                            <b-form-group label="Per page" label-cols-sm="6" label-align-sm="right" label-size="sm" label-for="perPageSelect" class="mb-0">
                                <b-form-select v-model="perPage" id="perPageSelect" size="sm" :options="pageOptions"></b-form-select>
                            </b-form-group>
                        </div>
                    </div> -->
                    <b-table show-empty small stacked="md" selectable :select-mode="selectMode" :busy="isBusy" sort-icon-left :items="ermList" :fields="fields" responsive="sm" :current-page="currentPage" :per-page="perPage" :filter="filter" :filterIncludedFields="filterOn" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection">
                        <template v-slot:cell(isPublic)="data">
                            <b-form-checkbox class="ml-3" v-model="data.value" @change="ermPublicAccess(data.item)">
                            </b-form-checkbox>
                        </template>
                        <template v-slot:cell(actions)="data">
                            <div v-if="data.item.isFreeze==true">
                                <a href="javascript:" class="text-center" title="Freezed"><i class="fa fa-lock fa-custom"></i></a>
                            </div>
                            <div v-else-if="data.item.createdById == userId">
                                <div>
                                    <span v-if="!data.item.isArchive">
                                        <a href="javascript:" class="text-center" title="Edit" @click="showEdit(data.item)"><i class="fa fa-pencil-square-o pr-1"></i></a>
                                        <a href="javascript:" class="text-center" title="Archive" @click.prevent="archiveERM(data.item)"><i class="fa fa-archive pr-1" aria-hidden="true"></i></a>
                                        <a href="javascript:" class="text-center" title="Delete" v-b-modal.deleteERM @click.prevent="deleteERM(data.item.id)" ><i class="fa fa-trash" aria-hidden="true"></i></a>                                                                            
                                    </span>
                                    <span v-if="data.item.isArchive">
                                        <a href="javascript:" class="text-center" title="Unarchive" @click.prevent="archiveERM(data.item)"><i class="fa fa-undo pr-1" aria-hidden="true"></i></a>
                                        <a href="javascript:" class="text-center" title="Delete" v-b-modal.deleteERM @click.prevent="deleteERM(data.item.id)" ><i class="fa fa-trash" aria-hidden="true"></i></a>                                        
                                    </span>
                                </div>

<!--                                 <a href="javascript:" class="text-center" title="Edit" @click="showEdit(data.item)"><i class="fa fa-pencil-square-o fa-custom fa-lg"></i></a> -->

                            </div>
                            <div v-else>
                                <a href="javascript:" class="text-center" title="NA">NA</a>
                            </div>


<!--                             <a v-if="data.item.isFreeze==true" href="javascript:" class="text-center" title="Freezed"><i class="fa fa-lock fa-custom"></i></a>
                            <a v-else href="javascript:" class="text-center" title="Edit" @click="showEdit(data.item)"><i class="fa fa-pencil-square-o fa-custom fa-lg"></i></a> -->
                            <!-- <a href="javascript:" title="Delete" @click="" data-toggle="modal" data-target="#removeERMMaster"><i class="fa fa-trash pl-2"></i></a> -->
                        </template>
                        <template v-slot:empty="scope">
                            <div class="text-center">No {{ ermMasterInfo.title }} found</div>
                        </template>
                        <template v-slot:emptyfiltered="scope">
                            <div class="text-center">{{ scope.emptyFilteredText }}</div>
                        </template>
                    </b-table>
                    <span :class="'badge-secondary'" class="fs-11 badge badge-pill pointer text-center float-left">{{this.totalRows}} Items</span> 
                    <b-pagination v-if="ermList && ermList.length!=0" pills v-model="currentPage" :total-rows="totalRows" :per-page="perPage" first-number last-number align="fill" size="sm" class="my-0 pull-right"></b-pagination>
                </div>
            </b-overlay>
        </b-modal>
        <!--        <Dialog id="deActivate" :onYes="onYes" :returnParams="deleteParam" title="Delete Confirmation" message="Are you sure to delete Comment?" />  -->
    </div>
</template>
<script>
import iziToast from 'izitoast';
import { required, email, minLength, maxLength, sameAs } from "vuelidate/lib/validators";
export default {
    props: {
        id: { type: String, default: 'ermMasterModal' },
        ermMasterInfo: Object
    },
    components: {

    },
    data() {
        return {
            entity: this.ermMasterInfo.entity,
            vhextAccountId: localStorage.getItem("vhextAccountId"),
            projectId: localStorage.getItem("projectId"),
            userId: localStorage.getItem("userId"),

            isArchive: false,            

            elements: {
                visible: false,
                editMode: false
            },

            isBusy: false,
            showOverlay: false,
            entityRecordMaster: {
                title: '',
                clientId: '',
                ermTypeId: '',
                description: '',
                createdById: localStorage.getItem("userId"),
                statusId: '',
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                projectId: localStorage.getItem("projectId"),
                entityId: '',
                entity: this.ermMasterInfo.entity,
                refEntityId: '',
                refEntity: this.ermMasterInfo.refEntity,
                isArchive: false,
                attachments: [],
                isPublic: false,
            },

            selectMode: 'single',
            totalRows: 1,
            currentPage: 1,
            perPage: 5,
            sortDesc: false,
            pageOptions: [2, 5, 10, 15],
            sortBy: '',
            sortDirection: 'asc',
            filter: null,
            filterOn: [],
            ermList: [],
            fields: [
                { key: 'entityRecordMasterId', label: 'Id', sortable: true, sortDirection: 'desc' },
                { key: 'title', label: 'Title', sortable: true, sortDirection: 'desc', class: '' },
                { key: 'createdBy', label: 'Created By', sortable: true, sortDirection: 'desc', class: '' },
                { key: 'isPublic', label: 'Is Public', sortable: true, sortDirection: 'desc', class: '' },
                { key: 'actions', label: 'Actions' }
            ],
        }
    },
    validations: {
        entityRecordMaster: {
            title: {
                required
            }
        }
    },
    watch: {
        'entity': {
            handler: function(val1, val2) {
                this.getERMMasterList();
            },
            deep: true,
            immediate: true
        },
    },
    mounted() {
        this.getERMMasterList();
        this.lookupData();
    },
    methods: {
        getERMList: function() {
            // this.archive = false;
            this.isArchive = false;

            this.getERMMasterList();
            // console.log(this.masterData);
        },
        getERMHistory: function() {
            // this.archive = true;
            this.isArchive = true;

            this.getERMMasterList();
            // console.log(this.masterData);
        },

        getERMMasterList: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
                projectId: this.projectId,
                userId: this.userId,
                entity: this.entity,
                isArchive: this.isArchive,
            }
            console.log(data);
            this.isBusy = true;
            axios.post(this.$store.getters.getAPIBasePath + '/common/entityrecordmaster/list', data)
                .then((response) => {
                    let result = response.data;
                    console.log(result);
                    if (result.entityRecordMasterList) {
                        this.ermList = result.entityRecordMasterList;         
                        this.totalRows = this.ermList.length;
                        this.isBusy = false;
                    }
                }).catch(error => {
                    this.isBusy = false;
                });
        },
        lookupData: function() {
            let data = {
                vhextAccountId: this.entityRecordMaster.vhextAccountId,
                projectId: this.entityRecordMaster.projectId,
                entity: this.ermMasterInfo.entity,
                refEntity: this.ermMasterInfo.refEntity,
            }
            console.log(data);
            axios.post(this.$store.getters.getAPIBasePath + '/common/entityrecordmaster/lookup/all', data)
                .then((response) => {
                    let result = response.data;
                    this.entityRecordMaster.entityId = result.entityId;
                    this.entityRecordMaster.refEntityId = result.refEntityId;
                })

        },
        saveERMMaster: function() {
            this.$v.entityRecordMaster.$touch();
            if (!this.$v.entityRecordMaster.$invalid) {
                if (this.elements.editMode) {
                    this.updateERMMaster();
                } else {
                    this.addERMMaster();
                }
            }
        },
        addERMMaster: function() {
            this.showOverlay = true;
            console.log(this.entityRecordMaster);
            axios.post(this.$store.getters.getAPIBasePath + '/common/entityrecordmaster/add', this.entityRecordMaster)
                .then((response) => {
                    this.showOverlay = false;
                    let result = response.data;
                    if (result.status == "CREATED") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.$emit('refresh-lookup');
                        this.clearERMMasterData();
                        this.getERMMasterList();
                    }
                })
                .catch(error => {
                    this.showOverlay = false;
                    iziToast.error({
                       title: 'Error',
                       message: 'Error',
                       position: 'topRight'
                   });
                })
        },
        updateERMMaster: function() {
            console.log(this.entityRecordMaster)
            let data = {
                entityRecordMasterId: this.entityRecordMaster.id,
                title: this.entityRecordMaster.title
            }
            this.showOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/common/entityrecordmaster/updatetitle', data)
                .then((response) => {
                    this.showOverlay = false;
                    let result = response.data;
                    console.log(result);
                    if (result.status == 'CREATED') {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.$emit('refresh-lookup');
                        this.clearERMMasterData();
                        this.getERMMasterList();
                    }
                })
                .catch(error => {
                    this.showOverlay = false;
                    iziToast.error({
                       title: 'Error',
                       message: 'Error',
                       position: 'topRight'
                   });
                })
        },
        ermPublicAccess: function(item) {
            let data = {
                entityRecordMasterId: item.id,
                isPublic: (item.isPublic == true ? false : true),
            }
            this.showOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/common/entityrecordmaster/publicaccess', data)
                .then((response) => {
                    this.showOverlay = false;
                    let result = response.data;
                    if (result.status == "CREATED") {
                        iziToast.success({
                            title: 'Record Updated',
                            position: 'topRight'
                        });
                        this.getERMMasterList();
                    }
                })
                .catch(error => {
                    this.showOverlay = false;
                    iziToast.error({
                       title: 'Error',
                       message: 'Error',
                       position: 'topRight'
                   });
                })
        },
        clearERMMasterData: function() {
            this.entityRecordMaster.title = '';
            this.$v.entityRecordMaster.$reset();
            this.elements.editMode = false;
        },
        showEdit: function(items) {
            let data = items;
            this.elements.visible = true;
            this.elements.editMode = true;
            this.entityRecordMaster.id = data.id;
            this.entityRecordMaster.title = data.title;
        },
        archiveERM: function(itemData) {
            let data = {
                entityRecordMasterId: itemData.id,
                isArchive: (itemData.isArchive == true ? false : true),
            }
            console.log(data.entityRecordMasterId, data.isArchive);
            var confirmMessage = '';
            if (data.isArchive == false) {
                confirmMessage = "Are you sure to unarchive?"
            } else {
                confirmMessage = "Are you sure to archive?"
            }
            this.$bvModal.msgBoxConfirm(confirmMessage, this.messageBoxData)
            .then(value => {
                if (value) {
                    axios.post(this.$store.getters.getAPIBasePath + '/common/entityrecordmaster/archive', data)
                        .then((response) => {
                            let result = response.data;
                            if (result.status == "CREATED") {
                                iziToast.success({
                                    title: 'Record Updated',
                                    // message: result.message,
                                    position: 'topCenter'
                                });
                                this.$emit('update-parent');
                                this.clearERMMasterData();
                                this.getERMMasterList();
                            }
                        })
                }
            })
        },        
        deleteERM: function(id) {
            this.deleteParam.id = id;
        },
        onYes: function() {
            let data = {
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                projectId: localStorage.getItem("projectId"),
                entity: this.entity,
                id: this.deleteParam.id,               
            }
            console.log("Del",data);
            this.isBusy = true;
            axios.post(this.$store.getters.getAPIBasePath + '/common/erm/delete', data)
                .then(response => {
                    this.isBusy = false;
                    var result = response.data;
                    if (result.status == "OK") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.$emit('update-parent');
                        this.clearERMMasterData();
                        this.getERMMasterList();
                    }
                })
                .catch(error => {
                    this.isBusy = false;
                    console.log(error);
                });
        },

        closeDialog() {
            this.$emit('closeDialog');
        }

    }
}
</script>