<template>
    <div class="assignmentPopup">
        <b-modal no-close-on-backdrop no-close-on-esc :cancel-only="calendarEvent.viewMode ? true : false" no-enforce-focus @hide="closeDialog" id="addCalEventPopup" ref="addCalEventRef" size="xl" modal-class="">
        	<template #modal-header="{ close }">
		      	<h5 class="m-0 popup-tab-header" :class="activeTab == 1 && calendarEvent.showMOM ? 'popup-active-tab' : 'popup-tab-header'" @click="activeTab = 1">{{ calendarEvent.editMode ? 'Edit Event / Meeting' : 'Event / Meeting' }}</h5>
		      	<div v-if="calendarEvent.showMOM" class="col-auto text-left ml-3">
			      	<h5 class="m-0 popup-tab-header" title="Agenda Items" :class="{ 'popup-active-tab' : activeTab == 2 }" @click="activeTab = 2;meetingMinute.entityRecordMasterId=calendarEvent.entityRecordMasterId;clearMMData();">Agenda Items</h5>
		      	</div>
		      	<div v-if="calendarEvent.showMOM" class="col text-left">
			      	<h5 class="m-0 popup-tab-header" title="Minutes of Meeting" :class="{ 'popup-active-tab' : activeTab == 3 }" @click="setMOMTab">MOM</h5>
		      	</div>
		      	<div v-if="activeTab == 1 && !calendarEvent.editMode && calendarEvent.viewMode && !calendarEvent.isMOMRecorder" class="col text-right">
		      		<nav class="breadcrumb-container d-sm-block d-lg-inline-block float-right" aria-label="breadcrumb">
	                    <ol class="breadcrumb pt-0 my-0">
	                        <li class="breadcrumb-item">
	                            <a href="javascript:" class="mx-1" @click="calendarEvent.viewMode=false;calendarEvent.editMode=true;"><i class="fa fa-pencil pr-2 action-link" aria-hidden="true"></i>Edit</a>
	                        </li>
	                        <li class="breadcrumb-item">
	                            <a href="javascript:" class="mx-1" title="Delete" @click="deleteCalendarEvent(calendarEvent.id)" v-b-modal.deleteCalendarEvent><i class="fa fa-trash pr-2 action-link" aria-hidden="true"></i>Delete</a>
	                        </li>
	                    </ol>
	                </nav>
                </div>
		      	<div v-if="activeTab == 3" class="col text-right">
		      		<nav class="breadcrumb-container d-sm-block d-lg-inline-block float-right" aria-label="breadcrumb">
	                    <ol class="breadcrumb pt-0 my-0">
	                        <li v-if="elements.viewModeMOM" class="breadcrumb-item">
	                            <a href="javascript:" title="Add/Modify Minutes of Meeting" class="mx-1" @click="elements.viewModeMOM = false;clearMMData();"><i class="fa fa-pencil pr-2 action-link" aria-hidden="true"></i>Add / Modify</a>
	                        </li>
	                        <li v-else class="breadcrumb-item">
	                            <a href="javascript:" title="View Minutes of Meeting" class="mx-1" @click="elements.viewModeMOM = true;clearMMData();"><i class="fa fa-eye pr-2 action-link" aria-hidden="true"></i>View</a>
	                        </li>
	                    </ol>
	                </nav>
                </div>
		      	<button type="button" @click="close()" aria-label="Close" class="close">×</button>
		    </template>
            <b-overlay :show="showOverlay" spinner-variant="info" opacity="0.4" rounded="sm">
                <b-container v-show="activeTab == 1" class="px-4">
	                <b-row class="mb-1 no-gutters">
	                    <b-col cols="2" class="my-auto">
	                        <label class="my-auto" for="title">Title</label>
	                    </b-col>
	                    <b-col cols="10">
	                    	<div class="row">
		                    	<div class="col-md-12">
			                        <div v-if="!calendarEvent.viewMode">
			                            <div class="required-field-block" :class="{ 'is-invalid': $v.calendarEvent.title.$error }">
			                                <b-form-input id="title" v-model="calendarEvent.title" :class="{ 'is-invalid': $v.calendarEvent.title.$error }" placeholder="Enter Event Title"></b-form-input>
			                                <div class="required-icon"></div>
			                            </div>
			                            <label v-if="$v.calendarEvent.title.$error && !$v.calendarEvent.title.minLength" class="text-danger">Title must have at least {{$v.calendarEvent.title.$params.minLength.min}} letters.</label>
			                            <label v-if="$v.calendarEvent.title.$error && !$v.calendarEvent.title.required" class="text-danger">Please enter Event Title</label>
			                            <label v-if="$v.calendarEvent.title.$error && !$v.calendarEvent.title.maxLength" class="text-danger">Title must not exceed {{$v.calendarEvent.title.$params.maxLength.max}} letters.</label>
			                        </div>
			                        <b-form-input v-if="calendarEvent.viewMode" id="title" disabled v-model="calendarEvent.title" placeholder="Enter Event Title"></b-form-input>
		                    	</div>
	                    	</div>
	                    </b-col>
	                </b-row>
	                <b-row class="my-2 no-gutters">
                        <b-col cols="2" class="my-auto">
                            <label class="my-auto" for="eventStartTime">Event Type</label>
                        </b-col>
                        <b-col cols="10">
                        	<div class="row">
		                        <div class="col-md-12">
		                        	<div class="row">
		                        		<div class="col-11 pr-0">
					                        <select v-if="!calendarEvent.viewMode" v-model="calendarEvent.eventTypeId" class="form-control" id="eventTypeId">
					                            <option value="">Select</option>
					                            <option v-for="(info, index) in eventTypes" :value="info.id">{{info.value}}</option>
					                        </select>
					                        <input type="text" class="form-control" disabled v-if="calendarEvent.viewMode" v-model="calendarEvent.type">
		                        		</div>
		                        		<div class="col-1 text-right pl-0">
					                        <a :title="typeLookupTitle" class="action-link" href="javascript:" @click="typePopupKey++" v-b-modal.eventTypeLookup><i class="fa fa-cog setting-icon-alt" aria-hidden="true"></i></a>
				                        </div>
		                        	</div>
		                        </div>
		                    </div>
                        </b-col>
                    </b-row>
                    <b-row class="my-2 no-gutters">
                        <b-col cols="2" class="my-auto">
                            <label class="my-auto" for="eventStartTime">Event Group</label>
                        </b-col>
                        <b-col cols="10">
                        	<div class="row">
		                    	<div class="col-md-12">
		                    		<div class="row">
		                    			<div class="col-11 pr-0">
				                            <div class="required-field-block" :class="{ 'is-invalid': $v.calendarEvent.entityRecordMasterId.$error }">
				                                <select :disabled="calendarEvent.viewMode" v-model="calendarEvent.entityRecordMasterId" :class="{ 'is-invalid': $v.calendarEvent.entityRecordMasterId.$error }" class="form-control">
				                                    <option value="">Select</option>
				                                    <option v-for="(info, index) in entityERMLookup" :value="info.id">{{info.value}}</option>
				                                </select>
				                                <div class="required-icon"></div>
				                            </div>
				                            <label v-if="$v.calendarEvent.entityRecordMasterId.$error && !$v.calendarEvent.entityRecordMasterId.required" class="text-danger">Please Select a Meeting</label>
		                    			</div>
				                        <div class="col-1 text-right pl-0">
					                        <a title="Create new Meeting" class="action-link" href="javascript:" @click="openErmMasterPop"><i class="fa fa-cog setting-icon-alt" aria-hidden="true"></i></a>
				                        </div>
		                    		</div>
		                        </div>
		                    </div>
                        </b-col>
                    </b-row>
                    <b-row class="my-2 no-gutters">
                        <b-col cols="2" class="my-auto">
                            <label class="my-auto" for="eventStartTime">Date</label>
                        </b-col>
                    	<b-col cols="10">
	                        <div class="row">
		                        <div class="col-6">
		                        	<b-row>
		                        		<b-col class="pr-0">
				                            <input type="date" class="form-control" v-if="!calendarEvent.viewMode" v-model="calendarEvent.eventStartDate" id="eventStartTime">                             
			                                <input v-if="calendarEvent.viewMode" type="text" class="form-control" disabled :value="calendarEvent.eventStartDate | formatDate">
				                        </b-col>
				                        <b-col v-if="!calendarEvent.viewMode || (calendarEvent.viewMode && calendarEvent.eventEndDate)" cols="1" class="px-0 my-auto text-center">
					                        <label class="my-auto" for="eventEndTime">to</label>
					                    </b-col>
				                        <b-col class="pl-0">
				                            <input type="date" class="form-control" v-if="!calendarEvent.viewMode" v-model="calendarEvent.eventEndDate" id="eventEndDate">
			                                <input v-if="calendarEvent.viewMode && calendarEvent.eventEndDate" type="text" class="form-control" disabled :value="calendarEvent.eventEndDate | formatDate">
				                        </b-col>
		                        	</b-row>
		                        </div>
			                    <b-col v-if="calendarEvent.viewMode && calendarEvent.isAllDayEvent" cols="2" class="p-0 m-0 text-center">
		                            <p class="mt-1 mb-0"><label class="badge badge-secondary">All Day</label></p>
			                    </b-col>
			                    <b-col v-if="!calendarEvent.viewMode" cols="2" class="p-0 m-0 text-center">
			                        <b-form-group v-slot="{ ariaDescribedby }">
		                                <b-form-radio-group v-model="calendarEvent.isAllDayEvent" :aria-describedby="ariaDescribedby" name="radio-sub-component" class="pt-1 m-0" :disabled="calendarEvent.viewMode" buttons button-variant="outline-secondary" size="xs">
		                                    <b-form-radio :value="true" class="py-1 px-2">All Day</b-form-radio>
		                                    <b-form-radio :value="false" class="py-1 px-2">Time Frame</b-form-radio>
		                                </b-form-radio-group>
		                            </b-form-group>
			                    </b-col>
		                        <div v-if="!calendarEvent.isAllDayEvent" class="col">
		                        	<div class="row">
				                        <b-col class="pr-0">
					                        <input type="time" class="form-control" v-if="!calendarEvent.viewMode" v-model="calendarEvent.eventStartTime" id="eventStartTime">
					                        <input type="text" class="form-control" disabled v-if="calendarEvent.viewMode" :value="calendarEvent.eventStartTime | formatTimeFromHM">
					                    </b-col>
					                    <b-col cols="1" class="px-0 my-auto text-center">
					                        <label class="my-auto" for="eventEndTime">to</label>
					                    </b-col>
					                    <b-col class="pl-0">
					                        <input type="time" class="form-control" v-if="!calendarEvent.viewMode" v-model="calendarEvent.eventEndTime" id="eventEndTime">
					                        <input type="text" class="form-control" disabled v-if="calendarEvent.viewMode" :value="calendarEvent.eventEndTime | formatTimeFromHM">
					                    </b-col>
					                </div>
					            </div>
					        </div>
					    </b-col>
                    </b-row>
	                <b-row class="my-2 no-gutters">
	                    <b-col cols="2" class="my-auto">
	                        <label class="my-auto" for="eventFrequency">Occurrence</label>
	                    </b-col>
	                    <b-col cols="10">
	                        <div class="row">
			                    <b-col cols="4">
			                        <select :disabled="calendarEvent.viewMode" v-model="calendarEvent.eventFrequency" class="form-control" id="eventFrequency">
			                            <option value="0">No Recurring</option>
			                            <option value="1">Recurring</option>
			                        </select>
			                    </b-col>
			                    <b-col v-if="calendarEvent.eventFrequency == 1" cols="8">
			                    	<div class="required-field-block" :class="{ 'is-invalid': !isRecurValid }">
		                                <multiselect :disabled="calendarEvent.viewMode" @input="isRecurValid = true;" :class="{ 'is-invalid': !isRecurValid }" v-model="calendarEvent.daysToRepeat" placeholder="Days to repeat" :options="days" name="repeat"  select-label="" deselect-label="" :close-on-select="false" :clear-on-select="false" :multiple="true" :taggable="false" id="daysOfWeek"></multiselect>
		                                <div class="required-icon"></div>
		                            </div>
		                            <label v-if="!isRecurValid" class="text-danger">Please select recurring days</label>
			                    </b-col>
			                </div>
			            </b-col>
	                </b-row>
	               <!--  <b-row class="my-2">
	                    <b-col cols="3" class="my-auto">
	                        <label class="my-auto" for="statusId">Status</label>
	                    </b-col>
	                    <b-col cols="9">
	                        <select v-if="!calendarEvent.viewMode" v-model="calendarEvent.statusId" class="form-control" id="statusId">
	                            <option value="">Select</option>
	                            <option v-for="(info, index) in eventStatus" :value="info.id">{{info.value}}</option>
	                        </select>
	                        <input type="text" class="form-control" disabled v-if="calendarEvent.viewMode" v-model="calendarEvent.status">
	                    </b-col>
	                </b-row> -->
	                <b-row class="my-2 no-gutters">
	                    <b-col cols="2" class="my-auto">
	                        <label class="my-auto" for="location">Location</label>
	                    </b-col>
	                    <b-col cols="10">
	                        <b-form-input id="location" :disabled="calendarEvent.viewMode" v-model="calendarEvent.location" placeholder="Enter Meeting Location"></b-form-input>
	                    </b-col>
	                </b-row>
	                <b-row class="my-2 no-gutters">
	                    <b-col cols="2" class="my-auto">
	                        <label class="my-auto" for="link">Link</label>
	                    </b-col>
	                    <b-col cols="10" >
                            <a v-if="calendarEvent.viewMode && calendarEvent.link" target="_blank" :href="calendarEvent.link"><i class="fa fa-globe ml-0 mr-2 font-weight-bold action-link" aria-hidden="true"></i>{{calendarEvent.link}}</a>
	                        <b-form-input v-if="!calendarEvent.viewMode" type="url" id="link" v-model="calendarEvent.link" placeholder="Enter a Redirect Link"></b-form-input>
	                    </b-col>
	                </b-row>
	                <b-row class="my-2 no-gutters">
	                	<b-col cols="2" class="my-auto">
	                        <label class="my-auto" for="hostedById">Host</label>
	                    </b-col>
		                <b-col cols="2">
	                    	<div class="row">
		                    	<div class="col-md-12">
			                    	<template v-if="!calendarEvent.viewMode">
				                        <div class="required-field-block" :class="{ 'is-invalid': $v.calendarEvent.hostedById.$error }">
			                                <select v-model="calendarEvent.hostedById" :class="{ 'is-invalid': $v.calendarEvent.hostedById.$error }" class="form-control" id="hostedById">
					                            <option value="">Select</option>
					                            <option v-for="(info, index) in users" :value="info.id">{{info.value}}</option>
					                        </select>
			                                <div class="required-icon"></div>
			                            </div>
			                            <label v-if="$v.calendarEvent.hostedById.$error && !$v.calendarEvent.hostedById.required" class="text-danger">Please Select a Meeting Host</label>
			                    	</template>
			                        <input v-if="calendarEvent.viewMode" type="text" class="form-control" disabled v-model="calendarEvent.hostedBy">
		                    	</div>
	                    	</div>
	                    </b-col>
	                    <b-col cols="8">
	                    	<div class="row">
			                    <b-col cols="3" class="my-auto text-right">
			                        <label class="m-0" for="momRecorderList">Recorders :</label>
			                    </b-col>
			                    <b-col cols="9">
			                        <multiselect :disabled="calendarEvent.viewMode" v-model="calendarEvent.momRecorderList" placeholder="Add MOM Recorders" :options="allParticipants" name="momRecorderList" label="value" track-by="id" select-label="" deselect-label="" :close-on-select="false" :clear-on-select="false" :multiple="true" :taggable="false" id="momRecorderList"></multiselect>
			                    </b-col>
			                </div>
			            </b-col>
	                </b-row>
	                <b-row class="my-3 no-gutters">
	                    <b-col cols="12">
	                        <label for="eventParticipant">Event Participants :</label>
	                    </b-col>
	                    <b-col cols="12">
	                        <multiselect :disabled="calendarEvent.viewMode" v-model="calendarEvent.eventParticipant" placeholder="Add Participants" :options="allParticipants" name="eventParticipant" label="value" track-by="id" select-label="" deselect-label="" :close-on-select="false" :clear-on-select="false" :multiple="true" :taggable="false" id="eventParticipant"></multiselect>
	                    </b-col>
	                </b-row>
	                <!-- <b-row class="my-3 no-gutters">
	                    <b-col cols="12">
	                        <label for="momRecorderList">MOM Recorders :</label>
	                    </b-col>
	                    <b-col cols="12">
	                        <multiselect :disabled="calendarEvent.viewMode" v-model="calendarEvent.momRecorderList" placeholder="Add MOM Recorders" :options="allParticipants" name="momRecorderList" label="value" track-by="id" select-label="" deselect-label="" :close-on-select="false" :clear-on-select="false" :multiple="true" :taggable="false" id="momRecorderList"></multiselect>
	                    </b-col>
	                </b-row> -->
	                <b-card no-body class="">
		                <b-card-header header-tag="header" class="p-0 accordion-header" role="tab">
		                    <label class="d-block p-2 accordion-title" block href="#" v-b-toggle.agenda>
		                        <span>Description</span>
		                    </label>
		                </b-card-header>
		                <b-collapse id="agenda" v-model="elements.visibleAjenda" role="tabpanel">
		                    <b-card-body class="p-2">
		                        <div class="row">
		                            <div class="col-12 col-sm-12">
		                                <div class="form-group">
		                                    <editor api-key="lbcn6twu8tv4xan8fn1tgqiqy9hhvsse01s48crluvfuafzp" :toolbar="toolbar" :init="initObj" :disabled="calendarEvent.viewMode" v-model="calendarEvent.description"></editor>
		                                </div>
		                            </div>
		                        </div>
		                    </b-card-body>
		                </b-collapse>
		            </b-card>
	            </b-container>
	            <b-container v-show="activeTab == 2" class="px-0">
            		<b-card no-body class="mb-3">
                        <b-card-header header-tag="header" class="p-0 accordion-header" role="tab">
                            <label class="d-block p-2 accordion-title" block href="#" v-b-toggle.accordion-1>
                            	<i class="fa fa-plus-circle pr-2 action-link"></i>Add Agenda Item
                                <span class="float-right mr-5">{{ meetingMinute.selectedDate | formatDateAlt }}</span>
                                <!-- <span v-if="!elements.editMode && !elements.viewMode"><i class="fa fa-plus-circle pr-2 action-link"></i>Add Meeting Minutes</span> -->
                                <!-- <span v-if="elements.editMode && !elements.viewMode">Edit MoM</span>  -->
                                <!-- <span v-if="elements.viewMode">View MoM</span> -->
                            </label>
                        </b-card-header>
                        <b-collapse id="accordion-1" accordion="my-accordion" v-model="elements.visible" role="tabpanel">
                            <b-card-body class="pt-2">
				            	<div class="row mb-2">
				                    <div class="col-12 col-md-6 col-lg-3">
				                        <div class="form-group">
				                            <label for="title">Agenda Item</label>
				                            <div v-if="!elements.viewMode">
				                                <div class="required-field-block" :class="{ 'is-invalid': $v.meetingMinute.title.$error }">
				                                    <input type="text" class="form-control" v-model="meetingMinute.title" id="title" :class="{ 'is-invalid': $v.meetingMinute.title.$error }" placeholder="Enter Agenda Item">
				                                    <div class="required-icon">
				                                    </div>
				                                </div>
				                                <label v-if="$v.meetingMinute.title.$error && !$v.meetingMinute.title.minLength" class="text-danger">Agenda Item must have at least {{$v.meetingMinute.title.$params.minLength.min}} letters.</label>
				                                <label v-if="$v.meetingMinute.title.$error && !$v.meetingMinute.title.required" class="text-danger">Please enter Agenda Item</label>
				                                <label v-if="$v.meetingMinute.title.$error && !$v.meetingMinute.title.maxLength" class="text-danger">Agenda Item must not exceed {{$v.meetingMinute.title.$params.maxLength.max}} letters.</label>
				                            </div>
				                            <b-input-group v-if="elements.viewMode" :prepend="meetingMinute.defectDbId" class="mb-2">
				                                <b-form-input aria-label="First name" disabled v-model="meetingMinute.title"></b-form-input>
				                            </b-input-group>
				                        </div>
				                    </div>
				                    <div class="col-12 col-md-6 col-lg-3">
				                        <div class="form-group">
				                            <label for="initiatedPerson">Initiated By</label>
				                            <select v-model="meetingMinute.initiatedPersonId" id="initiatedPersonId" class="form-control">
				                                <option value="">Select</option>
				                                <option v-for="(info, index) in users" :value="info.id">{{info.value}}</option>
				                            </select>
				                        </div>
				                    </div>
				                    <div class="col-12 col-md-6 col-lg-3">
				                        <div class="form-group">
				                            <label for="responsiblePersonMP">Person Responsible</label>
				                            <select v-model="meetingMinute.responsiblePersonId" id="responsiblePersonMP" class="form-control">
				                                <option value="">Select</option>
				                                <option v-for="(info, index) in users" :value="info.id">{{info.value}}</option>
				                            </select>
				                        </div>
				                    </div>
				                    <div class="col-12 col-md-6 col-lg-3">
				                        <div class="form-group">
				                            <label for="mp-duration">Duration</label>
				                            <b-input-group size="sm">
	                                            <EffortInput :daysVisible="false" 
	                                            hoursId="mp-duration" 
	                                            @vmx-effort-input="effortControlMpEstInput" 
	                                            :hrsValue="meetingMinute.estimatedHour"
	                                            :minsValue="meetingMinute.estimatedMinute"
	                                            ref="effortControlMpEst" />
	                                        </b-input-group>
				                        </div>
				                    </div>
				                </div>
				                <b-row>
				                    <div class="col-12">
				                        <div class="form-group">
				                            <label for="discussion">Description</label>
				                            <editor api-key="lbcn6twu8tv4xan8fn1tgqiqy9hhvsse01s48crluvfuafzp" :toolbar="toolbar" :init="initObj" v-model="meetingMinute.description" :disabled="elements.viewMode"></editor>
				                        </div>
				                    </div>
				                </b-row>
				                <button type="submit" @click="saveMOMData(false)" class="btn btn-primary mb-0 mr-2 pt-1 pb-1 pl-3 pr-3"><i class="fa fa-floppy-o pr-2"></i>Save</button>
                                <button type="submit" @click="clearMMData" class="btn btn-primary mb-0 pt-1 pb-1 pl-3 pr-3"><i class="fa fa-times pr-2"></i>Cancel</button>
				            </b-card-body>
				        </b-collapse>
				    </b-card>
	                <b-table show-empty small stacked="md" sort-icon-left :items="meetingMinutes" :fields="meetingFields" responsive="sm">
                        <template #cell(index)="data">
                            {{ data.index + 1 }}
                        </template>                            	                	
	                    <template v-slot:cell(description)="data">
                            <div v-html="data.value"></div>
                        </template>
                        <template v-slot:cell(estimatedHour)="data">
	                    	{{ formatDurationHM(data.item.estimatedHour, data.item.estimatedMinute) }}
		                </template>
	                    <template v-slot:cell(actions)="data">
	                        <a href="javascript:" title="Edit" @click="showEdit(data.item, false)"><i class="fa fa-pencil-square-o fa-custom fa-lg  pl-2"></i></a>
	                        <a href="javascript:" title="Delete" @click="deleteMeetingMinute(data.item.id)" v-b-modal.deleteMeetingMinute><i class="fa fa-trash pl-2"></i></a>
	                    </template>
	                    <template v-slot:empty="scope">
	                        <div class="text-center">No Agenda Item found</div>
	                    </template>
	                    <template v-slot:emptyfiltered="scope">
	                        <div class="text-center">{{ scope.emptyFilteredText }}</div>
	                    </template>
	                </b-table>
	            </b-container>
	            <b-container v-show="activeTab == 3" class="px-0">
	            	<div v-show="elements.viewModeMOM">
	            		<MOMReportViewComponent :key="momViewKey" :momData="momData" ref="meetingMinuteViewRef" />
	            	</div>
	            	<div v-show="!elements.viewModeMOM">
		            	<b-card no-body class="mb-3">
	                        <b-card-header header-tag="header" class="p-0 accordion-header" role="tab">
	                            <label class="d-block p-2 accordion-title" block href="#" v-b-toggle.accordion-1>
	                            	<i class="fa fa-plus-circle pr-2 action-link"></i>Add Meeting Minutes
	                                <span class="float-right mr-5">{{ meetingMinute.selectedDate | formatDateAlt }}</span>
	                                <!-- <span v-if="!elements.editMode && !elements.viewMode"><i class="fa fa-plus-circle pr-2 action-link"></i>Add Meeting Minutes</span> -->
	                                <!-- <span v-if="elements.editMode && !elements.viewMode">Edit MoM</span>  -->
	                                <!-- <span v-if="elements.viewMode">View MoM</span> -->
	                            </label>
	                        </b-card-header>
	                        <b-collapse id="accordion-1" accordion="my-accordion" v-model="elements.visible" role="tabpanel">
	                            <b-card-body class="pt-2">
					            	<div class="row mb-2">
					                    <div class="col-12 col-lg-6">
					                        <div class="form-group">
					                            <label for="title">Topic</label>
					                            <div v-if="!elements.viewMode">
					                                <div class="required-field-block" :class="{ 'is-invalid': $v.meetingMinute.title.$error }">
					                                    <input type="text" class="form-control" v-model="meetingMinute.title" id="title" :class="{ 'is-invalid': $v.meetingMinute.title.$error }" placeholder="Enter Meeting Topic">
					                                    <div class="required-icon">
					                                    </div>
					                                </div>
					                                <label v-if="$v.meetingMinute.title.$error && !$v.meetingMinute.title.minLength" class="text-danger">Title must have at least {{$v.meetingMinute.title.$params.minLength.min}} letters.</label>
					                                <label v-if="$v.meetingMinute.title.$error && !$v.meetingMinute.title.required" class="text-danger">Please enter meeting Topic</label>
					                                <label v-if="$v.meetingMinute.title.$error && !$v.meetingMinute.title.maxLength" class="text-danger">Title must not exceed {{$v.meetingMinute.title.$params.maxLength.max}} letters.</label>
					                            </div>
					                            <b-input-group v-if="elements.viewMode" :prepend="meetingMinute.defectDbId" class="mb-2">
					                                <b-form-input aria-label="First name" disabled v-model="meetingMinute.title"></b-form-input>
					                            </b-input-group>
					                        </div>
					                    </div>
					                    <div class="col-12 col-md-6 col-lg-3">
					                        <div class="form-group">
					                            <label for="initiatedPerson">Initiated By</label>
					                            <select v-model="meetingMinute.initiatedPersonId" id="initiatedPersonId" class="form-control">
					                                <option value="">Select</option>
					                                <option v-for="(info, index) in users" :value="info.id">{{info.value}}</option>
					                            </select>
					                        </div>
					                    </div>
					                    <div class="col-12 col-md-6 col-lg-3">
					                        <div class="form-group">
					                            <label for="mom-act-duration">Duration</label>
					                            <b-input-group size="sm">
		                                            <EffortInput :daysVisible="false" 
		                                            hoursId="mom-act-duration" 
		                                            @vmx-effort-input="effortControlMomActInput" 
		                                            :hrsValue="meetingMinute.actualHour"
		                                            :minsValue="meetingMinute.actualMinute"
		                                            ref="effortControlMomAct" />
		                                        </b-input-group>
					                        </div>
					                    </div>
					                </div>
					                <b-row>
					                	<div class="col-12 col-md-6 col-lg-6">
					                        <div class="form-group">
					                            <label for="description">Agenda Item</label>
					                            <editor api-key="lbcn6twu8tv4xan8fn1tgqiqy9hhvsse01s48crluvfuafzp" :toolbar="toolbar" :init="initObj" v-model="meetingMinute.description" :disabled="elements.viewMode"></editor>
					                        </div>
					                    </div>
					                    <div class="col-12 col-md-6">
					                        <div class="form-group">
					                            <label for="discussion">Discussion</label>
					                            <editor api-key="lbcn6twu8tv4xan8fn1tgqiqy9hhvsse01s48crluvfuafzp" :toolbar="toolbar" :init="initObj" v-model="meetingMinute.discussion" :disabled="elements.viewMode"></editor>
					                        </div>
					                    </div>

					                    <div class="col-12 col-md-6">
					                        <div class="form-group">
					                            <label for="decision">Decision / Action Items</label>
					                            <editor api-key="lbcn6twu8tv4xan8fn1tgqiqy9hhvsse01s48crluvfuafzp" :toolbar="toolbar" :init="initObj" v-model="meetingMinute.decision" :disabled="elements.viewMode"></editor>
					                        </div>
					                    </div>

					                    <div class="col-12 col-md-6">
					                        <div class="form-group">
					                            <label for="actionTaken">Action Taken</label>
					                            <editor api-key="lbcn6twu8tv4xan8fn1tgqiqy9hhvsse01s48crluvfuafzp" :toolbar="toolbar" :init="initObj" v-model="meetingMinute.actionTaken" :disabled="elements.viewMode"></editor>
					                        </div>
					                    </div>
					                    <div class="col-12 col-sm-4">
					                        <div class="form-group">
					                            <label for="responsiblePerson">Person Responsible</label>
					                            <select v-model="meetingMinute.responsiblePersonId" id="responsiblePersonId" class="form-control">
					                                <option value="">Select</option>
					                                <option v-for="(info, index) in users" :value="info.id">{{info.value}}</option>
					                            </select>
					                        </div>
					                    </div>
					                    <div class="col-12 col-sm-4">
					                        <div class="form-group">
					                            <label for="deadline">Deadline</label>
					                            <input v-model="meetingMinute.deadline" type="date" class="form-control" placeholder="Deadline">
					                        </div>
					                    </div>
					                    <div class="col-12 col-sm-4">
					                        <div class="form-group">
					                            <label for="statusId">Status</label>
					                            <select v-if="!elements.viewMode" v-model="meetingMinute.statusId" class="form-control" id="statusId">
					                                <option v-for="(info, index) in meetingMinuteStatus" :value="info.id">{{info.value}}</option>
					                            </select>
					                            <input v-if="elements.viewMode" type="text" disabled class="form-control" v-model="meetingMinute.status">
					                        </div>
					                    </div>
					                </b-row>
					                <button type="submit" @click="saveMOMData(true)" class="btn btn-primary mb-0 mr-2 pt-1 pb-1 pl-3 pr-3"><i class="fa fa-floppy-o pr-2"></i>Save</button>
	                                <button type="submit" @click="clearMMData" class="btn btn-primary mb-0 pt-1 pb-1 pl-3 pr-3"><i class="fa fa-times pr-2"></i>Cancel</button>
					            </b-card-body>
					        </b-collapse>
					    </b-card>
		                <b-table show-empty small stacked="md" sort-icon-left :items="meetingMinutes" :fields="meetingMinuteFields" responsive="sm">
	                        <template #cell(index)="data">
	                            {{ data.index + 1 }}
	                        </template>                            	                			                	
		                    <template v-slot:cell(description)="data">
	                            <div v-html="data.value"></div>
	                        </template>
	                        <template v-slot:cell(discussion)="data">
	                            <div v-html="data.value"></div>
	                        </template>
	                        <template v-slot:cell(actualHour)="data">
		                    	{{ formatDurationHM(data.item.actualHour, data.item.actualMinute) }}
			                </template>
		                    <template v-slot:cell(actions)="data">
		                        <a href="javascript:" title="Edit" @click="showEdit(data.item, true)"><i class="fa fa-pencil-square-o fa-custom fa-lg  pl-2"></i></a>
		                        <a href="javascript:" title="Delete" @click="deleteMeetingMinute(data.item.id)" v-b-modal.deleteMeetingMinute><i class="fa fa-trash pl-2"></i></a>
		                    </template>
		                    <template v-slot:empty="scope">
		                        <div class="text-center">No Meeting Minute found</div>
		                    </template>
		                    <template v-slot:emptyfiltered="scope">
		                        <div class="text-center">{{ scope.emptyFilteredText }}</div>
		                    </template>
		                </b-table>
	            	</div>
	            </b-container>
            </b-overlay>
            <template #modal-footer="{ cancel }">
                <div v-if="activeTab==1" class="w-100">
                    <b-button v-if="!calendarEvent.viewMode" variant="primary" size="sm" :disabled="showOverlay" @click="saveData" class="float-right mr-2">
                        <span v-if="showOverlay" >
                            <b-spinner small label="Small Spinner" class="mr-2"></b-spinner>{{ calendarEvent.editMode ? 'Updating..' : 'Adding..' }}
                        </span>
                        <span v-else>{{ calendarEvent.editMode ? 'Update Event' : 'Add Event' }}</span>
                    </b-button>
                    <b-button v-if="calendarEvent.editMode" variant="secondary" size="sm" class="float-right mr-2" @click="cancelEditMode">Cancel</b-button>
                    <b-button v-else variant="secondary" size="sm" class="float-right mr-2" @click="closeDialog();cancel();">Close</b-button>
                </div>
                <div v-if="activeTab==2 || activeTab==3" class="w-100">
                    <b-button variant="secondary" size="sm" class="float-right mr-2" @click="cancel();">Close</b-button>
                </div>
            </template>
        </b-modal>
        <TypeLookup :key="typePopupKey" ref="typeLookupModal" :projectTypeId="projectTypeId" id="eventTypeLookup" :entity="typeEntity" :typeLookupTitle="typeLookupTitle" @refresh-lookup="lookupData" />
        <ERMMasterPopup v-if="showDialog" :ermMasterInfo="ermMasterInfo" @refresh-lookup="lookupData();masterLookupRefresh();" @closeDialog="showDialog=false" />
        <DeleteComponent id="deleteMeetingMinute" :onYes="onYes" :returnParams="deleteParam" title="Delete Confirmation" message="Are you sure to delete Meeting Minute?" />
        <DeleteComponent id="deleteCalendarEvent" :onYes="onYesCal" :returnParams="deleteParam" title="Delete Confirmation" message="Are you sure to delete the Event?" />
    </div>
</template>
<script>
import iziToast from 'izitoast';
import Multiselect from 'vue-multiselect';
import moment from 'moment';
import dayList from '@/json/daysofweek.json';
import { required, email, minLength, maxLength, sameAs } from "vuelidate/lib/validators";
import Editor from '@tinymce/tinymce-vue';
import ERMMasterPopup from '@/components/popup/lookup/ERMMasterPopup.vue'
import MOMReportViewComponent from '@/components/common/MOMReportViewComponent.vue';
import TypeLookup from '@/components/popup/lookup/TypeLookup.vue'

export default {
    props: {
        calendarEventData: Object,
    },
    components: {
    	Multiselect,
    	Editor,
    	ERMMasterPopup,
    	MOMReportViewComponent,
    	TypeLookup
    },
    data() {
        return {
        	showDialog: false,
        	elements: {
        		visibleAjenda: false,
        		visible: false,
        		viewMode: false,
        		viewModeMOM: true,
        		editMode: false
        	},
        	activeTab: 1,
            showOverlay: false,
            // calendarEvent: this.calendarEventData,
            vhextAccountId: localStorage.getItem("vhextAccountId"),
            projectId: localStorage.getItem("projectId"),
            projectTypeId: localStorage.getItem("projectTypeId"),
            userId: localStorage.getItem("userId"),
            entity: "calendar_event",

            momViewKey: 0,

            typeEntity: 'event_type',
            typeLookupTitle: 'Meeting / Event Type Settings',
            typePopupKey: 0,

            ermMasterInfo: {
                title: "Meeting Preparation Master",
                entity: 'meeting_minute_master',
                refEntity: 'calendar_event',
            },

            isRecurValid: true,
            calendarEvent: {
                editMode: false,
                viewMode: null,
                showMOM: false,
                title: '',
                eventDate: this.getDateValue(new Date()),
                startDate: '',
                eventStartDate: this.getDateValue(new Date()),
                eventStartTime: this.getTimeValue(new Date()),
                endDate: '',
                eventEndDate: this.getDateValue(new Date()),
                eventEndTime: this.getTimeValue(new Date()),
                link: '',
                location: '',
                calendarTypeId: '',
	            calendarCategoryId: '',
                eventTypeId: '',
                eventFrequency: 0,
                statusId: '',
                isAllDayEvent: false,
                daysOfWeek: [],
                daysToRepeat: [],
                hostedById: '',
                description: '',
                createdById: localStorage.getItem("userId"),
                projectId: localStorage.getItem("projectId"),
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                entityRecordMasterId: '',
                participantId: localStorage.getItem("userId"),
                eventParticipant: [],
                momRecorderList: [],
            },

            meetingMinute: {
                id: '',
                meetingMinuteId: '',
                calendarEventId: '',
                eventId: '',
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                projectId: localStorage.getItem("projectId"),
                entityRecordMasterId: '',
                entity: 'meeting_minute',
                entityId: '',
                entityRefId: '',
                title: '',
                meetingtypeId: '',
                description: '',
                meetingPreparationId: '',
                discussion: '',
                decision: '',
                deadline: '',
                actionTaken: '',
                startDt: '',
                endDt: '',
                estimatedHour: '',
				estimatedMinute: '',
				actualHour: '',
				actualMinute: '',
                duration: '',
                initiatedPersonId: '',
                responsiblePersonId: '',
                statusId: '',
                selectedDate: '',
                isMOMRecorder: false,
            },

            momData: {
                calendarEventId: '',
                entityRecordMasterId: '',
                meetingDate: '',
            },

            days: dayList,
            users: [],
            allParticipants: [],
            eventStatus: [],
            eventTypes: [],
            entityERMLookup: [],

			meetingMinutes: [],
			meetingFields: [
				{ key: 'index', label: 'SN'},
                // { key: 'meetingMinuteId', label: 'Id', sortable: true, sortDirection: 'desc' },
                { key: 'title', label: 'Agenda Item',tdClass: 'table-title-col-size', sortable: true, sortDirection: 'desc' },
                { key: 'initiatedPerson', label: 'Initiated By',tdClass: 'table-name-col-size', sortable: true, sortDirection: 'desc' },
                { key: 'description', label: 'Description',tdClass: 'table-cw-300', sortable: true, sortDirection: 'desc' },
                { key: 'responsiblePerson', label: 'Person Responsible',tdClass: 'table-name-col-size', sortable: true, sortDirection: 'desc' },
                { key: 'estimatedHour', label: 'Duration' },
                { key: 'actions', label: 'Actions' }
            ],
            meetingMinuteFields: [
            	{ key: 'index', label: 'SN'},
                { key: 'meetingMinuteId', label: 'Id', sortable: true, sortDirection: 'desc' },
                { key: 'title', label: 'Topic',tdClass: 'table-title-col-size', sortable: true, sortDirection: 'desc' },
                { key: 'initiatedPerson', label: 'Initiated By',tdClass: 'table-name-col-size', sortable: true, sortDirection: 'desc' },
                { key: 'description', label: 'Description',tdClass: 'table-cw-300', sortable: true, sortDirection: 'desc' },
                { key: 'discussion', label: 'Discussion',tdClass: 'table-cw-300', sortable: true, sortDirection: 'desc' },
                { key: 'responsiblePerson', label: 'Person Responsible',tdClass: 'table-name-col-size', sortable: true, sortDirection: 'desc' },
                { key: 'actualHour', label: 'Duration' },
                { key: 'actions', label: 'Actions' }
            ],
            meetingMinuteStatus: [],
            meetingTypes: [],

            weblinks: 'google.com',
            toolbar: 'undo redo preview | formatselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | weblinks',
            initObj: {
                height: 200,
                branding: false,
                menubar: false,
                plugins: ['link', 'paste', 'wordcount', 'preview', 'image imagetools', 'lists'],
                paste_data_images: true,
                images_upload_url: 'postAcceptor.php',
                automatic_uploads: false,
                file_picker_types: 'image',
                relative_urls: false,
                remove_script_host: false,
            },

            deleteParam: {
                id: 0
            }
        }
    },
    computed: {
    	// currentUser() {
    	// 	let currentUser = this.users.filter((user) => user.id == this.userId);
    	// 	console.log(currentUser);
    	// 	return currentUser;
    	// }
    	// calendarEvent: {
    	// 	get() {
	    // 		return this.calendarEventData;
		   //  },
		   //  set() {
	    // 		return this.calendarEventData;
		   //  }
    	// }
    },
    watch: {
    	totalRowsMOM(val) {
    		if (val == 0) {
    			this.elements.viewModeMOM = false;
    		}
    	},

    },
    mounted() {
        this.lookupData();
        this.setCalendarEventData();
        this.lookupDataMM();
        if (this.$refs.meetingMinuteViewRef) {
	        this.$refs.meetingMinuteViewRef.getMeetingMinute();
        }
    },
    validations: {
        calendarEvent: {
            title: {
                required,
                minLength: minLength(2),
                maxLength: maxLength(100)
            },
            entityRecordMasterId: {
            	required
            },
            hostedById: {
            	required
            },
        },
        meetingMinute: {
            title: {
                required,
                minLength: minLength(2),
                maxLength: maxLength(100)
            }
        }
    },
    methods: {
    	getMeetingMinute: function(meetingDate) {
    		if (this.calendarEvent.showMOM) {
	            let data = {
	                entityRecordMasterId: this.calendarEvent.entityRecordMasterId,
	                calendarEventId: this.calendarEvent.id,
	                meetingDate: meetingDate,
	            }
	            console.log(data);
	            axios.post(this.$store.getters.getAPIBasePath + '/communication/meetingminutebydate/list', data)
	                .then((response) => {
	                    let result = response.data;
	                    this.meetingMinutes = result.meetingMinuteList;
	                    this.totalRowsMOM = this.meetingMinutes.length;
	                    console.log(this.meetingMinutes);
	                    this.meetingMinute.meetingPreparationId = this.meetingMinutes.length > 0 ? this.meetingMinutes[0].meetingPreparationId : null;
	                })
    		}
        },
        lookupData: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
                projectId: this.projectId,
                projectTypeId: this.projectTypeId,
                userId: this.userId,
                entity: this.entity,
            }
            axios.post(this.$store.getters.getAPIBasePath + '/communication/calendarevent/lookup/all', data)
                .then((response) => {
                    let result = response.data;
                    this.users = result.userLookup;
                    this.allParticipants = result.userLookup;
                    this.eventStatus = result.statusLookup;
                    this.eventTypes = result.customTypeLookup;
                    this.entityERMLookup = result.entityERMLookup;
                    console.log(result);
                })
        },
        lookupDataMM: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
                projectId: this.projectId,
                entity: this.meetingMinute.entity,
            }
            axios.post(this.$store.getters.getAPIBasePath + '/communication/meetingminute/lookup/all', data)
                .then((response) => {
                    let result = response.data;
                    this.meetingTypes = result.customTypeLookup;
                    this.meetingMinuteStatus = result.statusLookup;
                })
        },
        saveData: function() {
        	// validation to check daysToRepeat
        	if (!this.checkRecurValidation()) {
        		this.isRecurValid = false;
        		return;
        	} 
        	else this.isRecurValid = true;

        	this.$v.calendarEvent.$touch();
            if (!this.$v.calendarEvent.$invalid) {
	            this.calendarEvent.eventParticipantList = [];
	            let momRecorderList = [];
	            if (this.calendarEvent.eventParticipant.length > 0) {
	            	for (var index = 0; index < this.calendarEvent.eventParticipant.length; index++) {

		                let isParticipantExist = this.isParticipantExist(this.calendarEvent.momRecorderList, this.calendarEvent.eventParticipant[index].id);
		                if (isParticipantExist) {
		                	this.calendarEvent.eventParticipantList.push({ participantId: this.calendarEvent.eventParticipant[index].id, eventParticipantTypeId: 2 });
		                }
		                else {
		                	this.calendarEvent.eventParticipantList.push({ participantId: this.calendarEvent.eventParticipant[index].id, eventParticipantTypeId: 1 });
		                }
		            }
	            	if (this.calendarEvent.momRecorderList.length>0) {
	            		this.calendarEvent.momRecorderList.filter((data) => {
	            			if (!this.isParticipantExist(this.calendarEvent.eventParticipant, data.id)) {
	            				momRecorderList.push({ participantId: data.id, eventParticipantTypeId: 2 });
	            			}
	            		})
	            	}
		            this.calendarEvent.eventParticipantList = [...this.calendarEvent.eventParticipantList, ...momRecorderList];
	            }
	            else {
	            	for (var index = 0; index < this.calendarEvent.momRecorderList.length; index++) {
	            		this.calendarEvent.eventParticipantList.push({ participantId: this.calendarEvent.momRecorderList[index].id, eventParticipantTypeId: 2 });
	            	}
	            }

	            this.calendarEvent.eventParticipantList = this.calendarEvent.eventParticipantList.filter((participant) => 
            		(participant.participantId != this.calendarEvent.hostedById && participant.participantId != this.calendarEvent.createdById)
            	);

	            if (this.calendarEvent.createdById == this.calendarEvent.hostedById) {
		            this.calendarEvent.eventParticipantList.push({ participantId: this.calendarEvent.createdById, eventParticipantTypeId: 2 });
		        }
		        else {
		            this.calendarEvent.hostedById ? this.calendarEvent.eventParticipantList.push({ participantId: this.calendarEvent.hostedById, eventParticipantTypeId: 2 }) : '';
		            this.calendarEvent.eventParticipantList.push({ participantId: this.calendarEvent.createdById, eventParticipantTypeId: 2 });
		        }
	            
	            this.calendarEvent.startTime = null;
	            this.calendarEvent.endTime = null;
	            if (this.calendarEvent.isAllDayEvent) {
	                this.calendarEvent.startDate = this.getDateValue(this.calendarEvent.eventStartDate);
	                this.calendarEvent.endDate = this.calendarEvent.eventFrequency==0 ? null : this.getDateValue(this.calendarEvent.eventEndDate);
	                if (this.calendarEvent.eventFrequency==0) {
	                	this.calendarEvent.daysToRepeat = [];
	                	this.calendarEvent.daysOfWeek = null;
	                }
	                else if (this.calendarEvent.daysToRepeat && this.calendarEvent.daysToRepeat.length>0) {
	                    this.calendarEvent.daysOfWeek = [];
	                    this.calendarEvent.daysToRepeat.forEach((day) => {
	                        this.calendarEvent.daysOfWeek.push(this.days.indexOf(day));
	                    })
	                    this.calendarEvent.daysOfWeek = this.calendarEvent.daysOfWeek.toString();
	                } else this.calendarEvent.daysOfWeek = null;
	            } else {
	                if (this.calendarEvent.startDate == '' || this.calendarEvent.startDate == null) { 
	                    this.calendarEvent.startDate = this.combineDateAndTime(this.calendarEvent.eventStartDate, this.calendarEvent.eventStartTime) 
	                }
	                if (this.calendarEvent.endDate == '' || this.calendarEvent.endDate == null) { 
	                    this.calendarEvent.endDate = this.combineDateAndTime(this.calendarEvent.eventEndDate, this.calendarEvent.eventEndTime) 
	                }
	                if (this.calendarEvent.eventFrequency==0) {
	                    this.calendarEvent.daysToRepeat = [];
	                    this.calendarEvent.daysOfWeek = null;
	                }
	                if (this.calendarEvent.eventFrequency==1) {
	                    this.calendarEvent.startTime = this.calendarEvent.eventStartTime + ":00";
	                    this.calendarEvent.endTime = this.calendarEvent.eventEndTime + ":00";
		                if (this.calendarEvent.daysToRepeat && this.calendarEvent.daysToRepeat.length>0) {
		                    this.calendarEvent.daysOfWeek = [];
		                    this.calendarEvent.daysToRepeat.forEach((day) => {
		                        this.calendarEvent.daysOfWeek.push(this.days.indexOf(day));
		                    })
		                    this.calendarEvent.daysOfWeek = this.calendarEvent.daysOfWeek.toString();
		                } 
		                else this.calendarEvent.daysOfWeek = null;
	                }
	            }

	            console.log(this.calendarEvent);
            // return;
            	this.showOverlay = true;
                if (this.calendarEvent.editMode) {
                    this.updateEvent();
                } else {
                    this.saveEvent();
                }
            }
        },
        saveEvent: function() {
            console.log(this.calendarEvent);
            axios.post(this.$store.getters.getAPIBasePath + '/communication/calendarevent/add', this.calendarEvent)
                .then((response) => {
                    this.showOverlay = false;
                    let result = response.data;
                    if (result.status == "CREATED") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.$emit('refreshData');
                        this.closeDialog();
                        this.clearData();
                    }
                })
                .catch(error => {
                    this.showOverlay = false;
                    iziToast.error({
                       title: 'Error',
                       message: 'Error',
                       position: 'topRight'
                   });
                })
        },
        updateEvent: function() {
            console.log(this.calendarEvent);
            axios.post(this.$store.getters.getAPIBasePath + '/communication/calendarevent/update', this.calendarEvent)
                .then((response) => {
                    this.showOverlay = false;
                    let result = response.data;
                    if (result.status == "CREATED") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.$emit('refreshData');
                        this.closeDialog();
                        this.calendarEvent.editMode = false;
                        this.calendarEvent.viewMode = true;
                    }
                })
                .catch(error => {
                    this.showOverlay = false;
                    iziToast.error({
                       title: 'Error',
                       message: 'Error',
                       position: 'topRight'
                   });
                })
        },
        deleteCalendarEvent: function(id) {
            this.deleteParam.id = id;
        },
        onYesCal: function() {
        	let startDate = new Date(this.combineDateAndTime(this.calendarEvent.eventStartDate, this.calendarEvent.eventStartTime));
        	let currentDate = new Date();

        	console.log(startDate, currentDate);

        	if (startDate > currentDate) {
        		let data = {
        			erdId: this.deleteParam.id,
        			entity: 'calendar_event',
        		}
	            axios.delete(this.$store.getters.getAPIBasePath + '/communication/calendarevent/delete', data)
	                .then(response => {
	                    var result = response.data;
	                    if (result.status == "OK") {
	                        iziToast.success({
	                            title: 'Success',
	                            message: result.message,
	                            position: 'topRight'
	                        });
	                        this.$emit('refreshData');
	                        this.closeDialog();
	                    }
	                })
	                .catch(error => {
	                    console.log(error);
	                });
        	}
        	else {
        		// this.setCalendarEventData();
        		this.$nextTick(() => {
        			this.cancelEvent(currentDate);
        		})
        	}
        },
        cancelEvent: function(endDate) {
        	let data = {
                id: this.calendarEvent.id,
                endDate: endDate
            }
            axios.post(this.$store.getters.getAPIBasePath + '/communication/calendarevent/cancel', data)
                .then((response) => {
                    let result = response.data;
                    if (result.status == "CREATED") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.$emit('refreshData');
                        this.closeDialog();
                    }
                })
        },
        saveMOMData: function(isMomSave) {
            console.log(this.meetingMinute);
            this.$v.meetingMinute.$touch();
            if (!this.$v.meetingMinute.$invalid) {

            	if (isMomSave) {
		            this.meetingMinute.actualHour = this.$refs.effortControlMomAct.getHrs();
		            this.meetingMinute.actualMinute = this.$refs.effortControlMomAct.getMins();
            	} else {
            		this.meetingMinute.statusId = this.meetingMinute.statusId ? this.meetingMinute.statusId : '';
            		this.meetingMinute.estimatedHour = this.$refs.effortControlMpEst.getHrs();
		            this.meetingMinute.estimatedMinute = this.$refs.effortControlMpEst.getMins();
            	}
            	if (this.meetingMinutes.length == 0) {
            		this.meetingMinute.meetingPreparationId = null;
            	}
            	this.meetingMinute.meetingDate = this.getDateValue(this.meetingMinute.selectedDate);

                this.showOverlay = true;
                if (this.elements.editMode) {
                    this.updateMeetingMinute();
                } else {
                    this.saveMeetingMinute();
                }
            }
        },
        saveMeetingMinute: function() {
        	this.showOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/communication/meetingminute/add', this.meetingMinute)
                .then((response) => {
                	this.showOverlay = false;
                    let result = response.data;
                    if (result.status == "CREATED") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.meetingMinute.meetingPreparationId = result.id;
                        this.getMeetingMinute(this.meetingMinute.meetingDate);
                        this.momViewKey++;
                        this.clearMMData();
                    }
                })
                .catch(error => {
                    this.showOverlay = false;
                    iziToast.error({
                       title: 'Error',
                       message: 'Error',
                       position: 'topRight'
                   });
                })
        },
        updateMeetingMinute: function() {
        	this.showOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/communication/meetingminute/update', this.meetingMinute)
                .then((response) => {
                	this.showOverlay = false;
                    let result = response.data;
                    if (result.status == 'CREATED') {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.getMeetingMinute(this.meetingMinute.meetingDate);
                        this.momViewKey++;
                        this.clearMMData();
                    }
                })
                .catch(error => {
                    this.showOverlay = false;
                    iziToast.error({
                       title: 'Error',
                       message: 'Error',
                       position: 'topRight'
                   });
                })
        },
        deleteMeetingMinute: function(id) {
            this.deleteParam.id = id;
        },
        onYes: function() {
            axios.delete(this.$store.getters.getAPIBasePath + '/communication/meetingminute/delete/' + this.deleteParam.id)
                .then(response => {
                    var result = response.data;
                    if (result.status == "OK") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.getMeetingMinute(this.meetingMinute.meetingDate);
                        this.momViewKey++;
                    }
                })
                .catch(error => {
                    console.log(error);
                });
        },
        getParticipantList: function(eventId) {
            let data = {
                eventId: eventId
            }
            axios.post(this.$store.getters.getAPIBasePath + '/communication/eventparticipant/lookup/all', data)
                .then((response) => {
                    let result = response.data;
                    this.calendarEvent.eventParticipant = result.eventParticipantLookup;
                    this.calendarEvent.momRecorderList = this.getMomRecorders(result.eventParticipantLookup);
                    console.log(result);
                })
        },
        getMomRecorders(participants) {
        	return participants.filter(data => data.typeLookupId == 2);
        },
        // getMeetingParticipants(participants) {
        // 	return participants.filter(data => data.typeLookupId != 2);
        // },
        setCalendarEventData() {
        	if (this.calendarEventData.viewMode) {        		
				this.getParticipantList(this.calendarEventData.id);
        	}
			console.log(this.calendarEventData)
            this.calendarEvent.id = this.calendarEventData.id;
            this.calendarEvent.title = this.calendarEventData.title;
            this.calendarEvent.isAllDayEvent = this.calendarEventData.isAllDayEvent;
            this.calendarEvent.daysToRepeat = this.calendarEventData.daysToRepeat;
            this.calendarEvent.eventStartDate = this.getDateValue(this.calendarEventData.eventStartDate);
            this.calendarEvent.eventEndDate = this.getDateValue(this.calendarEventData.eventEndDate);
            this.calendarEvent.eventStartTime = this.calendarEventData.eventStartTime;
            this.calendarEvent.eventEndTime = this.calendarEventData.eventEndTime;
            this.calendarEvent.eventDate = this.calendarEventData.eventDate;
            this.calendarEvent.description = this.calendarEventData.description;
            this.calendarEvent.type = this.calendarEventData.type;
            this.calendarEvent.link = this.calendarEventData.link;
            this.calendarEvent.eventFrequency = this.calendarEventData.eventFrequency;
            this.calendarEvent.calendarTypeId = this.calendarEventData.calendarTypeId;
            this.calendarEvent.calendarCategoryId = this.calendarEventData.calendarCategoryId;
            this.calendarEvent.eventTypeId = this.calendarEventData.eventTypeId;
            this.calendarEvent.status = this.calendarEventData.status;
            this.calendarEvent.statusId = this.calendarEventData.statusId;
            this.calendarEvent.projectId = this.calendarEventData.projectId;
            this.calendarEvent.vhextAccountId = this.calendarEventData.vhextAccountId;
            this.calendarEvent.entityRecordMasterId = this.calendarEventData.entityRecordMasterId;
            this.calendarEvent.createdBy = this.calendarEventData.createdBy;
            this.calendarEvent.hostedBy = this.calendarEventData.hostedBy;
            this.calendarEvent.createdById = this.calendarEventData.createdById;
            this.calendarEvent.hostedById = this.calendarEventData.hostedById;
            this.calendarEvent.eventId = this.calendarEventData.eventId;
            this.calendarEvent.location = this.calendarEventData.location;
            this.calendarEvent.editMode = this.calendarEventData.editMode;
            this.calendarEvent.viewMode = this.calendarEventData.viewMode;
            this.calendarEvent.showMOM = this.calendarEventData.showMOM;
            this.calendarEvent.isMOMRecorder = this.calendarEventData.isMOMRecorder;
            this.meetingMinute.meetingDate = this.calendarEventData.selectedDate;
            this.meetingMinute.selectedDate = this.calendarEventData.selectedDate;
            this.meetingMinute.calendarEventId = this.calendarEventData.id;
            console.log(this.calendarEvent)

            let date = this.calendarEventData.selectedDate;
            this.meetingMinute.eventId = moment(new Date(date)).format('YYYYMMDD');
            // `${date.getFullYear()}${date.getMonth()+1}${date.getDate()}`;
            this.momData.calendarEventId = this.calendarEventData.id;
            this.momData.entityRecordMasterId = this.calendarEventData.entityRecordMasterId;
            this.momData.meetingDate = this.calendarEventData.selectedDate;
            this.$nextTick(() => {
	            this.getMeetingMinute(this.meetingMinute.meetingDate);
	            this.momViewKey++;
            })
        },
        showEdit: function(data, isMOM) {
            this.elements.editMode = true;
            this.elements.visible = true;
            this.elements.viewMode = false;
            this.meetingMinute.id = data.id;
            this.meetingMinute.meetingMinuteId = data.meetingMinuteId;
            this.meetingMinute.calendarEventId = data.calendarEventId;
            this.meetingMinute.eventId = data.eventId;
            this.meetingMinute.vhextAccountId = data.vhextAccountId;
            this.meetingMinute.projectId = data.projectId;
            this.meetingMinute.entityRecordMasterId = data.entityRecordMasterId;
            this.meetingMinute.entityId = data.entityId;
            this.meetingMinute.title = data.title;
            this.meetingMinute.meetingtypeId = data.meetingtypeId;
            this.meetingMinute.description = data.description;
            this.meetingMinute.meetingPreparationId = data.meetingPreparationId;
            this.meetingMinute.discussion = data.discussion;
            this.meetingMinute.decision =data.decision;
            this.meetingMinute.deadline =data.deadline;
            this.meetingMinute.actionTaken = data.actionTaken;
            this.meetingMinute.meetingDate = data.meetingDate;
            this.meetingMinute.startDt = data.startDt;
            this.meetingMinute.endDt = data.endDt;
            this.meetingMinute.estimatedHour = data.estimatedHour;
            this.meetingMinute.estimatedMinute = data.estimatedMinute;
            if (isMOM) {
	            this.meetingMinute.actualHour = data.estimatedHour;
	            this.meetingMinute.actualMinute = data.estimatedMinute;
            } else {
	            this.meetingMinute.actualHour = data.actualHour;
	            this.meetingMinute.actualMinute = data.actualMinute;
            }
            this.meetingMinute.duration = data.duration;
            this.meetingMinute.initiatedPersonId = data.initiatedPersonId;
            this.meetingMinute.responsiblePersonId = data.responsiblePersonId;
            this.meetingMinute.statusId = data.statusId;
            this.$refs.effortControlMpEst.setEffortInputData('', data.estimatedHour, data.estimatedMinute);
            this.$refs.effortControlMomAct.setEffortInputData('', data.actualHour, data.actualMinute);
        },
        combineDateAndTime: function(date, time) {
            console.log(date, time);
            if (date && date !== '') {
                if (time && time !== '') {
                    return moment(date.toString() + 'T' + time.toString());
                } else
                    return moment(date.toString() + 'T' + '00:00');
            }
        },
        clearData: function() {
            this.calendarEvent.title = '';
            this.calendarEvent.isAllDayEvent = false;
            this.calendarEvent.daysOfWeek = [];
            this.calendarEvent.daysToRepeat = [];
            this.calendarEvent.eventDate = this.getDateValue(new Date());
            this.calendarEvent.startDate = '';
            this.calendarEvent.endDate = '';
            this.calendarEvent.eventStartDate = this.getDateValue(new Date());
            this.calendarEvent.eventEndDate = this.getDateValue(new Date());
            this.calendarEvent.eventStartTime = this.getTimeValue(new Date());
            this.calendarEvent.eventEndTime = this.getTimeValue(new Date());
            this.calendarEvent.statusId = '';
            this.calendarEvent.hostedById = '';
            this.calendarEvent.link = '';
            this.calendarEvent.location = '';
            this.calendarEvent.description = '';
            this.calendarEvent.calendarTypeId = '';
            this.calendarEvent.calendarCategoryId = '';
            this.calendarEvent.eventTypeId = '';
            this.calendarEvent.eventFrequency = 0;
            this.calendarEvent.eventParticipantList = '';
            this.calendarEvent.eventParticipant = [];
            this.calendarEvent.momRecorderList = [];
            this.showOverlay = false;
            this.elements.visibleAjenda = false;
            this.isRecurValid = true;
            this.$v.calendarEvent.$reset();
        },
        cancelEditMode() {
        	this.setCalendarEventData();
        	this.calendarEvent.viewMode = true;
        	this.calendarEvent.editMode = false;
        },
        clearMMData: function() {
            this.elements.editMode = false;
            this.elements.viewMode = false;
            this.elements.visible = false;
            this.meetingMinute.id = '';
            this.meetingMinute.meetingMinuteId = '';
            this.meetingMinute.title = '';
            this.meetingMinute.meetingtypeId = '';
            this.meetingMinute.entityId = '';
            this.meetingMinute.description = '';
            this.meetingMinute.discussion = '';
            this.meetingMinute.decision = '';
            this.meetingMinute.deadline = '';
            this.meetingMinute.actionTaken = '';
            this.meetingMinute.startDt = '';
            this.meetingMinute.endDt = '';
            this.meetingMinute.estimatedHour = '';
            this.meetingMinute.estimatedMinute = '';
            this.meetingMinute.actualHour = '';
            this.meetingMinute.actualMinute = '';
            this.meetingMinute.duration = '';
            this.meetingMinute.initiatedPersonId = '';
            this.meetingMinute.responsiblePersonId = '';
            this.meetingMinute.statusId = '';
            this.$v.meetingMinute.$reset();
            this.$refs.effortControlMpEst.clearEffortInputData();
            this.$refs.effortControlMomAct.clearEffortInputData();
        },
        setMOMTab() {
        	this.activeTab = 3;
        	this.meetingMinute.statusId = this.meetingMinuteStatus.length>0 ? this.meetingMinuteStatus[0].id : '';
        	this.meetingMinute.entityRecordMasterId = this.calendarEvent.entityRecordMasterId;
        	this.clearMMData();
        	this.elements.viewModeMOM = this.totalRowsMOM > 0 ? true : false;
        },
        masterLookupRefresh() {
            this.$emit('masterLookupRefresh');
        },
        effortControlMpEstInput:function(data){
            this.$refs.effortControlMpEst.setEffortInputData(data.day, data.hrs, data.mins);
        },
        effortControlMomActInput:function(data){
            this.$refs.effortControlMomAct.setEffortInputData(data.day, data.hrs, data.mins);
        },
        isParticipantExist(arr, participantId) {
        	return arr.some(element => element.id == participantId);
        },
        checkRecurValidation() {
        	let result = false;
        	if (this.calendarEvent.eventFrequency==0) {
        		result = true
        	}
        	else if (this.calendarEvent.eventFrequency==1 && this.calendarEvent.daysToRepeat.length>0) {
        		result = true;
        	}
        	else {
        		result = false;
        	}
        	return result;
        },
        closeDialog() {
            this.clearData();
            this.clearMMData();
            this.$emit('closeDialog');
            this.$bvModal.hide('addCalEventPopup');
        },
        openErmMasterPop() {
            this.showDialog = true;
            this.$nextTick(()=> {
                this.$bvModal.show('ermMasterModal');
            })
        }
    }
}
</script>