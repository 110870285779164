<template>
    <div class="fixed-inner-header">
        <div class="container-fluid">
            <div class="row inner-header">
                <div class="col-12">
                    <div class="row">
                        <div class="col-lg-4 col-sm-4">
                            <h4 class="pl-2 mb-0 fs-16">{{this.ermTitle}}</h4>
                        </div>
                        <div class="col-lg-4 col-sm-4">
                            <h4 class="pl-2 mb-0 fs-14 text-center"><span class="text-muted">Requirement Specification</span></h4>
                        </div>

                        <div class="col-lg-4 col-sm-4">
                            <nav class="breadcrumb-container d-sm-block d-lg-inline-block float-right" aria-label="breadcrumb">
                                <ol class="breadcrumb pt-0 mb-0">
                                    <li class="breadcrumb-item">
                                        <a href="javascript:" @click="getPrevUrl()"><i class="fa fa-reply pr-2 action-link" aria-hidden="true"></i>Back</a>
                                    </li>
                                    <li class="breadcrumb-item">
                                        <a title="Board View" class="action-link" @click="routeReqBoard()">
                                            <i class="fa fa-sitemap px-1" aria-hidden="true"></i>
                                        </a>
                                    </li>
                                    <li class="breadcrumb-item">
                                        <h4 class="pl-2 mb-0 fs-14 text-right">#{{this.projId}} - {{this.projectName}}</h4>
                                    </li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                    <div class="separator mb-2"></div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-12 col-xl-12">
                    <div class="card mb-4">
                        <div class="card-body p-3">
                            <b-overlay :show="showOverlay" opacity="0.4">
                                <div v-if="ermIsFreeze!=true"  id="scope-app" class="mb-3">
                                    <b-card no-body class="">
                                        <b-card-header header-tag="header" class="p-0 accordion-header" role="tab">
                                            <label class="d-block p-2 accordion-title" block href="javascript:" v-b-toggle.accordion-1>
                                                <span v-if="!elements.editMode && !elements.viewMode"><i class="fa fa-plus-circle pr-2 action-link"></i>New Requirements</span>
                                                <span v-if="elements.editMode && !elements.viewMode">Edit Requirement</span>
                                                <span v-if="elements.viewMode">View Requirement</span>
                                            </label>
                                        </b-card-header>
                                        <b-collapse id="accordion-1" accordion="my-accordion" v-model="elements.visible" role="tabpanel">
                                            <b-card-body class="">
                                                <div v-if="!elements.viewMode">
                                                    <div class="row mb-2">
                                                        <div class="col-3">
                                                            <div class="form-group">
                                                                <label for="title">Title</label>
                                                                <div class="required-field-block" :class="{ 'is-invalid': $v.requirementSpec.title.$error }">
                                                                    <input type="text" class="form-control" ref="title" v-model="requirementSpec.title" id="title" :class="{ 'is-invalid': $v.requirementSpec.title.$error }" placeholder="Enter Title">
                                                                    <div class="required-icon"></div>
                                                                </div>
                                                                <label v-if="$v.requirementSpec.title.$error && !$v.requirementSpec.title.minLength" class="text-danger">Title must have at least {{$v.requirementSpec.title.$params.minLength.min}} letters.</label>
                                                                <label v-if="$v.requirementSpec.title.$error && !$v.requirementSpec.title.required" class="text-danger">Please enter Title</label>
                                                                <label v-if="$v.requirementSpec.title.$error && !$v.requirementSpec.title.maxLength" class="text-danger">Title must not exceed {{$v.requirementSpec.title.$params.maxLength.max}} letters.</label>
                                                            </div>
                                                        </div>
                                                        <div class="col-3">
                                                            <div class="form-group">
                                                                <label for="requirementTypeId">Requirement Type</label>
                                                                <select v-model="requirementSpec.requirementTypeId" id="requirementTypeId" class="form-control">
                                                                    <option value="">Select</option>
                                                                    <option v-for="(info, index) in requirementTypes" :value="info.id">{{info.value}}</option>
                                                                </select>
                                                                <a :title="typeLookupTitle" class="setting-icon" href="javascript:" v-b-modal.typeModal><i class="fa fa-cog" aria-hidden="true"></i></a>
                                                            </div>
                                                        </div>
                                                        <div class="col-3">
                                                            <div class="form-group">
                                                                <label for="requirementById">Requirement By</label>
                                                                <select v-model="requirementSpec.preparedById" id="preparedById" class="form-control">
                                                                    <option value="">Select</option>
                                                                    <option v-for="(info, index) in users" :value="info.id">{{info.value}}</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div class="col-3">
                                                            <div class="form-group">
                                                                <label for="preparedDt">Prepared Date</label>
                                                                <input v-model="requirementSpec.preparedDt" id="preparedDt" type="date" class="form-control">
                                                            </div>
                                                        </div>
                                                        <div class="col-12">
                                                            <div class="form-group">
                                                                <label for="requirementSpec">Requirement Specification</label>
                                                                <editor api-key="lbcn6twu8tv4xan8fn1tgqiqy9hhvsse01s48crluvfuafzp" :toolbar="toolbar" :init="initObj" v-model="requirementSpec.requirementSpecification"></editor>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="row mb-1">
                                                        <div class="col-12">
                                                            <AttachmentComponent ref="attachmentControl" @resetAttachmentStatus="resetAttachmentStatus" :attachmentInfo="attachmentInfo" :attachments="attachments" />
                                                        </div>
                                                    </div>
                                                    <div class="row mb-2">
                                                        <div class="col-3">
                                                            <div class="form-group">
                                                                <label for="priorityId">Priority</label>
                                                                <select v-model="requirementSpec.priorityId" id="priorityId" class="form-control">
                                                                    <option value="">Select</option>
                                                                    <option v-for="(info, index) in requirementPriorities" :value="info.id">{{info.value}}</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div class="col-3">
                                                            <div class="form-group">
                                                                <label for="dueDate">Due Date</label>
                                                                <input type="date" class="form-control" v-model="requirementSpec.dueDate" id="dueDate">
                                                            </div>
                                                        </div>
                                                        <div class="col-3">
                                                            <div class="form-group">
                                                                <label for="statusId">Status</label>
                                                                <div class="required-field-block" :class="{ 'is-invalid': $v.requirementSpec.statusId.$error }">
                                                                    <select v-model="requirementSpec.statusId" ref="statusId" id="statusId" :class="{ 'is-invalid': $v.requirementSpec.statusId.$error }" class="form-control">
                                                                        <option v-for="(info, index) in requirementStatus" :value="info.id">{{info.value}}</option>
                                                                        <!-- <option v-for="(info, index) in requirementStatus" :key="reqStatusKey" v-bind:value="info.id" v-bind:selected="info.value === 1">{{info.value}}</option> -->
                                                                    </select>
                                                                    <div class="required-icon"></div>
                                                                </div>
                                                                <label v-if="$v.requirementSpec.statusId.$error && !$v.requirementSpec.statusId.required" class="text-danger">Please enter Title</label>
                                                            </div>
                                                        </div>
                                                        <FunctionalReferencePopup ref="referenceModal" :entity="this.entity" :erdId="requirementSpec.id" />
                                                    </div>
                                                    <button type="submit" @click="saveData" :disabled="showOverlay" class="btn btn-primary mb-0 mr-2 pt-1 pb-1 pl-3 pr-3"><i class="fa fa-floppy-o pr-2"></i>Save</button>
                                                    <button type="submit" @click="clearData" class="btn btn-primary mb-0 pt-1 pb-1 pl-3 pr-3"><i class="fa fa-times pr-2"></i>Cancel</button>
                                                </div>
                                                <div v-if="elements.viewMode">
                                                    <div class="row">
                                                        <div class="col-12 col-sm-3">
                                                            <label for="title1">Title</label>
                                                            <b-input-group :prepend="requirementSpec.requirementSpecId" class="mb-2">
                                                                <b-form-input aria-label="First name" id="title1" ref="setFocus" disabled v-model="requirementSpec.title"></b-form-input>
                                                            </b-input-group>
                                                        </div>
                                                        <div class="col-12 col-sm-3">
                                                            <div class="form-group">
                                                                <label>Requirement Type</label>
                                                                <input type="text" class="form-control" disabled v-model="requirementSpec.type">
                                                            </div>
                                                        </div>
                                                        <div class="col-12 col-sm-3">
                                                            <div class="form-group">
                                                                <label>Prepared By</label>
                                                                <input type="text" class="form-control" disabled v-model="requirementSpec.preparedBy">
                                                            </div>
                                                        </div>
                                                        <div class="col-12 col-sm-3">
                                                            <div class="form-group">
                                                                <label>Prepared Date</label>
                                                                <div class="input-group date disabled-date" disabled>
                                                                    <input type="text" id='date-input' class="form-control" disabled :value="requirementSpec.preparedDt |formatDate" readonly>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <div class="col-12 col-sm-12">
                                                            <div class="form-group">
                                                                <label for="requirement">Requirement</label>
                                                                <editor api-key="lbcn6twu8tv4xan8fn1tgqiqy9hhvsse01s48crluvfuafzp" :init="menuSetting" disabled v-model="requirementSpec.requirementSpecification"></editor>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <div class="col-12 col-sm-3">
                                                            <div class="form-group">
                                                                <label>Priority</label>
                                                                <input type="text" class="form-control" disabled v-model="requirementSpec.priority">
                                                            </div>
                                                        </div>
                                                        <div class="col-12 col-sm-2">
                                                            <div class="form-group">
                                                                <label for="dueDate">Due Date</label>
                                                                <div class="input-group date disabled-date" disabled>
                                                                    <input type="text" class="form-control disabled" :value="requirementSpec.dueDate | formatDate" readonly>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-12 col-sm-3">
                                                            <div class="form-group">
                                                                <label>Status</label>
                                                                <input type="text" class="form-control" disabled v-model="requirementSpec.status">
                                                            </div>
                                                        </div>
                                                        <div class="col-12 col-sm-2">
                                                            <div class="form-group">
                                                                <label for="startDate">Created Date</label>
                                                                <div class="input-group date disabled-date" disabled>
                                                                    <input type="text" class="form-control disabled" :value="requirementSpec.createdDt | formatDate" readonly>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-12 col-sm-2">
                                                            <div class="form-group">
                                                                <label>Updated Date</label>
                                                                <div class="input-group date disabled-date">
                                                                    <input type="text" class="form-control disabled" :value="requirementSpec.lastUpdatedDt | formatDate" readonly>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-12">
                                                            <div class="form-group">
                                                                <label>Attachment</label>
                                                                <AttachmentComponent ref="attachmentControl" :attachmentInfo="attachmentInfo" viewAttachment="true" :attachments="attachments" />
                                                            </div>
                                                        </div>
                                                        <div class="col-12">
                                                            <FunctionalReferencePopup ref="referenceModal" :entity="this.entity" :erdId="requirementSpec.id" />
                                                        </div>
                                                    </div>
                                                    <button type="submit" @click="clearData" class="sbtn btn btn-primary mb-0 pt-1 pb-1 pl-3 pr-3"><i class="fa fa-times pr-2"></i>Cancel</button>
                                                </div>
                                            </b-card-body>
                                        </b-collapse>
                                    </b-card>
                                </div>
                                <template #overlay>
                                    <div class="text-center">
                                        <LoadingSpinner />
                                    </div>
                                </template>
                            </b-overlay>
                            <b-overlay :show="isBusy" spinner-variant="primary" opacity="0.4" rounded="sm">
                                <div class="row view-filter">
                                    <div class="col-sm-12">
                                        <div class="float-right">
                                            <div class="dataTables_length">
                                                <b-form-group label="Per page" label-cols-sm="6" label-align-sm="right" label-size="sm" label-for="perPageSelect" class="mb-0">
                                                    <b-form-select v-model="perPage" id="perPageSelect" size="sm" :options="pageOptions"></b-form-select>
                                                </b-form-group>
                                            </div>
                                        </div>
                                        <div class="float-left">
                                            <div class="dataTables_filter">
                                                <label>
                                                    <input type="search" v-model="filter" class="form-control form-control-sm" placeholder="Search...">
                                                </label>
                                            </div>
                                        </div>
                                        <div class="float-right my-2">
                                            <download-excel :data="requirementSpecs" :fields="requirementSpecExcelFields" :name="this.ermTitle">
                                                <a href="javascript:" @click=""><i class="fa fa-file-excel-o pr-2 green-color" aria-hidden="true"></i></a>
                                            </download-excel>
                                        </div>                                                                        
                                        <div class="clearfix"></div>
                                    </div>
                                </div>
                                <b-table show-empty small stacked="md" sort-icon-left :items="requirementSpecs" :fields="requirementSpecFields" responsive="sm" :current-page="currentPage" :per-page="perPage" :filter="filter" :filterIncludedFields="filterOn" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" @filtered="onFiltered" foot-clone foot-variant=light no-footer-sorting>
                                    <template v-slot:cell(requirementSpecId)="data">
                                        <div v-if="data.item.id == inlineEditRowId && data.field.key == inlineEditFieldName || data.item.requirementSpecId == ''">
                                            <input type="number" class="form-control" :id="data.field.key+data.item.id" v-model="data.value" placeholder="" @keyup.enter="updateInlineIdValue(data.item.id,data.value)" @blur="cancelInlineEdit(requirementSpecs, data.field.key)">
                                        </div>
                                        <div v-else @dblclick="focusInlineEdit(data.field.key+data.item.id, data.item.id, data.field.key, data.value)" class="action-link"> {{ data.value }} <span v-if="!data.value"> Id </span></div>
                                    </template>

    <!--                                 <template #cell(requirementSpecId)="data" v-if="edit">
                                        <b-form-input type="text" :value="data.value"></b-form-input>
                                    </template> -->
    <!--                                 <template v-slot:cell()="{ item, field: { key } }">
                                      <b-form-input v-model="item[0]" />
                                    </template>                                 -->
                                    <template v-slot:cell(title)="data">
                                        <a class="tooltiptext action-link" href="javascript:" @click="viewItemDetails(data.item)">{{data.value}}</a>
                                    </template>
                                    <template v-slot:cell(requirementSpecification)="data">
                                        <span class="tooltiptext" href="javascript:" v-html="data.value"></span>
                                    </template>
                                    <!--                                 <template v-slot:cell(requirementSpecification)="data">
                                            <a href="javascript:" v-html="data.value" v-b-modal.htmlDetailView @click="htmlDetailViews(data.item.requirementSpecification)"></a>
                                    </template> -->
                                    <template v-slot:cell(actions)="data">
                                        <div v-if="data.item.hasAttachment" class="dropdown dropright d-inline">
                                            <a title="Attachment" class="dropdown-toggle pl-2 fa fa-paperclip" href="javascript:" @click="getAttachments(data.item.id)" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            </a>
                                            <div class="dropdown-menu">
                                                <b-dropdown-item v-if="attachmentList.length==0"></b-dropdown-item>
                                                <div v-if="attachmentList.length>0">
                                                    <ul class="attachmentList-ul p-0 m-0">
                                                        <li v-for="attachment,index in attachmentList" :key="attachment.id" v-if="attachment.fileName && attachment.fileName !== null">
                                                            <a :href="attachment.attachmentPath" class="action-link" target="_blank">{{attachment.fileName}}</a>

                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <a href="javascript:" title="View" @click="showView(data.item)"><i class="fa fa-eye pl-2"></i></a>
                                        <a href="javascript:" title="Assign Task" @click="assignTask(data.item)" v-b-modal.ermAssignments><i class="fa fa-user-plus pl-2"></i></a>
                                        <!-- <span>{{data.item.preparedById}}{{ermCreatedById}}{{userId}}</span> -->
                                        <span v-if="data.item.preparedById == userId || userId == ermCreatedById">
                                            <a v-if="ermIsFreeze!=true" href="javascript:" title="Edit" @click="showEdit(data.item)"><i class="fa fa-pencil-square-o fa-custom fa-lg  pl-2"></i>
                                            </a>
                                            <a v-if="ermIsFreeze!=true" href="javascript:" title="Delete" @click="deleteRequirementSpec(data.item.id)" v-b-modal.deleteRequirementSpec><i class="fa fa-trash pl-2"></i></a>
                                        </span>
                                    </template>
                                    <template v-slot:empty="scope">
                                        <div class="text-center">No Requirements Found</div>
                                    </template>
                                    <template v-slot:emptyfiltered="scope">
                                        <div class="text-center">{{ scope.emptyFilteredText }}</div>
                                    </template>

                                    <template v-slot:foot()="data">
                                        <span></span>
                                    </template>
                                </b-table>
                                <span :class="'badge-secondary'" class="fs-11 badge badge-pill pointer text-center float-left">{{this.totalRows}} Items</span>
                                <b-pagination v-if="requirementSpecs.length!=0" pills v-model="currentPage" :total-rows="totalRows" :per-page="perPage" first-number last-number align="fill" size="sm" class="my-0 pull-right"></b-pagination>
                            </b-overlay>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <TypeLookup ref="typeLookupModal" :projectTypeId="this.projectTypeId" :entity="this.typeEntity" :typeLookupTitle="typeLookupTitle" @refresh-lookup="lookupData" />
        <HTMLDetailViewPopup ref="htmlDetailViewRef" :htmlData="htmlData" :htmlDetailViewTitle="htmlDetailViewTitle" />
        <DeleteComponent id="deleteRequirementSpec" :onYes="onYes" :returnParams="deleteParam" title="Delete Confirmation" message="Are you sure to delete the Requirement" />
        <ReqSpecDetailPopup v-if="showDialog" ref="reqSpecDetailPopupRef" :attachmentData="attachmentData" :detailViewData="detailViewData" :referenceDetailId="referencePopupId" @closeDialog="closeDialog" />
        <QuickTaskPopup ref="ermAssignmentsRef" :masterData="masterData" />
    </div>
</template>
<script>
import iziToast from 'izitoast';
import moment from 'moment';
import { required, email, minLength, maxLength, sameAs } from "vuelidate/lib/validators";
import TypeLookup from '@/components/popup/lookup/TypeLookup.vue';
import HTMLDetailViewPopup from '@/components/popup/itemdetail/HTMLDetailViewPopup.vue';
import FunctionalReferencePopup from '@/components/popup/reference/FunctionalReferencePopup.vue';
import Editor from '@tinymce/tinymce-vue';
import AttachmentComponent from '@/components/common/AttachmentComponent.vue';
import ReqSpecDetailPopup from '@/components/popup/itemdetail/ReqSpecDetailPopup.vue';
import QuickTaskPopup from '@/components/popup/action/QuickTaskPopup.vue';
export default {
    name: 'app',
    components: {
        TypeLookup,
        HTMLDetailViewPopup,
        FunctionalReferencePopup,
        ReqSpecDetailPopup,
        Editor,
        AttachmentComponent,
        QuickTaskPopup,
    },
    data() {
        return {
            showOverlay: false,
            isBusy: false,
            elements: {
                editMode: false,
                visible: false,
                viewMode: false
            },
            showDialog: false,
            notEnabled: true,

            // reqStatusKey: 0,

            vhextAccountId: localStorage.getItem("vhextAccountId"),
            projectId: localStorage.getItem("projectId"),
            projectTypeId: localStorage.getItem("projectTypeId"),
            projId: localStorage.getItem("projId"),
            projectName: localStorage.getItem("projectName"),

            entityRecordMasterId: this.$route.params.ermId,
            ermCreatedById: localStorage.getItem("ermCreatedById"),
            ermTitle: localStorage.getItem("ermTitle"),
            ermIsFreeze: true,

            htmlData: '',
            htmlDetailViewTitle: 'Requirement Specification',
            typeLookupTitle: 'Requirement Type Settings',
            functionalRefPopupTitle: 'Add Reference',
            entity: 'requirement_spec',
            typeEntity: 'requirement_spec_type',

            inlineEditRowId: '',
            inlineEditFieldValue: '',
            inlineEditFieldName: '',


            filteredStatusArray: [],
            users: [],
            requirementTypes: [],
            requirementStatus: [],
            requirementPriorities: [],
            requirementActivities: [],
            ermReferenceList: [],
            requirementSpec: {
                id: 0,
                requirementSpecId: '',
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                projectId: localStorage.getItem("projectId"),
                entityRecordMasterId: this.entityRecordMasterId,

                title: '',
                requirementTypeId: '',
                requirementSpecification: '',
                preparedById: '',
                isArchive: false,
                preparedDt: new Date().toISOString().substr(0, 10),
                priorityId: '',
                dueDate: '',
                statusId: '',
            },
            masterData: {
                refErmId: 0,
                refErdId: 0,
                refEntityId: 0,
                refErdTitle: '',
                refErdDescription: '',
                refEntity: '',
                statusId: 1,
            },
            
            attachmentList:[],

            detailViewData: {},
            referencePopupId: 0,
            attachmentData: {},

            transProps: {
                // Transition name
                name: 'flip-list'
            },
            requirementSpecs: [],
            requirementSpecFields: [
                { key: 'requirementSpecId', label: 'Id', tdClass: 'table-cw-100', sortable: true, sortDirection: 'desc', editable: true  },
                { key: 'title', label: 'Title', tdClass: 'table-title-col-size', sortable: true, sortDirection: 'desc' },
                { key: 'type', label: 'Requirement Type', tdClass: 'table-title-col-size', sortable: true, sortDirection: 'desc' },
                { key: 'requirementSpecification', label: 'Requirement Specification', tdClass: 'w-40', sortDirection: 'desc' },
                { key: 'priority', label: 'Priority', sortable: true, sortDirection: 'desc' },
                { key: 'dueDate', label: 'Due Date', formatter: "formatDate", sortable: true, sortDirection: 'desc' },
                { key: 'preparedBy', label: 'Prepared By', sortable: true, sortDirection: 'desc' },
                { key: 'status', label: 'Status', sortable: true, sortDirection: 'desc' },
                { key: 'actions', label: 'Actions' }
            ],
            requirementSpecExcelFields:{
                "Id":"requirementSpecId",
                "Title":"title",
                "Requirement Type":"type",
                "Requirement Specification":"requirementSpecification",
                "Due date":"dueDate",
                "Status":"status",
            },

            totalRows: 1,
            currentPage: 1,
            perPage: 10,
            pageOptions: [5, 10, 15, 20, 25, 50],
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',
            filter: null,
            filterOn: [],
            infoModal: {
                id: 'info-modal',
                title: '',
                content: ''
            },
            deleteParam: {
                id: 0
            },
            weblinks: 'google.com',
            toolbar: 'undo redo preview | formatselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | weblinks',
            initObj: {
                height: 300,
                branding: false,
                menubar: true,
                plugins: ['link', 'paste', 'wordcount', 'preview', 'image imagetools', 'lists'],
                paste_data_images: true,
                images_upload_url: 'postAcceptor.php',
                automatic_uploads: false,
                file_picker_types: 'image',
                relative_urls: false,
                remove_script_host: false,
                /* setup: function(editor) {
                     editor.ui.registry.addMenuButton('weblinks', {
                         text: "Web URL",
                         icon: "link",
                         fetch: function(callback) {
                             var items = [];
                             self.weblinks.map(function(value, key) {
                                 console.log(value);
                                 items.push({
                                     type: 'menuitem',
                                     text: value.name,
                                     onAction: function() {
                                         editor.insertContent('<a href="' + value.id + '">' + value.name + '</a>');
                                     }
                                 })
                             });
                             callback(items);
                         },
                         onSelect: function(e) {
                             tinyMCE.execCommand(this.value());
                             console.log(this.value());
                         },
                     });
                 }*/
            },
            attachmentInfo: {
                title: 'Attachment'
            },
            attachments: [],
            menuSetting: {
                height: 300,
                menubar: false,
            },
        }
    },
    computed: {
        sortOptions() {
            // Create an options list from our fields
            return this.fields
                .filter(f => f.sortable)
                .map(f => {
                    return { text: f.label, value: f.key }
                })
        },
    },
    mounted() {
        // this.requirementSpec.status = this.requirementStatus[0].id;
        this.initialSettings();
    },
    validations: {
        requirementSpec: {
            title: {
                required,
                minLength: minLength(2),
                maxLength: maxLength(100)
            },
            statusId: {
                required
            }
        }
    },
    methods: {
        initialSettings: function() {
            this.vhextAccountId = localStorage.getItem("vhextAccountId");
            this.projectId = localStorage.getItem("projectId");

            if (localStorage.getItem("ermId") != '')
                this.entityRecordMasterId = localStorage.getItem("ermId");
            else
                this.entityRecordMasterId = this.$route.params.ermId;

            // this.ermIsFreeze = localStorage.getItem("ermIsFreeze");
            // this.$store.getters.getERMData.erm_title;
            this.entity = 'requirement_spec';
            this.userId = localStorage.getItem("userId");

            this.requirementSpec.vhextAccountId = this.vhextAccountId;
            this.requirementSpec.projectId = this.projectId;
            this.requirementSpec.entityRecordMasterId = this.entityRecordMasterId;

            this.requirementSpec.preparedById = this.userId;

            if (this.entityRecordMasterId.length > 10) {
                this.getShareDecodedData(this.entityRecordMasterId);
            } else {
                this.lookupData();
                this.getRequirementSpecs();
            }
        },
        lookupData: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
                projectId: this.projectId,
                projectTypeId: this.projectTypeId,
                entity: 'requirement_spec',
            }
            axios.post(this.$store.getters.getAPIBasePath + '/requirement/requirementspec/lookup/all', data)
                .then((response) => {
                    let result = response.data;
                    this.requirementTypes = result.customTypeLookup;
                    this.requirementStatus = result.statusLookup;
                    this.requirementPriorities = result.priorityLookup;
                    this.users = result.userLookup;

                    this.requirementSpec.statusId = this.requirementStatus[0].id;

                })
        },
        getRequirementSpecs: function() {
            let data = {
                entityRecordMasterId: this.entityRecordMasterId,
            }
            axios.post(this.$store.getters.getAPIBasePath + '/requirement/requirementspec/list', data)
                .then((response) => {
                    let result = response.data;
                    if (result.length != 0) {
                        this.requirementSpecs = result.requirementSpecList;
                        if (this.requirementSpecs.length > 0) {
                            this.ermIsFreeze = this.requirementSpecs[0].ermFreeze==true ? true : false;
                        } 
                        else this.ermIsFreeze = false;
                        this.totalRows = this.requirementSpecs.length;
                        console.log(this.requirementSpecs);
                    }
                })
        },
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },
        async saveData() {
            this.$v.requirementSpec.$touch();

            if (!this.$v.requirementSpec.$invalid) {
                this.showOverlay = true;

                this.requirementSpec.hasAttachment = await this.$refs.attachmentControl.hasAttachment();

                let result;
                if (this.elements.editMode) {
                    result = await this.updateRequirementSpec();
                    await this.$refs.attachmentControl.saveAttachment(this.entity, this.requirementSpec.id);
                } else {
                    result = await this.saveRequirementSpec();
                    await this.$refs.attachmentControl.saveAttachment(this.entity, result.data.id);
                }

                console.log('result',result);
                this.showOverlay = false;
                if (result.data.status == "CREATED") {                 
                    iziToast.success({
                        title: 'Success',
                        message: result.data.message,
                        position: 'topRight'
                    });
                    this.getRequirementSpecs();
                    this.clearData();
                }
            }
            else this.focusFirstError('requirementSpec');
        },
        saveRequirementSpec: function() {
            try {
                return axios.post(this.$store.getters.getAPIBasePath + '/requirement/requirementspec/add', this.requirementSpec);
            }
            catch(error) {
                this.showOverlay = false;
                iziToast.error({
                    title: 'Error',
                    message: 'Error',
                    position: 'topRight'
                });
            }
        },
        updateRequirementSpec: function() {
            try {
                return axios.post(this.$store.getters.getAPIBasePath + '/requirement/requirementspec/update', this.requirementSpec);
            }
            catch(error) {
                this.showOverlay = false;
                iziToast.error({
                    title: 'Error',
                    message: 'Error',
                    position: 'topRight'
                });
            }
        },

        updateInlineIdValue: function(id,newErdId) {
            // alert(id);
            
            if (!this.matchNumValExists(id,newErdId)) {
                let data = {
                    idInitType: "PERD",
                    vhextAccountId: localStorage.getItem("vhextAccountId"),
                    projectId: localStorage.getItem("projectId"),
                    entity: this.entity,
                    id: id,  
                    newErdId: newErdId,            
                }
                console.log(data);
                this.isBusy = true;
                axios.post(this.$store.getters.getAPIBasePath + '/common/update/id', data)
                    .then(response => {
                        this.isBusy = false;
                        var result = response.data;
                        if (result.status == "OK") {
                            iziToast.success({
                                title: 'Success',
                                message: result.message,
                                position: 'topRight'
                            });
                            this.clearInlineEdit();
                            this.getRequirementSpecs();
                        }
                    })
                    .catch(error => {
                        this.isBusy = false;
                        this.cancelInlineEdit();
                        iziToast.error({
                           title: 'Error',
                           message: 'Error',
                           position: 'topRight'
                       });
                    })

            }
            else {
                iziToast.info({
                    message: 'Id already exist.',
                    position: 'center'
                });
            }
            
        },
        focusInlineEdit(cellId, rowId, fieldName, fieldValue) {
            console.log(cellId, rowId, fieldName, fieldValue);
            // alert(cellId);
            this.inlineEditRowId = rowId;
            this.inlineEditFieldValue = fieldValue;
            this.inlineEditFieldName = fieldName;
            this.$nextTick(() => {
                document.getElementById(cellId).focus();
            })
        },
        cancelInlineEdit: function(items,fieldName) {
            items = items.map(data => {
                if(data.id == this.inlineEditRowId) {
                    data[fieldName] = this.inlineEditFieldValue;
                    return data;
                }
                else {
                    return data;
                }
            });
            this.clearInlineEdit();
        },
        clearInlineEdit() {
            this.inlineEditRowId ='';
            this.inlineEditFieldValue ='';
            this.inlineEditFieldName ='';
        },               
        deleteRequirementSpec: function(id) {
            console.log(id)
            this.deleteParam.id = id;
        },
        onYes: function() {
            let data = {
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                projectId: localStorage.getItem("projectId"),
                entity: this.entity,
                id: this.deleteParam.id,               
            }
            console.log(data);

            axios.post(this.$store.getters.getAPIBasePath + '/common/erd/delete', data)
                .then(response => {
                    var result = response.data;
                    if (result.status == "OK") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.getRequirementSpecs();
                    }
                })
                .catch(error => {
                    console.log(error);
                });
        },        
        clearData: function() {
            this.attachmentInfo.title = "Attachment";
            this.elements.editMode = false;
            this.elements.visible = false;
            this.elements.viewMode = false;
            this.requirementSpec.id = null;
            this.requirementSpec.requirementSpecId = '';
            this.requirementSpec.title = '';
            this.requirementSpec.requirementTypeId = '';
            this.requirementSpec.type = '';
            this.requirementSpec.requirementSpecification = '';
            this.requirementSpec.priorityId = '';
            this.requirementSpec.priority = '';
            this.requirementSpec.dueDate = '';
            this.requirementSpec.statusId = this.requirementStatus[0].id;
            this.requirementSpec.status = '';
            this.requirementSpec.hasReference = '';
            this.requirementSpec.hasRefLink = '';
            this.requirementSpec.hasAttachment = '';
            this.requirementSpec.preparedById = this.userId;
            this.requirementSpec.preparedBy = '';
            this.requirementSpec.preparedDt = new Date().toISOString().substr(0, 10);
            this.$refs.attachmentControl.clearAttachment();
            this.attachments = [];
            this.$refs.referenceModal.clearReference();
            this.$v.requirementSpec.$reset();
        },
        showEdit: function(data) {
            console.log(data);
            // this.$refs.title.$el.focus();
            this.elements.editMode = true;
            this.elements.visible = true;
            this.elements.viewMode = false;
            this.requirementSpec.id = data.id;
            this.requirementSpec.requirementSpecId = data.requirementSpecId;
            this.requirementSpec.title = data.title;
            this.requirementSpec.requirementTypeId = data.requirementTypeId;
            this.requirementSpec.requirementSpecification = data.requirementSpecification;
            this.requirementSpec.isArchive = data.isArchive;
            this.requirementSpec.preparedById = data.preparedById;
            this.requirementSpec.preparedDt = data.preparedDt;
            this.requirementSpec.priorityId = data.priorityId;
            this.requirementSpec.dueDate = data.dueDate;
            this.requirementSpec.statusId = data.statusId;
            this.requirementSpec.hasReference = data.hasReference;
            this.requirementSpec.hasRefLink = data.hasRefLink;
            this.requirementSpec.hasAttachment = data.hasAttachment;
            this.attachmentInfo.title = "Attachment";
            this.$nextTick(() => {
                this.$refs.attachmentControl.getAttachmentData(this.entity, data.id, this.vhextAccountId, this.projectId);
            })
            this.setFocus();
        },
        setFocus() {

            this.$refs.title.focus();
            // this.$refs.input.$el.children[0].focus();
                 // this.form.email.focus()// don't  work
        },
        showView: function(data) {
            this.elements.visible = true;
            this.elements.viewMode = true;

            this.requirementSpec.id = data.id;
            this.requirementSpec.requirementSpecId = data.requirementSpecId.toString();
            this.requirementSpec.title = data.title;
            this.requirementSpec.requirementTypeId = data.requirementTypeId;
            this.requirementSpec.type = data.type;
            this.requirementSpec.requirementSpecification = data.requirementSpecification;
            this.requirementSpec.isArchive = data.isArchive;
            this.requirementSpec.preparedById = data.preparedById;
            this.requirementSpec.preparedDt = data.preparedDt;
            this.requirementSpec.priorityId = data.priorityId;
            this.requirementSpec.priority = data.priority;
            this.requirementSpec.dueDate = data.dueDate;
            this.requirementSpec.statusId = data.statusId;
            this.requirementSpec.status = data.status;
            this.requirementSpec.hasReference = data.hasReference;
            this.requirementSpec.hasRefLink = data.hasRefLink;
            this.requirementSpec.hasAttachment = data.hasAttachment;
            this.requirementSpec.createdDt = data.createdDt;
            this.requirementSpec.lastUpdatedDt = data.lastUpdatedDt;
            this.attachmentInfo.title = "";
            this.$nextTick(() => {
                this.$refs.attachmentControl.getAttachmentData(this.entity, data.id, this.vhextAccountId, this.projectId);
            })
            this.setFocus();

        },
        getDate() {
            var today = new Date();

            document.getElementById("date").value = today.getFullYear() + '-' + ('0' + (today.getMonth() + 1)).slice(-2) + '-' + ('0' + today.getDate()).slice(-2);
        },
        htmlDetailView: function(data) {
            this.htmlData = data;
            console.log(data);
        },

        // editor(HTML) {
        //     var input = HTML;
        //     return input.replace(/<(style|script|iframe)[^>]*?>[\s\S]+?<\/\1\s*>/gi, '').replace(/<[^>]+?>/g, '').replace(/\s+/g, ' ').replace(/ /g, ' ').replace(/>/g, ' ');
        // },
        getShareDecodedData: function(key) {
            let data = {
                key: String(key)
            }
            let ermId;
            console.log(this.$store.getters.getAPIBasePath);
            axios.post(this.$store.getters.getAPIBasePath + '/review/decodeddata', data)
                .then((response) => {
                    let result = response.data;
                    console.log(result);
                    if (result.decodedData.entityRecordMasterId != null) {
                        this.entityRecordMasterId = result.decodedData.entityRecordMasterId;
                        this.requirementSpec.entityRecordMasterId = this.entityRecordMasterId;
                        this.lookupData();
                        this.getRequirementSpecs();
                    }

                })
            return ermId;
        },
        attachDocument: function(entity, refRecordId) {
            this.$refs.attachmentControl.saveAttachment(entity, refRecordId);
        },
        viewItemDetails(data) {
            this.detailViewData = data;
            this.referencePopupId = data.id;
            this.attachmentData.refEntity = this.entity;
            this.attachmentData.refRecordId = data.id;
            this.showDialog = true;
            this.$nextTick(() => {
                this.$refs.reqSpecDetailPopupRef.getDetails(this.referencePopupId, null);
            })
        },
        closeDialog: function() {
            console.log('closed');
            this.showDialog = false;
        },
        assignTask: function(data) {
            console.log(data);
            this.masterData.refErmId = data.entityRecordMasterId;
            this.masterData.refErdId = data.id;
            this.masterData.refEntityId = '';
            this.masterData.refEntity = this.entity;
            this.masterData.refErdTitle = data.title;
            this.masterData.refErdDescription = data.requirementSpecification;
            this.$refs.ermAssignmentsRef.onRefDataChange();
            console.log(this.masterData.refErmId, this.masterData.refEntityId);
        },
        matchNumValExists: function(id,numVal) {
            let result = false;
            this.requirementSpecs.map(data => {
                if (numVal && data.requirementSpecId && data.id !== id) {
                    if (data.requirementSpecId == numVal) {
                        result = true;
                    }
                }
            });
            return result;
        },
        resetAttachmentStatus: function(refRecordId) {
            this.requirementSpecs = this.requirementSpecs.map(data => {
                if(data.id == refRecordId) {
                    data.hasAttachment = false;
                    return data;
                }
                else{
                    return data;
                }

            });
        },        

        // setErmData: function() {
        //     let ermData = {
        //         ermId: this.$store.getters.getERMData.erm_id,
        //         ermTitle: this.$store.getters.getERMData.erm_title,
        //     }

        //     console.log(ermData);
        //     this.$store.dispatch("setERMData", ermData);

        // },
        routeReqBoard() {
            // this.setErmData();

            // localStorage.setItem('ermId', ermId);
            // localStorage.setItem('ermTitle', ermTitle);
            // console.log(ermId, ermTitle);

            this.$router.push('/reqbrd');
        },
        getAttachments: function( refRecordId) {
            let data = {
                refEntity: this.entity,
                refRecordId: refRecordId,
                vhextAccountId: this.vhextAccountId,
                projectId: this.projectId
            } 
            this.attachmentList = {};
            return axios.post(this.$store.getters.getAPIBasePath + '/attachment/list', data)
                .then((response) => {
                    let result = response.data;
                    this.attachmentList = result.attachmentList;
                })
        },
        focusFirstError(objName) {
            var invalidFields = Object.keys(this.$v[objName].$params)
                .filter(fieldName => this.$v[objName][fieldName].$invalid);
                console.log(this.$refs)

            if (invalidFields) {
                console.log(this.$refs, invalidFields)
                this.$refs[invalidFields[0]].focus();
            }
        },

    }
}
</script>