<template>
    <div>
        <div class="fixed-inner-header">
            <div class="container-fluid">
                <div class="row inner-header pt-2">
                    <div class="col-12">
                        <div class="row">
                            <div class="col-lg-8 col-sm-6">
                                <h4 class="pl-2 mb-0 fs-16">Employee List</h4>
                            </div>
                            <div class="col-lg-4 col-sm-6">
                                <nav class="breadcrumb-container d-sm-block d-lg-inline-block float-right" aria-label="breadcrumb">
                                    <ol class="breadcrumb pt-0 mb-0">
                                        <!-- top to bottom -->
                                        <li class="breadcrumb-item">
                                            <a href="javascript:" @click="getPrevUrl()"><i class="fa fa-reply pr-2 action-link" aria-hidden="true"></i>Back</a>
                                        </li>
                                        <li class="breadcrumb-item">
                                            <a href="#" @click.prevent="print" title="Print"><i class="fa fa-print action-link"></i></a>
                                        </li>
                                        <li class="breadcrumb-item" title="Download">
                                            <a href="#" @click.prevent="print"><i class="fa fa-download action-link"></i></a>
                                        </li>
                                        <li v-if="projId" class="breadcrumb-item">
                                            <h4 class="pl-2 mb-0 fs-14 text-right">#{{this.projId}} - {{this.projectName}}</h4>
                                        </li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                        <div class="separator mb-2"></div>
                    </div>
                </div>
                <div id="printMe" class="row">
                    <div class="col-12 col-lg-12 col-xl-12 report-tables">
                        <b-card no-body :class="{'mb-2' : elements.filterVisible}">
                            <div class="landing-menu-switch pointer" v-b-toggle.accordion-ett>
                                <label v-if="elements.filterVisible" class="my-2 py-1">Hide</label>
                                <label v-else class="my-2 py-1">Filter</label>
                            </div>
                            <b-collapse id="accordion-ett" v-model="elements.filterVisible" accordion="my-accordion"  role="tabpanel">
                                <b-card-body class="p-2">
                                    <div class="comments-titel">
                                        <label class="d-block mt-0 mb-0"><i class="fa fa-filter pr-2" aria-hidden="true"></i>Filter</label>
                                    </div>
                                    <div class="comments-cox mt-2">
                                        <div class="row m-0">
<!--                                             <div class="col-4">
                                                <div class="form-group">
                                                    <label>Attendance From</label>
                                                    <input type="date" class="form-control" v-model="fromDate" id="dateWD" >
                                                </div>
                                            </div>
                                            <div class="col-4">
                                                <div class="form-group">
                                                    <label>Attendance Till</label>
                                                    <input type="date" class="form-control" v-model="tillDate">
                                                </div>
                                            </div> -->
                                            <div class="col-4">
                                                <div class="form-group">
                                                    <label for="companyId">Company</label>
                                                    <select v-model="companyId" class="form-control" >
                                                        <option value="">All Companies</option>
                                                        <option v-for="(info, index) in corpCompanies" :value="info.id" :key="index">{{info.value}}</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div class="col-4">
                                                <div class="form-group">
                                                    <label for="employeeStatus">Employee Status</label>
                                                    <select v-model="statusId" class="form-control" >
                                                        <option value="">All Status</option>
                                                        <option v-for="(info, index) in employeeStatuses" :value="info.id" :key="index">{{info.value}}</option>
                                                    </select>
                                                </div>
                                            </div>                                            
                                        </div>
                                        <div class="row m-0">
                                            <div class="col-12">
<!--                                                 <button type="submit" @click="getEmployees" class="sbtn btn btn-primary mb-3 mr-2 pt-1 pb-1 pl-3 pr-3 mt-1"><i class="fa fa-filter pr-2" aria-hidden="true"></i>Filter</button> -->
                                                <button type="submit" @click="closeFilter" class="sbtn btn btn-primary mb-3 mr-2 pt-1 pb-1 pl-3 pr-3 mt-1 task-close-btn"><i class="fa fa-times pr-2"></i>Close </button>
                                                <a href="javascript:" title="Reset Filter" @click="clearData"><i class="fa fa-refresh" aria-hidden="true"></i></a>
                                            </div>
                                        </div>
                                    </div>
                                </b-card-body>
                            </b-collapse>
                        </b-card>
                        <div v-if="!elements.filterVisible" class="card mb-2">
                            <div class="card-body p-3">
                                <div class="row">
                                    <div class="col-12 col-md-12">
                                        <div class="panel panel-default">
                                            <div class="panel-body">
                                                <div class="dl-horizontal">
                                                    <div class="form-row">
                                                        <div class="col">
                                                            <div class="row">
                                                                <div class="col-12 col-md-5 pr-0">
                                                                    <label class="mb-0 p-1 pt-2 pb-2 d-block title-block">Company</label>
                                                                </div>
                                                                <div class="col-12 col-md-7 pl-0">
                                                                    <label class="p-2 mb-0 d-block header-details">{{ company }}</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div v-if="statusId" class="col">
                                                            <div class="row">
                                                                <div class="col-12 col-md-5 pr-0">
                                                                    <label class="mb-0 p-1 pt-2 pb-2 d-block title-block">Status</label>
                                                                </div>
                                                                <div class="col-12 col-md-7 pl-0">
                                                                    <label class="p-2 mb-0 d-block header-details">{{ status }}</label>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="col">
                                                            <div class="row">
                                                                <div class="col-12 col-md-5 pr-0">
                                                                    <label class="mb-0 p-1 pt-2 pb-2 d-block title-block">Report By</label>
                                                                </div>
                                                                <div class="col-12 col-md-7 pl-0">
                                                                    <label class="p-2 mb-0 d-block header-details">{{ preparedBy }}</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col">
                                                            <div class="row">
                                                                <div class="col-12 col-md-5 pr-0">
                                                                    <label class="mb-0 p-1 pt-2 pb-2 d-block title-block">Report Date</label>
                                                                </div>
                                                                <div class="col-12 col-md-7 pl-0">
                                                                    <label class="p-2 mb-0 d-block header-details">{{ preparedDate | formatDate }}</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="card mb-2">
                            <div class="card mb-2">
                                <div class="card-body p-3">
                                    <p class="contact-title font-weight-bold mb-1"><span>Employee List [{{employees.length}}]</span></p>
                                    <div class="row">
                                        <div class="col-12 col-md-12">
                                            <div class="data-table-rows  data-tables-hide-filter">

                                                <b-table show-empty small stacked="md" sort-icon-left :busy="isBusy" :items="employees" :fields="employeeFields" responsive="sm" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection">
                                                    <template #cell(index)="data">
                                                        {{ data.index + 1 }}
                                                    </template>
                                                    <template #cell(name)="data">
                                                        <div class="row no-gutters align-items-center">
                                                            <div class="col-auto">
                                                                <b-avatar v-if="data.item.photo" class="mr-2" :src="data.item.photo"></b-avatar>
                                                                <b-avatar v-else class="mr-2"></b-avatar>
                                                            </div>
                                                            <div class="col">
                                                                <div class="mr-auto">{{ data.value }}</div>
                                                                <div class="mr-auto text-muted">{{ data.item.designation }}</div>
                                                            </div>
                                                        </div>
                                                    </template>   
                                                    <template #cell(department)="data">
                                                        <div>
                                                            {{ data.item.cmpShortName }}
                                                        </div>
                                                        <div class="text-muted">
                                                            {{ data.item.department }}
                                                        </div>
                                                    </template>   
                                                    <template #cell(experienceYears)="data">
                                                        <div class="row no-gutters align-items-center">
                                                            <div class="col">
                                                                <!-- <div class="mr-auto">{{ data.value }}</div> -->
                                                                <span v-if="data.item.experienceYears">{{ data.item.experienceYears }} year
                                                                </span>
                                                                <span v-if="data.item.experienceMonths">{{ data.item.experienceMonths }} months
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </template>                                                                                                                                                         
                                                    <template v-slot:empty="erm">
                                                        <div class="text-center">No Record Found</div>
                                                    </template>
                                                </b-table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import iziToast from 'izitoast';
import moment from 'moment';
export default {
    name: 'app',

    data() {
        return {
            elements: {
                filterVisible: true,
            },
            projId: localStorage.getItem("projId"),
            projectName: localStorage.getItem("projectName"),
            vhextAccountId: localStorage.getItem("vhextAccountId"),
            parentVhextAccountId: localStorage.getItem("parentVhextAccountId"),
            userId: localStorage.getItem("userId"),
            preparedBy: this.$store.getters.currentUser.name,
            preparedDate: this.getDateValue(new Date()),
            companyId: localStorage.getItem("vhextAccountId"),

            fromDate: this.getDateValue(new Date()),
            tillDate: this.getDateValue(new Date()),
            startDate: this.getDateValue(new Date()),
            endDate: this.getDateValue(new Date()),

            statusId: '',

            isBusy: false,
            employeeStatuses: [],
            corpCompanies: [],

            humanResources: [],

            employeeFields: [
                // { key: 'index', label: 'SN' },
                
                { key: 'name', label: 'Name', sortable: true, sortDirection: 'desc' },
                { key: 'employeeCode', label: 'EID', sortable: true, sortDirection: 'desc' },
                // { key: 'designation', label: 'Designation', sortable: true, sortDirection: 'desc' },
                { key: 'department', label: 'Company / Department', sortable: true, sortDirection: 'desc' },
                { key: 'qualification', label: 'Qualification' },
                { key: 'experienceYears', label: 'Experience' },
                { key: 'emailId', label: 'Email id' },
                { key: 'mobile', label: 'Mobile' ,sortable: true},
                { key: 'joiningDate', label: 'Joining Date' ,formatter: "formatDate",sortable: true, sortDirection: 'desc' },
                { key: 'reportingTo', label: 'Reporting To' ,sortable: true },
                { key: 'employeeStatus', label: 'Status', sortable: true, sortDirection: 'desc' },
            ],

            totalRows: 1,
            currentPage: 1,
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',
            filter: null,
            filterOn: [],
        }
    },
    computed: {
        employees() {
            let filteredItems = this.humanResources;
            if (this.companyId) {
                filteredItems = filteredItems.filter(data => data.vhextAccountId == this.companyId)
            }
            if(this.statusId){
                return filteredItems.filter(item => item.empId && item.employeeStatusId == this.statusId);
            }else{
                return filteredItems.filter(item => item.empId);
            }
        },
        company() {
            if (this.corpCompanies.length>0) {
                return this.companyId ? this.corpCompanies.find(data => data.id == this.companyId).value : 'All Companies';
            }
        },
        status() {
            if (this.employeeStatuses.length>0) {
                return this.statusId ? this.employeeStatuses.find(data => data.id == this.statusId).value : '';
            }
        },

    },

    mounted() {
        localStorage.removeItem("pageRedirectURL");
        this.lookupData();
        this.getEmployees();
    },
    methods: {
        getEmployees: function() {
            let data = {
                parentVhextAccountId: this.parentVhextAccountId,
                statusId: this.statusId,
            }
            this.startDate = this.fromDate;
            this.endDate = this.tillDate;
            this.isBusy = true;
            axios.post(this.$store.getters.getAPIBasePath + '/corporate/corpgrpemployees', data)
                .then((response) => {
                    this.isBusy = false;
                    let result = response.data;
                    this.humanResources = result.corpGroupEmployeeList;
                    this.totalRows = this.employees.length;

                })
                .catch(error => {
                    this.isBusy = false;
                    iziToast.error({
                       title: 'Error',
                       message: 'Error',
                       position: 'topRight'
                   });
                })
        },
        lookupData: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
                parentVhextAccountId: this.parentVhextAccountId,
            }
            axios.post(this.$store.getters.getAPIBasePath + '/corporate/employeeattendance/lookup/all', data)
                .then((response) => {
                    let result = response.data;
                    this.corpCompanies = result.corpGroupCompanyLookup;
                    this.employeeStatuses = result.employeeStatusLookup;
                })
        },
        closeFilter: function() {    
            this.elements.filterVisible = false;
            this.fromDate = this.getDateValue(this.startDate);
            this.tillDate = this.getDateValue(this.endDate);
        },
        clearData: function() {    
            this.startDate = this.getDateValue(new Date());
            this.endDate = this.getDateValue(new Date());
            this.fromDate = this.getDateValue(new Date());
            this.tillDate = this.getDateValue(new Date());
            this.statusId = '';
            this.getEmployees();
        },
        print: function() {
            this.$htmlToPaper('printMe');
        },

    }
}
</script>