<template>
    <div>
        <div class="container-fluid landing-mt new-landing">
            <div class="landing-menu-switch pointer" v-b-toggle.accordion-ett>
                <i class="fa fa-angle-double-down" aria-hidden="true"></i>
            </div>
            <b-collapse id="accordion-ett" v-model="elements.menuVisible" accordion="my-accordion"  role="tabpanel">
                <div class="row landing-btns">
                    <div v-for="moduleItem in landingCenterModules" class="col new-landing-box">
                        <a href="javascript:" @click="setActiveTab(moduleItem)" :class="{ 'landing-active' : activeTab == moduleItem.id }" class="card">
                            <div class="card-body text-center pt-2 pl-1 pr-1 pb-2">
                                <p class="card-text font-weight-semibold d-flex justify-content-center align-items-center mb-0">
                                    <i class="fa mb-0" :class="moduleItem.icon ? moduleItem.icon : 'fa-clock-o'" aria-hidden="true"></i>
                                    <span class="fs-12 pl-1">{{ moduleItem.displayName }}</span>
                                </p>
                            </div>
                        </a>
                    </div>
                    <!-- <div class="col new-landing-box">
                        <a href="javascript:" @click="setActiveTab('showHumanResources');" :class="{ 'landing-active' : activeTab == 'showHumanResources' }" class="card">
                            <div class="card-body text-center pt-2 pl-1 pr-1 pb-2">
                                <p class="card-text font-weight-semibold mb-0">
                                    <i class="fa fa-clock-o mb-0 action-link"></i>
                                    <span class="TopHorizontalMenu">Human Resource [{{this.humanResourceCount}}]</span>
                                </p>
                            </div>
                        </a>
                    </div> -->
                    <!-- <div class="col new-landing-box">
                        <a href="javascript:" @click="setActiveTab('showProjects');" :class="{ 'landing-active' : activeTab == 'showProjects' }" class="card">
                            <div class="card-body text-center pt-2 pl-1 pr-1 pb-2">
                                <p class="card-text font-weight-semibold mb-0">
                                    <i class="fa fa-id-card mb-0 action-link"></i>
                                    <span class="TopHorizontalMenu">Projects [{{this.projectCount}}]</span>
                                </p>
                            </div>
                        </a>
                    </div> -->
                    <!-- <div class="col new-landing-box">
                        <a href="javascript:" @click="setActiveTab('showDocs');" :class="{ 'landing-active' : activeTab == 'showDocs' }" class="card">
                            <div class="card-body text-center pt-2 pl-1 pr-1 pb-2">
                                <p class="card-text font-weight-semibold mb-0">
                                    <i class="fa fa-user-circle-o mb-0 action-link"></i>
                                    <span class="TopHorizontalMenu">Docs [{{this.docsCount}}]</span>
                                </p>
                            </div>
                        </a>
                    </div> -->
                    <!-- <div class="col new-landing-box">
                        <a href="javascript:" @click="setActiveTab('showReports');" :class="{ 'landing-active' : activeTab == 'showReports' }" class="card">
                            <div class="card-body text-center pt-2 pl-1 pr-1 pb-2">
                                <p class="card-text font-weight-semibold mb-0">
                                    <i class="fa fa-address-card-o mb-0 action-link"></i>
                                    <span class="TopHorizontalMenu">Reports [{{this.reportCount}}]</span>
                                </p>
                            </div>
                        </a>
                    </div> -->
                </div>
            </b-collapse>
            <div v-if="landingCenterModules.length>0" class="row mt-2">  
                <div class="col-12">
                    <keep-alive>
                        <component :is="activeComponent"></component>
                    </keep-alive>
                </div>             
                <!-- <div v-bind:class="[ (landingTabs.showDashboard === true) ? 'showDiv' : 'hideDiv']" class="col-12">
                    <HRMDashboard />
                </div> -->
                <!-- <div v-bind:class="[ (landingTabs.showHumanResources === true) ? 'showDiv' : 'hideDiv']" class="col-12">
                    <HumanResources ref="humanResources" @updateHRCount="updateHRCount" />
                </div> -->
                <!-- <div v-bind:class="[ (landingTabs.showProjects === true) ? 'showDiv' : 'hideDiv']" class="col-12">
                    <Project @updateProjectCount="updateProjectCount" />
                </div> -->
                <!-- <div v-bind:class="[ (landingTabs.showDocs === true) ? 'showDiv' : 'hideDiv']" class="col-12"> -->
                    <!-- <DocumentFolder /> -->
                <!-- </div> -->
                <!-- <div v-bind:class="[ (landingTabs.showContacts === true) ? 'showDiv' : 'hideDiv']" class="col-12">
                    <Contact @updateContactCount="updateContactCount" />
                </div> -->
                <!-- <div v-bind:class="[ (landingTabs.showReports === true) ? 'showDiv' : 'hideDiv']" class="col-12">
                    Reports
                </div> -->
            </div>
        </div>
    </div>
</template>
<script>
import CMRDashboard from '@/views/dashboard/CMRDashboard.vue';
import CorpGrpDashboard from '@/views/dashboard/CorpGrpDashboard.vue';
import HRMDashboard from '@/views/dashboard/HRMDashboard.vue';
import FNMDashboard from '@/views/dashboard/FNMDashboard.vue';
import MyDashboard from '@/views/dashboard/MyDashboard.vue';
import PRMDashboard from '@/views/dashboard/PRMDashboard.vue';
import TMBDashboard from '@/views/dashboard/TMBDashboard.vue';
import EMPDashboard from '@/views/dashboard/EMPDashboard.vue';
import CLIDashboard from '@/views/dashboard/CLIDashboard.vue';

// import HumanResources from '@/components/landingcomponents/EmpHumanResourceComp.vue';
import EmployeeTaskTimeBoard from '@/views/task/EmployeeTaskTimeBoard.vue';
import ClientProjects from '@/components/landingcomponents/ProjectComp.vue';
import LeaveTimeBoard from '@/views/resource/LeaveTimeBoard.vue';
import ProjectManage from '@/components/landingcomponents/ProjectComp.vue';
import Projects from '@/components/landingcomponents/ProjectComp.vue';
import Reports from '@/views/landing/ReportComponent.vue';
import SubContract from '@/components/landingcomponents/ContractComp.vue';
import SubContractManage from '@/components/landingcomponents/ContractComp.vue';
import { EventBus } from '@/main';
export default {
    name: 'app',
    components: {
        CMRDashboard,
        CorpGrpDashboard,
        HRMDashboard,
        FNMDashboard,
        MyDashboard,
        PRMDashboard,
        TMBDashboard,
        EMPDashboard,
        CLIDashboard,

        ClientProjects,
        EmployeeTaskTimeBoard,
        LeaveTimeBoard,
        ProjectManage,
        Projects,
        Reports,
        SubContract,
        SubContractManage,
    },
    data() {
        return {
            vhextAccountId: localStorage.getItem("vhextAccountId"),
            userId: localStorage.getItem("userId"),
            userTypeCode: localStorage.getItem("userTypeCode"),

            projectCount: '0',
            // humanResourceCount: '0',
            userCount: '0',
            reportCount: 0,
            contactCount: '0',
            docsCount: 0,
            
            activeTab: '',
            activeComponent: '',
            landingCenterModules: [],

            elements: {
                menuVisible: true,
            },

            landingTabs: {
                showProjects: false,
                showDashboard: true,
                // showHumanResources: false,
                showReports: false,
                showDocs: false,
            },

            clientProps: {
                addExistingClient: false,
            },
        }
    },
    created() {
        EventBus.$on('onLanCenterMenuReload', () => {
            this.onLanCenterMenuReload()
        });
    },
    mounted() {
        this.loadMenu();
    },
    beforeDestroy() {
        EventBus.$off('onLanCenterMenuReload');
    },
    methods: {
        loadMenu() {
            let data = {
                vhextAccountId: this.vhextAccountId,
                userId: this.userId,
            }
            axios.post(this.$store.getters.getAPIBasePath + '/menu/landingcentermenu', data)
                .then((response) => {
                    let result = response.data;
                    this.landingCenterModules = result.landingCenterMenuLookup;
                    if (this.landingCenterModules.length>0) {
                        let moduleData = this.landingCenterModules[0];
                        this.setActiveTab(moduleData);
                    }
                })
        },
        onLanCenterMenuReload: function() {
            this.loadMenu();
        },
        setActiveTab: function (moduleData) {
            // this.showComponent = true;
            this.activeComponent = moduleData.webUrl;
            // this.activeComponent = this.activeComponent.replaceAll('&', '');
            // if(this.activeComponent == 'SubContract') {
            //     this.activeComponent = '';
            //     this.$router.push('/subctr')
            // }
            console.log(this.activeComponent)
            this.activeTab = moduleData.id;
        },
        updateProjectCount: function(value) {
            this.projectCount = value;
        },
        // updateClientCount: function(value) {
        //     this.reportCount = value;
        // },
        // updateContactCount:function(value){
        //     this.contactCount = value;
        // },
        // updateHRCount: function(value) {
        //     this.humanResourceCount = value;
        // },
    }
}
</script>