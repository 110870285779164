<template>
    <div>
        <b-overlay :show="showDetOverlay" spinner-variant="primary" opacity="0.4" rounded="sm">
            <div class="card-body p-3">
                <div class="row input-group-label">
                    <div class="col-12">
                        <b-input-group :prepend="statusUpdate.refId ? statusUpdate.refId.toString() : ''" class="mb-3">
                            <b-form-input aria-label="First name" disabled v-model="statusUpdate.refRecordTitle"></b-form-input>
                        </b-input-group>
                    </div>
                    <div v-if="hasReferredTo" class="col-md-12">
                        <b-input-group prepend="Forward To" class="mb-3">
                            <b-form-input v-if="!elements.editMode" aria-label="First name" disabled v-model="statusUpdate.referredTo"></b-form-input>
                            <select v-if="elements.editMode" v-model="statusUpdate.referredToId" class="form-control">
                                <option value="">Select</option>
                                <option v-for="(info, index) in referredToLookup" :value="info.id">{{info.value}}</option>
                            </select>
                        </b-input-group>
                    </div>
                    <div class="col-6">
                        <b-input-group prepend="Status" class="mb-3">
                            <b-form-input v-if="!elements.editMode" aria-label="First name" disabled v-model="statusUpdate.status"></b-form-input>
                            <select v-if="elements.editMode" v-model="statusUpdate.statusId" class="form-control">
                                <option value="">Select</option>
                                <option v-for="(info, index) in statuses" :value="info.id">{{info.value}}</option>
                            </select>
                            <label v-if="$v.statusUpdate.statusId.$error && !$v.statusUpdate.statusId.required" class="text-danger">Please Select Status</label>
                        </b-input-group>
                    </div>
                    <div class="col-md-2 col-sm-2 pl-0 col-2 mr-0 ml-auto">
                        <div class="more-btns text-center">
                            <button v-if="!elements.editMode" href="javascript:" @click="elements.editMode = true;elements.visible = true;" v-b-toggle.statusTracker class="sbtn btn btn-xs action-link p-1" title="Edit">
                                <i class="fa fa-pencil" aria-hidden="true"></i>
                            </button>
                            <div v-if="elements.editMode">
                                <button type="submit" @click="saveStatusChange" title="Save" class="sbtn btn btn-xs action-link p-1"><i class="fa fa-check" aria-hidden="true"></i></button>
                                <button type="submit" @click="cancelEditMode" title="Cancel" class="sbtn btn btn-xs action-link p-1"><i class="fa fa-times" aria-hidden="true"></i></button>
                            </div>
                        </div>
                    </div>
                </div>
                <b-card no-body class="">
                    <b-card-header header-tag="header" class="p-0 accordion-header" role="tab">
                        <label class="d-block p-2 accordion-title" block href="#" v-b-toggle.statusTracker>
                            <span>Remarks</span>
                        </label>
                    </b-card-header>
                    <b-collapse id="statusTracker" v-model="elements.visible" role="tabpanel">
                        <b-card-body class="p-2">
                            <div class="row">
                                <div class="col-12 col-sm-12">
                                    <div v-if="elements.editMode" class="form-group">
                                        <!-- <editor api-key="lbcn6twu8tv4xan8fn1tgqiqy9hhvsse01s48crluvfuafzp" :toolbar="toolbar" :init="initObj" :disabled="!elements.editMode" v-model="statusUpdate.remarks"></editor> -->
                                        <VmxEditor v-model="statusUpdate.remarks" :disabled="!elements.editMode"></VmxEditor>
                                    </div>
                                    <div v-else class="form-group">
                                        <p><span v-html="statusUpdate.remarks"></span></p>
                                    </div>
                                    <button v-if="elements.editMode" type="submit" @click="saveStatusChange" class="btn btn-primary mb-0 mr-2"><i class="fa fa-floppy-o pr-2"></i>Save</button>
                                    <button v-if="elements.editMode" type="submit" @click="cancelEditMode" class="btn btn-primary mb-0 pt-1 pb-1 pl-3 pr-3"><i class="fa fa-times pr-2"></i>Cancel</button>
                                </div>
                            </div>
                        </b-card-body>
                    </b-collapse>
                </b-card>
                <div class="row">
                    <div class="col-12 mt-2">
                        <label class="d-block p-2 accordion-title" for="statusId">Status Trace</label>
                        <b-table ref="statusTrackerTable" v-model="mainTableArray" show-empty small class="preview-table" stacked="md" selectable :select-mode="selectMode" sort-icon-left :items="statusUpdates" @row-selected="onRowSelected" :fields="statusUpdateFields" responsive="sm" :current-page="currentPage" :per-page="perPage" :filter="filter" :filterIncludedFields="filterOn" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" @filtered="onFiltered">
                            <template #cell(index)="data">
                                {{ data.index + 1 }}
                            </template>                        
                            <template v-slot:cell(del)="data">        
                                <a href="javascript:" title="Delete" @click="deleteStatusUpdate(data.item.id)"  v-b-modal.deleteStatusUpdate><i class="fa fa-trash pl-2"></i></a>
                            </template>
                            <template v-slot:empty="scope">
                                <div class="text-center">No record found</div>
                            </template>
                            <template v-slot:emptyfiltered="scope">
                                <div class="text-center">{{ scope.emptyFilteredText }}</div>
                            </template>
                        </b-table>
                        <b-pagination v-if="statusUpdates.length!=0" pills v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="fill" size="sm" class="my-0 pull-right"></b-pagination>
                    </div>
                </div>
            </div>
        </b-overlay>
        <b-modal id="deleteStatusUpdate" centered  size="sm" title="Delete Confirmation" @ok="onYes" >
            <p class="my-1">Are you sure to delete status updates</p>
        </b-modal>
    </div>
</template>
<script>
import iziToast from 'izitoast';
import moment from 'moment';
import { required, email, minLength, maxLength, sameAs } from "vuelidate/lib/validators";
import TypeLookup from '@/components/popup/lookup/TypeLookup.vue';
import VmxEditor from '@/components/usercontrols/VmxEditor';

export default {
    name: 'app',
    props: {
        statusUpdate: Object,
        hasReferredTo: Boolean,
        referredToLookup: Array,
    },
    computed: {
        ermId() {
            return Number(this.$route.params.ermId);
        }
    },
    components: {
        VmxEditor,
    },

    data() {
        return {
            showDetOverlay: false,
            elements: {
                editMode: false,
                visible: false,
                viewMode: false
            },
            vhextAccountId: localStorage.getItem("vhextAccountId"),
            projectId: localStorage.getItem("projectId"),
            projId: localStorage.getItem("projId"),
            projectName: localStorage.getItem("projectName"),
            entityRecordMasterId: this.$route.params.ermId,
            entity: '',
            isWorkflowAutomated: false,
            statusId: '',
            statusUpdateURL: '',

            notEnabled: true,

            transProps: {
                // Transition name
                name: 'flip-list'
            },
            mainTableArray: [],
            statuses: [],
            statusUpdates: [],
            statusUpdateFields: [
                {key:'index',label: 'SN'},
                // { key: 'id', label: 'Id', sortable: true, sortDirection: 'desc' },
                { key: 'statusBy', label: 'Status By', sortable: true, sortDirection: 'desc' },
                { key: 'status', label: 'Status', sortable: true, sortDirection: 'desc' },
                { key: 'statusDt', label: 'Date', sortable: true, formatter: "formatDate", sortDirection: 'desc' },
                // { key: 'del', label: 'Del' }

            ],
            selectMode: 'single',
            totalRows: 1,
            currentPage: 1,
            perPage: 10,
            pageOptions: [5, 10, 15, 20],
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',
            filter: null,
            filterOn: [],
            infoModal: {
                id: 'info-modal',
                title: '',
                content: ''
            },
            deleteParam: {
                id: 0
            },
            weblinks: 'google.com',
            toolbar: 'undo redo | formatselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent',
            initObj: {
                height: 150,
                branding: false,
                menubar: false,
                plugins: ['link', 'paste', 'wordcount', 'preview', 'image imagetools', 'lists'],
                paste_data_images: true,
                images_upload_url: 'postAcceptor.php',
                automatic_uploads: false,
                file_picker_types: 'image',
                relative_urls: false,
                remove_script_host: false,
            },

        }
    },
    mounted() {
        this.initialSettings();
        this.lookupData();
        this.getStatusUpdate();
        console.log(this.statusUpdate);
    },
    validations: {
        statusUpdate: {
            statusId: {
                required,

            }
        },
    },
    watch: {
        'statusUpdate.refRecordTitle'() {
            this.elements.editMode = false;
            this.elements.visible = false;
        },
    },
    methods: {
        initialSettings: function() {

            this.vhextAccountId = this.statusUpdate.vhextAccountId;
            this.projectId = this.statusUpdate.projectId;
            this.statusUpdate;
            this.entityRecordMasterId = this.statusUpdate.refRecordId;
            this.isWorkflowAutomated = this.statusUpdate.isWorkflowAutomated;
            this.statusId = this.statusUpdate.statusId;
            this.entity = this.statusUpdate.functionalEntity ? this.statusUpdate.functionalEntity : this.statusUpdate.refEntity;
            this.userId = this.statusUpdate.statusById;
            
        },

        getStatusUpdate: function() {
            if (this.statusUpdate.refRecordId) {
                let data = {
                    refEntity: this.statusUpdate.refEntity,
                    refRecordId: this.statusUpdate.refRecordId,
                }
                console.log(data);
                this.showDetOverlay = true;
                axios.post(this.$store.getters.getAPIBasePath + '/common/entitystatuspdate/list', data)
                    .then((response) => {
                        this.showDetOverlay = false;
                        let result = response.data;
                        this.statusUpdates = result.entitystatusUpdateList;
                        if (this.statusUpdates != null && this.statusUpdates.length > 0) {
                            this.$nextTick(() => {
                                this.selectBTableRowById(this.statusUpdate.id, this.mainTableArray, 'statusTrackerTable');
                            })
                            this.totalRows = this.statusUpdates.length;
                        }
                        console.log(result);
                    })
                    .catch(error => {
                        this.showDetOverlay = false;
                        iziToast.error({
                           title: 'Error',
                           message: 'Error',
                           position: 'topRight'
                       });
                    })
            }
        },
        lookupData: function() {

            if (this.entity) {
                let data = {
                    vhextAccountId: this.vhextAccountId,
                    projectId: this.projectId,
                    entity: this.entity,
                    refRecordId: this.statusUpdate.refRecordId,
                    workflowActivityId: this.statusUpdate.workflowActivityId,
                    isWorkflowAutomated: this.isWorkflowAutomated ? 1 : 0,
                    statusId: this.statusId,
                }
                console.log(this.statusUpdate);
                console.log(data);
                axios.post(this.$store.getters.getAPIBasePath + '/common/statusupdate/lookup/all', data)
                    .then((response) => {
                        let result = response.data;
                        this.statuses = result.statusLookup;
                        // this.users = result.userLookup;
                        console.log(this.statusUpdate);
                    })
                    .catch(error => {
                        iziToast.error({
                           title: 'Error',
                           message: 'Error',
                           position: 'topRight'
                       });
                    })                    
            }
        },
        /*        saveData: function() {
                    this.$v.requirementSpec.$touch();
                    if (!this.$v.requirementSpec.$invalid) {        
                        if (this.elements.editMode) {
                            this.updateRequirementSpec();
                        } else {
                            this.saveStatusChange();
                        }
                    }
                },*/

        saveStatusChange: function() {

            console.log(this.statusUpdate);
            this.$v.statusUpdate.$touch();
            if (!this.$v.statusUpdate.$invalid) {
                this.statusUpdate.isWorkflowAutomated = this.statusUpdate.isWorkflowAutomated ? this.statusUpdate.isWorkflowAutomated : false;
                if(this.statusUpdate.isWorkflowAutomated){
                    this.statusUpdateURL = '/common/workflow/statusupdate/add';
                }else{
                    this.statusUpdateURL = '/common/statusupdate/add';
                }
                this.showDetOverlay = true;
                axios.post(this.$store.getters.getAPIBasePath + this.statusUpdateURL, this.statusUpdate)
                    .then((response) => {
                        this.showDetOverlay = false;
                        let result = response.data;
                        if (result.status == "CREATED") {
                            iziToast.success({
                                title: 'Success',
                                message: result.message,
                                position: 'topRight'
                            });
                            this.$emit('updateParent');
                            this.getStatusUpdate();
                            this.clearData();

                        }
                    })
                    .catch(error => {
                        this.showDetOverlay = false;
                        iziToast.error({
                           title: 'Error',
                           message: 'Error',
                           position: 'topRight'
                       });
                    })

            }
        },
        cancelEditMode: function() {
            console.log(this.statusUpdate);
            this.clearData();
            let index = this.statusUpdates.length - 1;
            if (index >= 0) {
                console.log(index)
                this.$refs.statusTrackerTable.selectRow(index);
            }
        },
        deleteStatusUpdate: function(id) {
            this.deleteParam.id = id;
        },
        onYes: function() {
            axios.delete(this.$store.getters.getAPIBasePath + '/common/statusupdate/delete/' + this.deleteParam.id)
                .then(response => {
                    var result = response.data;
                    if (result.status == "OK") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.getStatusUpdate();
                    }
                })
                .catch(error => {
                    console.log(error);
                });
        },
        clearData: function() {
            this.elements.editMode = false;
            this.elements.visible = false;
            this.elements.viewMode = false;

            this.statusUpdate.id = '';
            this.statusUpdate.statusUpdateId = '';
            // this.statusUpdate.statusId = '';
            // this.statusUpdate.remarks = '';
            this.$v.statusUpdate.$reset();
        },
        showEdit: function(data) {
            this.elements.editMode = true;
            this.$refs.statusTrackerTable.clearSelected();
            /*            this.elements.visible = true;
                        this.elements.viewMode = true;
            */
            this.refRecord.id = data.id;
            this.refRecord.refRecordId = data.refRecordId;
            this.refRecord.statusId = data.statusId;
            this.refRecord.status = data.status;
            this.refRecord.remarks = data.remarks;
            this.refRecord.statusUpdatedById = data.statusUpdatedById;
            this.refRecord.statusDate = data.statusDate;
            this.refRecord.referredToId = data.referredToId;
            this.refRecord.referredTo = data.referredTo;
        },

        onRowSelected: function(items) {
            if (items != null && items.length > 0) {
                let data = items[0];
                console.log(data.id);

                //this.viewStatusUpdate(data.id);
                // this.elements.visible = true;
                this.elements.editMode = false;
                this.statusUpdate.id = data.id;
                this.statusUpdate.statusUpdateId = data.statusUpdateId;
                this.statusUpdate.referredToId = data.referredToId;
                this.statusUpdate.referredTo = data.referredTo;
                this.statusUpdate.statusId = data.statusId;
                this.statusUpdate.status = data.status;
                this.statusUpdate.remarks = data.remarks;
            };
        },
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },
        onTitleChange() {
            console.log("test");
            this.getStatusUpdate();
        },


    }
}
</script>