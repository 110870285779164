<template>
	<div>
        <div class="col-lg-12 col-xl-12 p-0">
            <div class="card-body p-0">
                <div class="row view-filter">
                    <div class="col-sm-12">
                        <div class="float-right">
                            <div class="dataTables_length">
                                <b-form-group label="Per page" label-cols-sm="6" label-align-sm="right" label-size="sm" label-for="perPageSelect" class="mb-0">
                                    <b-form-select v-model="perPage" id="perPageSelect" size="sm" :options="pageOptions"></b-form-select>
                                </b-form-group>
                            </div>
                        </div>
                        <div class="float-left">
                            <div class="dataTables_filter">
                                <label>
                                    <input type="search" v-model="filter" class="form-control form-control-sm" placeholder="Search...">
                                </label>
                            </div>
                        </div>
                        <div class="clearfix"></div>
                    </div>
                </div>
                <b-table show-empty small stacked="md" sort-icon-left :items="timeSheets" :fields="timeSheetFields" responsive="sm" :current-page="currentPage" :per-page="perPage" :filter="filter" :filterIncludedFields="filterOn" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" @filtered="onFiltered">
                    <template v-slot:cell(actions)="data">
                        <a v-if="!elements.editTimeSheet" href="javascript:" title="Edit" @click="showEditTimeSheet(data.item)"><i class="fa fa-pencil-square-o fa-custom fa-lg  pl-2"></i></a>
                        <a v-if="!elements.editTimeSheet" href="javascript:" title="Delete" @click="deleteTimeSheet(data.item.id)" data-toggle="modal" data-target="#deleteTimeSheet"><i class="fa fa-trash pl-2"></i></a>
                        <div v-if="data.item.id == timeSheetEditId">
                            <a v-if="elements.editTimeSheet" href="javascript:" title="Save" @click="saveTimeSheetData"><i class="fa fa-check text-success pl-2"></i></a>
                            <a v-if="elements.editTimeSheet" href="javascript:" title="Cancel" @click="cancelTimeSheetEdit"><i class="fa fa-times text-danger pl-2"></i></a>
                        </div>
                    </template>
                    <template v-if="elements.editTimeSheet" v-slot:cell(title)="data">
                        <div v-if="data.item.id == timeSheetEditId">
                            <input type="text" class="form-control" v-model="timeSheet.title" placeholder="Title">
                        </div>
                        <div v-if="data.item.id !== timeSheetEditId"> {{ data.item.title }}</div>
                    </template>
                    <template v-if="elements.editTimeSheet" v-slot:cell(taskTitle)="data">
                        <div v-if="data.item.id == timeSheetEditId">
                            <select v-model="timeSheet.taskId" class="form-control">
                                <option value="">Select</option>
                                <option v-for="(info, index) in tsTasks" :value="info.id">{{info.value}}</option>
                            </select>
                        </div>
                        <div v-if="data.item.id !== timeSheetEditId"> {{ data.item.taskTitle }}</div>
                    </template>
                    <template v-if="elements.editTimeSheet" v-slot:cell(activity)="data">
                        <div v-if="data.item.id == timeSheetEditId">
                            <select v-model="timeSheet.activityId" class="form-control">
                                <option value="">Select</option>
                                <option v-for="(info, index) in tsActivities" :value="info.id">{{info.value}}</option>
                            </select>
                        </div>
                        <div v-if="data.item.id !== timeSheetEditId"> {{ data.item.activity }}</div>
                    </template>
                    <template v-if="elements.editTimeSheet" v-slot:cell(startDate)="data">
                        <div class="row" v-if="data.item.id == timeSheetEditId">
                            <b-col class="col-6 pl-0 pr-0">
                                <b-form-input type="date" v-model="timeSheet.startDateValue" :value="timeSheet.startDateValue" id="estimatedEndDt1"></b-form-input>
                            </b-col>
                            <b-col class="col-6 pl-1">
                                <b-form-input type="time" v-model="timeSheet.startTimeValue" :value="timeSheet.startTimeValue"></b-form-input>
                            </b-col>
                            <!-- <input v-model="timeSheet.startDate" type="datetime-local" class="form-control" placeholder="Start Date"> -->
                        </div>
                        <div v-if="data.item.id !== timeSheetEditId"> {{ data.item.startDate | formatDateTimeAmPm }}</div>
                    </template>
                    <template v-if="elements.editTimeSheet" v-slot:cell(endDate)="data">
                        <div class="row" v-if="data.item.id == timeSheetEditId">
                            <b-col class="col-6 pl-0 pr-0">
                                <b-form-input type="date" v-model="timeSheet.endDateValue" :value="timeSheet.endDateValue"></b-form-input>
                            </b-col>
                            <b-col class="col-6 pl-1">
                                <b-form-input type="time" v-model="timeSheet.endTimeValue" :value="timeSheet.endTimeValue"></b-form-input>
                            </b-col>
                            <!-- <input v-model="timeSheet.endDate" type="datetime-local" class="form-control" placeholder="Due Date">
-->
                        </div>
                        <div v-if="data.item.id !== timeSheetEditId"> {{ data.item.endDate | formatDateTimeAmPm }}</div>
                    </template>
                    <template v-if="elements.editTimeSheet" v-slot:cell(workHour)="data">
                        <div v-if="data.item.id == timeSheetEditId">
                            <b-input-group size="sm">
                                <EffortInput ref="effortControl"  />
                            </b-input-group>
                        </div>
                        <div v-if="data.item.id !== timeSheetEditId"> {{ workDuration(data.value,data.item.workMinute,data.item.workDay) }}</div>
                    </template>
                    <template v-if="elements.editTimeSheet" v-slot:cell(status)="data">
                        <div v-if="data.item.id == timeSheetEditId">
                            <select v-model="timeSheet.statusId" class="form-control">
                                <option value="">Select</option>
                                <option v-for="(info, index) in taskStatus" :value="info.id">{{info.value}}</option>
                            </select>
                        </div>
                        <div v-if="data.item.id !== timeSheetEditId"> {{ data.item.status }}</div>
                    </template>
                    <template v-if="!elements.newTimeSheet && !viewTimeSheet" v-slot:custom-foot="data">
                        <tr>
                            <td class="text-center bg-info text-white pointer" @click="addTimeSheet" :colspan="9">
                                <span class="">ADD TIME SHEET<i class="fa fa-plus ml-3" aria-hidden="true"></i></span>
                            </td>
                        </tr>
                    </template>
                    <template v-slot:empty="scope">
                        <div class="text-center">No Time Sheets found</div>
                    </template>
                    <template v-slot:emptyfiltered="scope">
                        <div class="text-center">{{ scope.emptyFilteredText }}</div>
                    </template>
                </b-table>
                <b-pagination v-if="timeSheets.length!=0" pills v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="fill" size="sm" class="mt-0 mb-3 pull-right"></b-pagination>
            </div>
        </div>
        <Dialog id="deleteTimeSheet" :onYes="onYesTimeSheet" :returnParams="deleteParam" title="Delete Confirmation" message="Are you sure to delete Time Sheet" />
	</div>
</template>
<script>
import iziToast from 'izitoast';
import moment from 'moment';
import { required, email, minLength, maxLength, sameAs, between } from "vuelidate/lib/validators";
export default {
    name: 'app',
    props: {
    	taskId: Number,
    	viewTimeSheet: Boolean,
    },
    data() {
        return {
            elements: {
                newTimeSheet: false,
                editTimeSheet: false,
            },
            projId: localStorage.getItem("projId"),
            projectName: localStorage.getItem("projectName"),

            vhextAccountId: localStorage.getItem("vhextAccountId"),
            projectId: localStorage.getItem("projectId"),
            projectTypeId: localStorage.getItem("projectTypeId"),
            entityRecordMasterId: this.$route.params.ermId,
            userId: localStorage.getItem("userId"),

            timeSheet: {
                id: '',
                timeSheetId: '',
                vhextAccountId: '',
                projectId: '',
                entityRecordMasterId: '',
                preparedById: '',
                title: '',
                taskId: '',
                task: '',
                activityId: '',
                activity: '',
                preparedBy: '',
                startDate: '',
                startDateValue: '',
                startTimeValue: '',
                endDate: '',
                endDateValue: '',
                endTimeValue: '',
                workDay: '',
                workHour: '',
                workMinute: '',
                description: '',
                statusId: '',
                status: '',
                createdDt: '',
                lastUpdatedDt: '',
                entity: 'time_sheet'
            },
            timeSheetEditId: '',

            timeSheets: [],
            tsTasks: [],
            tsActivities: [],
            taskStatus: [],
            timeSheetFields: [
                { key: 'timeSheetId', label: 'Id', sortable: true, sortDirection: 'desc' },
                { key: 'title', label: 'Title', sortable: true, sortDirection: 'desc' },
                { key: 'activity', label: 'Activity', sortable: true, sortDirection: 'desc' },
                { key: 'startDate', label: 'Start Date', formatter: "formatDateTimeAmPm", sortable: true, sortDirection: 'desc' },
                { key: 'endDate', label: 'End Date', formatter: "formatDateTimeAmPm", sortable: true, sortDirection: 'desc' },
                { key: 'workHour', label: 'Duration', sortable: true, sortDirection: 'desc' },
                { key: 'status', label: 'Status', sortable: true, sortDirection: 'desc' },
                { key: 'preparedBy', label: 'Prepared By', sortable: true, sortDirection: 'desc' },
                { key: 'actions', label: 'Actions' }
            ],
            totalRows: 1,
            currentPage: 1,
            perPage: 10,
            pageOptions: [10, 15, 20],
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',
            filter: null,
            filterOn: [],
            deleteParam: {
                id: 0
            },
        }
    },
    mounted() {
        this.lookupData()
        this.getTimeSheets(this.taskId);
    },
    methods: {
        getTimeSheets: function(id) {
        	if (id && id != '') {
	            let data = {
	                taskId: id
	            }
	            console.log(data);
	            axios.post(this.$store.getters.getAPIBasePath + '/task/timesheetbytask/list', data)
	                .then((response) => {
	                    let result = response.data;
	                    this.timeSheets = result.timeSheetListByTask;
	                    this.totalRows = this.timeSheets.length;
	                    console.log(result);
	                })
        	}
        },
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },
        lookupData: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
                projectId: this.projectId,
                projectTypeId: this.projectTypeId,
                entityRecordMasterId: this.entityRecordMasterId,
                entity: 'time_sheet'
            }
            console.log(data);
            axios.post(this.$store.getters.getAPIBasePath + '/task/timesheet/lookup/all', data)
                .then((response) => {
                    let result = response.data;
                    this.tsTasks = result.taskLookup ? result.taskLookup : [];
                    this.tsActivities = result.activityLookup;
                    this.taskStatus = result.statusLookup;
                })
        },
        saveTimeSheetData: function() {
            console.log(this.timeSheet);
            if (this.timeSheet.startDate == '' || this.timeSheet.startDate == null) { 
            	this.timeSheet.startDate = this.combineDateAndTime(this.timeSheet.startDateValue, this.timeSheet.startTimeValue) 
            };
            if (this.timeSheet.endDate == '' || this.timeSheet.endDate == null) { 
            	this.timeSheet.endDate = this.combineDateAndTime(this.timeSheet.endDateValue, this.timeSheet.endTimeValue) 
            };
            if (this.elements.editTimeSheet && !this.elements.newTimeSheet) {
                this.updateTimeSheet();
            }
            if (this.elements.newTimeSheet) {
                this.saveTimeSheet();
            }
        },
        addTimeSheet: function() {
            this.clearTimeSheet();
            console.log(this.timeSheets);
            this.timeSheets.push({ id: '', title: this.timeSheet.title, activityId: this.timeSheet.activityId, startDate: this.timeSheet.startDate, endDate: this.timeSheet.endDate, statusId: this.timeSheet.statusId });
            this.timeSheetEditId = '';
            this.elements.editTimeSheet = true;
            this.elements.newTimeSheet = true;
        },
        saveTimeSheet: function() {
            this.timeSheet.vhextAccountId = this.vhextAccountId;
            this.timeSheet.projectId = this.projectId;
            this.timeSheet.entityRecordMasterId = this.entityRecordMasterId;
            this.timeSheet.preparedById = this.userId;
            this.timeSheet.taskId = this.taskId;
            this.setEffortData();
            console.log(this.timeSheet);
            axios.post(this.$store.getters.getAPIBasePath + '/task/timesheet/add', this.timeSheet)
                .then((response) => {
                    let result = response.data;
                    if (result.status == "CREATED") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        console.log(this.taskId);
                        this.getTimeSheets(this.taskId);
                        this.clearTimeSheet();
                        this.elements.editTimeSheet = false;
                        this.elements.newTimeSheet = false;
                    }
                })
        },
        updateTimeSheet: function() {
            this.setEffortData();
            console.log(this.timeSheet);
            axios.post(this.$store.getters.getAPIBasePath + '/task/timesheet/update', this.timeSheet)
                .then((response) => {
                    console.log(this.timeSheet);
                    let result = response.data;
                    if (result.status == 'CREATED') {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.getTimeSheets(this.taskId);
                        this.clearTimeSheet();
                        this.elements.editTimeSheet = false;
                        this.elements.newTimeSheet = false;
                    }
                })
        },
        cancelTimeSheetEdit: function() {
            this.getTimeSheets(this.taskId);
            this.clearTimeSheet();
            this.elements.editTimeSheet = false;
            this.elements.newTimeSheet = false;
        },
        clearTimeSheet: function() {
            this.timeSheet.taskId = null;
            this.timeSheet.title = null;
            this.timeSheet.statusId = null;
            this.timeSheet.activityId = null;
            this.timeSheet.activity = null;
            this.timeSheet.status = null;
            this.timeSheet.activity = null;
            this.timeSheet.startDate = null;
            this.timeSheet.startDateValue = null;
            this.timeSheet.startTimeValue = null;
            this.timeSheet.endDate = null;
            this.timeSheet.endDateValue = null;
            this.timeSheet.endTimeValue = null;
            this.timeSheet.workDay = '';
            this.timeSheet.workHour = '';
            this.timeSheet.workMinute = '';
        },
        deleteTimeSheet: function(id) {
            this.deleteParam.id = id;
        },
        onYesTimeSheet: function() {
            axios.delete(this.$store.getters.getAPIBasePath + '/task/timesheet/delete/' + this.deleteParam.id)
                .then(response => {
                    var result = response.data;
                    if (result.status == "OK") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.getTimeSheets(this.taskId);
                    }
                })
                .catch(error => {
                    console.log(error);
                });
        },
        showEditTimeSheet: function(data) {
            this.elements.editTimeSheet = true;
            this.timeSheetEditId = data.id;
            console.log(this.timeSheetEditId);
            this.timeSheet.id = data.id;
            this.timeSheet.projectId = data.projectId;
            this.timeSheet.vhextAccountId = data.vhextAccountId;
            this.timeSheet.entityRecordMasterId = data.entityRecordMasterId;
            this.timeSheet.entity = data.entity;
            this.timeSheet.timeSheetId = data.timeSheetId;
            this.timeSheet.taskId = data.taskId;
            this.timeSheet.taskTitle = data.taskTitle;
            this.timeSheet.title = data.title;
            this.timeSheet.statusId = data.statusId;
            this.timeSheet.status = data.status;
            this.timeSheet.activityId = data.activityId;
            this.timeSheet.activity = data.activity;
            this.timeSheet.preparedById = data.preparedById;
            this.timeSheet.preparedBy = data.preparedBy;
            this.timeSheet.startDate = '';
            this.timeSheet.startDateValue = data.startDate ? this.getDateValue(data.startDate) : '';
            this.timeSheet.startTimeValue = this.getTimeValue(data.startDate)
            this.timeSheet.endDate = '';
            this.timeSheet.endDateValue = data.endDate ? this.getDateValue(data.endDate) : '';
            this.timeSheet.endTimeValue = this.getTimeValue(data.endDate)
            this.timeSheet.workDay = data.workDay;
            this.timeSheet.workHour = data.workHour;
            this.timeSheet.workMinute = data.workMinute;
            this.timeSheet.description = data.description;
            this.$refs.effortControl.setEffortInputData(data.workDay, data.workHour, data.workMinute);
            console.log(this.timeSheet);
        },
        setEffortData: function() {
            this.timeSheet.workHour = this.$refs.effortControl.getHrs();
            this.timeSheet.workMinute = this.$refs.effortControl.getMins();
            this.timeSheet.workDay = this.$refs.effortControl.getDays();
        },
        workDuration(workHour, workMinute, workDay) {
            if (workDay || workHour || workMinute) {
                return `${workDay ? workDay+' days' : ''} ${workHour ? workHour+' hrs' : ''} ${workMinute ? workMinute+' mins' : ''}`;
            }
            else return '';
        },
        combineDateAndTime: function(date, time) {
            console.log(date, time);
            if (date && date !== '') {
                if (time && time !== '') {
                    return moment(date.toString() + 'T' + time.toString());
                } else
                    return moment(date.toString() + 'T' + '00:00');
            }
        },
        getEffortType: function(effortTypes, type) {
            let effortType = this.effortTypeFilter(effortTypes, type);
            if (effortType != null) {
                return effortType[0].name;
            }
        },
    }
}
</script>