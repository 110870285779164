<template>
    <div>
        <b-overlay :show="showOverlay" spinner-variant="info" opacity="0.5" rounded="sm">
            <div class="container-fluid mail-page">
                <div class="row ">
                    <div class="col-12 p-2">
                        <div class="row">
                            <div class="col-lg-4 col-sm-4">
                                <h4 class="pl-2 mb-0 pb-0 fs-16">Notification</h4>
                            </div>
                            <div class="col-lg-8 col-sm-8">
                                <nav class="breadcrumb-container d-sm-block d-lg-inline-block float-right"
                                    aria-label="breadcrumb">
                                    <ol class="breadcrumb pt-0  mb-0">
                                        <li class="breadcrumb-item">
                                            <a href="javascript:" @click="getPrevUrl()"><i
                                                    class="fa fa-reply pr-2 action-link" aria-hidden="true"></i>Back</a>
                                        </li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                        <div class="separator"></div>
                    </div>
                    <div class="col-3 pr-1 pl-0">
                        <div class="dataTables_filter mb-1">
                            <input type="search" class="form-control form-control-lg" placeholder="Search...">
                        </div>
                        <div class="mail-page-col">
                            <b-tabs v-model="tabIndex" lazy>
                                <b-tab title="Mailbox" class="overflow-auto" style="height: 500px;">
                                    <template #title>
                                        Notifications <b-badge class="mt-0"
                                            variant="primary">{{ sendNotificationLength }}</b-badge>
                                    </template>
                                    <div class="card-media-mail pointer my-1 overflow: auto">
                                        <div>
                                            <b-list-group>
                                                <b-list-group-item v-for="notificationList in userNotifications"
                                                    :key="notificationList.id"
                                                    @mouseover="hoveredNotification = notificationList.id"
                                                    @mouseleave="hoveredNotification = null"
                                                    :class="{ 'hover-brighten': hoveredNotification === notificationList.id }"
                                                    @click="showSendNotification(notificationList.id)">
                                                    <div class="row">
                                                        <div class="col-7 text-left">
                                                            <div>
                                                                <span v-if="notificationList.isRead == true" disabled class=""
                                                                :class="{ 'text-white': hoveredNotification === notificationList.id }">{{
                                                                notificationList.title }}</span>                                              
                                                                <span v-else class="font-weight-bold"
                                                                :class="{ 'text-white': hoveredNotification === notificationList.id }">{{
                                                                notificationList.title }}</span>
                                                            </div>
                                                        </div>
                                                        <div class="col-5 float-right text-right">
                                                            <span
                                                                :class="{ 'text-white': hoveredNotification === notificationList.id }">{{
                                                                formatDate(notificationList.createdDt) }}</span>
                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <div class="col-11 float-right">
                                                            <span
                                                                :class="{ 'text-white': hoveredNotification === notificationList.id }">{{
                                                                truncateText(stripHtml(notificationList.description), 35) }}</span>
                                                        </div>
                                                    </div>
                                                </b-list-group-item>
                                            </b-list-group>
                                        </div>
                                    </div>
                                </b-tab>
                            </b-tabs>
                        </div>
                    </div>
                    <div class="col-9 pl-1 mt-7 pr-1 mail-page-col">
                        <div class="" v-if="showSendNotificationDetails == 1">
                            <!-- Card for Title and Actions -->
                            <div class="card ">
                                <div class="row mt-2">
                                    <div
                                        class="col-8 float-left font-weight-bold text-primary mb-0 pt-1 pb-1 pl-4 pr-3">
                                        <span style="font-size: 1.0rem;">{{ selectedNotification.title }}</span>
                                    </div>
                                    <div
                                        class="col-5 float-right font-weight-bold text-primary mb-0 pt-1 pb-1 pl-4 pr-3">
                                    </div>
                                </div>
                            </div>

                            <div class="card mt-4 mx-auto" style="max-width: 900px; padding: 0.35rem;">
                                <div class="row  justify-content-center">
                                    <div class="row w-100 mt-4">
                                        <div class="col-7 text-left">
                                            <span class="pt-1 pb-2 pr-3 pl-4" style="font-size: 0.75rem;">Posted To:{{
                                                selectedNotification.groupType }}</span>
                                        </div>
                                        <div class="col-5 text-right">
                                            <span class="pt-1 pb-2 pr-4" style="font-size: 0.75rem;">{{
                                                formatDate(selectedNotification.createdDt) }}</span>
                                        </div>
                                    </div>
                                    <div class="row w-100 mt-3 pl-4">
                                        <div class="col-auto  pr-2">
                                            <b-avatar class="mr-0"></b-avatar>
                                        </div>
                                        <div class="col-9 text-left pl-0 pr-0">
                                            <div style="font-size: 0.75rem;">From:</div>
                                            <div style="font-size: 0.8rem; font-weight: bold;">{{
                                                selectedNotification.createdBy }}</div>
                                        </div>
                                    </div>
                                    <div class="row w-100 mt-3 mb-4 pl-4 pr-4">
                                        <div class="col-12" style="text-align: justify;">
                                            <span class="" style="font-size: 1.0rem;"
                                                v-html="truncateText(selectedNotification.description, 5000)"></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </b-overlay>
    </div>
</template>
<script>
export default {
    name: 'app',

    data() {
        return {
            showOverlay: false,
            notifications: [],
            userNotifications: [],
            draftNotifications: [],
            notificationDetails: {
                title: '',
                body: '',
                sentDate: '',
                subject: '',
                isRead: '',
            },

            currentDate: new Date().toString().substr(0, 10),
            tabIndex: 0,
            visibleMailContent: false,
            showSendNotificationDetails: null,
            boxSelectionId: null,
            sendNotificationData: null,
            hoveredNotification: null,
            selectedNotification: null,
            selectedMailId: null,
            messageId: '',
            vhextAccountId: localStorage.getItem("vhextAccountId"),
            projectId: localStorage.getItem("projectId"),
            projId: localStorage.getItem("projId"),
            projectName: localStorage.getItem("projectName"),
            userId: localStorage.getItem("userId"),
            sendNotificationLength: null,
        }
    },

    mounted() {
        this.getNotifications();
    },
    methods: {

        getNotifications: function () {
            let data = {
                vhextAccountId: this.vhextAccountId,
                userId: this.userId,
            }
            console.log(data);
            axios.post(this.$store.getters.getAPIBasePath + '/communication/usernotification/list', data)
                .then((response) => {
                    let result = response.data;
                    console.log(result);
                    this.notifications = result.notificationList;
                    console.log(this.notifications);
                    this.userNotifications = this.notifications.filter(notification => notification.statusId === 662);
                    console.log(this.userNotifications);
                    this.sendNotificationLength = this.userNotifications.
                        length;

                })
        },

        truncateText(text, length) {
            if (text.length > length) {
                return text.substring(0, length) + '...';
            }
            return text;
        },

        stripHtml(html) {
            const tempDiv = document.createElement('div');
            tempDiv.innerHTML = html;
            return tempDiv.textContent || tempDiv.innerText || '';
        },

        formatDate(dateStr) {
            const date = new Date(dateStr);
            const now = new Date();
            const yesterday = new Date();
            yesterday.setDate(now.getDate() - 1);

            const options = {
                hour: '2-digit',
                minute: '2-digit',
                hour12: true,
            };

            if (date.toDateString() === now.toDateString()) {
                return date.toLocaleTimeString([], options);
            } else if (date.toDateString() === yesterday.toDateString()) {
                return 'Yesterday';
            } else {
                return date.toLocaleDateString();
            }
        },
        showSendNotification(id) {

            this.sendNotificationData = null;
            console.log(this.sendNotificationData);
            this.showSendNotificationDetails = 1;
            this.showDraftNotificationDetails = null;
            this.selectedNotification = this.notifications.find(notification => notification.id === id);
            console.log(this.selectedNotification);
            this.updateUserNotificationRead(id);

        },
        updateUserNotificationRead: function(notificationId) {
            let data = {
               vhextAccountId: this.vhextAccountId,
               refRecordId: notificationId,
               userId: this.userId,
            }       
            console.log(data);
            axios.post(this.$store.getters.getAPIBasePath + '/communication/notification/userread/update', data)
                .then((response) => {
                    let result = response.data;
                    // if (result.status == "CREATED") {
                    //     iziToast.success({
                    //         title: 'Success',
                    //         message: result.message,
                    //         position: 'topRight'
                    //     });
                    // }
                })
        },

    }

}
</script>