<template>
    <div>
        <b-modal ref="processActivityModal" size="lg" centered :id="id" modal-class="processActivityModal" @hide="closeDialog" :title="processActivityLookupTitle" :no-close-on-backdrop="true" ok-only ok-title="Close" ok-variant="secondary">
            <b-overlay :show="showOverlay" spinner-variant="info" opacity="0.4" rounded="sm">
                <div class="p-2">
                    <b-card no-body class="mb-3">
                        <b-card-header header-tag="header" class="p-0 accordion-header" role="tab">
                            <label class="d-block p-2 accordion-title" block href="#" v-b-toggle.accordion-add>
                                <span v-if="!elements.activityEdit"><i class="fa fa-plus-circle pr-2 action-link"></i>New Process / Activity</span>
                                <span v-if="elements.activityEdit">Edit</span>
                            </label>
                        </b-card-header>
                        <b-collapse id="accordion-add" accordion="my-accordion2" v-model="elements.visible" role="tabpanel">
                            <b-card-body class="px-3 py-1">
                                <div class="row">

                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label for="process">Process</label>
                                            <div class="required-field-block" :class="{ 'is-invalid': $v.process.processId.$error }"  @change="onProcessChange(process.processId)">
                                                <select v-model="process.processId" class="form-control">
                                                    <option value="">Select</option>
                                                    <option v-for="(info, index) in filteredProcess" :value="info.id">{{info.value}}</option>
                                                </select>
                                                <div class="required-icon"></div>
                                            </div>
                                            <label v-if="$v.process.processId.$error && !$v.process.processId.required" class="text-danger">Please Select Process</label>
                                            <a :title="processLookupTitle" class="setting-icon pl-0" href="javascript:" v-b-modal.processModal><i class="fa fa-cog action-link" aria-hidden="true"></i></a>
                                        </div>
                                    </div>                                           
                                    <div class="col-1">
                                        <div class="form-group">
                                            <label for="budget">Active</label>
                                            <div class="input-group ">
                                                <b-form-checkbox class="custom-toggle" v-model="process.active" name="check-button" switch>
                                                </b-form-checkbox>
                                            </div>
                                        </div>
                                    </div>                                    
<!--                                     <div class="col-8">
                                        <div class="form-group">
                                            <label for="titleDet">Process</label>
                                            <div class="required-field-block" :class="{ 'is-invalid': $v.processActivity.title.$error }">
                                                <input type="text" class="form-control" maxlength="100" v-model="processActivity.title" id="title" :class="{ 'is-invalid': $v.processActivity.title.$error }" placeholder="Enter Title">
                                                <div class="required-icon"></div>
                                            </div>  
                                            <label v-if="$v.processActivity.title.$error && !$v.processActivity.title.minLength" class="text-danger">Title must have at least {{$v.processActivity.title.$params.minLength.min}} letters.</label>
                                            <label v-if="$v.processActivity.title.$error && !$v.processActivity.title.required" class="text-danger">Please enter Title</label>
                                            <label v-if="$v.processActivity.title.$error && !$v.processActivity.title.maxLength" class="text-danger">Title must not exceed {{$v.processActivity.title.$params.maxLength.max}} letters.</label>
                                        </div>
                                    </div> -->
                                    <div class="col-12">
                                        <div class="form-group pr-3">
                                            <div class="d-block mb-2">Activities
                                                <button type="submit" @click="openETVX()" class="btn sbtn btn-xs btn-outline-secondary px-2 float-right">ETVX</button>
                                            </div>
                                            <multiselect v-model="processActivity.activities" :options="activities" name="activity" label="value" track-by="id" select-label="" deselect-label="" :close-on-select="false" :clear-on-select="false" :multiple="true" :taggable="false"></multiselect>
                                        </div>
                                        <div class="col-12">
                                            <a :title="activityLookupTitle" class="setting-icon" href="javascript:" v-b-modal.activityModal><i class="fa fa-cog action-link" aria-hidden="true"></i></a>
                                        </div>
                                    </div>
                                </div>
                                <div class="row text-right">
                                    <div class="col-12 mb-2">
                                        <button type="submit" @click="saveData" :diabled="showOverlay" class="btn btn-primary btn-xs mb-0 mr-2 py-1 px-3"><i class="fa fa-floppy-o pr-2"></i>{{ elements.editMode ? 'Update' : 'Save'}}</button>
                                        <button type="submit" @click="clearData" class="btn btn-primary btn-xs mb-0 py-1 px-3"><i class="fa fa-times pr-2"></i>Cancel</button>
                                    </div>
                                </div>
                            </b-card-body>
                        </b-collapse>
                    </b-card>
                    <div class="row view-filter">
                        <div class="col-sm-12">
                            <div class="float-left">
                                <div class="dataTables_filter">
                                    <label>
                                        <input type="search" v-model="filter" class="form-control form-control-sm" placeholder="Search...">
                                    </label>
                                </div>
                            </div>
<!--                             <div class="float-right">
                                <div class="dataTables_filter">
                                    <b-form-group label="Per page" label-cols-sm="6" label-align-sm="right" label-size="sm" label-for="perPageSelect" class="mb-0">
                                        <b-form-select v-model="perPage" id="perPageSelect" size="sm" :options="pageOptions"></b-form-select>
                                    </b-form-group>                                    
                                </div>
                            </div> -->
                            <div class="clearfix"></div>
                        </div>
                    </div>                    
<!--                     <div class="float-right">
                        <div class="dataTables_length">

                        </div>
                    </div> -->
                    <b-table show-empty small outlined head-variant=:primary sticky-header="30vh" :busy="isBusy" sort-icon-left :items="processListAll" :fields="processActivityFields" responsive="sm" :filter="filter" :filterIncludedFields="filterOn" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection">
                        <template #cell(index)="data">
                            {{ data.index + 1 }}
                        </template>                        
                        <template v-slot:cell(active)="data">
                            <div v-if="data.item.createdById == userId">
                                <b-form-checkbox class="ml-3 float-left pointer" v-model="data.item.active" @change="assignActivationState(data.item)">
                                </b-form-checkbox>
                            </div>
                            <div v-else>
                                <span v-if="data.item.active" class="text-success ml-3" title="UnEditable" ><i class="fa fa-check"></i></span>
                            </div>
                        </template>
                        <template v-slot:cell(actions)="data">
                            <div v-if="data.item.createdById == userId">
                                <a href="javascript:" title="Edit Types" @click="showActivityEdit(data.item)"><i class="fa fa-pencil-square-o fa-custom fa-lg  pl-2"></i></a>
                                <a href="javascript:" title="Delete" @click="deleteProcessActivity(data.item.processId)" v-b-modal.deleteProcessActivity><i class="fa fa-trash pl-2"></i></a>
                            </div>
                            <div v-else>NA</div>
                        </template>
                        <template v-slot:empty="scope">
                            <div class="text-center">No Process Activities found</div>
                        </template>
                        <template v-slot:emptyfiltered="scope">
                            <div class="text-center">{{ scope.emptyFilteredText }}</div>
                        </template>
                    </b-table>
                    <span :class="'badge-secondary'" class="fs-11 badge badge-pill pointer text-center float-left">{{this.totalRows}} Items</span>
<!--                     <b-pagination v-if="processListAll.length!=0" pills v-model="currentPage" :total-rows="totalRows" :per-page="perPage" first-number last-number align="fill" size="sm" class="my-0 pull-right"></b-pagination> -->
                </div>
            </b-overlay>
        </b-modal>
        <ProcessLookup ref="processLookupModal" :entity="this.entity" :processLookupTitle="processLookupTitle" @refresh-lookup="lookupData();getProcessActivityList();" />
        <ActivityLookup ref="activityLookupModal" :projectTypeId="this.projectTypeId" :entity="entity" :activityLookupTitle="activityLookupTitle" @refresh-lookup="lookupData" />
        <ActivityETVXPopup ref="activityETVXPopupRef" @updateETVXActivities="updateETVXActivities" />
        <DeleteComponent id="deleteProcessActivity" :onYes="onYes" :returnParams="deleteParam" title="Delete Confirmation" message="Are you sure to delete Activity ?" />
    </div>
</template>
<script>
import iziToast from 'izitoast';
import { required, email, minLength, maxLength, sameAs } from "vuelidate/lib/validators";
import ProcessLookup from '@/components/popup/lookup/ProcessLookup.vue';
import ActivityLookup from '@/components/popup/lookup/ActivityLookup.vue';
import ActivityETVXPopup from '@/components/popup/action/ActivityETVXPopup.vue';
import Multiselect from 'vue-multiselect';
export default {
    props: {
        id: { type: String, default: 'processActivityModal' },
        processActivityLookupTitle: String,
        masterProjectId: Number, 
    },
    components: {
        ProcessLookup,
        ActivityLookup,
        ActivityETVXPopup,
        Multiselect,
    },
    data() {
        return {
            vhextAccountId: localStorage.getItem("vhextAccountId"),
            projectId: this.masterProjectId,
            projectTypeId: localStorage.getItem("projectTypeId"),
            userId: localStorage.getItem("userId"),
            processId: '',
            elements: {
                visible: false,
                editMode: false
            },
            showOverlay: false,
            isBusy: false,
            notEnabled: true,
            activityLookupTitle: 'Activity Settings',
            entity: 'process',

            process: {
                id: '',
                processId: '',
                title: '',
                vhextAccountId: '',
                projectId: '',
                projectTypeId: '',
                projectType: '',
                active: true,
                description: '',
                activities: [],
                createdById: localStorage.getItem("userId"),            
            },

            processActivity: {
                id: '',
                processId: '',
                title: '',
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                projectId: localStorage.getItem("projectId"),
                projectTypeId: localStorage.getItem("projectTypeId"),
                active: true,
                description: '',
                activities: [],
                createdById: localStorage.getItem("userId"),            
            },
            activities: [],
            processListAll: [],
            processActivityFields: [
                { key: 'index', label: 'SN' },
                // { key: 'processId', label: 'Id', sortable: true, sortDirection: 'desc' },
                { key: 'title', label: 'Process', sortable: true, sortDirection: 'desc' },
                { key: 'activities', label: 'Activity', sortable: true, sortDirection: 'desc' },
                { key: 'createdBy', label: 'Created By', sortable: true, sortDirection: 'desc' },
                { key: 'active', label: 'Active', sortable: true, sortDirection: 'desc' },
                { key: 'actions', label: 'Actions' }
            ],

            totalRows: 1,
            currentPage: 1,
            perPage: 5,
            sortDesc: false,
            pageOptions: [5, 10],
            sortBy: '',
            sortDirection: 'asc',
            filter: null,
            filterOn: [],
            deleteParam: {
                id: 0
            },
        }
    },
    validations: {
        process: {
            processId: {
                required,
            },            
        },

        processActivity: {
            title: {
                required,
                minLength: minLength(2),
                maxLength: maxLength(100)
            },
        }
    },
    computed: {
        filteredProcess(){
            return this.processes.filter(item => !this.processListAll.some(item2 => item2.processId == item.id));
        }
    },

    mounted() {
        this.lookupData();
        this.getProcessActivityList();
    },
    methods: {
        lookupData: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
                projectId: this.projectId,
                projectTypeId: this.projectTypeId,
            }
            axios.post(this.$store.getters.getAPIBasePath + '/process/lookup/all', data)
                .then((response) => {
                    let result = response.data;
                    this.processes = result.processLookup;                    
                    this.activities = result.activityLookup;
                })
        },

        getProcessActivityList: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
                projectId: this.projectId,
                projectTypeId: this.projectTypeId,
            }
            axios.post(this.$store.getters.getAPIBasePath + '/process/processactivity/list',data)
                .then((response) => {
                    let result = response.data;
                    if (result.processActivityList && result.processActivityList.length > 0) {
                        this.processListAll = result.processActivityList;
                        this.totalRows = this.processListAll.length;
                    }
                })
        },
        getProcessActivities: function(processId) {
            if (processId) {
                let data = {
                    vhextAccountId: this.vhextAccountId,
                    projectId: this.projectId,
                    projectTypeId: this.projectTypeId,
                    processId: processId,
                    refEntity: 'process',
                }
                console.log(data);
                this.showDetOverlay = true;
                axios.post(this.$store.getters.getAPIBasePath + '/process/processactivity/activity',data)
                    .then((response) => {
                        this.showOverlay = false;
                        let result = response.data;
                        this.processActivity.activities = result.processActivityLookup;
                        console.log("ACT",result.processActivityLookup);
                    })
                    .catch(error => {
                        this.showDetOverlay = false;
                        iziToast.error({
                           title: 'Error',
                           message: 'Error',
                           position: 'topRight'
                       });
                    })
            }
        },
        saveData: function() {
            this.$v.processActivity.$touch();
            if (!this.$v.processActivity.$invalid) {
                if (!this.matchTextValExists(this.processActivity.title, this.processActivity.id)) {
                    this.processActivity.vhextAccountId = this.vhextAccountId;
                    this.processActivity.projectId = this.projectId;
                    this.processActivity.projectTypeId = this.projectTypeId;
                    this.processActivity.createdById = this.userId;
                    this.processActivity.processActivityList = [];
                    this.processActivity.activities.forEach((data) => {
                        this.processActivity.processActivityList.push({ 
                            activityId: data.id, 
                            activity: data.value,
                            input: data.input ? data.input : '',
                            output: data.output ? data.output : '',
                            task: data.task ? data.task : '',
                            verify: data.verify ? data.verify : '',
                        });
                    })
                    if (this.elements.editMode) {
                        this.updateProcessActivity();
                    } else {
                        this.addProcessActivity();
                    }
                }
                else {
                    iziToast.info({
                        message: 'Process already exist.',
                        position: 'topCenter'
                    });
                }

            }
        },
        addProcessActivity: function() {
            // this.processActivity.vhextAccountId = this.vhextAccountId;
            // this.processActivity.projectId = this.projectId;
            // this.processActivity.projectTypeId = this.projectTypeId;
            this.processActivity.entity = this.entity;
            console.log("ACT_A",this.processActivity);
            this.showOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/process/add', this.processActivity)
                .then((response) => {
                    this.showOverlay = false;
                    let result = response.data;
                    if (result.status == "CREATED") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.$emit('refresh-lookup');
                        this.getProcessActivityList();
                        this.clearData();
                        
                    }
                })
                .catch((error)=> {
                    this.showOverlay = false;
                    iziToast.error({
                        title: 'Error',
                        message: 'Error',
                        position: 'topRight'
                    });
                })
        },
        updateProcessActivity: function() {
            console.log("ACT_U",this.processActivity);
            this.showOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/process/update', this.processActivity)
                .then((response) => {
                    this.showOverlay = false;
                    let result = response.data;
                    console.log(result);
                    if (result.status == 'CREATED') {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.$emit('refresh-lookup');
                        this.getProcessActivityList();
                        this.clearData();
                    }
                })
                .catch((error)=> {
                    this.showOverlay = false;
                    iziToast.error({
                        title: 'Error',
                        message: 'Error',
                        position: 'topRight'
                    });
                })
        },
        deleteProcessActivity: function(id) {
            console.log(id)
            this.deleteParam.id = id;
        },
        onYes: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
                projectId: this.projectId,
                processId: this.deleteParam.id,               
            }

            axios.post(this.$store.getters.getAPIBasePath + '/process/processactivity/delete' , data)
                .then(response => {
                    var result = response.data;
                    if (result.status == "OK") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.$emit('refresh-lookup');
                        this.getProcessActivityList();
                    }
                })
                .catch(error => {
                    console.log(error);
                });
        },
        assignActivationState: function(item) {
            this.isBusy = true;
            if (item.active == true) {
                this.processActivity.id = item.id;
                this.processActivity.active = 0;
                this.processActivity.entity = 'process';
                this.updateActivationState();
            } else {
                this.processActivity.id = item.id;
                this.processActivity.active = 1;
                this.processActivity.entity = 'process';
                this.updateActivationState();
            }
        },
        updateActivationState: function() {
            axios.post(this.$store.getters.getAPIBasePath + '/dynamicsql/activationstate/update', this.processActivity)
                .then((response) => {
                    this.isBusy = false;
                    let result = response.data;
                    if (result.status == 'CREATED') {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.$emit('refresh-lookup');
                        this.getProcessActivityList();
                        this.clearData();
                    }
                })
                .catch((error)=> {
                    this.isBusy = false;
                    iziToast.error({
                        title: 'Error',
                        message: 'Error',
                        position: 'topRight'
                    });
                })
        },
        openETVX: function() {
            this.etvxEditMode = true;
            // this.etvxEditMode = this.elements.editMode ? true : false;
            this.$nextTick(() => {
                this.$refs.activityETVXPopupRef.setActivity(this.processActivity.activities, this.etvxEditMode);
            })
        },
        clearData: function() {
            this.elements.visible = false;
            this.elements.editMode = false;
            this.processActivity.id = '';
            this.processActivity.processId = '';
            this.processActivity.createdById = localStorage.getItem("userId");
            this.processActivity.description = '';
            this.processActivity.title = '';
            this.processActivity.active = true;
            this.processActivity.activities = [];
            this.$v.processActivity.$reset();
        },
        showActivityEdit: function(items) {
            let data = items;
            this.elements.visible = true;
            this.elements.editMode = true;

            this.processActivity.id = data.id;
            this.processActivity.title = data.title;
            this.processActivity.processId = data.id;
            this.processActivity.userName = data.userName;
            this.processActivity.createdById = data.createdById;
            this.processActivity.description = data.description;
            // this.processActivity.activities = data.activities;
            this.processActivity.active = data.active;
            this.processActivity.createdDt = data.createdDt;
            this.processActivity.lastUpdatedDt = data.lastUpdatedDt;

            this.getProcessActivities(data.processId);
        },
        updateETVXActivities: function(activities) {
            this.processActivity.activities = activities;
        },
        matchTextValExists: function(textVal, id) {
            let result = false;
            this.processListAll.map(data => {
                if (textVal && data.title && data.id !== id) {
                    if (data.title.toLowerCase() == textVal.toLowerCase()) {
                        result = true;
                    }
                }
            });
            return result;
        },        
        closeDialog() {
            this.$emit('closeDialog');
        }

    }
}
</script>