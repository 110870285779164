<template>
    <div class="assignmentPopup">
        <b-modal no-close-on-backdrop no-close-on-esc centered :title="popupTitle" @hide="clearData" no-enforce-focus :id="id" @close="showOverlay ? $event.preventDefault() : $event.vueTarget.hide();" ref="ermAssignmentsRef" size="md" modal-class="ermAssignmentsRef">
            <b-overlay :show="showOverlay" spinner-variant="primary" opacity="0.4" rounded="sm">
                <b-container fluid>
                    <b-row class="mb-1">
                        <b-col cols="3">Task</b-col>
                        <b-col cols="8 pr-1">
                            <div class="required-field-block" :class="{ 'is-invalid': $v.task.title.$error }">
                                <b-form-input id="title" v-model="task.title" placeholder="Enter Task Title" required></b-form-input>
                                <div class="required-icon"></div>                                
                            </div>
                            <label v-if="$v.task.title.$error && !$v.task.title.minLength" class="text-danger">Task Title must have at least {{$v.task.title.$params.minLength.min}} letters.</label>
                            <label v-if="$v.task.title.$error && !$v.task.title.required" class="text-danger">Please Enter Task Title</label>
                            <label v-if="$v.task.title.$error && !$v.task.title.maxLength" class="text-danger">Task Title must not exceed {{$v.task.title.$params.maxLength.max}} letters.</label>
                        </b-col>
                        <a v-if="false" href="javascript:" title="Record task" class="microphone-link" v-b-modal.audioRecordModal><i class="fa fa-microphone microphone-icon-alt" aria-hidden="true"></i></a>                                         
                        <a href="javascript:" title="Record task" class="microphone-link" @click="showAudioRecord"><i class="fa fa-microphone microphone-icon-alt" aria-hidden="true"></i></a>                                         
                    </b-row>
                    <b-row class="mb-1">
                        <b-col cols="3"></b-col>
                        <b-col cols="9">
                            <div class="close-audio">
                                <audio v-if="audioUrl != ''" controls  :src="audioUrl"></audio>
                                <a  v-if="audioUrl != ''" href="javascript:" title="Close Record" @click="audioUrl = ''" class="close-audio-btn"><i class="fa fa-close" aria-hidden="true"></i></a>                                         
                            </div>
                        </b-col>
                    </b-row>
                    <b-row class="mb-1">
                        <b-col cols="3">Start Date</b-col>
                        <b-col class="col-5 pr-0">
                            <b-form-input type="date" v-model="task.estimatedStartDate" required></b-form-input>
                        </b-col>
                        <b-col class="col-4">
                            <b-form-input type="time" v-model="task.estimatedStartTime" required></b-form-input>
                        </b-col>
                    </b-row>
                    <b-row class="mb-1">
                        <b-col cols="3">Due Date</b-col>
                        <b-col class="col-5 pr-0">
                            <b-form-input type="date" v-model="task.estimatedEndDate" required></b-form-input>
                        </b-col>
                        <b-col class="col-4">
                            <b-form-input type="time" v-model="task.estimatedEndTime" required></b-form-input>
                        </b-col>
                    </b-row>
                    <b-row class="mb-1">
                        <b-col cols="3">Est. Effort</b-col>
                        <b-col class="col-9">
                            <b-input-group size="sm">
                                <EffortInput ref="effortControl" />
                            </b-input-group>
                        </b-col>
                    </b-row>
                    <b-row class="mb-1">
                        <b-col cols="3">Est. Cost</b-col>
                        <b-col class="col-9">
                            <VhextMoneyControl :value="Math.abs(Number(task.estimatedCost))" @money-input="moneyControlInput" />
                        </b-col>
                    </b-row>
                    <!-- <b-row class="mb-1">
                        <b-col cols="3">Activity</b-col>
                        <b-col cols="8 pr-1">
                            <select v-model="task.activityId" class="form-control">
                                <option value="">Select</option>
                                <option v-for="(info, index) in taskActivity" :value="info.id">{{ info.value }}</option>
                            </select>
                        </b-col>
                        <a :title="processActivityLookupTitle" class="action-link" href="javascript:" @click="showDialog=true" v-b-modal.taskProcessActivityModal><i class="fa fa-cog setting-icon-alt" aria-hidden="true"></i></a>
                    </b-row> -->
                    <b-row class="mb-1">
                        <b-col cols="3">Process/Activity</b-col>
                        <b-col cols="8 pr-1">
                            <select v-model="task.processActivity" class="form-control" id="activityId1">
                                <option :value="{activityId: '', processId: ''}">Select</option>
                                <option v-for="(info, index) in processActivities" :value="{activityId: info.activityId, processId: info.processId}">{{ info.process ? info.process + ' / ' : ''}}{{info.activity}}</option>
                            </select>
                        </b-col>
                        <a class="action-link" href="javascript:" @click="showDialog=true" v-b-modal.userActivityModal><i class="fa fa-cog setting-icon-alt" aria-hidden="true"></i></a>
                    </b-row>
                    <b-row class="mb-1">
                        <b-col cols="3">Assigned To</b-col>
                        <b-col>
                            <select v-model="task.assignedToId" class="form-control">
                                <option value="">Select</option>
                                <option v-for="(info, index) in users" :value="info.id">{{info.altValue}}</option>
                            </select>
                        </b-col>
                    </b-row>
                    <b-row class="mb-1">
                        <b-col cols="3">Status</b-col>
                        <b-col>
                            <div class="required-field-block" :class="{ 'is-invalid': $v.task.statusId.$error }">
                                <select v-model="task.statusId" class="form-control">
                                    <!-- <option value="">Select</option> -->
                                    <option v-for="(info, index) in taskStatus" :value="info.id">{{info.value}}</option>
                                </select>
                                <div class="required-icon"></div>
                            </div>
                            <label v-if="$v.task.statusId.$error && !$v.task.statusId.required" class="text-danger">Please Select a Status</label>
                        </b-col>
                    </b-row>
                    <b-row class="mb-2">
                        <b-col cols="3">Task Plan / Task Group</b-col>
                        <b-col cols="8 pr-1">
                            <div class="required-field-block" :class="{ 'is-invalid': $v.task.entityRecordMasterId.$error }">
                                <select v-model="task.entityRecordMasterId" class="form-control">
                                    <option value="">Select</option>
                                    <option v-for="(info, index) in entityERMLookupList" :value="info.id">{{info.value}}</option>
                                </select>
                                <div class="required-icon"></div>
                            </div>
                            <label v-if="$v.task.entityRecordMasterId.$error && !$v.task.entityRecordMasterId.required" class="text-danger">Please Select a Plan</label>
                        </b-col>
                        <a title="Create new plan" class="action-link" href="javascript:" @click="openReqPop"><i class="fa fa-cog setting-icon-alt" aria-hidden="true"></i></a>
                    </b-row>
                    <b-row>
                        <div class="col-12">
                            <div class="card-body p-0 my-0">
                                <b-card no-body>
                                    <b-card-header header-tag="header" class=" accordion-header p-0" role="tab">
                                        <label class="d-block p-1 my-0 accordion-title" block href="javascript:" v-b-toggle.accordion-taskPopup>
                                            <label class="mb-1 pt-1">Description</label>
                                        </label>
                                    </b-card-header>
                                    <b-collapse id="accordion-taskPopup" v-model="visibleDescription" accordion="my-accordion1" role="tabpanel">
                                        <b-card-body class="p-0 pb-0">
                                            <div class="row">
                                                <div class="col-12">
                                                    <div class="form-group">
                                                        <!-- <editor api-key="lbcn6twu8tv4xan8fn1tgqiqy9hhvsse01s48crluvfuafzp" :toolbar="toolbar" :init="initObj" v-model="task.description"></editor> -->
                                                        <VmxEditor v-model="task.description"></VmxEditor>
                                                    </div>
                                                </div>
                                            </div>
                                        </b-card-body>
                                    </b-collapse>
                                </b-card>
                            </div>
                        </div>
                    </b-row>
                </b-container>
            </b-overlay>
            <template #modal-footer>
                <div class="w-100">
                    <a class="float-left action-link" v-b-modal.checklistPopup @click="openChecklistPopup" href="#" title="Add Checklist">
                        <i v-if="hasChecklist" class="fa fa-list-ul pr-1 mt-1 mb-0" aria-hidden="true"></i>
                        <i v-else class="fa fa-plus-circle pr-1 mt-1 mb-0" aria-hidden="true"></i>
                        Checklist <span v-if="hasChecklist" :title="task.checklistList.length + ' Checklist'">[{{ task.checklistList.length }}]</span>
                    </a>
                    <b-button class="float-right"  variant="outline-primary" size="sm" @click="clearData">
                        Clear
                    </b-button>
                    <b-button variant="primary" :disabled="showOverlay" size="sm" @click="saveTask" class="float-right mr-1">
                        <span v-if="elements.editMode">Update Task</span>
                        <span v-else>Add Task</span>
                    </b-button>
                </div>
            </template>
        </b-modal>
        <AddUserActivityPopup v-if="showDialog" id="userActivityModal" ref="addUserActivityModal"  @refresh-lookup="lookupData" @closeDialog="closeDialog" />   
<!--         <ProcessActivityLookup v-if="showDialog" id="taskProcessActivityModal" ref="processActivityModal" :entity="entity" :processActivityLookupTitle="processActivityLookupTitle" :masterProjectId="masterData.projectId" @refresh-lookup="lookupData" @closeDialog="closeDialog" /> -->
        <ChecklistPopup ref="checklistPopupRef" @addChecklistList="addChecklistList" @closeDialog="closeDialog" />
        <!-- <ActivityLookup v-if="showDialog" id="taskActivityModal" ref="activityLookupModal" :entity="entity" :projectTypeId="projectTypeId" :activityLookupTitle="activityLookupTitle" @refresh-lookup="lookupData" @closeDialog="closeDialog" /> -->
        <ERMMasterPopup id="quickTaskErmPopup" v-if="showDialog" :ermMasterInfo="ermMasterInfo" @refresh-lookup="lookupData();masterLookupRefresh();" @closeDialog="showDialog=false" />
        <AudioRecord ref="audioRecordRef" :recordAudio="true" recordModalId="recordModal"  :refEntity="entity" :refRecordId="(task.id).toString()" @assign-data="assignAudioUrl" />
    </div>
</template>
<script>
import iziToast from 'izitoast';
import moment from 'moment';
import Multiselect from 'vue-multiselect';
import { required, email, minLength, maxLength, sameAs } from "vuelidate/lib/validators";
import AddUserActivityPopup from '@/components/popup/action/AddUserActivityPopup.vue';
import ChecklistPopup from '@/components/popup/action/ChecklistPopup.vue';
import ProcessActivityLookup from '@/components/popup/lookup/ProcessActivityLookup.vue';
// import ActivityLookup from '@/components/popup/lookup/ActivityLookup.vue';
import ERMMasterPopup from '@/components/popup/lookup/ERMMasterPopup.vue'
// import Editor from '@tinymce/tinymce-vue';
import VmxEditor from '@/components/usercontrols/VmxEditor';
import AudioRecord from '@/components/usercontrols/RecordControl.vue';
import VhextMoneyControl from '@/components/usercontrols/VhextMoneyControl.vue';
export default {
    props: {
        id: { type: String, default: 'ermAssignments' },
        masterData: Object,
        ermLookupList: Array,
    },
    components: {
        // ActivityLookup,
        AddUserActivityPopup,
        ChecklistPopup,
        ProcessActivityLookup,
        ERMMasterPopup,
        VmxEditor,
        AudioRecord,
        VhextMoneyControl
    },
    data() {
        return {
            showOverlay: false,
            visibleDescription: false,
            processActivityLookupTitle: 'Process / Activity Settings',
            activityLookupTitle: 'Activity Settings',
            entity: 'task',
            showDialog: false,
            hasChecklist: false,
            vhextAccountId: localStorage.getItem("vhextAccountId"),
            projectId: localStorage.getItem("projectId"),
            projectTypeId: localStorage.getItem("projectTypeId"),

            userId: localStorage.getItem("userId"),

            elements: {
                editMode: false,
            },

            ermMasterInfo: {
                title: "Task Plan",
                entity: 'development_plan_master',
                // entityId: 142,
                refEntity: 'task',
                // refEntityId: 74,
            },

            task: {
                id: '',
                taskId: '',
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                projectId: localStorage.getItem("projectId"),
                entityRecordMasterId: '',
                userId: localStorage.getItem("userId"),
                entity: 'task',
                refEntity: '',
                title: '',
                planId: '',
                requirementId: '',
                requirement: '',
                processId: '',
                process: '',
                activityId: '',
                activity: '',
                processActivity: {
                    activityId: '',
                    processId: '',
                },
                taskTypeId: '',
                type: '',
                isArchive: false,
                description: '',
                priorityId: '',
                priority: '',
                startDate: '',
                endDate: '',
                estimatedStartDt: '',
                estimatedStartDate: this.getDateValue(new Date()),
                estimatedStartTime: this.getTimeValue(new Date()),
                estimatedEndDt: '',
                estimatedEndDate: this.getDateValue(new Date()),
                estimatedEndTime: this.getTimeValue(new Date()),
                assignedById: localStorage.getItem("userId"),
                assignedTo: '',
                assignedToId: '',
                effort: '',
                effortType: '',
                workHour: '',
                workMinute: '',
                workDay: '',
                estimatedCost: '',
                progressPercentage: '',
                statusId: '',
                status: '',
                hasReference: '',
                hasAttachment: '',
                hasComment: '',
                entityId: '',

                refErmId: '',
                refErdId: '',
                refEntityId: '',
                refErdTitle: '',
                refErdDescription: '',
                refEntity: '',

                createdDt: '',
                lastUpdatedDt: '',
                taskAssignment: '',
                attachments: [],
                checklistList: [],
            },
            popupTitle: 'Assign Task',
            entityERMLookup: [],
            users: [],
            requirements: [],
            processActivities: [],
            taskActivity: [],
            taskTypes: [],
            taskPriorities: [],
            taskStatus: [],

            weblinks: 'google.com',
            toolbar: 'undo redo preview | formatselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | weblinks',
            initObj: {
                height: 200,
                branding: false,
                menubar: false,
                plugins: ['link', 'paste', 'wordcount', 'preview', 'image imagetools', 'lists'],
                paste_data_images: true,
                images_upload_url: 'postAcceptor.php',
                automatic_uploads: false,
                file_picker_types: 'image',
                relative_urls: false,
                remove_script_host: false,
            },
            audioUrl:''
        }
    },
    watch: {
        'masterData.refErdDescription': {
            handler: function(val1, val2) {  
                // this.onRefDataChange();
            },
            deep: true,
            immediate: true
        },
    },
    validations: {
        task: {
            title: {
                required,
                minLength: minLength(2),
                maxLength: maxLength(100)
            },
            statusId: {
                required
            },
            entityRecordMasterId: {
                required
            }
        }
    },
    computed: {
        entityERMLookupList() {
            if (this.ermLookupList) {
                return this.ermLookupList;
            } else {
                return this.entityERMLookup;
            }
        }
    },
    mounted() {
        this.lookupData();
    },
    methods: {
        lookupData: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
                projectId: this.projectId,
                projectTypeId: this.projectTypeId,
                userId: this.userId,
                entity: 'task'
            }
            axios.post(this.$store.getters.getAPIBasePath + '/task/lookup/all', data)
                .then((response) => {
                    let result = response.data;
                    this.users = result.userLookup;
                    this.requirements = result.requirementLookup;
                    this.taskActivity = result.activityLookup;
                    this.processActivities = result.userProcessActivityLookup;
                    this.taskStatus = result.statusLookup;
                    // this.taskTypes = result.customTypeLookup;
                    this.taskPriorities = result.priorityLookup;
                    this.entityERMLookup = result.entityERMLookup;
                    console.log(result);

                    this.task.statusId = this.taskStatus[0].id;
                    // this.task.entityRecordMasterId = this.entityERMLookupList.length>0 ? this.entityERMLookupList[0].id : '';
                })
        },
        saveTask: function() {

            this.task.estimatedStartDt = this.combineDateAndTime(this.task.estimatedStartDate, this.task.estimatedStartTime);
            this.task.estimatedEndDt = this.combineDateAndTime(this.task.estimatedEndDate, this.task.estimatedEndTime);
            this.task.processId = this.task.processActivity.processId;
            this.task.activityId = this.task.processActivity.activityId;
            this.setEffortData();

            
            console.log(this.elements.editMode);
            console.log("Task",this.task);

            this.$v.task.$touch();
            if (!this.$v.task.$invalid) {
                if (this.elements.editMode) {
                    this.updateTask();
                } else {
                    this.addTask();
                }
            }
        },
        addTask() {
            this.showOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/task/add', this.task)
                .then((response) => {
                    this.showOverlay = false;
                    let result = response.data;                        
                    if (result.status == "CREATED") {
                        this.$refs.audioRecordRef.onDataChange(result.id);
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.$emit('refresh-task');
                        this.clearData();
                        this.$bvModal.hide(this.id);
                    }
                })
                .catch(error => {
                    this.showOverlay = false;
                    iziToast.error({
                       title: 'Error',
                       message: 'Error',
                       position: 'topRight'
                   });
                })
        },
        updateTask() {
            this.showOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/task/update', this.task)
                .then((response) => {
                    this.showOverlay = false;
                    let result = response.data;                        
                    if (result.status == "CREATED") {
                        this.$refs.audioRecordRef.onDataChange(result.id);
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.$emit('refresh-task');
                        this.clearData();
                        this.$bvModal.hide(this.id);
                    }
                })
                .catch(error => {
                    this.showOverlay = false;
                    iziToast.error({
                       title: 'Error',
                       message: 'Error',
                       position: 'topRight'
                   });
                })
        },
        onRefDataChange: function(title) {
            console.log("ERM",this.masterData);
            console.log("TASK",this.task);
            this.elements.editMode = false;

            if (title && title == 'Quick Task') {
                this.popupTitle = 'Quick Task';
                this.task.entityRecordMasterId = this.masterData.entityRecordMasterId;
                this.task.projectId = this.masterData.projectId;
                this.task.assignedToId = this.masterData.assignedToId;
                this.task.estimatedStartDate = this.getDateValue(new Date());
                this.task.estimatedEndDate = this.getDateValue(new Date());
                this.task.statusId = this.getStatusId(this.masterData.statusId);

                this.audioUrl = '';
                this.visibleDescription = false;
            } else {
                this.popupTitle = 'Assign Task';
                this.task.entityRecordMasterId = this.masterData.entityRecordMasterId;
                // this.task.entityRecordMasterId = this.entityERMLookupList.length>0 ? this.entityERMLookupList[0].id : '';
                this.task.projectId = this.masterData.projectId;

                this.task.entityId = this.masterData.refEntityId;
                this.task.refErmId = this.masterData.refErmId;
                this.task.refErdId = this.masterData.refErdId;
                this.task.refEntity = this.masterData.refEntity;
                this.task.refErdTitle = this.masterData.refErdTitle;
                this.task.title = this.masterData.refErdTitle;
                this.task.statusId = this.getStatusId(this.masterData.statusId);
                this.task.description = this.masterData.refErdDescription;
                this.task.activityId = this.masterData.activityId ?? '';
                this.task.estimatedCost = this.masterData.estimatedCost ?? '';
                this.task.workHour = this.masterData.workHour ?? '';
                this.task.estimatedStartDate = this.masterData.estimatedStartDate ? this.getDateValue(this.masterData.estimatedStartDate) : this.task.estimatedStartDate;
                this.task.estimatedEndDate = this.masterData.estimatedEndDate ? this.getDateValue(this.masterData.estimatedEndDate) : this.task.estimatedEndDate;
                this.$refs.effortControl.setEffortInputData(this.task.workDay,this.task.workHour,this.task.workMinute);
                this.visibleDescription = true;
            }
        },
        showEdit: function(data) {
            console.log("TASK",this.task);
            this.popupTitle = 'Edit Task';
            this.elements.editMode = true;
            this.task.id = data.id;
            this.task.title = data.title;
            this.task.estimatedStartDt = '';
            this.task.estimatedStartDate = this.getDateValue(data.estimatedStartDt);
            this.task.estimatedStartTime = this.getTimeValue(data.estimatedStartDt);
            this.task.estimatedEndDt = '';
            this.task.estimatedEndDate = this.getDateValue(data.estimatedEndDt);
            this.task.estimatedEndTime = this.getTimeValue(data.estimatedEndDt);
            this.task.isArchive = data.isArchive;
            this.task.taskAssignment = data.taskAssignment;
            this.task.processActivity.activityId = data.activityId;
            this.task.processActivity.processId = data.processId;
            this.task.requirementId = data.requirementId;
            this.task.requirement = data.requirement;
            this.task.effort = data.effort;
            this.task.effortType = data.effortType;
            this.task.estimatedCost = data.estimatedCost;
            this.task.progressPercentage = data.progressPercentage ? data.progressPercentage : 0;
            this.task.statusId = data.statusId;
            this.task.status = data.status;
            this.task.taskId = data.taskId;
            this.task.vhextAccountId = data.vhextAccountId;
            this.task.projectId = data.projectId;
            this.task.entityRecordMasterId = data.entityRecordMasterId;
            this.task.planId = data.planId;
            this.task.activity = data.activity;
            this.task.taskTypeId = data.taskTypeId;
            this.task.type = data.type;
            this.task.description = data.description;
            this.task.priorityId = data.priorityId;
            this.task.priority = data.priority;
            this.task.startDate = data.startDate;
            this.task.endDate = data.endDate;
            this.task.assignedBy = data.assignedBy;
            this.task.assignedById = data.assignedById;
            this.task.assignedTo = data.assignedTo;
            this.task.assignedToId = data.assignedToId;
            this.task.hasReference = data.hasReference;
            this.task.hasAttachment = data.hasAttachment;
            this.task.hasComment = data.hasComment;
            this.task.hasChecklist = data.hasChecklist;
            this.task.entityId = data.entityId;
            this.task.refErmId = data.refErmId;
            this.task.createdDt = data.createdDt;
            this.task.lastUpdatedDt = data.lastUpdatedDt;
            this.task.response = data.response;
            this.task.workHour = data.workHour;
            this.task.workMinute = data.workMinute;
            this.task.workDay = data.workDay;
            this.$refs.effortControl.setEffortInputData(data.workDay, data.workHour, data.workMinute);
        },

        clearData: function() {
            this.task.id = '';
            this.task.taskId = '';
            this.task.entityRecordMasterId = this.masterData?.entityRecordMasterId ?? '';
            this.task.projectId = this.masterData?.projectId ?? '';
            this.task.planId = '';
            this.task.activity = '';
            this.task.taskTypeId = '';
            this.task.type = '';
            this.task.title = '';
            this.task.estimatedStartDt = '';
            this.task.estimatedStartDate = this.getDateValue(new Date());
            this.task.estimatedStartTime = this.getTimeValue(new Date());
            this.task.estimatedEndDt = '';
            this.task.estimatedEndDate = this.getDateValue(new Date());
            this.task.estimatedEndTime = this.getTimeValue(new Date());
            this.task.taskAssignment = '';
            this.task.processActivity.processId = '';
            this.task.processActivity.activityId = '';
            this.task.processId = '';
            this.task.activityId = '';
            this.task.requirementId = '';
            this.task.requirement = '';
            this.task.effort = '';
            this.task.effortType = '';
            this.task.workDay = '';
            this.task.workHour = '';
            this.task.workMinute = '';
            this.task.estimatedCost = '';
            this.task.progressPercentage = '';
            this.task.statusId = this.task.statusId ?? this.taskStatus[0].id;
            this.task.assignedTo = '';
            this.task.assignedToId = '';
            this.task.description = '';
            this.task.priorityId = '';
            this.task.priority = '';
            this.task.startDate = '';
            this.task.endDate = '';
            this.task.hasReference = '';
            this.task.hasAttachment = '';
            this.task.hasComment = '';
            this.task.entityId = '';
            this.task.refErmId = '';
            this.task.attachments = [];
            this.task.checklistList = [];
            this.hasChecklist = false;
            this.$v.task.$reset();
            this.$refs.effortControl.setEffortInputData(this.task.workDay,this.task.workHour,this.task.workMinute);
        },
        getStatusId(statusLookupId) {
            if (this.taskStatus.length > 0) {
                let status = this.taskStatus.find(status => status.statusLookupId == statusLookupId);
                return status ? status.id : this.taskStatus[0].id;
            }
        },

        addChecklistList(checklistArray) {
            this.task.checklistList = JSON.parse(checklistArray);
            this.hasChecklist = this.task.checklistList.length>0 ? true : false;
        },
        masterLookupRefresh() {
            this.$emit('masterLookupRefresh');
        },
        openReqPop() {
            this.showDialog = true;
            this.$nextTick(()=> {
                this.$bvModal.show('quickTaskErmPopup');
            })
        },
        openChecklistPopup() {
            let checklistArray = JSON.stringify(this.task.checklistList);
            console.log(checklistArray)
            this.$nextTick(()=> {
                this.$refs.checklistPopupRef.loadChecklist(checklistArray);
            })
        },
        // handleClose(e) {
        //     console.log(e)
        //     e.vueTarget.hide();
        // },
        setEffortData: function() {
            this.task.workHour = this.$refs.effortControl.getHrs();
            this.task.workMinute = this.$refs.effortControl.getMins();
            this.task.workDay = this.$refs.effortControl.getDays();
        },
        closeDialog: function() {
            this.showDialog = false;
            // this.clearData();
        },
        moneyControlInput: function(value) {
            this.task.estimatedCost = Math.abs(value);
        },
        assignAudioUrl: function(value) {
            this.audioUrl = value;
        },
        showAudioRecord:function(){
            this.$bvModal.show('recordModal');
        }
    }
}
</script>