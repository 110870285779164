<template>
    <div class="fixed-inner-header">
        <div class="container-fluid p-0">
            <div class="row inner-header">
                <div class="col-12">
                    <div class="row tassk-top-menu" v-bind:class="{disabled: !elements.isDisable }">
                        <div class="col-lg-1 col-sm-1 mt-2 pr-0 mr-0">
                            <h4 class="pl-2 mb-0 fs-16">
                                <!-- <div class="ingov-app-header">
                                    <div class="app-options">
                                        <div class="app-menu1">
                                            <i class="fa fa-bars" aria-hidden="true"></i>
                                        </div>
                                    </div>
                                </div> -->
                                Task
                            </h4>
                        </div>
                        <div class="col pl-0 mb-1 ml-0 text-left">
                            <select v-model="ermObject" @change="ermFilter(ermObject)" class="form-control" id="taskId">
<!--                                 <option value="all" >All Plan</option> -->
                                <option v-for="(info, index) in ermLookup" :value="{id: info.id, value:info.value}" :title="info.value">{{info.value}}</option>
                            </select>
                        </div>
                        <div class="col pr-0">
                            <div class="dataTables_filter">
                                <label>
                                    <i class="fa fa-search icon-search" aria-hidden="true"></i>
                                    <input type="search" v-model="search" class="form-control search-task" placeholder="Search Title..">
                                </label>
                            </div>
                        </div>
                        <div class="col-lg-3 col-sm-3 mb-1 ml-0 text-left pl-1">
                            
                            <div class="carousel-users">
                                
                                <carousel v-if="carousel" :autoplay="false" :dots="false" :nav="false" :responsive="{0:{items:5,nav:false},600:{items:5,nav:false},900:{items:6,nav:false}}">
                                    <template slot="prev"><span class="prev prev-arrow-btn"><i aria-hidden="true" class="fa fa-angle-left"></i></span></template>
                                        <div v-if="!elements.clientTaskPage" v-for="user in users" @click="teamMemberFilter(user.id); filteredUserId = user.id" class="my-0 py-0 text-right user-icon" >
                                            <span class="pointer align-self-center user-name-filter-icon" :class="{'active-filter-icon' : user.id == filteredUserId}" :title="user.value">{{ user.value | getAcronym }}</span>
                                        </div>
                                    <template slot="next"><span class="next next-arrow-btn"><i aria-hidden="true" class="fa fa-angle-right"></i></span></template>
                                </carousel>
                                
                                <b-button v-if="elements.filterMode && !elements.clientTaskPage" class="btn btn-xs text-right px-2 mx-3 all-user-btn" variant="outline-success" @click="clearFilter" size="sm">All</b-button>
                            </div>
                        </div>
                        <div class="col-lg-1 col-sm-1 mb-1 p-0">
                            <b-button class="addnew-tassk-btn float-right " variant="outline-info" v-bind:class="{disabled: !elements.isDisable }" @click="elements.viewOnlyComponent=false; task.id=null; erdId=null;toggleDiv();task.assignedToId = filteredUserId ? filteredUserId : ''" size="sm"><i class="fa fa-plus pr-1"></i>New Task</b-button>
                        </div>
                        <div class="col-lg-4 col-sm-4 mt-2">
                            <nav class="breadcrumb-container d-sm-block d-lg-inline-block float-right" aria-label="breadcrumb">
                                <ol class="breadcrumb pt-0 mb-0">
                                    <li class="breadcrumb-item">
                                        <a href="javascript:" @click="getPrevUrl()"><i class="fa fa-reply action-link pr-2" aria-hidden="true"></i>Back</a>
                                    </li>
                                    <li class="breadcrumb-item">
                                        <a href="javascript:" title="List View" class="action-link" @click="redirectPage"><i class="fa fa-list px-1" aria-hidden="true"></i></a>
                                    </li>                                    
                                    <li class="breadcrumb-item">
                                        <a href="javascript:" title="Gantt View" @click="$router.push('/taskganttchart');"><i class="fa fa-sliders action-link px-1" aria-hidden="true"></i></a>
                                    </li>
                                    <li class="breadcrumb-item">
                                        <h4 class="pl-2 mb-0 fs-14 text-right mr-2">#{{this.projId}} - {{this.projectName}}</h4>
                                    </li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                    <div class="separator mb-2"></div>
                </div>
            </div>
            <div class="row">
                <div class="col-12 col-lg-12 col-xl-12 position-relative">
                    <div class="row mr-0">
                        <div v-for="status in taskStatus" v-if="status.value !=='Cancelled'" class="col-12 col-md-3 mb-1 pr-1">
                            <div class="card mb-2 b-radius-5 ">
                                <div class="card-body px-3 py-2">
                                    <div class="form-group mb-0">
                                        <label class="mt-1 mb-0 font-weight-bold" for="title">{{ status.value }}</label>
                                        <b-button class="pull-right addnew-quicktassk-btn" v-bind:class="{disabled: !elements.isDisable }" @click="quickTask(status.statusLookupId)" variant="outline-info" size="sm" v-b-modal.ermAssignments><i class="fa fa-plus"></i> Quick Task</b-button>
                                    </div>
                                </div>
                            </div>
                            <div class="tasskLists" v-bind:class="{hide: !elements.isHide }">
                                <div v-for="task in filteredTasks">
                                    <!-- <div v-if="task.status == status.value" :style="`border-top: 3px solid ${priorityColour(task.priority)};`" class="card-media mb-2"> -->
                                    <div v-if="task.status == status.value" class="card-media mb-2">
                                        <div class="card-media-body dropdown show">
                                            <a type="button" class="float-right" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                <i class="fa fa-ellipsis-v p-1"></i>
                                            </a>
                                            <div class="dropdown-menu dropdown-primary" aria-labelledby="dropdownMenuLink">
                                                <a class="dropdown-item addnew-tassk pb-1 py-1" @click="showView(task);toggleDiv()" href="#"><i class="fa fa-list-alt pr-2" aria-hidden="true"></i>Task Details</a>
                                                <a v-if="task.assignedById == userId" class="dropdown-item addnew-tassk py-1" @click="showEdit(task);elements.taskStatusUpdate = true;toggleDiv();" href="#"><i class="fa fa-pencil pr-2"></i>Edit Task</a>
                                                <a class="dropdown-item addnew-tassk pb-1 py-1" @click="toggleDiv();duplicateTask(task);" href="#"><i class="fa fa-copy pr-2" aria-hidden="true"></i>Duplicate Task</a>
                                                <a v-if="task.assignedById == userId || task.assignedToId == userId" class="dropdown-item addnew-tassk my-0 py-1" @click="showStatusPopup(task)" href="#"><i class="fa fa-edit pr-2 mt-1 mb-0" aria-hidden="true"></i>Task Status Update</a>
                                                <a v-if="task.assignedById == userId" class="dropdown-item py-1" @click="deleteTask(task.id)" v-b-modal.deleteTask href="#"><i class="fa fa-trash pr-2"></i>Delete Task</a>
                                                <a v-if="task.assignedById == userId || task.assignedToId == userId" class="dropdown-item addnew-tassk my-0 py-1" @click="openTimeSheet(task)" href="#"><i class="fa fa-history pr-2 mt-1 mb-0" aria-hidden="true"></i>Time Sheet</a>
                                                <a v-if="task.assignedById == userId || userTypeCode == 'PRM' || userTypeCode == 'CMR' || userTypeCode == 'VSA'" class="dropdown-item addnew-tassk my-0 py-1" @click="archiveTask(task)" href="#"><i class="fa fa-archive pr-2 mt-1 mb-0" aria-hidden="true"></i>Archive</a>
                                            </div>

                                            <span v-if="task.status!=null" title="Status" class="card-media-object-tag subtle float-right mr-1">{{ task.status }}</span>
                                            <span title="Created on" class="subtle float-right mr-1">{{ getTimeDuration(task.createdDt) }}</span>
                                            <div class="card-media-body-top">
                                                <span class="">
                                                    <a href="#" class="action-link" @click="viewItemDetails(task)">{{ task.title }}</a>
                                                    <i v-if="task.priority" class="fa fa-exclamation-circle pl-1 pointer" :class="priorityColour(task.priority)" :title="`${task.priority ? task.priority : 'Low'} priority`"></i>
                                                </span>
                                                <!-- <span class="pointer" v-on:click="viewReferenceLookup(task)">{{ task.title }}</span> -->
                                            </div>
                                            <div class="divider-line"></div>
                                            <div>
                                                <div class="row">
                                                    <div class="col-6 my-auto">
                                                        <a href="#" class="subtle align-middle" @click="viewProcessActivity(task)" title="Process/Activity">{{ task.activity }} <i class="fa fa-info-circle" aria-hidden="true"></i></a>
                                                    </div>
                                                    <div class="col-6">
                                                        <span class="subtle" title="Task Progression">{{ task.progressPercentage ? task.progressPercentage : 0 }}% done</span>
                                                        <b-progress height="7px" :variant="progressBarVariant(task)" v-model="task.progressPercentage"></b-progress>
                                                    </div>
                                                </div>
                                                <div class="divider-line"></div>
                                                <div v-if="(task.estimatedStartDt != null) || (task.estimatedEndDt != null)">
                                                    <div class="row">
                                                        <div class="col-6">
                                                            <span class="subtle" title="Start Date"><i class="fa fa-clock-o pr-1" aria-hidden="true"></i>{{ task.estimatedStartDt | formatDateTimeYearCheck }}</span>
                                                        </div>
                                                        <div class="col-6">
                                                            <span class="subtle float-right" :class="(status.value !== 'Closed' && status.value !== 'Completed' && status.value !== 'Cancelled') ? dueDateStyling(task.estimatedEndDt) : ''" title="Due Date"><i class="fa fa-clock-o pr-1" aria-hidden="true"></i>{{ task.estimatedEndDt | formatDateTimeYearCheck }}</span>
                                                        </div>
                                                    </div>
                                                    <div class="divider-line"></div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-12">
                                                        <div class="row card-media-object-social-list pt-1">
                                                            <div class="col-auto pl-0">
                                                                <div v-if="(task.assignedBy != null)">
                                                                    <b-badge class="task-badge" title="Assigned By"> {{ task.assignedBy }} </b-badge>
                                                                </div>
                                                            </div>
                                                            <div class="col-auto p-0">
                                                                <div v-if="(task.assignedTo != null)">
                                                                    <b-badge class="task-badge" :title="`Assigned To: ${task.assignedTo}`"> {{ task.assignedTo | getAcronym }} </b-badge>
                                                                </div>
                                                            </div>

                                                            <div v-if="(task.assignedBy != null)" class="col-4 p-0 pt-1 mr-0 ml-auto text-right">
                                                                <div v-if="(task.checklistItemCount > 0)">
<!--                                                                     <div v-if="(task.assignedBy != null)"> -->
                                                                        <a href="#"  @click="openChecklistReportPopup(task)" title="Show Checklist"><i class="fa fa-th-list action-link"> Checklist {{task.checklistItemCount}}</i></a>
<!--                                                                     </div> -->
                                                                </div>
                                                                <div v-else>
<!--                                                                     <div v-if="(task.assignedBy != null)"> -->
                                                                        <a href="#"  @click="openChecklistReportPopup(task)" title="Show Checklist"><i class="fa fa-plus-circle action-link"> Checklist</i></a>
<!--                                                                     </div> -->
                                                                </div>
                                                            </div>                                                            

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div v-if="!getTaskByStatusId(status.id).length" class="card-media mb-2">
                                    <div class="card-media-body">
                                        <div class="stage-card text-center">No Task Found</div>
                                    </div>
                                </div>
                                <div class="row" v-if="status.value==='Closed'">
                                    <div class="col-12 mt-3">
                                        <div v-for="status in taskStatus" v-if="status.value==='Cancelled'">
                                            <div class="card mb-2 b-radius-5 ">
                                                <div class="card-body px-3 py-2">
                                                    <div class="form-group mb-0">
                                                        <label class="mt-1 mb-0 font-weight-bold" for="title">{{ status.value }}</label>
                                                        <b-button class="pull-right addnew-quicktassk-btn" v-bind:class="{disabled: !elements.isDisable }" @click="quickTask(status.statusLookupId)" variant="outline-info" size="sm" v-b-modal.ermAssignments><i class="fa fa-plus"></i> Quick Task</b-button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="tasskLists" v-bind:class="{'hide': !elements.isHide }">
                                                <div v-for="task in filteredTasks">
                                                    <!-- <div v-if="task.status == status.value" :style="`border-top: 3px solid ${priorityColour(task.priority)};`" class="card-media mb-2"> -->
                                                    <div v-if="task.status == status.value" class="card-media mb-2">
                                                        <div class="card-media-body dropdown show">
                                                            <a type="button" class="float-right" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                <i class="fa fa-ellipsis-v p-1"></i>
                                                            </a>
                                                            <div class="dropdown-menu dropdown-primary" aria-labelledby="dropdownMenuLink">
                                                                <a class="dropdown-item addnew-tassk pb-1 py-1" @click="showView(task);toggleDiv()" href="#"><i class="fa fa-list-alt pr-2" aria-hidden="true"></i>Task Details</a>
                                                                <a v-if="task.assignedById == userId" class="dropdown-item addnew-tassk py-1" @click="showEdit(task);elements.taskStatusUpdate = true;toggleDiv();" href="#"><i class="fa fa-pencil pr-2"></i>Edit Task</a>
                                                                <a class="dropdown-item addnew-tassk pb-1 py-1" @click="toggleDiv();duplicateTask(task);" href="#"><i class="fa fa-copy pr-2" aria-hidden="true"></i>Duplicate Task</a>
                                                                <a v-if="task.assignedById == userId || task.assignedToId == userId" class="dropdown-item addnew-tassk my-0 py-1" @click="showStatusPopup(task)" href="#"><i class="fa fa-edit pr-2 mt-1 mb-0" aria-hidden="true"></i>Task Status Update</a>
                                                                <a v-if="task.assignedById == userId" class="dropdown-item py-1" @click="deleteTask(task.id)" v-b-modal.deleteTask href="#"><i class="fa fa-trash pr-2"></i>Delete Task</a>
                                                                <a v-if="task.assignedById == userId || task.assignedToId == userId" class="dropdown-item addnew-tassk my-0 py-1" @click="openTimeSheet(task)" href="#"><i class="fa fa-history pr-2 mt-1 mb-0" aria-hidden="true"></i>Time Sheet</a>
                                                                <a v-if="task.assignedById == userId || userTypeCode == 'PRM' || userTypeCode == 'CMR' || userTypeCode == 'VSA'" class="dropdown-item addnew-tassk my-0 py-1" @click="archiveTask(task)" href="#"><i class="fa fa-archive pr-2 mt-1 mb-0" aria-hidden="true"></i>Archive</a>
                                                            </div>
                                                            <span v-if="task.status!=null" title="Status" class="card-media-object-tag subtle float-right mr-1">{{ task.status }}</span>
                                                            <span title="Created on" class="subtle float-right mr-1">{{ getTimeDuration(task.createdDt) }}</span>

                                                            <div class="card-media-body-top">
                                                                <span class="">
                                                                    <a href="#" class="action-link" @click="viewItemDetails(task)">{{ task.title }}</a>
                                                                    <i v-if="task.priority" class="fa fa-exclamation-circle pl-1 pointer" :class="priorityColour(task.priority)" :title="`${task.priority ? task.priority : 'Low'} priority`"></i>
                                                                </span>
                                                                <!-- <span class="pointer" v-on:click="viewReferenceLookup(task)">{{ task.title }}</span> -->
                                                            </div>
                                                            <div class="divider-line"></div>
                                                            <div class="">
                                                                <div class="row">
                                                                    <!-- <div v-if="task.effort" class="col-6 my-auto">
                                                                        <span class="subtle align-middle" title="Effort">{{ task.effort }} {{ findEffortType(task.effortType) }}</span>
                                                                    </div> -->
                                                                    <div class="col-6 my-auto">
                                                                        <a href="#" class="subtle align-middle" @click="viewProcessActivity(task)" title="Process/Activity">{{ task.activity }}</a>
                                                                    </div>
                                                                    <div class="col-6">
                                                                        <span class="subtle" title="Task Progression">{{ task.progressPercentage ? task.progressPercentage : 0 }}% done</span>
                                                                        <b-progress height="7px" :variant="progressBarVariant(task)" v-model="task.progressPercentage"></b-progress>
                                                                    </div>
                                                                </div>
                                                                <div class="divider-line"></div>
                                                                <div v-if="(task.estimatedStartDt != null) || (task.estimatedEndDt != null)">
                                                                    <div class="row">
                                                                        <div class="col-6">
                                                                            <span class="subtle" title="Start Date"><i class="fa fa-clock-o pr-1" aria-hidden="true"></i>{{ task.estimatedStartDt | formatDateTimeYearCheck }}</span>
                                                                        </div>
                                                                        <div class="col-6">
                                                                            <span class="subtle float-right" :class="(status.value !== 'Closed' && status.value !== 'Completed' && status.value !== 'Cancelled') ? dueDateStyling(task.estimatedEndDt) : ''" title="Due Date"><i class="fa fa-clock-o pr-1" aria-hidden="true"></i>{{ task.estimatedEndDt | formatDateTimeYearCheck }}</span>
                                                                        </div>
                                                                    </div>
                                                                    <div class="divider-line"></div>
                                                                </div>
                                                                <div class="row">
                                                                    <div class="col-12">
                                                                        <div class="row card-media-object-social-list">
                                                                            <div class="col-6 p-0">
                                                                                <div v-if="(task.assignedBy != null)">
                                                                                    <b-badge class="task-badge" title="Assigned By"> {{ task.assignedBy }} </b-badge>
                                                                                </div>
                                                                            </div>
                                                                            <div class="col-6 p-0 text-right">
                                                                                <div v-if="(task.assignedTo != null)">
                                                                                    <b-badge class="task-badge" title="Assigned To"> {{ task.assignedTo }} </b-badge>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div v-if="!getTaskByStatusId(status.id).length" class="card-media mb-2">
                                                    <div class="card-media-body">
                                                        <div class="stage-card text-center">No Task Found</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- <nav v-if="!elements.clientTaskPage" class="app-menu-nav task-nav-menu left-side">
                        <div class="task-menu-title">
                            <label class="d-block pt-2"><i class="fa fa-home px-1"></i>Development Plan<span><a href="#" class="float-right close-comments" id="close-task-menu"><i class="fa fa-times " aria-hidden="true"></i></a></span></label>
                        </div>
                        <ul v-for="entityRecordMaster in ermLookup" :class="{ 'task-active-tab': (entityRecordMaster.id == task.entityRecordMasterId) }" class="task-menu">
                            <li>
                                <a href="#" class="task task-nav-submenu" @click="changeERMPlan(entityRecordMaster.id);">{{ entityRecordMaster.value }}</a>
                            </li>
                        </ul>
                    </nav> -->
                    <AddTaskComponent ref="addTaskRef" :elements="elements" @refresh-task="getTasks" />
                </div>
            </div>
            
        </div>
        <ActivityDetailPopup ref="activityDetailPopupRef" @closeDialog="closeDialog" />
        <ChecklistReportPopup ref="checklistReportPopupRef" :checklistTaskRef="checklistTaskRef" @updateChecklist="getTasks(task.entityRecordMasterId)" @closeDialog="closeDialog" />
        <TaskDetailPopup v-if="showDialog" ref="taskDetailPopupRef" :detailViewData="detailViewData" :referenceDetailId="referencePopupId" @closeDialog="closeDialog" />
        <TimeSheetPopup ref="timeSheetPopupRef" :taskId="taskId" @closeDialog="closeDialog" />
        <TaskStatusUpdatePopup v-if="showDialog" ref="taskSatatusUpdatePopupRef" :statusUpdate="statusUpdate" @updateParent="getTasks(task.entityRecordMasterId)" @closeDialog="closeDialog" />
        <QuickTaskPopup ref="ermAssignmentsRef" :masterData="task" :projectTypeId="this.projectTypeId" @refresh-task="getTasks($route.params.ermId)" @masterLookupRefresh="lookupData" />  
        <DeleteComponent id="deleteTask" :onYes="onYes" :returnParams="deleteParam" title="Delete Confirmation" message="Are you sure to delete Task?" />
    </div>
</template>
<script>
import iziToast from 'izitoast';
import moment from 'moment';
import { required, email, minLength, maxLength, sameAs, between, numeric } from "vuelidate/lib/validators";
import ActivityDetailPopup from '@/components/popup/itemdetail/ActivityDetailPopup.vue';
import AddTaskComponent from '@/components/common/AddTaskComponent.vue';
import QuickTaskPopup from '@/components/popup/action/QuickTaskPopup.vue';
import ChecklistReportPopup from '@/components/popup/action/ChecklistReportPopup.vue';
import Editor from '@tinymce/tinymce-vue';
import Multiselect from 'vue-multiselect';
import FunctionalReferencePopup from '@/components/popup/reference/FunctionalReferencePopup.vue';
import TaskDetailPopup from '@/components/popup/itemdetail/TaskDetailPopup.vue';
import TimeSheetPopup from '@/components/popup/action/TimeSheetPopup.vue';
import TaskStatusUpdatePopup from '@/components/popup/action/TaskStatusUpdatePopup.vue';
// import TimeSheetComponent from '@/components/common/TimeSheetComponent.vue';
import carousel from 'vue-owl-carousel';

export default {
    name: 'app',
    components: {
        ActivityDetailPopup,
        ChecklistReportPopup,
        TaskDetailPopup,
        Multiselect,
        Editor,
        FunctionalReferencePopup,
        TimeSheetPopup,
        AddTaskComponent,
        // TimeSheetComponent,
        QuickTaskPopup,
        TaskStatusUpdatePopup,
        carousel
    },
    data() {
        return {
            showOverlay: false,
            elements: {
                clientTaskPage: '',
                editMode: false,
                viewMode: false,
                viewOnlyComponent: false,
                filterMode: false,
                taskStatusUpdate: false,
                isHide: true,
                isDisable: true
            },
            carousel:false,
            showDialog: false,
            taskId: null,
            effortType: '',

            vhextAccountId: localStorage.getItem("vhextAccountId"),
            projectId: localStorage.getItem("projectId"),
            projectTypeId: localStorage.getItem("projectTypeId"),
            projId: localStorage.getItem("projId"),
            projectName: localStorage.getItem("projectName"),
            userId: localStorage.getItem("userId"),
            userTypeCode: localStorage.getItem("userTypeCode"),
            entity: 'task',

            filteredUserId: null,

            task: {
                id: null,
                taskId: '',
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                projectId: localStorage.getItem("projectId"),
                entityRecordMasterId: this.$route.params.ermId,
                userId: localStorage.getItem("userId"),
                // ermEntity: 'task',
                entity: 'task',
                title: '',
                isArchive: false,
                planId: '',
                requirementId: '',
                requirement: '',
                activityId: '',
                activity: '',
                taskTypeId: '',
                type: '',
                description: '',
                priorityId: '',
                priority: '',
                startDate: '',
                endDate: '',
                estimatedStartDt: '',
                estimatedStartDate: this.getDateValue(new Date()),
                estimatedStartTime: '',
                estimatedEndDt: '',
                estimatedEndDate: this.getDateValue(new Date()),
                estimatedEndTime: '',
                assignedById: localStorage.getItem("userId"),
                assignedTo: '',
                assignedToId: '',
                effort: '',
                effortType: '1',
                progressPercentage: 0,
                statusId: '',
                status: '',
                hasReference: '',
                hasAttachment: '',
                hasComment: '',
                hasChecklist: '',
                entityId: '',
                refErmId: '',
                createdDt: '',
                lastUpdatedDt: '',
                taskAssignment: '',
                attachments: [],
                checkListTag: '',
                response: '',
                checklistList: [],
                checklistItemCount: '',
            },

            statusUpdate: {
                id: '',
                taskId: '',
                title: '',
                entityRecordMasterId: '',
                progressPercentage: 0,
                statusId: '',
                status: '',
                activityId: '',
            },

            checklistTaskRef: {
                id: '',
                title: '',
                itemCount: '',
                checkeditemCount: '',
            },
            detailViewData: {},
            referencePopupId: 0,


            // effortTypes: [],
            effortTypes: [
                { name: "Man Hours", id: 1 },
                { name: "Man Days", id: 2 },
                { name: "Man Months", id: 3 },
                { name: "Man Years", id: 4 },
            ],

            allTasks: [],
            tasks: [],
            taskCheckList: [],
            taskCheckListAll: [],
            comments: [],
            addMoreAttachment: [],
            users: [],
            ermLookup: [],
            ermObject: {
                id:   '',
                value:   '',
            },

            taskStatus: [],
            entityRecordMasters: [],
            search: '',
            totalRows: 1,
            currentPage: 1,
            perPage: 10,
            pageOptions: [10, 15, 20],
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',
            filter: null,
            filterOn: [],
            deleteParam: {
                id: 0
            },
            toolbar: 'undo redo preview | formatselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | weblinks',
            initObj: {
                height: 300,
                branding: false,
                menubar: true,
                plugins: ['link', 'paste', 'wordcount', 'preview', 'image imagetools'],
                paste_data_images: true,
                images_upload_url: 'postAcceptor.php',
                automatic_uploads: false,
                file_picker_types: 'image',
                relative_urls: false,
                remove_script_host: false,
            },
        }
    },
    computed: {
        filteredTasks() {
            return this.tasks.filter(task => {
                return task.title.toLowerCase().includes(this.search.toLowerCase())
            })
        }
    },
    mounted() {

        this.ermObject.id = localStorage.getItem("ermId");
        this.ermObject.value = localStorage.getItem("ermTitle");

        this.setupPage();
        console.log(this.projectTypeId);
        this.lookupData();

        this.getTasks(this.$route.params.ermId);
        this.addMoreAttachment.push(this.addMoreAttachment.length + 1);
        let erdId = localStorage.getItem("erdId"); 
        if(erdId != null && erdId.length >0){
            this.fromActionItem(erdId);
        }


        /*this.elements.editMode = false;
        this.elements.viewMode = true;
        this.elements.viewOnlyComponent = true;*/



        /* this.elements.viewMode = false;
         this.elements.visibileChecklist = false;
         this.elements.visibileCommAccordian = false;
         this.elements.visibileTimeSheet = false;
         this.elements.viewOnlyComponent = true;*/
    },
    validations: {
        task: {
            title: {
                required,
                minLength: minLength(2),
                maxLength: maxLength(100)
            },
            statusId: {
                required
            },
            progressPercentage: {
                between: between(0, 100),
                numeric
            },
            entityRecordMasterId: {
                required
            }
        },
    },
    methods: {
        fromActionItem(erdId){
            this.$nextTick(()=> {                
                this.$refs.addTaskRef.viewTask(erdId);
            })
            this.toggleDiv();
            localStorage.setItem("erdId", null);
        },
        toggleDiv: function() {
            this.elements.isHide = !this.elements.isHide;
            this.elements.isDisable = !this.elements.isDisable;
        },
        setupPage: function() {
            console.log(this.$route.name);
            if (this.$route.name == 'clitask' || this.$route.name == 'usertask') {
                this.elements.clientTaskPage = true;
            } else this.elements.clientTaskPage = false;
        },
        getTasks: function(ermId) {
            if (this.elements.clientTaskPage && this.projectId) {
                let data = {
                    vhextAccountId: localStorage.getItem("vhextAccountId"),
                    projectId: localStorage.getItem("projectId"),
                    userId: localStorage.getItem("userId"),
                    isArchive: false
                }
                console.log(data);
                axios.post(this.$store.getters.getAPIBasePath + '/task/clienttask/list', data)
                    .then((response) => {
                        let result = response.data;
                        this.tasks = result.taskList;
                        this.allTasks = this.tasks;
                        console.log(this.tasks);
                    })
            }
            else if (!this.projectId) {
                let data = {
                    vhextAccountId: localStorage.getItem("vhextAccountId"),
                    projectId: localStorage.getItem("projectId"),
                    userId: localStorage.getItem("userId"),
                    isArchive: false
                }
                axios.post(this.$store.getters.getAPIBasePath + '/task/usertask/list', data)
                    .then((response) => {
                        let result = response.data;
                        this.tasks = result.taskList;
                        this.allTasks = this.tasks;
                        console.log(this.tasks);
                    })
            }
        },
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },
        lookupData: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
                projectId: this.projectId,
                projectTypeId: this.projectTypeId,
                userId: this.userId,
                entity: this.entity,
            }
            axios.post(this.$store.getters.getAPIBasePath + '/task/lookup/all', data)
                .then((response) => {
                    let result = response.data;
                    this.users = result.userLookup;
                    this.taskStatus = result.statusLookup;
                    this.ermLookup = result.entityERMLookup;

                    this.task.statusId = this.taskStatus[0].statusLookupId;
                    this.carousel = true;
                })
        },
        ermFilter: function(ermData) {
            console.log(ermData);
            if (ermData.id !== null && ermData.id) {
                this.changeERMPlan(ermData.id);
                this.setERMData(ermData.id,ermData.value);

            } else if (ermData == 'all') {
                this.tasks = this.allTasks;
            }
            console.log(ermData);
        },
        showView: function(data) {
            this.$nextTick(()=> {                
                this.$refs.addTaskRef.showView(data);
            })
        },
        showEdit: function(data) {
            this.$nextTick(()=> {                
                this.$refs.addTaskRef.showEdit(data);
            })
        },
        duplicateTask: function(data) {
            this.$nextTick(()=> {                
                this.$refs.addTaskRef.duplicateTask(data);
            });
        },
        changeERMPlan: function(ermId) {
            if (this.task.entityRecordMasterId != ermId) {
                this.task.entityRecordMasterId = ermId;
                this.getTasks(ermId);
                // this.$router.push('/tskbrd/' + ermId);
            }
        },
        archiveTask: function(item) {
            let data = {
                id: item.id,
                entity: this.entity,
                columnName: 'is_archive',
                stateValue: (item.isArchive == true ? false : true)
            }
            console.log(data)
            axios.post(this.$store.getters.getAPIBasePath + '/dynamicsql/recordstate/update', data)
                .then((response) => {
                    let result = response.data;
                    if (result.status == "CREATED") {
                        iziToast.success({
                            title: 'Task Archived',
                            position: 'topRight'
                        });
                        this.getTasks(this.task.entityRecordMasterId);
                    }
                })
        },
        deleteTask: function(id) {
            console.log(id)
            this.deleteParam.id = id;
        },
        onYes: function() {
            let data = {
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                projectId: localStorage.getItem("projectId"),
                entity: this.entity,
                erdId: this.deleteParam.id,               
            }
            console.log(data);

            axios.post(this.$store.getters.getAPIBasePath + '/task/delete/', data)
                .then(response => {
                    var result = response.data;
                    if (result.status == "OK") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.getTasks(this.task.entityRecordMasterId);
                    }
                })
                .catch(error => {
                    console.log(error);
                });
        },
        getTaskByStatusId(statusId) {
            return this.tasks.filter(task => task.statusId == statusId);
        },
        teamMemberFilter: function(userId) {
            this.elements.filterMode = true;
            this.tasks = this.allTasks;
            if (this.tasks != null) {
                var userTask = this.tasks;
                this.tasks = userTask.filter(function(e) {
                    return e.assignedToId == userId || e.assignedById == userId;
                });
            }
        },
        clearFilter: function() {
            this.tasks = this.allTasks;
            this.filteredUserId = null;
            this.elements.filterMode = false;
        },
        progressBarVariant: function(task) {
            if (task.progressPercentage != null) {
                if (task.progressPercentage < 25) {
                    return 'danger';
                } else if (task.progressPercentage < 50) {
                    return 'warning';
                } else if (task.progressPercentage < 75) {
                    return 'info';
                } else if (task.progressPercentage < 100) {
                    return 'success';
                } else if (task.progressPercentage == 100) {
                    return 'primary';
                }
            }
        },
        gatGanttChart: function() {
            if (this.task.entityRecordMasterId !== null) {
                this.$router.push('/taskgantt/' + this.task.entityRecordMasterId);
            }
        },
        dueDateStyling(date){
            date = new Date(date);
            let today = new Date();

            if (date && date !== null) {

                if (this.getDateValue(date) == this.getDateValue(today) && (date > today)) {        
                    return 'text-warning';
                }
                else if (date < today) {        
                    return 'text-danger';
                }
                else if (date > today) {        
                    return 'text-success';
                }
            }
        },
        priorityColour(priority) {
            if (priority && priority == 'High') {
                return 'text-danger';
            }
            if (priority && priority == 'Medium') {
                return 'text-warning';
            }
            else {
                return 'text-success';
            }
        },
        quickTask: function(statusId) {
            this.task.statusId = statusId;
            this.task.entityRecordMasterId = this.$route.params.ermId;
            this.task.assignedToId = this.filteredUserId ? this.filteredUserId : '';
            this.$refs.ermAssignmentsRef.onRefDataChange('Quick Task');
        },
        viewItemDetails: function(data) {
            this.detailViewData = data;
            this.referencePopupId = data.id;
            this.showDialog= true;
            this.$nextTick(()=> {
                this.$refs.taskDetailPopupRef.getDetails(this.referencePopupId, null);
            })
        },
        viewProcessActivity: function(data) {
            this.showDialog= true;
            this.$nextTick(()=> {
                this.$refs.activityDetailPopupRef.getDetails(data.processId, data.activityId);
            })
        },
        showStatusPopup: function(data) {
            console.log(data);
            if (data && data !== null) {
                this.statusUpdate.id = data.id;
                this.statusUpdate.taskId = data.taskId;
                this.statusUpdate.title = data.title;
                this.statusUpdate.entityRecordMasterId = data.entityRecordMasterId;
                this.statusUpdate.statusId = data.statusId;
                this.statusUpdate.status = data.status;
                this.statusUpdate.activityId = data.activityId;
                this.statusUpdate.progressPercentage = data.progressPercentage ? data.progressPercentage : 0;

                this.showDialog = true;
                this.$nextTick(()=> {
                    this.$bvModal.show('taskSatatusUpdatePopup');
                })
            }
        },
        openTimeSheet: function(data) {
            this.taskId = data.id;
            this.showDialog= true;
            this.$nextTick(()=> {
                this.$refs.timeSheetPopupRef.openDialog();
            })
        },
        openChecklistReportPopup(data) {
            
            this.taskId = data.id;
            let refEntity = 'task';
            this.checklistTaskRef.title = data.title +  ' - Checklist';
            // alert(this.checklistTaskRef.title);
            this.$refs.checklistReportPopupRef.getChecklist(this.taskId, refEntity);
        },
        closeDialog: function() {
            console.log('closed');
            this.showDialog = false;
            // this.clearData();
        },
        setERMData: function(ermId,ermTitle) {
            localStorage.setItem('ermId', ermId);
            localStorage.setItem('ermTitle', ermTitle);
        },

        redirectPage: function() {
            if (this.ermObject.id != null && this.ermObject.id > 0) {
                this.$router.push('/task/' + this.ermObject.id);
            } else {
                this.$router.push('/dvpmas/list');
            }
        },
        // findEffortType(typeId) {
        //     let type = this.effortTypes.find(eff => eff.id == typeId);
        //     return type ? type.name : '';
        // }
        // function ddlToolTipsRegion(ddlRegion) {  
        //     if (ddlRegion.value == 0) {  
        //         ddlRegion.title = "";  
        //     } else {  
        //         ddlRegion.title = "Selected Region is : " + ddlRegion.options[ddlRegion.selectedIndex].text;  
        //     }  
        // }  

    }
}
</script>