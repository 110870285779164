<template>
    <div>
        <div class="fixed-inner-header">
            <div class="container-fluid">
                <div class="row inner-header pt-2">
                    <div class="col-12">
                        <div class="row">
                            <div class="col-lg-8 col-sm-6">
                                <h4 class="pl-2 mb-0 fs-16">Task By Plan Report</h4>
                            </div>
                            <div class="col-lg-4 col-sm-6">
                                <nav class="breadcrumb-container d-sm-block d-lg-inline-block float-right" aria-label="breadcrumb">
                                    <ol class="breadcrumb pt-0 mb-0">
                                        <!-- top to bottom -->
                                        <li class="breadcrumb-item">
                                            <a href="javascript:" @click.prevent="getPrevUrl()"><i class="fa fa-reply pr-2 action-link" aria-hidden="true"></i>Back</a>
                                        </li>
                                        <li class="breadcrumb-item">
                                            <a href="#" @click.prevent="print" title="Print"><i class="fa fa-print action-link"></i></a>
                                        </li>
                                        <li class="breadcrumb-item" title="Download">
                                            <a href="#" @click.prevent="print"><i class="fa fa-download action-link"></i></a>
                                        </li>
                                        <li v-if="projId" class="breadcrumb-item">
                                            <h4 class="pl-2 mb-0 fs-14 text-right">#{{this.projId}} - {{this.projectName}}</h4>
                                        </li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                        <div class="separator mb-2"></div>
                    </div>
                </div>
                <div id="printMe" class="row">
                    <div class="col-12 col-lg-12 col-xl-12 report-tables">
                        <b-card no-body :class="{'mb-2' : elements.filterVisible}">
                            <div class="landing-menu-switch pointer" v-b-toggle.accordion-ett>
                                <label v-if="elements.filterVisible" class="my-2 py-1">Hide</label>
                                <label v-else class="my-2 py-1">Filter</label>
                            </div>
                            <b-collapse id="accordion-ett" v-model="elements.filterVisible" accordion="my-accordion"  role="tabpanel">
                                <b-card-body class="p-2">
                                    <div class="comments-titel">
                                        <label class="d-block mt-0 mb-0"><i class="fa fa-filter pr-2" aria-hidden="true"></i>Filter</label>
                                    </div>
                                    <div class="comments-cox mt-2">
                                        <div class="row m-0">
                                            <!-- <div class="col-4">
                                                <div class="form-group">
                                                    <label>From Date</label>
                                                    <input type="date" class="form-control" v-model="fromDate" id="dateWD" >
                                                </div>
                                            </div>
                                            <div class="col-4">
                                                <div class="form-group">
                                                    <label>Till Date</label>
                                                    <input type="date" class="form-control" v-model="tillDate">
                                                </div>
                                            </div> -->
                                            <div class="col-4">
                                                <div class="form-group">
                                                    <label for="preparedBy">Task Plan</label>
                                                    <select v-model="ermId" class="form-control" >
                                                        <option value="">All Plans</option>
                                                        <option v-for="(info, index) in ermLookup" :value="info.id" :key="index">{{info.value}}</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row m-0">
                                            <div class="col-12">
                                                <button type="submit" @click="filterReport" class="sbtn btn btn-primary mb-3 mr-2 pt-1 pb-1 pl-3 pr-3 mt-1"><i class="fa fa-filter pr-2" aria-hidden="true"></i>Filter</button>
                                                <button type="submit" @click="closeFilter" class="sbtn btn btn-primary mb-3 mr-2 pt-1 pb-1 pl-3 pr-3 mt-1 task-close-btn"><i class="fa fa-times pr-2"></i>Close </button>
                                                <a href="javascript:" title="Reset Filter" @click="clearData"><i class="fa fa-refresh" aria-hidden="true"></i></a>
                                            </div>
                                        </div>
                                    </div>
                                </b-card-body>
                            </b-collapse>
                        </b-card>
                        <div v-if="!elements.filterVisible" class="card mb-2">
                            <div class="card-body p-3">
                                <div class="row">
                                    <div class="col-12 col-md-12">
                                        <div class="panel panel-default">
                                            <div class="panel-body">
                                                <div class="dl-horizontal">
                                                    <div class="form-row">
                                                        <div class="col">
                                                            <div class="row">
                                                                <div class="col-12 col-md-5 pr-0">
                                                                    <label class="mb-0 p-1 pt-2 pb-2 d-block title-block">Task Plan</label>
                                                                </div>
                                                                <div class="col-12 col-md-7 pl-0">
                                                                    <label class="p-2 mb-0 d-block header-details">{{ ermTitle }}</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col">
                                                            <div class="row">
                                                                <div class="col-12 col-md-5 pr-0">
                                                                    <label class="mb-0 p-1 pt-2 pb-2 d-block title-block">Report By</label>
                                                                </div>
                                                                <div class="col-12 col-md-7 pl-0">
                                                                    <label class="p-2 mb-0 d-block header-details">{{ preparedBy }}</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col">
                                                            <div class="row">
                                                                <div class="col-12 col-md-5 pr-0">
                                                                    <label class="mb-0 p-1 pt-2 pb-2 d-block title-block">Report Date</label>
                                                                </div>
                                                                <div class="col-12 col-md-7 pl-0">
                                                                    <label class="p-2 mb-0 d-block header-details">{{ preparedDate | formatDate }}</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="card mb-2">
                            <div class="card mb-2">
                                <div class="card-body p-3">
                                    
                                    <div class="row">
                                        <div class="col-12 col-lg-12 col-xl-12 position-relative">
                                            <div class="form-row mr-0">
                                                <p class="col-3 contact-title font-weight-bold mr-2 py-2"><span>Task List</span></p>
                                                <!-- <label class="font-weight-bold p-2 ml-3 mt-1" for="title">Task Stats</label> -->
                                                <div v-for="status in taskByStatus" class="col-9 col-md-2 mb-0 pr-1 text-center float-right">
                                                    <div class="card mb-2 b-radius-5">
                                                        <div class="card-body px-3 py-2">
                                                            <div class="form-group mb-0">
                                                                <label class="mt-1 mb-0 font-weight-bold" for="title">{{ status.status }} 
                                                                    <b-badge class="ml-2" title="Tasks">{{ getItemCount(status.statusId) }}</b-badge>
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>                                                            
                                    <div class="row">
                                        <div class="col-12 col-md-12">
                                            <div class="data-table-rows  data-tables-hide-filter">
                                                <b-table show-empty small stacked="md" sort-icon-left :busy="isBusy" :items="taskReportList" :fields="taskFields" responsive="sm" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection">
                                                    <template #cell(index)="data">
                                                        {{ data.index + 1 }}
                                                    </template>
                                                    <template v-slot:cell(title)="data">
                                                        <a class="tooltiptext action-link" href="#" @click="viewItemDetails(data.item)">{{data.value}}</a>
                                                    </template>
                                                    <template v-slot:cell(effort)="data">
                                                        {{ workDuration(data.item.workHour,data.item.workMinute,data.item.workDay) }}
                                                    </template>
                                                    <template v-slot:cell(actEffortMins)="data">
                                                        {{ data.value ? convertToDaysAndHours(data.value) : '' }}
                                                    </template>
                                                    <template v-slot:cell(duration)="data">
                                                        {{ data.value ? data.value + ' Day(s)' : '' }}
                                                    </template>
                                                    <template v-slot:cell(progressPercentage)="data">
                                                        <span title="Performance">{{ data.value ? data.value : 0 }}%</span>
                                                        <b-progress height="7px" :value="data.value" variant="secondary"></b-progress>
                                                    </template>
                                                    <template v-slot:cell(empPerformance)="data">
                                                        <label class="m-0 d-flex justify-content-between" title="Performance">
                                                            <span>{{ getPerformanceRemark(calculatePerformance(data.item.estEffortMins,data.item.actEffortMins)) }}</span>
                                                            <span class="float-right">{{ calculatePerformance(data.item.estEffortMins,data.item.actEffortMins) }}%</span>
                                                        </label>
                                                        <b-progress height="7px" :value="calculatePerformance(data.item.estEffortMins,data.item.actEffortMins)" :variant="performanceProgressVariant(calculatePerformance(data.item.estEffortMins,data.item.actEffortMins))"></b-progress>
                                                    </template>
                                                    <template v-slot:empty="scope">
                                                        <div class="text-center">No Task found</div>
                                                    </template>
                                                </b-table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <TaskDetailPopup v-if="showDialog" ref="taskDetailPopupRef" :attachmentData="attachmentData"  @closeDialog="showDialog=false;" />
    </div>
</template>
<script>
import TaskDetailPopup from '@/components/popup/itemdetail/TaskDetailPopup.vue';
import iziToast from 'izitoast';
import moment from 'moment';
export default {
    name: 'app',
    components: {
        TaskDetailPopup
    },
    data() {
        return {
            elements: {
                filterVisible: true,
            },
            showDialog: false,
            projId: localStorage.getItem("projId"),
            projectName: localStorage.getItem("projectName"),

            vhextAccountId: localStorage.getItem("vhextAccountId"),
            projectId: localStorage.getItem("projectId"),
            userId: localStorage.getItem("userId"),
            preparedBy: this.$store.getters.currentUser.name,
            preparedDate: this.getDateValue(new Date()),

            entityRecordMasterId: '',
            ermId: '',

            attachmentData: {},

            isBusy: false,
            ermLookup: [],

            taskCount: 0,
            filteredTaskReports: [],
            taskByStatus: [],

            taskReports: [],
            taskFields: [
                { key: 'index', label: 'SN', sortable: true, sortDirection: 'desc' },
                { key: 'title', label: 'Task Title', tdClass: 'table-title-col-size', sortable: true, sortDirection: 'desc' },
                { key: 'assignedTo', label: 'Assigned To', tdClass: 'table-cw-400', sortDirection: 'desc' },                
                { key: 'activity', label: 'Activity', sortable: true, sortDirection: 'desc' },
                { key: 'estimatedStartDt', label: 'Start Time', formatter: 'formatDateTimeAmPm', sortable: true, sortDirection: 'desc' },
                { key: 'estimatedEndDt', label: 'End Time', formatter: 'formatDateTimeAmPm', sortable: true, sortDirection: 'desc' },
                { key: 'duration', label: 'Period', tdClass: 'table-cw-300',  sortDirection: 'desc' },
                { key: 'effort', label: 'Est. Effort', tdClass: 'table-cw-300',  sortDirection: 'desc' },
                { key: 'actEffortMins', label: 'Act. Effort', tdClass: 'table-cw-300',  sortDirection: 'desc' },
                { key: 'progressPercentage', label: 'Task Progression', sortDirection: 'desc' },
                { key: 'status', label: 'Status', sortable: true, tdClass: 'table-cw-100', sortDirection: 'desc' },
                { key: 'empPerformance', label: 'Emp. Efficiency', sortDirection: 'desc' },

            ],

            totalRows: 1,
            currentPage: 1,
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',
            filter: null,
            filterOn: [],
        }
    },
    computed: {
        ermTitle() {
            if (this.ermLookup.length>0) {
                return this.entityRecordMasterId ? this.ermLookup.find(data => data.id == this.entityRecordMasterId).value : 'All Plans';
            }
        },
        taskByStatusList() {
            if (this.taskByStatus.length>0) {
                if (this.entityRecordMasterId && this.entityRecordMasterId != '') {
                    return this.taskByStatus.filter((data) => data.entityRecordMasterId == this.entityRecordMasterId);
                } else {
                    return this.taskByStatus;
                }
            } else {
                return [];
            }
        },

        taskReportList() {
            if (this.taskReports.length>0) {
                if (this.entityRecordMasterId && this.entityRecordMasterId != '') {
                    this.filteredTaskReports = this.taskReports.filter((data) => data.entityRecordMasterId == this.entityRecordMasterId);
                    console.log("flt2",this.filteredTaskReports);
                    return this.filteredTaskReports;
                } else {
                    this.filteredTaskReports = this.taskReports;
                    console.log("flt1",this.filteredTaskReports);
                    return this.filteredTaskReports;
                }
            } else {
                return [];
            }
        },


    },
    mounted() {
        localStorage.removeItem("pageRedirectURL");
        this.getTaskReport();
        this.lookupData();
    },
    methods: {
        getTaskReport: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
                projectId: this.projectId,
                entityRecordMasterId: this.ermId,
            }
            this.entityRecordMasterId = this.ermId;
            this.isBusy = true;
            axios.post(this.$store.getters.getAPIBasePath + '/task/reportbyplan/list', data)
                .then((response) => {
                    this.isBusy = false;
                    let result = response.data;
                    this.taskReports = result.taskReportByPlan;
                    this.taskByStatus = result.taskByStatus;
                    console.log("TSK",this.taskReports);
                    console.log("TBS",this.taskByStatus);
                    this.totalRows = this.taskReports.length;

                })
                .catch(error => {
                    this.isBusy = false;
                    iziToast.error({
                       title: 'Error',
                       message: 'Error',
                       position: 'topRight'
                   });
                })
        },
        lookupData: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
                projectId: this.projectId,
                userId: this.userId,
                entity: 'development_plan_master'
            }
            axios.post(this.$store.getters.getAPIBasePath + '/lookup/entityerm', data)
                .then((response) => {
                    let result = response.data;
                    this.ermLookup = result.entityERMLookup;
                })
        },
        viewItemDetails: function(data) {
            this.attachmentData.refEntity = 'task';
            this.attachmentData.refRecordId = data.id;
            this.showDialog = true;
            this.$nextTick(()=> {
                this.$refs.taskDetailPopupRef.getDetails(data.id, 'task');
            })
        },
        workDuration(workHour, workMinute, workDay) {
            if (workDay || workHour || workMinute) {
                return `${workDay ? workDay+' days' : ''} ${workHour ? workHour+' hrs' : ''} ${workMinute ? workMinute+' mins' : ''}`;
            }
            else return '';
        },
        calculatePerformance(estEffort, actEffort) {
            return actEffort ? Math.round((estEffort / actEffort) * 100) : 0;
        },
        getPerformanceRemark(percentage) {
            let output = '';
            if (percentage) {
                if (percentage <= 80)
                    output = 'Unacceptable';
                else if (percentage <= 90)
                    output = 'Needs Improvement';
                else if (percentage <= 100)
                    output = 'Meets Expectations';
                else if (percentage <= 130)
                    output = 'Exceeds Expectations';
                else
                    output = 'Outstanding';
            }
            return output;
        },
        getItemCount: function(statusId) {
            let output = '';
            if (this.entityRecordMasterId) {

                output = this.filteredTaskReports.filter((itemData)=> itemData.statusId==statusId && itemData.entityRecordMasterId==this.entityRecordMasterId).length;
            }else {
                output = this.filteredTaskReports.filter((itemData)=> itemData.statusId==statusId).length;
            }
            return output;
        },        
        filterReport() {
            this.entityRecordMasterId = this.ermId;
        },
        closeFilter: function() {    
            this.elements.filterVisible = false;
            this.ermId = this.entityRecordMasterId;
        },
        clearData: function() {
            this.entityRecordMasterId = '';
            this.ermId = '';
            this.getTaskReport();
        },
        print: function() {
            this.$htmlToPaper('printMe');
        },

    }
}
</script>