<template>
    <div>
        <MeetingPreparationMaster :ermMasterInfo="ermMasterInfo"></MeetingPreparationMaster>
    </div>
</template>
<script>
import iziToast from 'izitoast';
import MeetingPreparationMaster from '@/components/blocks/erm/ERMMaster.vue'
export default {
    name: 'app',
    components: {
        MeetingPreparationMaster,
    },
    data() {
        return {
            elements: {
                showDetail: false,
            },
            ermMasterInfo: {
                title: "Meeting Preparation Master",
                entity: 'meeting_preparation_master',
                refEntity: 'meeting_preparation',
                ermListRedirectURL: '/mtpmas/list',
                erdRedirectURL: '/meetingpreparation/'
            }
        }
    },
    mounted() {},
    methods: {}
}
</script>