<template>
    <div class="container-fixed">
        <b-overlay :show="showOverlay" opacity="0.4">
            <div class="form-row">
                <div class="col-2">
                    <div class="card">
                        <div class="card-body p-3">
                            <div class="row">
                                <div class="col-sm-12 mb-1">
                                    <div class="card p-1">
                                        <p class="chart-title text-left pl-1 mb-1">Users By Type
                                            <span class="badge badge-light float-right pointer py-1 px-2" title="Total Users">{{ userCount }}</span>
                                        </p>
                                        <div class="separator mb-1"></div>
                                        <div class="card-body p-1 text-center">
                                            <DoughnutChart :styles="myStyles" :chartdata="userByTypeData" :options="chartOptionsNoLegend" class="" :key="pieChartKey"/>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-12 mb-1">
                                    <div class="card p-1">
                                        <p class="chart-title text-left pl-1 mb-1">Projects 
                                            <span class="badge badge-light float-right pointer py-1 px-2" title="Total Projects">{{ projectCount }}</span>
                                        </p>
                                        <div class="separator mb-1"></div>
                                        <div class="card-body p-1 text-center">
                                            <PieChart v-if="projectCount" :styles="myStyles" :chartdata="projectByStatusData" :options="chartOptionsNoLegend" class="" :key="pieChartKey"/>
                                            <div v-else class="my-4 py-2">
                                                No Project Found
                                            </div>
                                        </div>
                                    </div>
                                </div>                                
                                <div class="col-sm-12 mb-1">
                                    <div class="card p-1">
                                        <p class="chart-title text-left pl-1 mb-1">Clients
                                            <span class="badge badge-light float-right pointer py-1 px-2" title="Total Clients">{{ clientCount }}</span>
                                        </p>
                                        <div class="separator mb-1"></div>
                                        <div class="card-body p-1 text-center">
                                            <DoughnutChart v-if="clientCount" :styles="myStyles" :chartdata="clientByTypeData" :options="chartOptions" class="" :key="pieChartKey"/>
                                            <div v-else class="my-4 py-2">
                                                No Client Found
                                            </div>
                                        </div>
                                    </div>
                                </div>                                
                                <div class="col-sm-12 mb-1">
                                    <div class="card p-1">
                                        <p class="chart-title text-left pl-1 mb-1">Emp. By Gender
                                            <span class="badge badge-light float-right pointer py-1 px-2" title="Distribution By Gender : Total Employees">{{ employeeCount }}</span>
                                        </p>
                                        <div class="separator mb-1"></div>
                                        <div class="card-body p-1 text-center">
                                            <DoughnutChart :styles="myStyles" :chartdata="genderDistributionData" :options="chartOptions" class="" :key="pieChartKey"/>
                                        </div>
                                    </div>
                                </div>                                
                                <div class="col-sm-12 mb-1">
                                    <div class="card p-1">
                                        <p class="chart-title text-left pl-1 mb-1">Emp. By Work Type 
                                            <span class="badge badge-light float-right pointer py-1 px-2" title="Total Employees">{{ employeeCount }}</span>
                                        </p>
                                        <div class="separator mb-1"></div>
                                        <div class="card-body p-1 text-center">
                                            <PieChart :styles="myStyles" :chartdata="workTypeChartData" :options="chartOptionsNoLegend" class="" :key="pieChartKey"/>
                                        </div>
                                    </div>
                                </div>                                
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-10">
                    <div class="form-row mb-2">
                        <div class="col-9">
                            <div class="card">
                                <div class="card-body p-3">
                                    <div class="form-row">
                                        <div class="col-md-3 col-xl-2">
                                            <div class="card p-1">
                                                <p class="chart-title text-left pl-1 mb-1">
                                                    Resource Pool
                                                    <span class="badge badge-light float-right pointer py-1 px-2" title="Total Users">{{ resourceCount }}</span>
                                                </p>
                                                <div class="separator mb-1"></div>
                                                <div class="card-body py-1 text-center">
                                                    <DoughnutChart v-if="resourceCount" :styles="myStyles" :chartdata="resourcePoolData" :options="chartOptionsNoLegend" :key="pieChartKey"/>
                                                    <div v-else class="my-4 py-2">
                                                        No Resource Found
                                                    </div>                                            
                                                </div>
                                            </div>
                                        </div>                                        
                                        <div class="col-md-3 col-xl-5">
                                            <div class="card p-1">
                                                <p class="chart-title text-left pl-1 mb-1">Age Distribution                                                    
                                                    <span class="badge badge-light float-right pointer py-1 px-2" title="Distribution By Age : Total Employees">{{ employeeCount }}</span></p>
                                                <div class="separator mb-1"></div>
                                                <div class="card-body p-1 text-center">
                                                    <DoughnutChart :styles="myStyles" :chartdata="ageDistributionData" :options="chartOptions" class="" :key="pieChartKey"/>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-6 col-xl-5">
                                            <div class="card p-1">
                                                <p class="chart-title text-left pl-1 mb-1">
                                                    Leaves By Type
                                                    <span class="badge badge-light float-right pointer py-1 px-2" title="Distribution By Leave Type : Total Leaves">{{ leaveCount }}</span>
                                                </p>
                                                <div class="separator mb-1"></div>
                                                <div class="card-body p-1 text-center">
                                                    <DoughnutChart :styles="myStyles" :chartdata="employeeLeaveByTypeData" :options="chartOptions" class="" :key="pieChartKey"/>
                                                </div>                                        
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-3">
                            <div class="card">
                                <div class="card-body px-0 pb-0 pt-1" >
                                    <div class="row no-gutters">
                                        <div class="col-md-4 col-xl-6">
                                            <p class="chart-title text-left pl-2 mb-0 mt-3 pb-0">Attendance Today</p>
                                        </div>
                                        <div class="col-md-6 col-xl-6 pt-2 pr-3 text-right">
                                            <input type="date" v-model="selectedDate" @keyup.enter="getEmployeeAttendanceSummaryByDate" class="">
                                        </div>
                                    </div>
                                    <div class="separator mb-1 mx-1 pt-1"></div>
                                    <!-- <b-tabs content-class="mt-2" lazy>
                                        <b-tab title="Attendance Today"> -->
                                            <div class="col-md-12 col-xl-12">
                                                <div class=" p-1 mb-3">
                                                    <div class="col-sm-12 text-center my-auto mx-auto">
                                                        <div class="row">
                                                            <div class="col-3 card p-1">
                                                                <div class="text-primary mb-0">Total</div>
                                                                <div class="">{{ employeeAttendanceSummary.empCount }}</div>
                                                            </div>
                                                            <div class="col-3 card p-1">
                                                                <div class="text-success mb-0">Present</div>
                                                                <div class="">{{ employeeAttendanceSummary.totalPresent }}</div>
                                                            </div>
                                                            <div class="col-3 card p-1">
                                                                <div class="text-warning mb-0">Half Day</div>
                                                                <div class="">{{ employeeAttendanceSummary.totalHalfDayLeave }}</div>
                                                            </div>
                                                            <div class="col-3 card p-1">
                                                                <div class="text-danger mb-0">Leave</div>
                                                                <div class="">{{ employeeAttendanceSummary.totalFullDayLeave }}</div>
                                                            </div>
                                                        </div>
                                                        <!-- <hr class="m-1"> -->
                                                        <div class="mb-0 mt-1 pb-1">Attendees - <span title="Performance">{{attendeesPercentage}} %</span></div>
                                                        <b-progress class="" height="10px" :value="attendeesPercentage" :variant="dailyAttendanceProgressVariant(attendeesPercentage)"></b-progress>
                                                    </div>
                                                </div>
                                            </div>
                                        <!-- </b-tab>
                                    </b-tabs> -->
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-row mb-2">
                        <div class="col-12">
                            <div class="card">
                                <div class="card-body p-3">
                                    <div class="form-row">
                                        <div class="col-md-4 col-xl-4">
                                            <div class="card p-3">
                                                <p class="chart-title text-left pl-1 mb-1">Employee By Department</p>
                                                <div class="separator mb-1"></div>
                                                <div class="card-body p-0">
<!--                                                     <BarChart :chartdata="employeeByDepartmentData" :options="barChartOptions" class="d-block"style="position: relative; height:40vh;" :key="barChartKey"/> -->
                                                    <BarChart :chartdata="employeeByDepartmentData" :options="barChartOptions" class="d-block w-100-h-350" :key="barChartKey"/>

                                                </div>
                                            </div>
                                        </div>    
                                        <div class="col-md-4 col-xl-4">
                                            <div class="card p-3">
                                                <p class="chart-title text-left pl-1 mb-1">Employee By Job Category</p>
                                                <div class="separator mb-1"></div>
                                                <div class="card-body p-0">
                                                    <BarChart :chartdata="employeeByJobCategoryData" :options="barChartOptions" class="d-block w-100-h-350" :key="barChartKey"/>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-4 col-xl-4">
                                            <div class="card p-3">
                                                <p class="chart-title text-left pl-1 mb-1">Employee By Joining Month</p>
                                                <div class="separator mb-1"></div>
                                                <div class="card-body p-0">
                                                    <BarChart :chartdata="empByMonthData" :options="barChartOptions" class="d-block w-100-h-350" :key="barChartKey"/>
                                                </div>
                                            </div>
                                        </div>

<!--                                         <div class="col-md-6 col-xl-6">
                                            <div class="card p-3">
                                                <p class="chart-title text-left pl-1 mb-1">Employee By Joining Month</p>
                                                <div class="separator mb-1"></div>
                                                <div class="card-body p-0">
                                                    <BarChart :chartdata="empByMonthData" :options="barChartOptions" class="d-block w-100-h-350" :key="barChartKey"/>
                                                </div>
                                            </div>
                                        </div>
 -->
                                    </div>
                                </div>
                            </div>
                        </div>                        
                    </div>                    
                    <div class="form-row">
                        <div class="col-12">
                            <div class="card">
                                <div class="card-body p-3">
                                    <div class="form-row">
                                        <div class="col-md-4 col-xl-4">
                                            <div class="card p-3">
                                                <p class="chart-title text-left pl-1 mb-1">Employee Attendance Performance</p>
                                                <div class="separator mb-1"></div>
                                                <div class="card-body p-0">
                                                    <BarChart :chartdata="empAttendanceSummaryByDate" :options="barChartOptions" class="d-block w-100-h-350" :key="barChartKey"/>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-4 col-xl-4">
                                            <div class="card p-3">
                                                <p class="chart-title text-left pl-1 mb-1">Employee Utilization</p>
                                                <div class="separator mb-1"></div>
                                                <div class="card-body p-0">
                                                    <HorizontalBarChart :chartdata="empUtilizationByDate" :options="barChartOptions" class="d-block w-100-h-350" :key="barChartKey"/>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-4 col-xl-4">
                                            <div class="card p-3">
                                                <p class="chart-title text-left pl-1 mb-1">Employee Utilization By Dept.</p>
                                                <div class="separator mb-1"></div>
                                                <div class="card-body p-0">
                                                    <!-- <BarChart :chartdata="empByMonthData" :options="barChartOptions" class="d-block w-100-h-350" :key="barChartKey"/> -->
                                                    <HorizontalBarChart :chartdata="employeeUtilizationData" :options="barChartOptions" class="d-block w-100-h-350" :key="barChartKey"/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="form-row">
                        <div class="col-12">
                            <div class="card">
                                <div class="card-body p-3">
                                    <div class="form-row">
                                        <div class="col-md-3 col-xl-3">
                                            <div class="card p-3">
                                                <p class="chart-title text-left pl-1 mb-1">Salary By Month</p>
                                                <div class="separator mb-1"></div>
                                                <div class="card-body p-0">
                                                    <HorizontalBarChart :chartdata="salaryByMonthData" :options="barChartOptions" class="d-block w-100-h-350" :key="barChartKey"/>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-3 col-xl-3">
                                            <div class="card p-3">
                                                <p class="chart-title text-left pl-1 mb-1">Asset Summary</p>
                                                <div class="separator mb-1"></div>
                                                <div class="card-body p-0">
                                                    <!-- <BarChart :chartdata="empByMonthData" :options="barChartOptions" class="d-block w-100-h-350" :key="barChartKey"/> -->
                                                    <HorizontalBarChart :chartdata="assetSummaryByTypeData" :options="barChartOptions" class="d-block w-100-h-350" :key="barChartKey"/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <template #overlay>
                <div class="text-center">
                    <LoadingSpinner />
                </div>
            </template>
        </b-overlay>
    </div>
</template>
<script>
import iziToast from 'izitoast';
import moment from 'moment';
import { required, email, minLength, maxLength, sameAs, between, numeric } from "vuelidate/lib/validators";
import BarChart from "@/components/blocks/charts/BarChart.vue";
import DoughnutChart from "@/components/blocks/charts/DoughnutChart.vue";
import HorizontalBarChart from "@/components/blocks/charts/HorizontalBarChart.vue";
import PieChart from "@/components/blocks/charts/PieChart.vue";
export default {
    name: "App",
    components: {
        BarChart,
        DoughnutChart,
        HorizontalBarChart,
        PieChart,
    },
    data() {
        return {
            showOverlay: false,
            vhextAccountId: localStorage.getItem("vhextAccountId"),
            projectId: localStorage.getItem("projectId"),
            projectTypeId: localStorage.getItem("projectTypeId"),
            projId: localStorage.getItem("projId"),
            projectName: localStorage.getItem("projectName"),
            userId: localStorage.getItem("userId"),
            userTypeCode: localStorage.getItem("userTypeCode"),

            selectedDate: this.getDateValue(new Date()),

            ganttChartKey: 0,
            barChartKey: 0,
            pieChartKey: 0,

            ganttChartScales:'day',

            projectCount: 0,
            resourceCount: 0,
            employeeCount: 0,
            userCount: 0,
            clientCount: 0,
            leaveCount: 0,

            empByMonthData: {},
            employeeUtilizationData: {},
            salaryByMonthData: {},
            assetSummaryByTypeData: {},
            employeeByDepartmentData: {},
            employeeByJobCategoryData: {},
            resourcePoolData: {},
            employeeByDepartment: {},
            employeeAttendanceSummary: {},
            empAttendanceSummaryByDate: {},
            empUtilizationByDate: {},
            workTypeChartData: {},
            genderDistributionData: {},
            ageDistributionData: {},
            employeeLeaveByTypeData: {},
            projectByStatusData: {},
            clientByTypeData: {},
            userByTypeData: {},

            chartOptionsNoLegend: {
                responsive: true,
                maintainAspectRatio: false,
                legend: {
                    display: false,
                },
            },
            chartOptions: {
                responsive: true,
                maintainAspectRatio: false,
                legend: {
                    display: true,
                    position: 'right',
                },
/*                scales: {
                    xAxes: [{
                        stacked: false,
                        categoryPercentage: 0.5,
                        barPercentage: 1
                    }],
                    yAxes: [{
                        stacked: false
                    }]
                }
*/          },
            chartOptionsLarge: {
                responsive: true,
                maintainAspectRatio: true,
                legend: {
                    display: true,
                    position: 'top',
                },
            },
            barChartOptions: {
                responsive: true,
                maintainAspectRatio: false,
                legend: {
                    display: true,
                    position: 'top',
                },
                scales: {
                    xAxes: [{
                        ticks: {
                            // fontSize: 20,
                            beginAtZero: true,
                        },
                        // stacked: true,
                        // categoryPercentage: 0.5,
                        // barPercentage: 1
                    }],
                    yAxes: [{
                        stacked: true
                    }]
                }
            },
            tasks: {
                data: [],
                links: []
            },
            selectedTask: null,
            messages: []

        }
    },
    computed: {
        attendeesPercentage() {
            if(this.employeeAttendanceSummary.empCount) {
                return parseFloat((((this.employeeAttendanceSummary.totalPresent+(this.employeeAttendanceSummary.totalHalfDayLeave/2))/this.employeeAttendanceSummary.empCount) * 100).toFixed(1));
            } else return 0;
        },
        empAttendeesPercentage(data) {
            let totalCount = data.totalPresent + data.totalFirstHalfLeave + data.totalSecondHalfLeave + data.totalFullDayLeave;
            let empTotalPresent = data.totalPresent;
            let empTotalHalfDayLeave = data.totalFirstHalfLeave + data.totalSecondHalfLeave;

            return parseFloat(totalCount ? (((empTotalPresent+(empTotalHalfDayLeave/2))/totalCount) * 100).toFixed(1) : 0);
        },        

        myStyles() {
            return {
                height: '80px',
                width: 'auto',
                // width: '230px',
                position: 'relative'
            }
        },
        myStyles2() {
            return {
                height: '72px',
                width: 'auto',
                // width: '230px',
                position: 'relative'
            }
        },
    },
    mounted() {
        this.getDashboardData();
    },
    validations: {
    },
    methods: {
        getDashboardData: function() {
            let data = {
                vhextAccountId: localStorage.getItem("vhextAccountId"),
            }
            this.showOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/dashboard/hrmdata/all', data)
                .then((response) => {
                    this.showOverlay = false;
                    let result = response.data;
                    console.log(result);
                    if (result.employeeAttendanceSummary.length>0) {
                        this.employeeAttendanceSummary = result.employeeAttendanceSummary[0];
                    }

                    this.ganttChartKey += 1;                        
                    this.barChartKey += 1;
                    this.pieChartKey += 1;

                    if (result.employeeByMonth != null) {
                        this.empByMonthData = {
                            labels: result.employeeByMonth.map(item => item.joiningMonth),
                            datasets: [{
                                    label: 'Employee Count',
                                    backgroundColor: '#2a93d5',
                                    data: result.employeeByMonth.map(item => item.empCount),
                                }
                            ]
                        }
                    }
                    if (result.empAttendanceSummaryByDate != null) {

                        // Sort combined array by date
                        result.empAttendanceSummaryByDate.sort((a, b) => new Date(a.workingDate) - new Date(b.workingDate));                        
                        this.empAttendanceSummaryByDate = {
                            labels: result.empAttendanceSummaryByDate.map(item => item.workingDate),
                            datasets: [{
                                    label: 'Attendance Performance [%] ',
                                    data: result.empAttendanceSummaryByDate.map(item => item.performance),
                                    backgroundColor: this.getColors(result.empAttendanceSummaryByDate.map(item => item.performance)),
                                    // backgroundColor: '#2a93d5',

                                }
                            ]
                        }
                    }

                    if (result.empUtilizationByDate != null) {
                        this.empUtilizationByDate = {
                            labels: result.empUtilizationByDate.map(item => item.workingDate),
                            datasets: [{
                                    label: 'Employee Utilization [%] ',
                                    data: result.empUtilizationByDate.map(item => item.performance),
                                    backgroundColor: this.getEUPColors(result.empUtilizationByDate.map(item => item.performance)),
                                    // backgroundColor: '#2a93d5',

                                }
                            ]
                        }
                    }

                    if (result.salaryByMonth != null) {
                        this.salaryByMonthData = {
                            labels: result.salaryByMonth.map(item => item.dateString),
                            datasets: [{
                                    label: 'Salary',
                                    backgroundColor: '#2a93d5',
                                    data: result.salaryByMonth.map(item => item.moneyValue),
                                }
                            ]
                        }

                    }
                    if (result.assetSummaryList != null) {
                        this.assetSummaryByTypeData = {
                            labels: result.assetSummaryList.map(item => item.assetType),
                            datasets: [{
                                    label: 'Asset Count',
                                    data: result.assetSummaryList.map(item => item.itemCount),
                                    backgroundColor: '#2a93d5',
                                    // backgroundColor: '#2a93d5',

                                }
                            ]
                        }
                    }

                    // if (result.assetSummaryList != null) {
                    //     this.assetSummaryByTypeData = {
                    //         labels: result.assetSummaryList.map(item => item.assetType),
                    //         datasets: [{
                    //                 label: 'Type',
                    //                 backgroundColor: '#2a93d5',
                    //                 data: result.assetSummaryList.map(item => item.itemCount),
                    //             }
                    //         ]
                    //     }

                    // }                    
                    
                    if (result.empUtilizationByDepartment != null) {
                        this.employeeUtilizationData = {
                            labels: result.empUtilizationByDepartment.map(item => item.department),
                            datasets: [{
                                    label: 'Average Utilization',
                                    backgroundColor: '#2a93d5',
                                    data: result.empUtilizationByDepartment.map(item => item.averageEmpUtilization),
                                }
                            ]
                        }
                    }
                    if (result.employeeByDepartment != null) {
                        this.employeeByDepartmentData = {
                            labels: result.employeeByDepartment.map(item => item.department),
                            datasets: [{
                                    label: 'Employee Count',
                                    backgroundColor: ['grey','#2a93d5','#00FF00','#FFFF00','#FF7F00','#FF0000','#0000FF','#4B0082','#9400D3'],
                                    data: result.employeeByDepartment.map(item => item.empCount),
                                }
                            ]
                        }
                    }
                    if (result.employeeByJobCategory != null) {
                        this.employeeByJobCategoryData = {
                            labels: result.employeeByJobCategory.map(item => item.jobCategory),
                            datasets: [{
                                    label: 'Employee Count',
                                    backgroundColor: ['grey','#2a93d5','#00FF00','#FFFF00','#FF7F00','#FF0000','#0000FF','#4B0082','#9400D3'],
                                    data: result.employeeByJobCategory.map(item => item.empCount),
                                }
                            ]
                        }
                    }                    
                    this.resourcePoolData = {
                        labels: result.resourcePoolData.map(item => item.resourceGroup),
                        // labels: ['Non Employees','Employees'],
                        datasets: [{
                            backgroundColor: ['#2a93d5', '#8e9599', '#47799a'],
                            data: result.resourcePoolData.map(item => {
                                this.resourceCount += Number(item.resourceCount);
                                return item.resourceCount;
                            })
                        }]
                    }
                    this.employeeByDepartment = {
                        labels: result.employeeByDepartment.map(item => item.department),
                        datasets: [{
                            backgroundColor: ['#2a93d5', '#8e9599', '#47799a'],
                            data: result.employeeByDepartment.map(item => {
                                this.employeeCount += Number(item.empCount);
                                return item.empCount;
                            })
                        }]
                    }
                    this.projectByStatusData = {
                        labels: result.projectByStatus.map(item => item.status),
                        datasets: [{
                            backgroundColor: ['#2a93d5', '#8e9599', '#47799a'],
                            data: result.projectByStatus.map((item) => {
                                this.projectCount += Number(item.empCount);
                                return item.empCount;
                            })
                        }]
                    }
                    this.userByTypeData = {
                        labels: result.userByType.map(item => item.type),
                        datasets: [{
                            backgroundColor: ['grey','#2a93d5','#FFFF00','#FF7F00','#FF0000','#0000FF','#4B0082','#00FF00','#9400D3'],
                            data: result.userByType.map(item => {
                                this.userCount += Number(item.userCount);
                                return item.userCount;
                            })
                        }]
                    }
                    this.clientByTypeData = {
                        labels: result.clientByType.map(item => item.clientType),
                        datasets: [{
                            backgroundColor: ['#2a93d5', '#8e9599', '#47799a'],
                            data: result.clientByType.map(item => {
                                this.clientCount += Number(item.clientCount);
                                return item.clientCount;
                            })
                        }]
                    }
                    this.workTypeChartData = {
                        labels: result.workTypeData.map(item => item.workType),
                        datasets: [{
                            backgroundColor: ['#2a93d5', '#8e9599', '#47799a'],
                            data: result.workTypeData.map(item => item.resourceCount)
                        }]
                    }
                    this.genderDistributionData = {
                        labels: result.genderDistributionData.map(item => item.gender),
                        datasets: [{
                            backgroundColor: ['#2a93d5', '#8e9599', '#47799a'],
                            data: result.genderDistributionData.map(item => item.empCount)
                        }]
                    }
                    this.ageDistributionData = {
                        labels: result.ageDistributionData.map(item => item.ageGroup),
                        // ['Under 30', '31 - 50', 'Above 50' ],
                        datasets: [{
                            backgroundColor: ['grey','#2a93d5' ,'#90EE90', 'green','yellow','red'],
                            data: result.ageDistributionData.map(item => item.age),
                        }]
                    }
                    this.employeeLeaveByTypeData = {
                        labels: result.employeeLeaveByType.map(item => item.leaveType),
                        datasets: [{
                            backgroundColor: ['grey','#2a93d5' ,'#90EE90', 'green','yellow','red'],
                            data: result.employeeLeaveByType.map(item => {
                                this.leaveCount += Number(item.totalLeave);
                                return item.totalLeave;
                            })
                        }]
                    }

                })
                .catch(error => {
                    this.showOverlay = false;
                    iziToast.error({
                       title: 'Error',
                       message: 'Error',
                       position: 'topRight'
                   });
                })
        },
        getEmployeeAttendanceSummaryByDate: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
                dateValue: this.selectedDate,
            }
            console.log("Data",data);
            this.showOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/dashboard/empattendance/summary', data)
                .then((response) => {
                    this.showOverlay = false;
                    let result = response.data;
                    if (result.employeeAttendanceSummary.length>0) {
                        this.employeeAttendanceSummary = result.employeeAttendanceSummary[0];
                    }
                })
                .catch(error => {
                    this.showOverlay = false;
                });
        },
        getColors(dataArray) {
          // Customize this function based on your condition
          return dataArray.map(value => (value == 100 ? 'green' :
           (value >= 80 && value <= 99) ? 'grey' : 'red'));
        },      
        getEUPColors(dataArray) {
          // Customize this function based on your condition
          return dataArray.map(value => (value >= 90 ? 'green' :
           (value >= 75 && value < 90) ? 'grey' : 'red'));
        },        

        abbrevName: function (str1) {
            var split_names = str1.trim().split(" ");
            if (split_names.length > 1) {
                return (split_names[0] + " " + split_names[1].charAt(0) + ".");
            }
            return split_names[0];
        }        
    }
}
</script>