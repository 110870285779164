<template>
    <div class="container-fixed">
        <b-overlay :show="showOverlay" opacity="0.4">
            <div class="form-row">
                <div class="col-2">
                    <div class="card">
                        <div class="card-body p-3">
                            <div class="row">
                                <div class="col-sm-12 mb-1">
                                    <div class="card p-1">
                                        <p class="chart-title text-left pl-1 mb-1">Users
                                            <span class="badge badge-light float-right pointer py-1 px-2" title="Total Users">{{ userCount }}</span>
                                        </p>
                                        <div class="separator mb-1"></div>
                                        <div class="card-body p-1 text-center">
                                            <DoughnutChart v-if="userCount" :styles="myStyles" :chartdata="cgrUserByTypeData" :options="chartOptionsNoLegend" class="" :key="pieChartKey"/>
                                            <div v-else class="my-4 py-2">
                                                No User Found
                                            </div>                                                                                
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-12 mb-1">
                                    <div class="card p-1">
                                        <p class="chart-title text-left pl-1 mb-1">Clients
                                            <span class="badge badge-light float-right pointer py-1 px-2" title="Total Clients">{{ clientCount }}</span>
                                        </p>
                                        <div class="separator mb-1"></div>
                                        <div class="card-body p-1 text-center">
                                            <DoughnutChart v-if="clientCount" :styles="myStyles" :chartdata="clientByCompanyData" :options="chartOptionsNoLegend" class="" :key="pieChartKey"/>
                                            <div v-else class="my-4 py-2">
                                                No Client Found
                                            </div>
                                        </div>
                                    </div>
                                </div>    
                                <div class="col-sm-12 mb-1">
                                    <div class="card p-1">
                                        <p class="chart-title text-left pl-1 mb-1">Projects 
                                            <span class="badge badge-light float-right pointer py-1 px-2" title="Total Projects">{{ projectCount }}</span>
                                        </p>
                                        <div class="separator mb-1"></div>
                                        <div class="card-body p-1 text-center">
                                            <PieChart v-if="projectCount" :styles="myStyles" :chartdata="projectByCompanyData" :options="chartOptionsNoLegend" class="" :key="pieChartKey"/>
                                            <div v-else class="my-4 py-2">
                                                No Project Found
                                            </div>
                                        </div>
                                    </div>
                                </div>                                

                                <div class="col-sm-12 mb-1">
                                    <div class="card p-1">
                                        <p class="chart-title text-left pl-1 mb-1">Emp. By Gender
                                            <span class="badge badge-light float-right pointer py-1 px-2" title="Distribution By Gender : Total Employees">{{ employeeCount }}</span>
                                        </p>
                                        <div class="separator mb-1"></div>
                                        <div class="card-body p-1 text-center">
                                            <DoughnutChart :styles="myStyles" :chartdata="genderDistributionData" :options="chartOptions" class="" :key="pieChartKey"/>
                                        </div>
                                    </div>
                                </div>                                
                                <div class="col-sm-12 mb-1">
                                    <div class="card p-1">
                                        <p class="chart-title text-left pl-1 mb-1">Emp. By Work Type 
                                            <span class="badge badge-light float-right pointer py-1 px-2" title="Total Employees">{{ employeeCount }}</span>
                                        </p>
                                        <div class="separator mb-1"></div>
                                        <div class="card-body p-1 text-center">
                                            <PieChart :styles="myStyles" :chartdata="workTypeChartData" :options="chartOptionsNoLegend" class="" :key="pieChartKey"/>
                                        </div>
                                    </div>
                                </div>                                
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-10">
                    <div class="form-row mb-2">
                        <div class="col-9">
                            <div class="card">
                                <div class="card-body p-3">
                                    <div class="form-row">
                                        <div class="col-md-3 col-xl-3">
                                            <div class="card p-1">
                                                <p class="chart-title text-left pl-1 mb-1">
                                                    Employee By Status
                                                    <span class="badge badge-light float-right pointer py-1 px-2" title="All Employees">{{ totalEmployeeCount }}</span>
                                                </p>
                                                <div class="separator mb-1"></div>
                                                <div class="card-body py-1 text-center">
                                                    <DoughnutChart v-if="totalEmployeeCount" :styles="myStyles" :chartdata="employeeByStatusData" :options="chartOptionsNoLegend" :key="pieChartKey"/>
                                                    <div v-else class="my-4 py-2">
                                                        No Resource Found
                                                    </div>                                            
                                                </div>
                                            </div>
                                        </div>          
                                        <div class="col-md-5 col">
                                            <div class="card p-1">
                                                <p class="chart-title text-left pl-1 mb-1">
                                                    Employee By Company
                                                    <span class="badge badge-light float-right pointer py-1 px-2" title="Employee By Company : Total Employees">{{ employeeCount }}</span>
                                                </p>
                                                <div class="separator mb-1"></div>
                                                <div class="card-body p-1 text-center">
                                                    <DoughnutChart :styles="myStyles" :chartdata="employeeByCompanyData" :options="chartOptions" class="" :key="pieChartKey"/>
                                                </div>                                        
                                            </div>
                                        </div>
                                        <div class="col-md-4 col">
                                            <div class="card p-1">
                                                <p class="chart-title text-left pl-1 mb-1">Age Distribution                                                    
                                                    <span class="badge badge-light float-right pointer py-1 px-2" title="Distribution By Age : Total Employees">{{ employeeCount }}</span></p>
                                                <div class="separator mb-1"></div>
                                                <div class="card-body p-1 text-center">
                                                    <DoughnutChart :styles="myStyles" :chartdata="ageDistributionData" :options="chartOptions" class="" :key="pieChartKey"/>
                                                </div>
                                            </div>
                                        </div>


                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-3">
                            <div class="card">
                                <div class="card-body px-0 pb-0 pt-1" >
                                    <div class="row no-gutters">
                                        <div class="col-md-4 col-xl-6">
                                            <p class="chart-title text-left pl-2 mb-0 mt-3 pb-0">Attendance Today</p>
                                        </div>
                                        <div class="col-md-6 col-xl-6 pt-2 pr-3 text-right">
                                            <input type="date" v-model="selectedDate" @change="getEmployeeAttendanceSummaryByDate" class="">
                                        </div>
                                    </div>
                                    <div class="separator mb-1 mx-1 pt-1"></div>
                                    <div class="col-md-12 col-xl-12">
                                        <div class=" p-1 mb-3">
                                            <div class="col-sm-12 text-center my-auto mx-auto">
                                                <div class="row">
                                                    <div class="col-3 card p-1">
                                                        <div class="text-primary mb-0">Total</div>
                                                        <div class="">{{ employeeAttendanceSummary.empCount }}</div>
                                                    </div>
                                                    <div class="col-3 card p-1">
                                                        <div class="text-success mb-0">Present</div>
                                                        <div class="">{{ employeeAttendanceSummary.totalPresent }}</div>
                                                    </div>
                                                    <div class="col-3 card p-1">
                                                        <div class="text-warning mb-0">Half Day</div>
                                                        <div class="">{{ employeeAttendanceSummary.totalHalfDayLeave }}</div>
                                                    </div>
                                                    <div class="col-3 card p-1">
                                                        <div class="text-danger mb-0">Leave</div>
                                                        <div class="">{{ employeeAttendanceSummary.totalFullDayLeave }}</div>
                                                    </div>
                                                </div>
                                                <div class="mb-0 mt-1 pb-1">Attendees - <span title="Performance">{{attendeesPercentage}} %</span></div>
                                                <b-progress class="" height="10px" :value="attendeesPercentage" :variant="dailyAttendanceProgressVariant(attendeesPercentage)"></b-progress>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-row mb-2">
                        <div class="col-12">
                            <div class="card">
                                <div class="card-body p-3">
                                    <div class="form-row">
                                        <div class="col-md-4 col-xl-4">
                                            <div class="card p-3">
                                                <p class="chart-title text-left pl-1 mb-1">Employees By Company
                                                    <span class="badge badge-light float-right pointer py-1 px-2" title="Corp Group Employees Count">{{ employeeCount }}</span>
                                                </p>
                                                <div class="separator mb-1"></div>
                                                <div class="card-body p-0">
                                                    <BarChart :chartdata="employeeByCompanyData" :options="barChartOptionsNoLegend" class="d-block w-100-h-350" :key="barChartKey"/>
                                                </div>
                                            </div>
                                        </div>    
                                        <div class="col-md-4 col-xl-4">
                                            <div class="card p-3">
                                                <p class="chart-title text-left pl-1 mb-1">Employee By Job Category
                                                    <span class="badge badge-light float-right pointer py-1 px-2" title="Corp Group Employees Count">{{ employeeCount }}</span>
                                                </p>
                                                <div class="separator mb-1"></div>
                                                <div class="card-body p-0">
                                                    <BarChart :chartdata="employeeByJobCategoryData" :options="barChartOptionsNoLegend" class="d-block w-100-h-350" :key="barChartKey"/>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-4 col-xl-4">
                                            <div class="card p-3">
                                                <p class="chart-title text-left pl-1 mb-1">Employee By Joining Month
                                                    <span class="badge badge-light float-right pointer py-1 px-2" title="Corp Group Employees Count">{{ employeeCount }}</span>
                                                </p>
                                                <div class="separator mb-1"></div>
                                                <div class="card-body p-0">
                                                    <BarChart :chartdata="empByMonthData" :options="barChartOptions" class="d-block w-100-h-350" :key="barChartKey"/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>                        
                    </div>                    
                    <div class="form-row">
                        <div class="col-12">
                            <div class="card">
                                <div class="card-body p-3">
                                    <div class="form-row">
                                        <div class="col-md-4 col-xl-4">
                                            <div class="card p-3">
                                                <p class="chart-title text-left pl-1 mb-1">Employee Attendance Performance - Last 7 days
                                                    <span class="badge badge-light float-right pointer py-1 px-2" title="Corp Group Employees Count">{{ employeeCount }}</span>
                                                </p>
                                                <div class="separator mb-1"></div>
                                                <div class="card-body p-0">
                                                    <BarChart :chartdata="empAttendanceSummaryByDateData" :options="stackedBarChartOptions" class="d-block w-100-h-350" :key="barChartKey"/>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-4 col-xl-4">
                                            <div class="card p-3">
                                                <p class="chart-title text-left pl-1 mb-1">Employee Timesheet Submission Statistics
                                                    <span class="badge badge-light float-right pointer py-1 px-2" title="Corp Group Employees Count">{{ employeeCount }}</span>
                                                </p>
                                                <div class="separator mb-1"></div>
                                                <div class="card-body p-0">
                                                    <BarChart :chartdata="cgrEmpTimeSheetComplianceData" :options="stackedBarChartOptions" class="d-block w-100-h-350" :key="barChartKey"/>
                                                </div>
                                            </div>
                                        </div>                                        
                                        <div class="col-md-4 col-xl-4">
                                            <div class="card p-3">
                                                <p class="chart-title text-left pl-1 mb-1">Employee Utilization - Productive Time</p>
                                                <div class="separator mb-1"></div>
                                                <div class="card-body p-0">
                                                    <BarChart :chartdata="empUtilizationByDate" :options="barChartOptions" class="d-block w-100-h-350" :key="barChartKey"/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="form-row">
                        <div class="col-12">
                            <div class="card">
                                <div class="card-body p-3">
                                    <div class="form-row">
                                        <div class="col-md-4 col-xl-4">
                                            <div class="card p-3">
                                                <p class="chart-title text-left pl-1 mb-1">Employee CTC By Company
                                                    <span class="badge badge-light float-right pointer py-1 px-2" title="Total Corp Group CTC">{{ cgrEmpMonthlyCTC }}</span>
                                                </p>
                                                <div class="separator mb-1"></div>
                                                <div class="card-body p-0">
                                                    <BarChart :chartdata="cgrEmpCTCByCompanyData" :options="barChartOptions" class="d-block w-100-h-350" :key="barChartKey"/>
                                                </div>
                                            </div>
                                        </div>    

                                        <div class="col-md-4 col-xl-4">
                                            <div class="card p-3">
                                                <p class="chart-title text-left pl-1 mb-1">Salary By Month</p>
                                                <div class="separator mb-1"></div>
                                                <div class="card-body p-0">
                                                    <BarChart :chartdata="cgrSalaryByMonthData" :options="barChartOptions" class="d-block w-100-h-350" :key="barChartKey"/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="col-12">
                            <div class="card">
                                <div class="card-body p-3">
                                    <div class="form-row">
                                        <div class="col-md-3 col-xl-3">
                                            <div class="card p-3">
                                                <p class="chart-title text-left pl-1 mb-1">Asset Summary</p>
                                                <div class="separator mb-1"></div>
                                                <div class="card-body p-0">
                                                    <!-- <BarChart :chartdata="empByMonthData" :options="barChartOptions" class="d-block w-100-h-350" :key="barChartKey"/> -->
                                                    <HorizontalBarChart :chartdata="assetSummaryByTypeData" :options="barChartOptions" class="d-block w-100-h-350" :key="barChartKey"/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <template #overlay>
                <div class="text-center">
                    <LoadingSpinner/>
                </div>
            </template>
        </b-overlay>
    </div>
</template>
<script>
import iziToast from 'izitoast';
import moment from 'moment';
import { required, email, minLength, maxLength, sameAs, between, numeric } from "vuelidate/lib/validators";
import BarChart from "@/components/blocks/charts/BarChart.vue";
import DoughnutChart from "@/components/blocks/charts/DoughnutChart.vue";
import HorizontalBarChart from "@/components/blocks/charts/HorizontalBarChart.vue";
import PieChart from "@/components/blocks/charts/PieChart.vue";
export default {
    name: "App",
    components: {
        BarChart,
        DoughnutChart,
        HorizontalBarChart,
        PieChart,
    },
    data() {
        return {
            showOverlay: false,
            parentVhextAccountId: localStorage.getItem("parentVhextAccountId"),
            vhextAccountId: localStorage.getItem("vhextAccountId"),
            projectId: localStorage.getItem("projectId"),
            projectTypeId: localStorage.getItem("projectTypeId"),
            projId: localStorage.getItem("projId"),
            projectName: localStorage.getItem("projectName"),
            userId: localStorage.getItem("userId"),
            userTypeCode: localStorage.getItem("userTypeCode"),

            // add more colors if needed
            standardColors: ['#2a93d5', '#8e9599', '#47799a','#FFFF00','#FF7F00','#FF0000','#0000FF','#4B0082','#9400D3'],

            selectedDate: this.getDateValue(new Date()),

            ganttChartKey: 0,
            barChartKey: 0,
            pieChartKey: 0,

            ganttChartScales:'day',

            projectCount: 0,
            resourceCount: 0,
            totalEmployeeCount: 0,
            cgrEmpMonthlyCTC: 0,
            totalCGRSalary: 0,
            employeeCount: 0,
            userCount: 0,
            clientCount: 0,
            leaveCount: 0,

            empByMonthData: {},
            employeeUtilizationData: {},
            salaryByMonthData: {},
            assetSummaryByTypeData: {},
            employeeByCompanyData: {},
            employeeByDepartmentData: {},
            employeeByJobCategoryData: {},
            resourcePoolData: {},
            employeeByStatusData: {},
            employeeByDepartment: {},
            employeeAttendanceSummary: {},
            empAttendanceSummaryByDateData: {},
            empUtilizationByDate: {},
            cgrEmpTimeSheetComplianceData: {},
            cgrEmpCTCByCompanyData: {},
            cgrSalaryByMonthData: {},
            workTypeChartData: {},
            genderDistributionData: {},
            ageDistributionData: {},
            employeeLeaveByTypeData: {},
            projectByCompanyData: {},
            clientByCompanyData: {},
            cgrUserByTypeData: {},

            arrEmpAttendanceByCompany: {},

            chartOptionsNoLegend: {
                responsive: true,
                maintainAspectRatio: false,
                legend: {
                    display: false,
                },
            },
            chartOptions: {
                responsive: true,
                maintainAspectRatio: false,
                legend: {
                    display: true,
                    position: 'right',
                },
/*                scales: {
                    xAxes: [{
                        stacked: false,
                        categoryPercentage: 0.5,
                        barPercentage: 1
                    }],
                    yAxes: [{
                        stacked: false
                    }]
                }
*/          },
            chartOptionsLarge: {
                responsive: true,
                maintainAspectRatio: true,
                legend: {
                    display: true,
                    position: 'top',
                },
            },
            barChartOptions: {
                responsive: true,
                maintainAspectRatio: false,
                legend: {
                    display: true,
                    position: 'top',
                },
                scales: {
                    xAxes: [{
                        ticks: {
                            // fontSize: 20,
                            beginAtZero: true,
                        },
                        // stacked: true,
                        // categoryPercentage: 0.5,
                        // barPercentage: 1
                    }],
                    yAxes: [{
                        ticks: {
                            // fontSize: 20,
                            beginAtZero: true,
                        },

                        stacked: false,
                    }]
                }
            },
            barChartOptionsNoLegend: {
                responsive: true,
                maintainAspectRatio: false,
                legend: {
                    display: false,
                },
                scales: {
                    xAxes: [{
                        ticks: {
                            beginAtZero: true,
                        },
                    }],
                    yAxes: [{
                        ticks: {
                            beginAtZero: true,
                        },

                        stacked: false,
                    }]
                }
            },

            stackedBarChartOptions: {
                responsive: true,
                maintainAspectRatio: false,
                legend: {
                    display: false,
                    position: 'top',
                },
                scales: {
                    xAxes: [{
                        // ticks: {
                        //     // fontSize: 20,
                        //     beginAtZero: true,
                        // },
                        stacked: true,
                        // categoryPercentage: 0.5,
                        // barPercentage: 1
                    }],
                    yAxes: [{
                        ticks: {
                            // fontSize: 20,
                            beginAtZero: true,
                        },

                        stacked: true,
                    }]
                },
            },

            tasks: {
                data: [],
                links: []
            },
            selectedTask: null,
            messages: []

        }
    },
    computed: {
        attendeesPercentage() {
            if(this.employeeAttendanceSummary.empCount) {
                return parseFloat((((this.employeeAttendanceSummary.totalPresent+(this.employeeAttendanceSummary.totalHalfDayLeave/2))/this.employeeAttendanceSummary.empCount) * 100).toFixed(1));
            } else return 0;
        },
        empAttendeesPercentage(data) {
            let totalCount = data.totalPresent + data.totalFirstHalfLeave + data.totalSecondHalfLeave + data.totalFullDayLeave;
            let empTotalPresent = data.totalPresent;
            let empTotalHalfDayLeave = data.totalFirstHalfLeave + data.totalSecondHalfLeave;

            return parseFloat(totalCount ? (((empTotalPresent+(empTotalHalfDayLeave/2))/totalCount) * 100).toFixed(1) : 0);
        },        
        myStyles() {
            return {
                height: '80px',
                width: 'auto',
                // width: '230px',
                position: 'relative'
            }
        },
        myStyles2() {
            return {
                height: '72px',
                width: 'auto',
                // width: '230px',
                position: 'relative'
            }
        },
    },
    mounted() {
        this.getDashboardData();
        this.getEmployeeAttendanceSummaryByDate();
    },
    validations: {
    },
    methods: {
        getDashboardData: function() {
            let data = {
                parentVhextAccountId: this.parentVhextAccountId,
                vhextAccountId: localStorage.getItem("vhextAccountId"),
            }
            this.showOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/dashboard/cgrdata/all', data)
                .then((response) => {
                    let result = response.data;
                    console.log(result);
                    this.ganttChartKey += 1;                        
                    this.barChartKey += 1;
                    this.pieChartKey += 1;

                    if (result.employeeByMonth != null) {
                        this.empByMonthData = {
                            labels: result.employeeByMonth.map(item => item.joiningMonth ?? 'No Date'),
                            datasets: [{
                                    label: 'Employee Count',
                                    backgroundColor: '#2a93d5',
                                    data: result.employeeByMonth.map(item => item.empCount),
                                }
                            ]
                        }
                    }
                    if (result.empAttendanceSummaryByDate != null) {
                        // Sort combined array by date
                        // result.empAttendanceSummaryByDate.sort((a, b) => new Date(a.workingDate) - new Date(b.workingDate));

                        const groupedSummary = this.groupBy(result.empAttendanceSummaryByDate,'workingDate');
                        const groupByCompany = this.groupBy(result.empAttendanceSummaryByDate,'cmpShortName');

                        const labels = Object.keys(groupedSummary);
                        const datasets = Object.keys(groupByCompany);

                        console.log("GBD",labels);
                        console.log("GBC",datasets);

                        this.empAttendanceSummaryByDateData = {
                            labels: labels,
                            datasets: [
                                ...datasets.map((item, i) => {
                                    // console.log("GBC1",groupByCompany[item]);
                                    return{
                                        label: item  + ' : Present',
                                        data: labels.map(label => { 
                                            const found = groupByCompany[item].find(data => data.workingDate === label); 
                                            return found ? found.totalPresent ?? 0 : 0; 
                                        }),
                                        // data: groupByCompany[item].map(data => data.totalPresent ?? 0),
                                        backgroundColor: 'green',
                                        stack: 'Stack_' +i,
                                    }
                                }),
                                ...datasets.map((item, i) => {
                                    // console.log("GBC2",groupByCompany[item]);
                                    return{
                                        label: item  + ' : Absent',
                                        data: labels.map(label => { 
                                            const found = groupByCompany[item].find(data => data.workingDate === label); 
                                            return found ? found.totalLeave ?? 0 : 0; 
                                        }),
                                        // data: groupByCompany[item].map(data => data.totalLeave ?? 0),
                                        backgroundColor: 'red',
                                        stack: 'Stack_' +i,
                                    }
                                }),
                                ...datasets.map((item, i) => {
                                    // console.log("GBC2",groupByCompany[item]);
                                    return{
                                        label: item  + ' : No Attendance',
                                        data: labels.map(label => { 
                                            const found = groupByCompany[item].find(data => data.workingDate === label); 
                                            return found ? found.noAttendanceCount ?? 0 : 0; 
                                        }),
                                        // data: groupByCompany[item].map(data => data.totalLeave ?? 0),
                                        backgroundColor: 'grey',
                                        stack: 'Stack_' +i,
                                    }
                                })                                
                            ]

                        }
                    }
                    if (result.cgrEmpTimeSheetComplianceList != null) {

                        console.log("TSCMP",result.cgrEmpTimeSheetComplianceList);
                        // Sort combined array by date
                        // result.cgrEmpTimeSheetComplianceList.sort((a, b) => new Date(a.workingDate) - new Date(b.workingDate));

                        const groupByDate = this.groupBy(result.cgrEmpTimeSheetComplianceList,'workingDate');
                        const groupByCompany = this.groupBy(result.cgrEmpTimeSheetComplianceList,'cmpShortName');

                        const labels = Object.keys(groupByDate);
                        const datasets = Object.keys(groupByCompany);

                        // console.log("GBD",groupByDate);
                        // console.log("GBC",groupByCompany);


                        this.cgrEmpTimeSheetComplianceData = {
                            // labels: result.empUtilizationByDate.map(item => item.workingDate),
                            labels: labels,

                            datasets: [
                                ...datasets.map((item, i) => {
                                    // console.log("GBC1",groupByCompany[item]);
                                    return{
                                        label: item  + ' : Time Sheet Submitted',
                                        data: labels.map(label => { 
                                            const found = groupByCompany[item].find(data => data.workingDate === label); 
                                            return found ? found.tsSubmissionEmpCount ?? 0 : 0; 
                                        }),                                        
                                        // data: groupByCompany[item].map(data => data.tsSubmissionEmpCount ?? 0),
                                        backgroundColor: 'green',
                                        stack: 'Stack_' +i,
                                    }
                                }),
                                ...datasets.map((item, i) => {
                                    // console.log("GBC2",groupByCompany[item]);
                                    return{
                                        label: item  + ' : Time Sheet Not Submitted',
                                        data: labels.map(label => { 
                                            const found = groupByCompany[item].find(data => data.workingDate === label); 
                                            return found ? found.tsNonSubmissionEmpCount ?? 0 : 0; 
                                        }),                                        
                                        // data: groupByCompany[item].map(data => data.tsNonSubmissionEmpCount ?? 0),
                                        backgroundColor: 'red',
                                        stack: 'Stack_' +i,
                                    }
                                }),

                            ]

                        }
                    }
                    if (result.empUtilizationByDate != null) {

                        // Sort combined array by date
                        result.empUtilizationByDate.sort((a, b) => new Date(a.workingDate) - new Date(b.workingDate));

                        const groupedSummary = this.groupBy(result.empUtilizationByDate,'workingDate');
                        const groupByCompany = this.groupBy(result.empUtilizationByDate,'cmpShortName');

                        const labels = Object.keys(groupedSummary);
                        const datasets = Object.keys(groupByCompany);

                        this.empUtilizationByDate = {
                            // labels: result.empUtilizationByDate.map(item => item.workingDate),
                            labels: labels,

                            datasets: datasets.map((item, i) => {
                                return {
                                    label: item,
                                    data: groupByCompany[item].map(data => data.performance),
                                    backgroundColor: this.standardColors[i],
                                }
                            })
                        }
                    }
                    if (result.cgrEmpCTCByCompany != null) {
                        this.cgrEmpCTCByCompanyData = {
                            labels: result.cgrEmpCTCByCompany.map(item => item.cmpShortName),
                            datasets: [{
                                    label: 'Employee CTC',
                                    backgroundColor: ['#2a93d5', '#8e9599', '#47799a','#FFFF00','#FF7F00','#FF0000','#0000FF','#4B0082','#9400D3'],
                                    data: result.cgrEmpCTCByCompany.map(item => {
                                        this.cgrEmpMonthlyCTC += Number(item.moneyValue);
                                        return item.moneyValue;
                                    }),
                                }
                            ]
                        }
                    }

                    if (result.cgrSalaryByMonth != null) {

                        // Sort combined array by date
                        result.cgrSalaryByMonth.sort((a, b) => new Date(a.dateString) - new Date(b.dateString));

                        const groupedSummary = this.groupBy(result.cgrSalaryByMonth,'dateString');
                        const groupByCompany = this.groupBy(result.cgrSalaryByMonth,'cmpShortName');

                        const labels = Object.keys(groupedSummary);
                        const datasets = Object.keys(groupByCompany);

                        this.cgrSalaryByMonthData = {
                            labels: labels,
                            datasets: datasets.map((item, i) => {
                                return {
                                    label: item,
                                    data: groupByCompany[item].map(item => item.moneyValue),
                                    backgroundColor: this.standardColors[i],
                                }
                            })
                        }
                    }
                    if (result.assetSummaryList != null) {
                        this.assetSummaryByTypeData = {
                            labels: result.assetSummaryList.map(item => item.assetType),
                            datasets: [{
                                    label: 'Asset Count',
                                    data: result.assetSummaryList.map(item => item.itemCount),
                                    backgroundColor: '#2a93d5',
                                    // backgroundColor: '#2a93d5',

                                }
                            ]
                        }
                    }

                    // if (result.assetSummaryList != null) {
                    //     this.assetSummaryByTypeData = {
                    //         labels: result.assetSummaryList.map(item => item.assetType),
                    //         datasets: [{
                    //                 label: 'Type',
                    //                 backgroundColor: '#2a93d5',
                    //                 data: result.assetSummaryList.map(item => item.itemCount),
                    //             }
                    //         ]
                    //     }

                    // }                    
                    
                    if (result.empUtilizationByDepartment != null) {
                        this.employeeUtilizationData = {
                            labels: result.empUtilizationByDepartment.map(item => item.department),
                            datasets: [{
                                    label: 'Average Utilization',
                                    backgroundColor: '#2a93d5',
                                    data: result.empUtilizationByDepartment.map(item => item.averageEmpUtilization),
                                }
                            ]
                        }
                    }
                    if (result.employeeByCompany != null) {
                        this.employeeByCompanyData = {
                            
                            labels: result.employeeByCompany.map(item => item.shortName),
                            datasets: [{
                                    label: 'Employee Count',
                                    backgroundColor: ['#2a93d5', '#8e9599', '#47799a','#FFFF00','#FF7F00','#FF0000','#0000FF','#4B0082','#9400D3'],
                                    data: result.employeeByCompany.map(item => item.empCount),
                                }
                            ]
                        }
                    }
                    if (result.employeeByJobCategory != null) {
                        this.employeeByJobCategoryData = {
                            labels: result.employeeByJobCategory.map(item => item.jobCategory),
                            datasets: [{
                                    label: 'Employee Count',
                                    backgroundColor: ['grey','#2a93d5','#00FF00','#FFFF00','#FF7F00','#FF0000','#0000FF','#4B0082','#9400D3'],
                                    data: result.employeeByJobCategory.map(item => item.empCount),
                                }
                            ]
                        }
                    }                    
                    // if (result.resourcePoolData != null) {
                    //     this.resourcePoolData = {
                    //         labels: result.resourcePoolData.map(item => item.resourceGroup),
                    //         // labels: ['Non Employees','Employees'],
                    //         datasets: [{
                    //             backgroundColor: ['#2a93d5', '#8e9599', '#47799a'],
                    //             data: result.resourcePoolData.map(item => {
                    //                 this.resourceCount += Number(item.resourceCount);
                    //                 return item.resourceCount;
                    //             })
                    //         }]
                    //     }
                    // }
                    if (result.employeeByStatusData != null) {
                        this.employeeByStatusData = {
                            labels: result.employeeByStatusData.map(item => item.status),
                            // labels: ['Non Employees','Employees'],
                            datasets: [{
                                backgroundColor: ['#2a93d5', '#8e9599', '#47799a'],
                                data: result.employeeByStatusData.map(item => {
                                    this.totalEmployeeCount += Number(item.totalEmpCount);
                                    return item.totalEmpCount;
                                })
                            }]
                        }
                    }

                    if (result.employeeByDepartment != null) {
                        this.employeeByDepartment = {
                            labels: result.employeeByDepartment.map(item => item.department),
                            datasets: [{
                                backgroundColor: ['#2a93d5', '#8e9599', '#47799a'],
                                data: result.employeeByDepartment.map(item => {
                                    this.employeeCount += Number(item.empCount);
                                    return item.empCount;
                                })
                            }]
                        }
                    }
                    if (result.userByCompany != null) {                        
                        this.cgrUserByTypeData = {
                            labels: result.userByCompany.map(item => item.shortName),
                            // labels: ['Non Employees','Employees'],
                            datasets: [{
                                backgroundColor: ['#2a93d5', '#8e9599', '#47799a' ,'#0000FF'],
                                data: result.userByCompany.map(item => {
                                    this.userCount += Number(item.userCount);

                                    return item.userCount;

                                })
                            }]
                        }
                    }
                    if (result.clientByCompany != null) {
                        this.clientByCompanyData = {
                            labels: result.clientByCompany.map(item => item.shortName),
                            datasets: [{
                                backgroundColor: ['#2a93d5','#FFFF00','#FF7F00','#FF0000','#0000FF','#4B0082','#00FF00','#9400D3'],
                                data: result.clientByCompany.map(item => {
                                    this.clientCount += Number(item.clientCount);
                                    return item.clientCount;
                                })
                            }]
                        }
                    }
                    if (result.projectByCompany != null) {
                        this.projectByCompanyData = {
                            labels: result.projectByCompany.map(item => item.shortName),
                            datasets: [{
                                backgroundColor: ['#2a93d5', '#8e9599', '#47799a'],
                                data: result.projectByCompany.map((item) => {
                                    this.projectCount += Number(item.projectCount);
                                    return item.projectCount;
                                })
                            }]
                        }
                    }

                    this.workTypeChartData = {
                        labels: result.employeeByWorkTypeData.map(item => item.workType),
                        datasets: [{
                            backgroundColor: ['#2a93d5', '#8e9599', '#47799a'],
                            data: result.employeeByWorkTypeData.map(item => item.resourceCount)
                        }]
                    }
                    this.genderDistributionData = {
                        labels: result.genderDistributionData.map(item => item.gender),
                        datasets: [{
                            backgroundColor: ['#2a93d5', '#8e9599', '#47799a'],
                            data: result.genderDistributionData.map(item => item.empCount)
                        }]
                    }
                    this.ageDistributionData = {
                        labels: result.ageDistributionData.map(item => item.ageGroup),
                        // ['Under 30', '31 - 50', 'Above 50' ],
                        datasets: [{
                            backgroundColor: ['grey','#2a93d5' ,'#90EE90', 'green','yellow','red'],
                            data: result.ageDistributionData.map(item => item.age),
                        }]
                    }
                    if (result.employeeByCompany != null) {
                        this.employeeByCompanyData = {
                            labels: result.employeeByCompany.map(item => item.shortName),
                            datasets: [{
                                backgroundColor: ['grey','#2a93d5' ,'#90EE90', 'green','yellow','red'],
                                data: result.employeeByCompany.map(item => {
                                    this.employeeCount += Number(item.empCount);
                                    return item.empCount;
                                })
                            }]
                        }
                    }

                    this.showOverlay = false;

                })
                .catch(error => {
                    this.showOverlay = false;
                    console.error(error);
                    iziToast.error({
                       title: 'Error',
                       message: 'Error',
                       position: 'topRight'
                   });
                })
        },
        getEmployeeAttendanceSummaryByDate: function() {
            let data = {
                parentVhextAccountId: this.parentVhextAccountId,
                dateValue: this.selectedDate,
            }
            console.log("Data",data);
            // this.showOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/dashboard/cgrempattendance/summary', data)
                .then((response) => {
                    // this.showOverlay = false;
                    let result = response.data;
                    if (result.employeeAttendanceSummary.length>0) {
                        this.employeeAttendanceSummary = result.employeeAttendanceSummary[0];
                    }
                })
                .catch(error => {
                    // this.showOverlay = false;
                });
        },
        getColors(dataArray) {
          // Customize this function based on your condition
          return dataArray.map(value => (value == 100 ? 'green' :
           (value >= 80 && value <= 99) ? 'grey' : 'red'));
        },      
        getEUPColors(dataArray) {
          // Customize this function based on your condition
          return dataArray.map(value => (value >= 90 ? 'green' :
           (value >= 75 && value < 90) ? 'grey' : 'red'));
        },        

        abbrevName: function (str1) {
            var split_names = str1.trim().split(" ");
            if (split_names.length > 1) {
                return (split_names[0] + " " + split_names[1].charAt(0) + ".");
            }
            return split_names[0];
        },
        groupBy(array, key) {
            return array.reduce((result, currentItem) => {
                const groupKey = currentItem[key];
                if (!result[groupKey]) {
                    result[groupKey] = [];
                }
                result[groupKey].push(currentItem);
                return result;
            }, {});
        }                
    }
}
</script>