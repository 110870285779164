<template>
    <div class="fixed-inner-header">
        <div class="container-fluid">
            <div class="row inner-header">
                <div class="col-12">
                    <div class="row tassk-top-menu" v-bind:class="{disabled: !elements.isDisable }">

                        <div class="col-1 col-lg-1 col-sm-1 mt-2 pr-0 mr-0">
                            <h4 class="pl-2 mb-0 fs-16">Task Board</h4>
                        </div>
                        <div class="col-2 pr-0 mr-0">
                            <select v-model="ermObject" @change="ermFilter(ermObject)" class="form-control" id="taskId" :title="ermObject.value">
                                <!-- <option value="all" >All Task Plan</option> -->
                                <option v-for="(info, index) in ermLookup" v-bind:value="{id: info.id, value:info.value,projectId:info.projectId}">{{info.value}}</option>
                            </select>
                        </div>
                        <div class="col-1 px-0">
                            <div class="d-flex align-middle">
                                <a class="cust-setting-icon mr-1" href="javascript:" v-b-modal.manageErmMasterPopup title="Manage Task Plan"><i class="fa fa-cog fa-lg action-link" aria-hidden="true"></i></a>
                            </div>
                        </div>
<!--                         <div class="col-1 ml-0 float-left pl-0 mt-2">
                            <a class="setting-icon-custom2 action-link pl-2 float-left" href="javascript:" v-b-modal.addErmMasterPopup title="Add Task Plan"><i class="fa fa-plus-circle" aria-hidden="true"></i></a>                            
                            <a class="setting-icon-custom2 action-link pl-2 float-left" href="javascript:" v-b-modal.manageErmMasterPopup title="Manage Task Plan"><i class="fa fa-cog" aria-hidden="true"></i></a>      
                        </div>                         -->
                        <div class="col-2 pr-0">
                            <div class="dataTables_filter">
                                <label>
                                    <i class="fa fa-search icon-search" aria-hidden="true"></i>
                                    <input type="search" v-model="search" class="form-control search-task" placeholder="Search Title..">
                                </label>
                            </div>
                        </div>
                        <div class="col-lg-2 col-sm-2 mb-0 ml-0 text-left pl-1">
                            
                            <div class="carousel-users">
                                
                                <carousel v-if="carousel" :autoplay="false" :dots="false" :nav="false" :responsive="{0:{items:5,nav:false},600:{items:5,nav:false},900:{items:6,nav:false}}">
                                    <template slot="prev"><span class="prev prev-arrow-btn"><i aria-hidden="true" class="fa fa-angle-left"></i></span></template>
                                        <div v-if="!elements.clientTaskPage" v-for="user in users" @click="teamMemberFilter(user.id); filteredUserId = user.id" class="my-0 py-0 text-right user-icon" >
                                            <span class="pointer align-self-center user-name-filter-icon" :class="{'active-filter-icon' : user.id == filteredUserId}" :title="user.value">{{ user.value | getAcronym }}</span>
                                        </div>
                                    <template slot="next"><span class="next next-arrow-btn"><i aria-hidden="true" class="fa fa-angle-right"></i></span></template>
                                </carousel>
                                
                                <b-button v-if="elements.filterMode && !elements.clientTaskPage" class="btn btn-xs text-left px-2 mx-3 all-user-btn" variant="outline-success" @click="clearFilter" size="sm">All</b-button>
                            </div>
                        </div>
                        <div class="col-2 mb-1 p-0">
                            <b-button class="addnew-tassk-btn float-right " variant="outline-info" v-bind:class="{disabled: !elements.isDisable }" @click="elements.viewOnlyComponent=false; task.id=null; erdId=null;toggleDiv();task.assignedToId = filteredUserId ? filteredUserId : ''" size="sm"><i class="fa fa-plus pr-1"></i>New Task</b-button>
                            <b-button class="addnew-tassk-btn float-right mr-1" variant="outline-info" v-b-modal.taskMemoryListPopup size="sm"><i class="fa fa-plus pr-1"></i>M +</b-button>
                        </div>
                        <div class="col-lg-2 col-sm-2 mt-2">
                            <nav class="breadcrumb-container d-sm-block d-lg-inline-block float-right" aria-label="breadcrumb">
                                <ol class="breadcrumb pt-0 mb-0">
                                    <li class="breadcrumb-item">
                                        <a href="javascript:" @click="getPrevUrl()"><i class="fa fa-reply action-link pr-2" aria-hidden="true"></i>Back</a>
                                    </li>
                                    <li v-if=" isArchive== false" class="breadcrumb-item">
                                        <a href="javascript:" title="Archive"  @click="isArchive=true; getTasks(ermObject.id)"><i class="fa fa-undo action-link pr-2" aria-hidden="true"></i>Archive</a>
                                    </li> 
                                    <li v-if=" isArchive== true" class="breadcrumb-item">
                                        <a href="javascript:" title="TaskBoard" @click="isArchive=false"><i class="fa fa-undo action-link pr-2" aria-hidden="true"></i>Task Board</a>
                                    </li> 

                                    <li class="breadcrumb-item">
                                        <a href="javascript:" title="Task List" class="action-link" @click="redirectPage"><i class="fa fa-list px-1" aria-hidden="true"></i></a>
                                    </li>                                    
                                    <li class="breadcrumb-item">
                                        <a href="javascript:" title="Gantt View" @click="redirectTaskGantt"><i class="fa fa-sliders action-link px-1" aria-hidden="true"></i></a>
                                    </li>
                                    <li v-if="projId" class="breadcrumb-item">
                                        <h4 class="pl-2 mb-0 fs-14 text-right mr-2">#{{this.projId}} - {{this.projectName}}</h4>
                                    </li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                    <div class="separator mb-2"></div>
                </div>
            </div>
            <b-overlay  :show="showOverlay" spinner-variant="primary" opacity="0.4" rounded="sm">
                <b-table v-if="isArchive" outlined show-empty small head-variant=:primary sticky-header="65vh" selectable :select-mode="selectMode" :items="tasks" :fields="taskFields" responsive="sm" :filter="taskFilter" :filterIncludedFields="filterOn" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection">
                    <template #cell(index)="data">
                        {{ data.index + 1 }}
                    </template>
                    <template v-slot:cell(title)="data">
                        <a class="tooltiptext action-link" href="javascript:" @click="viewItemDetails(data.item)">{{data.value}}</a>
                    </template>
                    <template v-slot:cell(workHour)="data">
                        {{convertToHM(data.item.estEffortMins)}}
                    </template>
                    <template v-slot:cell(estimatedCost)="data">
                        <span v-if="data.value">{{ currencySymbol }}{{ data.value }}</span>
                    </template> 
                    <template v-slot:cell(assignedToId)="data">    
                        {{ data.item.assignedTo }}
                    </template>
                    <template v-slot:cell(statusId)="data">    
                        {{ data.item.status }}
                    </template>
                    <template v-slot:cell(actions)="data">
                        <span v-if="data.item.assignedById == userId">
                            <a href="#" class="mr-2" title="UnArchive" @click="archiveTask(data.item)"><i class="fa fa-undo" aria-hidden="true"></i></a>
                        </span>
                        <span v-else title="No Action">NA</span>
                    </template>
                    <template v-slot:empty="scope">
                        <div class="text-center">No Record found</div>
                    </template>
                    <template v-slot:emptyfiltered="scope">
                        <div class="text-center">{{ scope.emptyFilteredText }}</div>
                    </template>
                </b-table>
                <div v-else class="row">
                    <div class="col-12 col-lg-12 col-xl-12 position-relative">
                        <div class="form-row mr-0">
                            <div v-for="status in taskStatus" v-if="status.value !=='Cancelled'" class="col-12 col-md-2 mb-1 pr-1">
                                <div class="card mb-2 b-radius-5 ">
                                    <div class="card-body px-3 py-2">
                                        <div class="form-group mb-0">
                                            <label class="mt-1 mb-0 font-weight-bold" for="title">{{ status.value }} 
                                                <b-badge class="ml-2" title="Tasks">{{ getItemCount(status.id) }}</b-badge>
                                            </label>
                                            <b-button class="pull-right addnew-quicktassk-btn" v-bind:class="{disabled: !elements.isDisable }" @click="quickTask(status.statusLookupId)" variant="outline-info" size="sm" ><i class="fa fa-plus"></i> Quick Task</b-button>
                                        </div>
                                    </div>
                                </div>
                                <div class="taskLists" v-bind:class="{hide: !elements.isHide }">
                                    <div v-for="task,key in filteredTasks">
                                        <div v-if="task.status == status.value" class="card-media mb-2">

                                            <div class="card-media-body dropdown show">
                                                
                                                <a type="button" class="float-right" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                    <i class="fa fa-ellipsis-v p-1"></i>
                                                </a>
                                                <div class="dropdown-menu dropdown-primary" aria-labelledby="dropdownMenuLink">
                                                    <a class="dropdown-item addnew-tassk pb-1 py-1" @click="viewItemDetails(task)" href="javascript:"><i class="fa fa-list-alt pr-2" aria-hidden="true"></i>Task Details</a>
                                                    <a v-if="task.assignedById == userId" class="dropdown-item addnew-tassk py-1" @click="showEdit(task)" href="javascript:"><i class="fa fa-pencil pr-2"></i>Edit Task</a>
                                                    <a class="dropdown-item addnew-tassk pb-1 py-1" @click="toggleDiv();duplicateTask(task);" href="javascript:"><i class="fa fa-copy pr-2" aria-hidden="true"></i>Duplicate Task</a>
                                                    <a v-if="task.assignedById == userId || task.assignedToId == userId" class="dropdown-item addnew-tassk my-0 py-1" @click="showStatusPopup(task, false)" href="javascript:"><i class="fa fa-edit pr-2 mt-1 mb-0" aria-hidden="true"></i>Task Status Update</a>
                                                    <a v-if="task.assignedById == userId || task.assignedToId == userId" class="dropdown-item addnew-tassk my-0 py-1" @click="openTimeSheet(task)" href="javascript:"><i class="fa fa-clock-o pr-2 mt-1 mb-0" aria-hidden="true"></i>Timesheet</a>
                                                    <a v-if="task.assignedById == userId || task.assignedToId == userId" class="dropdown-item addnew-tassk my-0 py-1" @click="showTaskFinPopup(task)" href="javascript:"><i class="fa fa-money pr-2 mt-1 mb-0" aria-hidden="true"></i>Task Cost Update</a>
                                                    <a v-if="task.assignedById == userId" class="dropdown-item py-1" @click="deleteTask(task.id)" v-b-modal.deleteTask href="javascript:"><i class="fa fa-trash pr-2"></i>Delete Task</a>
                                                    <a v-if="task.assignedById == userId || userTypeCode == 'PRM' || userTypeCode == 'CAM' || userTypeCode == 'VSA'" class="dropdown-item addnew-tassk my-0 py-1" @click="archiveTask(task)" href="javascript:"><i class="fa fa-archive pr-2 mt-1 mb-0" aria-hidden="true"></i>Archive</a>
                                                    <a v-if="task.assignedById == userId || userTypeCode == 'PRM' || userTypeCode == 'CAM' || userTypeCode == 'VSA'" class="dropdown-item addnew-tassk my-0 py-1" @click.prevent="memorizeTask(task)" href="javascript:"><span class="fs-11 pr-1">M<sup>+</sup></span>{{ task.isMemorized ? 'Remove Task From Memory' : 'Add Task To Memory' }}</a>
                                                </div>

                                                <span v-if="task.status!=null" title="Status" class="card-media-object-tag subtle float-right mr-1">{{ task.status }}</span>
                                                <span title="Created on" class="subtle float-right mr-1">{{ getTimeDuration(task.createdDt) }}</span>
                                                <div class="card-media-body-top">
                                                    <span class="">
                                                        <a href="javascript:" class="action-link" @click="viewItemDetails(task)">{{ task.title }}</a>
                                                        <i v-if="task.priority" class="fa fa-exclamation-circle pl-1 pointer" :class="priorityColour(task.priority)" :title="`${task.priority ? task.priority : 'Low'} priority`"></i>
                                                        <a v-if="(task.checklistItemCount > 0)" href="javascript:" class="ml-2" @click="openChecklistReportPopup(task)" title="Show Checklist"><i class="fa fa-list"></i></a>
                                                    </span>
                                                </div>
                                                <div class="divider-line"></div>
                                                <div class="">
                                                    <div class="row">
                                                        <div class="col-6 my-auto">
                                                            <a href="javascript:" class="subtle align-middle" @click="viewProcessActivity(task)" title="Process/Activity">{{ task.activity }} <i class="fa fa-info-circle" aria-hidden="true"></i></a>
                                                        </div>
                                                        <div class="col-6">
                                                            <span class="subtle" title="Task Progression">{{ task.progressPercentage ? task.progressPercentage : 0 }}% done</span>
                                                            <b-progress height="7px" :variant="progressBarVariant(task)" v-model="task.progressPercentage"></b-progress>
                                                        </div>
                                                    </div>
                                                    <div class="divider-line"></div>
                                                    <div v-if="(task.estimatedStartDt != null) || (task.estimatedEndDt != null)">
                                                        <div class="row">
                                                            <div class="col-6">
                                                                <span class="subtle" title="Start Date"><i class="fa fa-clock-o pr-1" aria-hidden="true"></i>{{ task.estimatedStartDt | formatDateTimeYearCheck }}</span>
                                                            </div>
                                                            <div class="col-6">
                                                                <span class="subtle float-right" :class="(status.value !== 'Closed' && status.value !== 'Completed' && status.value !== 'Cancelled') ? dueDateStyling(task.estimatedEndDt) : ''" title="Due Date"><i class="fa fa-clock-o pr-1" aria-hidden="true"></i>{{ task.estimatedEndDt | formatDateTimeYearCheck }}</span>
                                                            </div>
                                                        </div>
                                                        <div class="divider-line"></div>
                                                    </div>
                                                    <div class="row">
                                                        <div class="col-12">
                                                            <div class="row card-media-object-social-list pt-1">
                                                                <div class="col-auto pl-0">
                                                                    <div v-if="(task.assignedBy != null)">
                                                                        <b-badge class="task-badge" title="Assigned By"> {{ task.assignedBy }} </b-badge>
                                                                    </div>
                                                                </div>
                                                                <div class="col-auto p-0">
                                                                    <div v-if="(task.assignedTo != null)">
                                                                        <b-badge class="task-badge" :title="`Assigned To: ${task.assignedTo}`"> {{ task.assignedTo | getAcronym }} </b-badge>
                                                                    </div>
                                                                </div>
                                                                <!-- <div class="col p-0 text-right">
                                                                    <div v-if="task.assignedToId == userId">
                                                                        <a v-if="(empWorkState == 1 || empWorkState == 3) && empWorkStatus.taskId && empWorkStatus.taskId==task.id" href="javascript:" title="" class="pointer emp-tl-indicator p-1 green" @click="showStatusPopup(task, true)">
                                                                            <i class="fa fa-clock-o pt-1 pr-1"></i>{{ timerDuration }}
                                                                        </a>
                                                                        <button v-else type="button" @click="taskId=task.id;addEmployeeAttendanceTimeline(1)" class="btn sbtn btn-primary btn-xs px-2 float-right">
                                                                            <i class="fa fa-play pr-1" aria-hidden="true"></i>Start Task
                                                                        </button>
                                                                    </div>
                                                                </div> -->
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div v-if="!getTaskByStatusId(status.id).length" class="card-media mb-2">
                                        <div class="card-media-body">
                                            <div class="stage-card text-center">No Task Found</div>
                                        </div>
                                    </div>
                                    <div class="row" v-if="status.value==='Closed'">
                                        <div class="col-12 mt-3">
                                            <div v-for="status in taskStatus" v-if="status.value==='Cancelled'">
                                                <div class="card mb-2 b-radius-5 ">
                                                    <div class="card-body px-3 py-2">
                                                        <div class="form-group mb-0">
                                                            <label class="mt-1 mb-0 font-weight-bold" for="title">{{ status.value }} 
                                                                <b-badge class="ml-2" title="Tasks">{{ getItemCount(status.id) }}</b-badge>
                                                            </label>
                                                            <b-button class="pull-right addnew-quicktassk-btn" v-bind:class="{disabled: !elements.isDisable }" @click="quickTask(status.statusLookupId)" variant="outline-info" size="sm"><i class="fa fa-plus"></i> Quick Task</b-button>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="taskLists" v-bind:class="{'hide': !elements.isHide }">
                                                    <div v-for="task in filteredTasks">
                                                        <div v-if="task.status == status.value" class="card-media mb-2">
                                                            <div class="card-media-body dropdown show">
                                                                <a type="button" class="float-right" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                    <i class="fa fa-ellipsis-v p-1"></i>
                                                                </a>
                                                                <div class="dropdown-menu dropdown-primary" aria-labelledby="dropdownMenuLink">
                                                                    <a class="dropdown-item addnew-tassk pb-1 py-1" @click="viewItemDetails(task)" href="javascript:"><i class="fa fa-list-alt pr-2" aria-hidden="true"></i>Task Details</a>
                                                                    <a v-if="task.assignedById == userId" class="dropdown-item addnew-tassk py-1" @click="showEdit(task)" href="javascript:"><i class="fa fa-pencil pr-2"></i>Edit Task</a>
                                                                    <a class="dropdown-item addnew-tassk pb-1 py-1" @click="toggleDiv();duplicateTask(task);" href="javascript:"><i class="fa fa-copy pr-2" aria-hidden="true"></i>Duplicate Task</a>
                                                                    <a v-if="task.assignedById == userId || task.assignedToId == userId" class="dropdown-item addnew-tassk my-0 py-1" @click="taskId=task.id;showStatusPopup(task, false)" href="javascript:"><i class="fa fa-edit pr-2 mt-1 mb-0" aria-hidden="true"></i>Task Status Update</a>
                                                                    <a v-if="task.assignedById == userId || task.assignedToId == userId" class="dropdown-item addnew-tassk my-0 py-1" @click="openTimeSheet(task)" href="javascript:"><i class="fa fa-clock-o pr-2 mt-1 mb-0" aria-hidden="true"></i>Timesheet</a>
                                                                    <a v-if="task.assignedById == userId || task.assignedToId == userId" class="dropdown-item addnew-tassk my-0 py-1" @click="showTaskFinPopup(task)" href="javascript:"><i class="fa fa-money pr-2 mt-1 mb-0" aria-hidden="true"></i>Task Cost Update</a>
                                                                    <a v-if="task.assignedById == userId" class="dropdown-item py-1" @click="deleteTask(task.id)" v-b-modal.deleteTask href="javascript:"><i class="fa fa-trash pr-2"></i>Delete Task</a>

                                                                    <a v-if="task.assignedById == userId || userTypeCode == 'PRM' || userTypeCode == 'CAM' || userTypeCode == 'VSA'" class="dropdown-item addnew-tassk my-0 py-1" @click="archiveTask(task)" href="javascript:"><i class="fa fa-archive pr-2 mt-1 mb-0" aria-hidden="true"></i>Archive</a>
                                                                </div>
                                                                <span v-if="task.status!=null" title="Status" class="card-media-object-tag subtle float-right mr-1">{{ task.status }}</span>
                                                                <span title="Created on" class="subtle float-right mr-1">{{ getTimeDuration(task.createdDt) }}</span>

                                                                <div class="card-media-body-top">
                                                                    <span class="">
                                                                        <a href="javascript:" class="action-link" @click="viewItemDetails(task)">{{ task.title }}</a>
                                                                        <i v-if="task.priority" class="fa fa-exclamation-circle pl-1 pointer" :class="priorityColour(task.priority)" :title="`${task.priority ? task.priority : 'Low'} priority`"></i>
                                                                    </span>
                                                                </div>
                                                                <div class="divider-line"></div>
                                                                <div class="">
                                                                    <div class="row">
                                                                        <div class="col-6 my-auto">
                                                                            <a href="javascript:" class="subtle align-middle" @click="viewProcessActivity(task)" title="Process/Activity">{{ task.activity }}</a>
                                                                        </div>
                                                                        <div class="col-6">
                                                                            <span class="subtle" title="Task Progression">{{ task.progressPercentage ? task.progressPercentage : 0 }}% done</span>
                                                                            <b-progress height="7px" :variant="progressBarVariant(task)" v-model="task.progressPercentage"></b-progress>
                                                                        </div>
                                                                    </div>
                                                                    <div class="divider-line"></div>
                                                                    <div v-if="(task.estimatedStartDt != null) || (task.estimatedEndDt != null)">
                                                                        <div class="row">
                                                                            <div class="col-6">
                                                                                <span class="subtle" title="Start Date"><i class="fa fa-clock-o pr-1" aria-hidden="true"></i>{{ task.estimatedStartDt | formatDateTimeYearCheck }}</span>
                                                                            </div>
                                                                            <div class="col-6">
                                                                                <span class="subtle float-right" :class="(status.value !== 'Closed' && status.value !== 'Completed' && status.value !== 'Cancelled') ? dueDateStyling(task.estimatedEndDt) : ''" title="Due Date"><i class="fa fa-clock-o pr-1" aria-hidden="true"></i>{{ task.estimatedEndDt | formatDateTimeYearCheck }}</span>
                                                                            </div>
                                                                        </div>
                                                                        <div class="divider-line"></div>
                                                                    </div>
                                                                    <div class="row">
                                                                        <div class="col-12">
                                                                            <div class="row card-media-object-social-list">
                                                                                <div class="col-6 p-0">
                                                                                    <div v-if="(task.assignedBy != null)">
                                                                                        <b-badge class="task-badge" title="Assigned By"> {{ task.assignedBy }} </b-badge>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-6 p-0 text-right">
                                                                                    <div v-if="(task.assignedTo != null)">
                                                                                        <b-badge class="task-badge" title="Assigned To"> {{ task.assignedTo }} </b-badge>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div v-if="!getTaskByStatusId(status.id).length" class="card-media mb-2">
                                                        <div class="card-media-body">
                                                            <div class="stage-card text-center">No Task Found</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <AddTaskComponent ref="addTaskRef" :elements="elements" @refresh-task="getTasks" />
                    </div>
                </div>
            </b-overlay>
        </div>
        <ManageErmMasterPopup id="manageErmMasterPopup" ref="manageErmMasterPopup" :ermMasterInfo="ermMasterInfo" @update-parent="lookupData();" @closeDialog="showDialog=false"/>  
        <ActivityDetailPopup ref="activityDetailPopupRef" @closeDialog="closeDialog" />
        <ChecklistReportPopup ref="checklistReportPopupRef" :checklistTaskRef="checklistTaskRef" @updateChecklist="getTasks(task.entityRecordMasterId)" @closeDialog="closeDialog" />
        <TaskMemoryListPopup :key="taskMemoryPopupKey" :onMemoryItemSelect="onMemoryItemSelect" />
        <TaskDetailPopup v-if="showDialog" ref="taskDetailPopupRef" :detailViewData="detailViewData" :attachmentData="attachmentData" :referenceDetailId="referencePopupId" @closeDialog="closeDialog" />
        <TaskTimeSheetPopup ref="timeSheetPopupRef" :taskData="timeSheetTaskData"  @closeDialog="closeDialog" />
        <TaskStatusUpdatePopup v-if="showDialog" ref="taskSatatusUpdatePopupRef" :statusUpdate="statusUpdate" @updateParent="getTasks(task.entityRecordMasterId);addEmployeeAttendanceTimeline(4)" @closeDialog="closeDialog" />
        <QuickTaskPopup ref="ermAssignmentsRef" :masterData="task" :projectTypeId="this.projectTypeId" @refresh-task="getTasks(entityRecordMasterId)" @masterLookupRefresh="lookupData" />  
        <TaskFinancialPopup :key="taskFinancialPopupKey" ref="taskFinancialPopupRef" id="task-financial-popup" :taskFinancialData="taskFinancialData" @refreshData="" @closeDialog="closeDialog" />
        <DeleteComponent id="deleteTask" :onYes="onYes" :returnParams="deleteParam" title="Delete Confirmation" message="Are you sure to delete Task?" />
    </div>
</template>
<script>
import iziToast from 'izitoast';
import moment from 'moment';
import { required, email, minLength, maxLength, sameAs, between, numeric } from "vuelidate/lib/validators";
// import AddErmMasterPopup from '@/components/popup/action/AddErmMasterPopup.vue';
import ManageErmMasterPopup from '@/components/popup/action/ManageErmMasterPopup.vue';
import ActivityDetailPopup from '@/components/popup/itemdetail/ActivityDetailPopup.vue';
import AddTaskComponent from '@/components/common/AddTaskComponent.vue';
import QuickTaskPopup from '@/components/popup/action/QuickTaskPopup.vue';
import ChecklistReportPopup from '@/components/popup/action/ChecklistReportPopup.vue';
import TaskMemoryListPopup from '@/components/popup/action/TaskMemoryListPopup.vue';
import Editor from '@tinymce/tinymce-vue';
import Multiselect from 'vue-multiselect';
import FunctionalReferencePopup from '@/components/popup/reference/FunctionalReferencePopup.vue';
import TaskDetailPopup from '@/components/popup/itemdetail/TaskDetailPopup.vue';
import TaskFinancialPopup from '@/components/popup/action/TaskFinancialPopup.vue';
import TaskTimeSheetPopup from '@/components/popup/action/TaskTimeSheetPopup.vue';
import TaskStatusUpdatePopup from '@/components/popup/action/TaskStatusUpdatePopup.vue';
// import TimeSheetComponent from '@/components/common/TimeSheetComponent.vue';
import { EventBus } from '@/main';
import carousel from 'vue-owl-carousel';

export default {
    name: 'app',
    props: {
        taskBoard: Object,
    },
    components: {
        // AddErmMasterPopup,
        ManageErmMasterPopup,
        // ERMMasterPopup,
        ActivityDetailPopup,
        ChecklistReportPopup,
        TaskDetailPopup,
        Multiselect,
        Editor,
        FunctionalReferencePopup,
        TaskMemoryListPopup,
        TaskTimeSheetPopup,
        AddTaskComponent,
        // TimeSheetComponent,
        QuickTaskPopup,
        TaskFinancialPopup,
        TaskStatusUpdatePopup,
        carousel
    },
    data() {
        return {
            showOverlay: false,
            elements: {
                clientTaskPage: '',
                editMode: false,
                viewMode: false,
                viewOnlyComponent: false,
                filterMode: false,
                taskStatusUpdate: false,
                isHide: true,
                isDisable: true
            },
            taskMemoryPopupKey: 100,
            isArchive: false,
            ermMasterInfo: {
                title: "Task Plan",
                entity: 'development_plan_master',
                refEntity: 'task',
                tooltip: 'New Task Plan',
                titleLabelName: 'Plan Title',
                redirectURLName: 'task',
                newEntityMasterURL: 'dvpmas',
                redirectURL: '/dvpmas',
                actionName: 'Task List',
                previewURLName: 'tslrep',
                sharedUrlName:'task',
                showDateRange: true,
                showPlanLinkControl: true,
                masterLinkTitle: 'Master Plan Link',
                masterLinkEntity: 'plan_master',
                hideProject: false,
                hideClient: false,
                showMultipleMasterControl: true,
            },

            // ermMasterInfo: {
            //     title: "Task Plan",
            //     entity: 'development_plan_master',
            //     refEntity: 'task',
            //     tooltip: 'New Task Plan',
            //     titleLabelName: 'Plan Title',
            // },
            carousel:false,
            showDialog: false,
            taskId: null,
            timeSheetTaskData: null,
            effortType: '',

            vhextAccountId: localStorage.getItem("vhextAccountId"),
            projectId: localStorage.getItem("projectId"),
            // entityRecordMasterId: this.$route.params.ermId,
            projectTypeId: localStorage.getItem("projectTypeId"),
            projId: localStorage.getItem("projId"),
            projectName: localStorage.getItem("projectName"),

            userId: localStorage.getItem("userId"),
            userTypeCode: localStorage.getItem("userTypeCode"),
            entity: 'task',

            filteredUserId: null,

            startTime: '',
            timerDuration: '00:00:00',
            timerValue: 0,
            empWorkStatus: {
                empTimelineStateEnum: 0,
                empTimelineDt: '',
                task: '',
            },
            empWorkState: '',

            task: {
                id: null,
                taskId: '',
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                projectId: localStorage.getItem("projectId"),
                entityRecordMasterId: '',
                userId: localStorage.getItem("userId"),
                entity: 'task',
                title: '',
                isArchive: false,
                planId: '',
                requirementId: '',
                requirement: '',
                activityId: '',
                activity: '',
                taskTypeId: '',
                type: '',
                description: '',
                priorityId: '',
                priority: '',
                startDate: '',
                endDate: '',
                estimatedStartDt: '',
                estimatedStartDate: this.getDateValue(new Date()),
                estimatedStartTime: '',
                estimatedEndDt: '',
                estimatedEndDate: this.getDateValue(new Date()),
                estimatedEndTime: '',
                assignedById: localStorage.getItem("userId"),
                assignedTo: '',
                assignedToId: '',
                effort: '',
                effortType: '1',
                progressPercentage: 0,
                statusId: '',
                status: '',
                hasReference: '',
                hasAttachment: '',
                hasComment: '',
                hasChecklist: '',
                entityId: '',
                refErmId: '',
                createdDt: '',
                lastUpdatedDt: '',
                taskAssignment: '',
                attachments: [],
                checkListTag: '',
                response: '',
                checklistList: [],
                checklistItemCount: '',
            },

            taskFinancialData: {
                vhextAccountId: '',
                projectId: '',
                taskId: '',
            },
            taskFinancialPopupKey: 0,

            statusUpdate: {
                id: '',
                taskId: '',
                title: '',
                entityRecordMasterId: '',
                progressPercentage: 0,
                statusId: '',
                status: '',
                activityId: '',
                startDateValue: '',
                endDateValue: '',
                startTimeValue: '',
                endTimeValue: '',
                popupTitle: '',
            },

            checklistTaskRef: {
                id: '',
                title: '',
                itemCount: '',
                checkeditemCount: '',
            },
            detailViewData: {},
            referencePopupId: 0,

            employeeAttendance: {
                id: '',
                empAttendanceTimelineId: '',
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                employeeAttendanceId: '',
                humanResourceId: '',
                empTimelineStateEnum: '',
                empTimelineDt: '',
                nextEmpTimelineDt: '',
                createdById: localStorage.getItem("userId"),
                workingDayId: '',
                leaveTypeId: '',
                leaveTimeId: '',
                hrmLeaveTypeId: '',
                remark: '',
                empAttendanceStatusId: '',
                statusLookupId: '',
            },

            messageBoxData: {
                title: 'Please Confirm',
                size: 'md',
                buttonSize: 'xs',
                okVariant: 'primary',
                cancelTitle: 'No',
                okTitle: 'Yes',
                headerClass: 'py-2 px-3',
                bodyClass: 'fs-14 px-3',
                footerClass: 'py-2 px-3',
                centered: true,
                hideHeaderClose: false
            },


            // effortTypes: [],
            effortTypes: [
                { name: "Man Hours", id: 1 },
                { name: "Man Days", id: 2 },
                { name: "Man Months", id: 3 },
                { name: "Man Years", id: 4 },
            ],

            attachmentData: {},

            allTasks: [],
            tasks: [],
            taskCheckList: [],
            taskCheckListAll: [],
            comments: [],
            addMoreAttachment: [],
            users: [],
            ermLookup: [],
            ermObject: {
                id:   '',
                value:   '',
                projectId: '',
            },

            taskToDoCount: 0,
            taskStatus: [],
            entityRecordMasters: [],
            search: '',
            totalRows: 1,
            currentPage: 1,
            perPage: 10,
            pageOptions: [10, 15, 20],
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',
            filter: null,
            filterOn: [],
            taskFields: [
                { key: 'index', label: 'SN' },
                { key: 'title', label: 'Task Title', tdClass: 'table-title-col-size', sortable: true, sortDirection: 'desc' },
                // { key: 'planTitle', label: 'Plan Item', tdClass: 'table-title-col-size', sortable: true, sortDirection: 'desc' },
                // { key: 'taskTypeId', label: 'Type', sortable: true, sortDirection: 'desc' },
                { key: 'estimatedStartDt', label: 'Start Date', formatter: 'formatDateTimeAmPm', sortable: true, sortDirection: 'desc' },
                { key: 'estimatedEndDt', label: 'Due Date', formatter: 'formatDateTimeAmPm', sortable: true, sortDirection: 'desc' },
                { key: 'workHour', label: 'Effort', tdClass: 'table-cw-400', sortDirection: 'desc' },
                { key: 'estimatedCost', label: 'Cost', tdClass: 'table-title-col-size', sortable: true, sortDirection: 'desc' },
                { key: 'assignedBy', label: 'Assigned By', tdClass: 'table-title-col-size', sortable: true, sortDirection: 'desc' },
                { key: 'assignedToId', label: 'Assigned To', tdClass: 'table-title-col-size', sortable: true, sortDirection: 'desc' },
                { key: 'statusId', label: 'Status', sortable: true, tdClass: 'table-cw-100', sortDirection: 'desc' },
                { key: 'actions', label: 'Actions' }
            ],
            deleteParam: {
                id: 0
            },
            toolbar: 'undo redo preview | formatselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | weblinks',
            initObj: {
                height: 300,
                branding: false,
                menubar: true,
                plugins: ['link', 'paste', 'wordcount', 'preview', 'image imagetools'],
                paste_data_images: true,
                images_upload_url: 'postAcceptor.php',
                automatic_uploads: false,
                file_picker_types: 'image',
                relative_urls: false,
                remove_script_host: false,
            },
        }
    },
    computed: {
        issueStatus() {
            return this.allStatus.filter(status => status.value !== 'Draft' && status.value !== 'Re-opened');
        },

        filteredTasks() {
            if (this.tasks && this.tasks.length>0) {
                return this.tasks.filter(task => {
                    return task.title.toLowerCase().includes(this.search.toLowerCase())
                })
            }
            else {
                return [];
            }
        }
    },
    mounted() {
        // this.pageTitleClock();
        this.ermObject.id = localStorage.getItem("ermId");
        this.ermObject.value = localStorage.getItem("ermTitle");
        this.setupPage();
        console.log(this.projectTypeId);
        this.lookupData();
        this.addMoreAttachment.push(this.addMoreAttachment.length + 1);
        let erdId = localStorage.getItem("erdId"); 
        if(erdId != null && erdId.length >0){
            this.fromActionItem(erdId);
        }
        this.getAttendanceData();
        this.getEmpWorkStatus();

        /*this.elements.editMode = false;
        this.elements.viewMode = true;
        this.elements.viewOnlyComponent = true;*/



        /* this.elements.viewMode = false;
         this.elements.visibileChecklist = false;
         this.elements.visibileCommAccordian = false;
         this.elements.visibileTimeSheet = false;
         this.elements.viewOnlyComponent = true;*/
    },
    validations: {
        task: {
            title: {
                required,
                minLength: minLength(2),
                maxLength: maxLength(100)
            },
            statusId: {
                required
            },
            progressPercentage: {
                between: between(0, 100),
                numeric
            },
            entityRecordMasterId: {
                required
            }
        },
    },
    methods: {
        lookupData: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
                projectId: this.projectId,
                projectTypeId: this.projectTypeId,
                userId: this.userId,
                entity: this.entity,
            }
            axios.post(this.$store.getters.getAPIBasePath + '/task/lookup/all', data)
                .then((response) => {
                    let result = response.data;
                    this.users = result.userLookup;
                    this.taskStatus = result.statusLookup;
                    this.ermLookup = result.entityERMLookup;

                    // this.entityRecordMasterId=this.$route.params.ermId;

                    if(!this.entityRecordMasterId){
                        this.entityRecordMasterId = this.ermLookup && this.ermLookup.length>0 ? this.ermLookup[0].id : '';
                        this.task.entityRecordMasterId = this.ermLookup && this.ermLookup.length>0 ? this.ermLookup[0].id : '';
                        this.projectId = this.ermLookup && this.ermLookup.length>0 ? this.ermLookup[0].projectId : '';
                    }

                    let ermLookupFilter = this.ermLookup.find((ermData)=> ermData.id==this.entityRecordMasterId);
                    this.getTasks(this.entityRecordMasterId);

                    if(ermLookupFilter){

                        this.ermObject.id = ermLookupFilter.id;
                        this.ermObject.value = ermLookupFilter.value;
                        this.ermObject.projectId = ermLookupFilter.projectId;
                        
                        this.setERMData(this.ermObject.id,this.ermObject.value);

                    }

                    this.task.statusId = this.taskStatus[0].statusLookupId;
                    this.carousel = true;

                    this.getTasks(this.entityRecordMasterId);
                })
        },
        getTasks: function(ermId) {
            if (this.elements.clientTaskPage && this.projectId) {
                let data = {
                    vhextAccountId: localStorage.getItem("vhextAccountId"),
                    projectId: this.projectId,
                    userId: localStorage.getItem("userId"),
                    isArchive: this.isArchive
                }
                axios.post(this.$store.getters.getAPIBasePath + '/task/clienttask/list', data)
                    .then((response) => {
                        let result = response.data;
                        this.tasks = result.taskList;
                        this.allTasks = this.tasks;
                        console.log(this.tasks);
                    })
            }
            else if (!this.elements.clientTaskPage && this.projectId) {
                let data = {
                    entityRecordMasterId: ermId,
                    isArchive: this.isArchive,
                }
                console.log(data);
                axios.post(this.$store.getters.getAPIBasePath + '/task/list', data)
                    .then((response) => {
                        let result = response.data;
                        this.tasks = result.taskList;
                        this.allTasks = this.tasks;
                        console.log(this.tasks);
                        this.$nextTick(() => {                            
                            if (this.elements.filterMode && this.filteredUserId) {
                                this.teamMemberFilter(this.filteredUserId);
                            } else this.clearFilter();
                        })
                    })
            }
            else {
                let data = {
                    vhextAccountId: localStorage.getItem("vhextAccountId"),
                    entityRecordMasterId: ermId,
                    userId: localStorage.getItem("userId"),
                    isArchive: this.isArchive
                }
                console.log(data);
                axios.post(this.$store.getters.getAPIBasePath + '/task/usertask/list', data)
                    .then((response) => {
                        let result = response.data;
                        this.tasks = result.taskList;
                        this.allTasks = this.tasks;
                        console.log(this.tasks);
                        this.ermFilter(this.ermObject);
                        this.$nextTick(() => {                            
                            if (this.elements.filterMode && this.filteredUserId) {
                                this.teamMemberFilter(this.filteredUserId);
                            } else this.clearFilter();
                        })
                    })
            }
        },        
        fromActionItem(erdId){
            this.$nextTick(()=> {                
                this.$refs.addTaskRef.viewTask(erdId);
            })
            this.toggleDiv();
            localStorage.setItem("erdId", null);
        },
        toggleDiv: function() {
            this.elements.isHide = !this.elements.isHide;
            this.elements.isDisable = !this.elements.isDisable;
        },
        setupPage: function() {
            console.log(this.$route.name);
            if (this.$route.name == 'clitask' || this.$route.name == 'usertask') {
                this.elements.clientTaskPage = true;
            } else this.elements.clientTaskPage = false;
        },

        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },
        ermFilter: function(ermData) {
            console.log(ermData);

            if (ermData.id !== null && ermData.id) {
                this.projectId = ermData.projectId;
                this.task.projectId = ermData.projectId;
                this.changeERMPlan(ermData.id);
                this.setERMData(ermData.id,ermData.value);

            } else if (ermData == 'all') {
                this.tasks = this.allTasks;
            }
            console.log(ermData);
        },
        showView: function(data) {
            this.$nextTick(()=> {                
                this.$refs.addTaskRef.showView(data);
            })
        },
        duplicateTask: function(data) {
            this.$nextTick(()=> {                
                this.$refs.addTaskRef.duplicateTask(data);
            });
        },
        onMemoryItemSelect: function(data) {
            this.toggleDiv();
            this.$nextTick(()=> {                
                this.$refs.addTaskRef.duplicateTask(data);
            });
        },
        changeERMPlan: function(ermId) {
            if (this.task.entityRecordMasterId != ermId) {
                this.task.entityRecordMasterId = ermId;
                this.entityRecordMasterId = ermId;

                this.getTasks(ermId);
                // alert(this.taskBoard.isPublic);
                if(this.taskBoard.isPublic){
                    this.$route.path != '/landing/tskbrd' ? this.$router.push('/landing/tskbrd') : null;
                }else{
                   // this.$router.push('/tskbrd/' + ermId); 
                }
                
                
            }
        },
        archiveTask: function(item) {
            let data = {
                id: item.id,
                entity: this.entity,
                columnName: 'is_archive',
                stateValue: (item.isArchive == true ? false : true)
            }
            console.log(data)
            this.showOverlay=true;
            axios.post(this.$store.getters.getAPIBasePath + '/dynamicsql/recordstate/update', data)
                .then((response) => {
                    this.showOverlay=false;
                    let result = response.data;
                    if (result.status == "OK") {
                        iziToast.success({
                            title: 'Task Archived',
                            position: 'topRight'
                        });
                        this.getTasks(this.task.entityRecordMasterId);
                    }
                })
                .catch(error => {
                    this.showOverlay=false;
                });
        },
        deleteTask: function(id) {
            console.log(id)
            this.deleteParam.id = id;
        },
        onYes: function() {
            let data = {
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                projectId: localStorage.getItem("projectId"),
                entity: this.entity,
                id: this.deleteParam.id,               
            }
            console.log(data);

            axios.post(this.$store.getters.getAPIBasePath + '/task/delete', data)
                .then(response => {
                    var result = response.data;
                    if (result.status == "OK") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.getTasks(this.task.entityRecordMasterId);
                    }
                })
                .catch(error => {
                    console.log(error);
                });
        },
        getTaskByStatusId(statusId) {
            return this.tasks && this.tasks.length>0 ? this.tasks.filter(task => task.statusId == statusId) : [];
        },
        teamMemberFilter: function(userId) {
            this.elements.filterMode = true;
            this.tasks = this.allTasks;
            if (this.tasks != null) {
                var userTask = this.tasks;
                this.tasks = userTask.filter(function(e) {
                    return e.assignedToId == userId || e.assignedById == userId;
                });
            }
        },
        clearFilter: function() {
            this.tasks = this.allTasks;
            this.filteredUserId = null;
            this.elements.filterMode = false;
        },
        progressBarVariant: function(task) {
            if (task.progressPercentage != null) {
                if (task.progressPercentage < 25) {
                    return 'danger';
                } else if (task.progressPercentage < 50) {
                    return 'warning';
                } else if (task.progressPercentage < 75) {
                    return 'info';
                } else if (task.progressPercentage < 100) {
                    return 'success';
                } else if (task.progressPercentage == 100) {
                    return 'primary';
                }
            }
        },
        gatGanttChart: function() {
            if (this.task.entityRecordMasterId !== null) {
                this.$router.push('/taskgantt/' + this.task.entityRecordMasterId);
            }
        },
        dueDateStyling(date){
            date = new Date(date);
            let today = new Date();

            if (date && date !== null) {

                if (this.getDateValue(date) == this.getDateValue(today) && (date > today)) {        
                    return 'text-warning';
                }
                else if (date < today) {        
                    return 'text-danger';
                }
                else if (date > today) {        
                    return 'text-success';
                }
            }
        },
        priorityColour(priority) {
            if (priority && priority == 'High') {
                return 'text-danger';
            }
            if (priority && priority == 'Medium') {
                return 'text-warning';
            }
            else {
                return 'text-success';
            }
        },
        quickTask: function(statusId) {
            this.$bvModal.show('ermAssignments');
            this.$nextTick(() => {
                this.task.statusId = statusId;
                this.task.entityRecordMasterId = this.entityRecordMasterId;
                this.task.projectId = this.projectId;
                this.task.assignedToId = this.filteredUserId ? this.filteredUserId : '';
                this.$refs.ermAssignmentsRef.onRefDataChange('Quick Task');
            })
        },
        showEdit: function(data) {
            this.$bvModal.show('ermAssignments');
            this.$nextTick(() => {
                this.$refs.ermAssignmentsRef.showEdit(data);
            })
        },
        viewItemDetails: function(data) {
            this.detailViewData = data;
            this.referencePopupId = data.id;
            this.attachmentData.refEntity = this.entity;
            this.attachmentData.refRecordId = data.id;
            this.showDialog = true;
            this.$nextTick(()=> {
                this.$refs.taskDetailPopupRef.getDetails(this.referencePopupId, null);
            })
        },
        viewProcessActivity: function(data) {
            this.showDialog= true;
            this.$nextTick(()=> {
                this.$refs.activityDetailPopupRef.getDetails(data.processId, data.activityId);
            })
        },
        showStatusPopup: function(data, endTimeline) {
            console.log(data);
            if (data && data !== null) {
                this.statusUpdate.id = data.id;
                this.statusUpdate.taskId = data.taskId;
                this.statusUpdate.title = data.title;
                this.statusUpdate.entityRecordMasterId = data.entityRecordMasterId;
                this.statusUpdate.statusId = data.statusId;
                this.statusUpdate.status = data.status;
                this.statusUpdate.activityId = data.activityId;
                this.statusUpdate.progressPercentage = data.progressPercentage ? data.progressPercentage : 0;

                if (endTimeline) {
                    this.statusUpdate.popupTitle = null;
                    this.statusUpdate.startDateValue = this.empWorkStatus.empTimelineDt ? this.getDateValue(this.empWorkStatus.empTimelineDt) : this.getDateValue(new Date());
                    this.statusUpdate.endDateValue = this.getDateValue(new Date());
                    this.statusUpdate.startTimeValue = this.empWorkStatus.empTimelineDt ? this.getTimeValue(this.empWorkStatus.empTimelineDt) : null;
                    this.statusUpdate.endTimeValue = this.getTimeValue(new Date());
                }
                else {
                    this.statusUpdate.popupTitle = null;
                    this.statusUpdate.startDateValue = this.getDateValue(new Date());
                    this.statusUpdate.endDateValue = this.getDateValue(new Date());
                    this.statusUpdate.endTimeValue = '';
                    this.statusUpdate.startTimeValue = '';
                }

                this.showDialog = true;
                this.$nextTick(()=> {
                    this.$bvModal.show('taskSatatusUpdatePopup');
                })
            }
        },
        showTaskFinPopup: function(data) {
            console.log(this.$refs)
            this.taskFinancialData.vhextAccountId = data.vhextAccountId;
            this.taskFinancialData.projectId = data.projectId;
            this.taskFinancialData.taskId = data.id;
            this.taskFinancialPopupKey++;
            this.$nextTick(()=> {
                this.$bvModal.show('task-financial-popup');
            })
        },
        openTimeSheet: function(data) {
            this.$nextTick(()=> {
                this.timeSheetTaskData = data;
            });
            this.showDialog = true;
            this.$nextTick(()=> {
                this.$refs.timeSheetPopupRef.openDialog();
            })
        },
        openChecklistReportPopup(data) {
            
            this.taskId = data.id;
            let refEntity = 'task';
            this.checklistTaskRef.title = data.title +  ' - Checklist';
            // alert(this.checklistTaskRef.title);
            this.$refs.checklistReportPopupRef.getChecklist(this.taskId, refEntity);
        },
        closeDialog: function() {
            console.log('closed');
            this.showDialog = false;
            // this.clearData();
        },
        setERMData: function(ermId,ermTitle) {
            localStorage.setItem('ermId', ermId);
            localStorage.setItem('ermTitle', ermTitle);
        },

        redirectPage: function() {

            if(this.taskBoard.isPublic){
                if (this.ermObject.id != null && this.ermObject.id > 0) {
                    this.$router.push('/landing/task');
                } else {
                    this.$router.push('/landing/dvpmas/list');
                }
            }else{

                if (this.ermObject.id != null && this.ermObject.id > 0) {
                    this.$router.push('/task/' + this.ermObject.id);
                } else {
                    this.$router.push('/dvpmas/list');
                }

            }
        },
        routeToMasterPage() {
            let routePath = this.$route.path;
            console.log(routePath)
            if (routePath.includes('landing')) {
                this.$router.push('/landing/dvpmas/list');
            } else {
                this.$router.push('/dvpmas/list');
            }
        },
        redirectTaskGantt: function() {
            if(this.taskBoard.isPublic){
                this.$router.push('/landing/taskganttchart');
            }
            else{
                this.$router.push('/taskganttchart');
            }

        },
        getItemCount: function(statusId) {

            return (this.filteredTasks.filter((itemData)=> itemData.statusId==statusId)).length;

        },
        memorizeTask: function(itemData) {
            let data = {
                'id': itemData.id,
                'entity': 'task',
                'columnName': 'is_memorized',
                'stateValue': itemData.isMemorized ? false : true,
            }
            var confirmMessage = '';
            if (itemData.isMemorized) {
                confirmMessage = "Are you sure to remove the task from memory?"
            } else {
                confirmMessage = "Are you sure to add the task to memory?"
            }
            this.$bvModal.msgBoxConfirm(confirmMessage, this.messageBoxData)
            .then(value => {
                if (value) {
                    this.showOverlay = true;
                    axios.post(this.$store.getters.getAPIBasePath + '/dynamicsql/recordstate/update', data)
                        .then((response) => {
                            this.showOverlay = false;
                            let result = response.data;
                            if (result.status == "OK") {
                                iziToast.success({
                                    title: 'Updated',
                                    position: 'topCenter'
                                });
                                this.getTasks(this.entityRecordMasterId);
                                this.taskMemoryPopupKey++;
                            }
                        })
                        .catch((error) => {
                            this.showOverlay = false;
                        })
                }
            })
        },
        getAttendanceData: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
                userId: this.userId,
                workingDate: new Date(),
            }
            this.showOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/company/dailyemployeeattendance/view', data)
                .then((response) => {
                    this.showOverlay = false;
                    let result = response.data;
                    this.employeeAttendanceData = result.employeeAttendance;
                })
                .catch(error => {
                    this.showOverlay = false;
                    iziToast.error({
                       title: 'Error',
                       message: 'Error',
                       position: 'topRight'
                   });
                })
        },
        addEmployeeAttendanceTimeline: function(empTimelineStateEnum) {
            // temporarily added return
            // remove the return once this functionality is completed
            return;

            this.$nextTick(() => {
                this.clearTimerVal();
            });
            this.showOverlay = true;
            // this.employeeAttendance.id = data.id;
            // this.employeeAttendance.empAttendanceTimelineId = data.empAttendanceTimelineId;
            this.employeeAttendance.vhextAccountId = localStorage.getItem("vhextAccountId");
            this.employeeAttendance.employeeAttendanceId = this.employeeAttendanceData.employeeAttendanceId;
            this.employeeAttendance.humanResourceId = this.employeeAttendanceData.humanResourceId;
            this.employeeAttendance.taskId = this.taskId;
            this.employeeAttendance.empTimelineDt = new Date();
            this.employeeAttendance.nextEmpTimelineDt = new Date();
            this.employeeAttendance.workingDate = new Date();
            this.employeeAttendance.createdById = this.employeeAttendanceData.createdById;
            this.employeeAttendance.workingDayId = this.employeeAttendanceData.workingDayId;
            this.employeeAttendance.leaveTypeId = this.employeeAttendanceData.leaveTypeId;
            this.employeeAttendance.leaveTimeId = this.employeeAttendanceData.leaveTimeId;
            this.employeeAttendance.hrmLeaveTypeId = this.employeeAttendanceData.hrmLeaveTypeId;
            // this.employeeAttendance.remark = this.employeeAttendanceData.remark;
            this.employeeAttendance.empTimelineStateEnum = empTimelineStateEnum;

            // statusLookupId denotes present status
            this.employeeAttendance.statusLookupId = 0;
            console.log(this.employeeAttendance);
            // return;
            axios.post(this.$store.getters.getAPIBasePath + '/resource/empattendancetimeline/add', this.employeeAttendance)
                .then((response) => {
                    this.showOverlay = false;
                    let result = response.data;
                    if (result.status == "CREATED") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.getAttendanceData();
                        this.getEmpWorkStatus();
                        this.$nextTick(() => {
                            EventBus.$emit('onEmpAttTimelineUpdate');
                        })
                    }
                })
                .catch(error => {
                    this.showOverlay = false;
                    iziToast.error({
                       title: 'Error',
                       message: 'Error',
                       position: 'topRight'
                   });
                })
        },
        getEmpWorkStatus: function() {
            let data = {
                userId: this.userId,
            }
            axios.get(this.$store.getters.getAPIBasePath + '/resource/empworkstatus/view/'  + this.userId)
                .then((response) => {
                    let result = response.data;
                    console.log(this.empWorkStatus, this.empWorkState, 1)
                    this.empWorkStatus = result.empWorkStatus;
                    this.empWorkState = this.empWorkStatus ? this.empWorkStatus.empTimelineStateEnum : 0;

                    if (this.empWorkStatus) {
                        this.$nextTick(() => {
                            if (this.empWorkStatus.empTimelineStateEnum == 1) {
                                this.startTime = this.empWorkStatus.empTimelineDt;
                                this.pageTitleClock();
                            }
                            else if (this.empWorkStatus.empTimelineStateEnum == 2) {
                                this.startTime = this.empWorkStatus.empTimelineDt;
                                this.pageTitleClock();
                            }
                            else if (this.empWorkStatus.empTimelineStateEnum == 3) {
                                this.startTime = this.empWorkStatus.empTimelineDt;
                                this.pageTitleClock();
                            }
                            else if (this.empWorkStatus.empTimelineStateEnum == 4) {
                                this.startTime = '';
                                this.timerDuration = '00:00:00';
                            }
                        })
                    }
                })
        },
        pageTitleClock: function() {
            // console.log(this.timerDuration, this.timerValue)
            this.timerDuration = this.startTime ? this.getTimeDiff(new Date(), this.startTime, true) : '00:00:00';
            this.timerValue = setTimeout(this.pageTitleClock, 1000);
        },
        clearTimerVal() {
            clearTimeout(this.timerValue);
            this.startTime = '';
            this.timerDuration = '00:00:00';
        },

        // findEffortType(typeId) {
        //     let type = this.effortTypes.find(eff => eff.id == typeId);
        //     return type ? type.name : '';
        // }
        // function ddlToolTipsRegion(ddlRegion) {  
        //     if (ddlRegion.value == 0) {  
        //         ddlRegion.title = "";  
        //     } else {  
        //         ddlRegion.title = "Selected Region is : " + ddlRegion.options[ddlRegion.selectedIndex].text;  
        //     }  
        // }  
        // pageTitleClock: function() {
        //     var date = new Date()
        //     var year = date.getFullYear();
        //     var month = date.getMonth();
        //     var day = date.getDate();
        //     var hour = date.getHours();
        //     var minute = date.getMinutes();
        //     var second = date.getSeconds();
        //     var months = new Array("JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC");
        //     var monthname = months[month];
        //     if (hour > 12) {hour = hour - 12}
        //     if (minute < 10) {minute = "0" + minute}
        //     if (second < 10) {second = "0" + second}
        //     document.title = monthname + " " + day + ", " + year + " - " + hour + ":" + minute + ":" + second
        //     setTimeout(
        //         this.pageTitleClock
        //     , 1000);
        // }
    },
}
</script>