<template>
    <div class="fixed-inner-header">
        <div class="container-fluid">
            <div class="row inner-header">
                <div class="col-12">
                    <div class="row">
                        <div class="col-sm-3">
                            <h4 class="pl-2 mb-0 fs-16">{{ showEmployee ? 'Employee' : 'Non Employee' }} Attendance Sheet</h4>
                        </div>

                        <div class="col-sm-3 text-right">
                            <b-form-group v-slot="{ ariaDescribedby }">
                                <b-form-radio-group v-model="showEmployee" :aria-describedby="ariaDescribedby" name="radio-sub-component" class="top-header-btn" buttons button-variant="outline-primary sbtn" size="xs">
                                    <b-form-radio :value="true" class="py-1 px-2"><i class="fa fa-users pr-1"></i>Employees</b-form-radio>
                                    <b-form-radio :value="false" class="py-1 px-2"><i class="fa fa-user-secret pr-1"></i>Non Employees</b-form-radio>
                                </b-form-radio-group>
                            </b-form-group>
                        </div>
                        <div class="col-sm-6">
                            <nav class="breadcrumb-container d-sm-block d-lg-inline-block float-right" aria-label="breadcrumb">
                                <ol class="breadcrumb pt-0 mb-0">
                                    <li class="breadcrumb-item">
                                        <a href="javascript:" @click="openWorkingDay(0)" v-b-modal.setWorkingDayPopup ><i class="fa fa-cogs action-link pr-1"></i>Manage Daily Work Shift</a>
                                    </li>
                                    <li class="breadcrumb-item">
                                        <a href="javascript:" @click="getPrevUrl()"><i class="fa fa-reply action-link pr-2" aria-hidden="true"></i>Back</a>
                                    </li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                    <div class="separator mb-2"></div>
                </div>
            </div>
            <div class="form-row">
                <div class="col-lg-11 col-xl-11">
                    <div class="card h-100">
                        <div class="card-body px-3 pb-3 pt-2">
                            <b-overlay :show="showOverlay" spinner-variant="primary" opacity="0.4" rounded="sm">
                                <div class="row view-filter mb-1">
                                    <div class="col-3">
                                        <div class="row">
                                            <b-col xl="5" cols="5" class="pr-0 mt-1">
                                                <label class="mb-0 mt-1">Attendance Date</label>
                                            </b-col>
                                            <b-col xl="7" cols="7" class="pl-0">
                                                <input type="date" class="form-control" v-model="searchDate" id="dateWD" >
                                            </b-col>
                                        </div>
                                    </div>
                                    <div class="col-2">
                                        <select v-model="departmentId" class="form-control" >
                                            <option value="">All Departments</option>
                                            <option v-for="(info, index) in departments" :value="info.id" :key="index">{{info.value}}</option>
                                        </select>
                                    </div>
                                    <div class="col-2">
                                        <select v-model="workShiftId" class="form-control" >
                                            <option value="">All Work Shift</option>
                                            <option v-for="(info, index) in workShifts" :value="info.id" :key="index">{{info.value}}</option>
                                        </select>
                                    </div>
                                    <div class="col-1 text-left">
                                        <button type="submit" @click="getEmployees()" class="sbtn btn btn-primary btn-xs px-2 mr-1 mt-1">Show</button>
                                    </div>
                                    <div class="col-2">
                                        <p class="mb-0 mt-1 title-highlight">{{ workingDate | formatFullDate }}</p>
                                    </div>
                                    <div class="col-2 text-right">
                                        <div class="dataTables_filter">
                                            <label>
                                                <input type="search" v-model="filter" class="form-control form-control-sm" placeholder="Search...">
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <template v-if="showEmployee">
                                    <AttendanceTableComponent :editId="editId" :filterModel="filter" :totalRows="totalRowsEmp" :items="employees" :fields="employeeFields" @editEmpOvertime="editEmpOvertime" @editEmpLeaveType="editEmpLeaveType" @saveEmpOvertimeData="saveEmpOvertimeData" @onFiltered="onFiltered" @onRowSelected="onRowSelected" @openWorkingDay="openWorkingDay" @clearData="clearData" @showTimelineListPopup="showTimelineListPopup" @viewItemDetails="viewItemDetails" @row-selected="onRowSelected" :filter="filter" @filtered="onFilteredEmp" />
                                </template>
                                <template v-else>
                                    <AttendanceTableComponent :editId="editId" :filterModel="filter" :totalRows="totalRowsEmp" :items="otherResources" :fields="otherResourceFields" @editEmpOvertime="editEmpOvertime" @editEmpLeaveType="editEmpLeaveType" @saveEmpOvertimeData="saveEmpOvertimeData" @onFiltered="onFiltered" @onRowSelected="onRowSelected" @openWorkingDay="openWorkingDay" @clearData="clearData" @showTimelineListPopup="showTimelineListPopup" @viewItemDetails="viewItemDetails" @row-selected="onRowSelected" :filter="filter" @filtered="onFilteredEmp" />
                                </template>
                            </b-overlay>
                        </div>
                    </div>
                </div>
                <div class="col-lg-1 col-xl-1">
                    <div class="card h-100">
                        <div class="card-body mx-auto py-2 px-0">
                            <div class="row">
                                <!-- <label class="mx-auto mb-3 d-block">Summary</label> -->
                                <div class="col-sm-12 text-center my-auto mx-auto">
                                    <div v-if="showEmployee" class="card mb-1 py-2 px-1">
                                        <p class="mb-0 mt-1 fs-12">Attendees</p>
                                        <span title="Performance">{{ empAttendeesPercentage ? empAttendeesPercentage : 0 }} %</span>
                                        <b-progress class="mx-3" height="10px" :value="empAttendeesPercentage" :variant="dailyAttendanceProgressVariant(empAttendeesPercentage)"></b-progress>
                                    </div>
                                    <div v-else class="card mb-1 py-2 px-1">
                                        <p class="mb-0 mt-1 fs-12">Attendees</p>
                                        <span title="Performance">{{ attendeesPercentage ? attendeesPercentage : 0 }} %</span>
                                        <b-progress class="mx-3" height="10px" :value="attendeesPercentage" :variant="dailyAttendanceProgressVariant(attendeesPercentage)"></b-progress>
                                    </div>
                                    <div class="card mb-1 py-2 px-1">
                                        <p class="mb-0 mt-1 fs-12">Total</p>
                                        <span class="badge badge-primary px-4 mx-auto fs-12">{{ showEmployee ? empTotalCount : totalCount }}</span>
                                    </div>
                                    <div class="card mb-1 py-2 px-1">
                                        <p class="mb-0 mt-1 fs-12">Present</p>
                                        <span class="badge badge-success px-4 mx-auto fs-12">{{ showEmployee ? empTotalPresent : totalPresent }}</span>
                                    </div>
                                    <div class="card mb-1 py-2 px-1">
                                        <p class="mb-0 mt-1 fs-12">Half Day Leave</p>
                                        <span class="badge badge-warning px-4 mx-auto fs-12">{{ showEmployee ? empTotalHalfDayLeave : totalHalfDayLeave }}</span>
                                    </div>
                                    <div class="card mb-1 py-2 px-1">
                                        <p class="mb-0 mt-1 fs-12">Leave</p>
                                        <span class="badge badge-danger px-4 mx-auto fs-12">{{ showEmployee ? empTotalLeave : totalLeave }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <b-modal id="leaveUpdatePopup" centered ref="leaveUpdatePopupRef" @close="clearData" modal-class="" title="Update Leave Type" no-close-on-backdrop no-close-on-esc no-enforce-focus>
            <b-overlay :show="showOverlay" spinner-variant="info" opacity="0.4" rounded="sm">
                <b-container fluid>
                    <b-row class="mb-2">
                        <b-col cols="3" class="my-auto">Leave Type</b-col>
                        <b-col cols="9" class="">
                            <div class="required-field-block" :class="{ 'is-invalid': $v.employeeAttendance.hrmLeaveTypeId.$error }">
                                <select v-model="employeeAttendance.hrmLeaveTypeId" class="form-control" :class="{ 'is-invalid': $v.employeeAttendance.hrmLeaveTypeId.$error }" >
                                    <option value="">Select</option>
                                    <option v-for="(info, index) in leaveTypes" :value="info.id" :key="index">{{info.value}}</option>
                                </select>
                                <div class="required-icon"></div>
                            </div>  
                            <label v-if="$v.employeeAttendance.hrmLeaveTypeId.$error && !$v.employeeAttendance.hrmLeaveTypeId.required" class="text-danger">Please select a Leave Type</label>
                        </b-col>
                    </b-row>
                    <b-row class="mb-2">
                        <b-col cols="3" class="my-auto"></b-col>
                        <b-col cols="9" class="text-right">
                            <b-form-checkbox id="checkbox-1" v-model="employeeAttendance.isLop" name="checkbox-1" @change="onChangeIsLop($event)" >
                              Mark as Loss Of Pay
                            </b-form-checkbox>
                        </b-col>
                    </b-row>
                    <b-row v-if="employeeAttendance.isLop" class="mb-2">
                        <b-col cols="3" class="my-auto">Loss Of Pay</b-col>
                        <b-col cols="9" class="">
                            <select v-model="employeeAttendance.empLop" class="form-control" @change="onChangeLop">
                                <option value="">Select</option>
                                <option value="0.5">Half Day Loss of Pay</option>
                                <option v-if="!(employeeAttendance.statusLookupId == 1 || employeeAttendance.statusLookupId == 2)" value="1">Full Day Loss of Pay</option>
                            </select>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col cols="12">Remarks</b-col>
                        <b-col cols="12">
                            <editor api-key="lbcn6twu8tv4xan8fn1tgqiqy9hhvsse01s48crluvfuafzp" :toolbar="toolbar" :init="initObj" v-model="employeeAttendance.remark"></editor>
                        </b-col>
                    </b-row>
                </b-container>
            </b-overlay>
            <template #modal-footer="{ close }">
                <div class="w-100">
                    <b-button variant="outline-primary" size="sm" :disabled="showOverlay" @click="close();clearData();" class="float-right">Cancel</b-button>
                    <b-button variant="primary" size="sm" :disabled="showOverlay" @click="saveLeaveTypeData" class="float-right mr-2">
                        <span v-if="showOverlay" >
                            <b-spinner small label="Small Spinner" class="mr-2"></b-spinner>Saving..
                        </span>
                        <span v-else>Save</span>
                    </b-button>
                </div>
            </template>
        </b-modal>
        <EmpAttendanceTimelineDetPopup v-if="showDialog" ref="empAttendanceTimelinePopupRef" @closeDialog="showDialog=false;" />
        <LeaveTimeDetailPopup v-if="showDialog" ref="leaveTimeDetailPopupRef" :attachmentData="attachmentData" :referenceDetailId="referencePopupId" @closeDialog="showDialog=false;" />
        <SetWorkingDayPopup ref="setWorkingDayPopupRef" :workShiftId="selWorkShiftId" @refreshData="getEmployees" />
    </div>
</template>
<script>
import iziToast from 'izitoast';
import moment from 'moment';
import { required, email, minLength, maxLength, sameAs } from "vuelidate/lib/validators";
import AttendanceTableComponent from '@/components/common/AttendanceTableComponent.vue';
import EmpAttendanceTimelineDetPopup from '@/components/popup/itemdetail/EmpAttendanceTimelineDetPopup.vue';
import LeaveTimeDetailPopup from '@/components/popup/itemdetail/LeaveTimeDetailPopup.vue';
import SetWorkingDayPopup from '@/components/popup/action/SetWorkingDayPopup.vue';
import Editor from '@tinymce/tinymce-vue';
export default {
    name: 'app',
    components: {
        AttendanceTableComponent,
        Editor,
        EmpAttendanceTimelineDetPopup,
        LeaveTimeDetailPopup,
        SetWorkingDayPopup,
    },
    data() {
        return {
            showOverlay: false,
            showDialog: false,
            showEmployee: true,
            elements: {
                showDetail: false,
                editMode: false,
                visible: false,
                viewMode: false
            },

            vhextAccountId: localStorage.getItem("vhextAccountId"),
            projectId: localStorage.getItem("projectId"),
            projId: localStorage.getItem("projId"),
            projectName: localStorage.getItem("projectName"),
            userId: localStorage.getItem("userId"),

            searchDate: this.getDateValue(new Date()),
            workingDate: this.getDateValue(new Date()),
            workShiftId: '',
            selWorkShiftId: null,
            departmentId: '',

            attachmentData: {},
            referencePopupId: 0,

            editId: '',
            humanResourceId: '',

            employeeAttendance: {
                id: '',
                employeeAttendanceId: '',
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                humanResourceId: '',
                workingDayId: '',
                leaveTypeId: '',
                hrmLeaveTypeId: '',
                empLop: '',
                empOvertime: '',
                remark: '',
                empAttendanceStatusId: '',
                leaveTimeTypeId: '',
                leaveTimeId: '',
                workingDate: '',
                workDayType: '',
                statusLookupId: '',
                createdById: localStorage.getItem("userId"),
                isLop: false,
            },

            departments: [],
            workShifts: [],
            leaveTypes: [],

            employeeAttendanceList: [],
            employeeFields: [
                { key: 'index',label: 'SN', thStyle: "width: 10px" },            
                { key: 'name', label: 'Name', sortable: true, sortDirection: 'desc' },
                { key: 'employeeCode', label: 'EID', tdClass: 'align-middle', sortable: true, sortDirection: 'desc' },
                { key: 'department', label: 'Department', tdClass: 'align-middle', sortable: true, sortDirection: 'desc' },
                { key: 'workShiftId', label: 'Work Shift', tdClass: 'align-middle', sortable: true, sortDirection: 'desc' },
                { key: 'present', label: 'Present' , tdClass: 'align-middle',},
                { key: 'leaveFirstHalf', label: 'Leave 1st Half', tdClass: 'align-middle' },
                { key: 'leaveSecondHalf', label: 'Leave 2nd Half', tdClass: 'align-middle' },
                { key: 'leave', label: 'Leave' , tdClass: 'align-middle'},
                { key: 'empStatus', label: 'Emp. Status', tdClass: 'align-middle', sortable: true, sortDirection: 'desc'},
                { key: 'status', label: 'HRM Status', thClass: 'text-middle', tdClass: 'align-middle', sortable: true, sortDirection: 'desc' },
                { key: 'empOvertime', label: 'Overtime', tdClass: 'table-cw-100', tdClass: 'align-middle',sortable: true, sortDirection: 'desc' },
                { key: 'actions', label: 'Actions', tdClass: 'align-middle'},
            ],
            otherResourceFields: [
                { key: 'index',label: 'SN', thStyle: "width: 10px" },            
                { key: 'name', label: 'Name', sortable: true, sortDirection: 'desc' },
                { key: 'department', label: 'Department', sortable: true, sortDirection: 'desc' },
                { key: 'workShiftId', label: 'Work Shift', sortable: true, sortDirection: 'desc' },
                { key: 'present', label: 'Present' },
                { key: 'leaveFirstHalf', label: 'Leave 1st Half' },
                { key: 'leaveSecondHalf', label: 'Leave 2nd Half' },
                { key: 'leave', label: 'Leave' },
                { key: 'empStatus', label: 'Emp. Status', sortable: true, sortDirection: 'desc' },
                { key: 'status', label: 'HRM Status', thClass: 'text-center', sortable: true, sortDirection: 'desc' },
                { key: 'empOvertime', label: 'Overtime', tdClass: 'table-cw-100', sortable: true, sortDirection: 'desc' },
                { key: 'actions', label: 'Actions'},
            ],

            weblinks: 'google.com',
            toolbar: 'undo redo preview | formatselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | weblinks',
            initObj: {
                height: 200,
                branding: false,
                menubar: false,
                plugins: ['link', 'paste', 'wordcount', 'preview', 'image imagetools', 'lists'],
                paste_data_images: true,
                images_upload_url: 'postAcceptor.php',
                automatic_uploads: false,
                file_picker_types: 'image',
                relative_urls: false,
                remove_script_host: false,
            },

            selectMode: 'single',
            totalRows: 1,
            totalRowsEmp: 1,
            currentPage: 1,
            currentPageEmp: 1,
            perPage: 0,
            pageOptions: [10, 25, 50, 0],
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',
            filter: null,
            filterOn: [],
            deleteParam: {
                id: 0
            }
        }
    },
    computed: {
        employees() {
            return this.employeeAttendanceList.filter(item => item.id && (item.employmentStatusLookupId == 1 || item.employmentStatusLookupId == 2 || item.employmentStatusLookupId == 3 ));
        },
        otherResources() {
            return this.employeeAttendanceList.filter(item => !(item.id));
        },
        empTotalPresent() {
            return this.employees.filter(data => data.statusLookupId == 4).length;
        },
        empTotalHalfDayLeave() {
            return this.employees.filter(data => data.statusLookupId && (data.statusLookupId == 1 || data.statusLookupId == 2)).length;
        },
        empTotalLeave() {
            return this.employees.filter(data => data.statusLookupId && (data.statusLookupId == 3)).length;
        },
        empTotalCount() {
            // return this.employees.filter(data => data.statusLookupId != null).length;
            return this.employees.length;
        },
        empAttendeesPercentage() {
            return parseFloat(this.empTotalCount ? (((this.empTotalPresent+(this.empTotalHalfDayLeave/2))/this.empTotalCount) * 100).toFixed(1) : 0);
        },
        totalPresent() {
            return this.otherResources.filter(data => data.statusLookupId == 4).length;
        },
        totalHalfDayLeave() {
            return this.otherResources.filter(data => data.statusLookupId && (data.statusLookupId == 1 || data.statusLookupId == 2)).length;
        },
        totalLeave() {
            return this.otherResources.filter(data => data.statusLookupId && (data.statusLookupId == 3)).length;
        },
        totalCount() {
            // return this.otherResources.filter(data => data.statusLookupId != null).length;
            return this.otherResources.length;
        },
        attendeesPercentage() {
            return parseFloat(this.totalCount ? (((this.totalPresent+(this.totalHalfDayLeave/2))/this.totalCount) * 100).toFixed(1) : 0);
        },
    },
    validations: {
        employeeAttendance: {
            hrmLeaveTypeId: {
                required,
            },
            // empOvertime: {
            //     required,
            // },
        }
    },
    mounted() {
        this.lookupData();
        this.getEmployees();
    },
    methods: {
        getEmployees: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
                workingDate: this.searchDate,
                departmentId: this.departmentId,
                workShiftId: this.workShiftId,
            }
            this.workingDate = this.searchDate;
            this.showOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/company/dailyemployeeattendance/list', data)
                .then((response) => {
                    this.showOverlay = false;
                    let result = response.data;
                    this.employeeAttendanceList = result.employeeAttendanceList;
                    this.totalRows = this.otherResources.length;
                    this.totalRowsEmp = this.employees.length;

                })
                .catch(error => {
                    this.showOverlay = false;
                    iziToast.error({
                       title: 'Error',
                       message: 'Error',
                       position: 'topRight'
                   });
                })
        },
        lookupData: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
            }
            axios.post(this.$store.getters.getAPIBasePath + '/company/employeeattendance/lookup/all', data)
                .then((response) => {
                    let result = response.data;
                    this.departments = result.departmentLookup;
                    this.workShifts = result.workShiftLookup;
                    this.leaveTypes = result.customTypeLookup;
                })
        },
        editEmpOvertime(data, statusLookupId) {
            this.editId = data.humanResourceId;
            this.elements.editMode = true;
            this.addEmployeeAttendance(data, statusLookupId);
        },
        editEmpLeaveType(data, statusLookupId) {
            this.editId = '';
            this.elements.editMode = false;
            this.addEmployeeAttendance(data, statusLookupId);
        },
        addEmployeeAttendance: function(data, statusLookupId) {
            this.humanResourceId = data.humanResourceId;
            this.employeeAttendance.workingDate = this.workingDate;
            this.employeeAttendance.humanResourceId = data.humanResourceId;
            this.employeeAttendance.statusLookupId = statusLookupId;
            this.employeeAttendance.employeeAttendanceId = data.employeeAttendanceId;
            this.employeeAttendance.vhextAccountId = localStorage.getItem("vhextAccountId");
            this.employeeAttendance.workingDayId = data.workingDayId;
            this.employeeAttendance.leaveTimeTypeId = data.leaveTimeTypeId;
            this.employeeAttendance.leaveTypeId = data.leaveTypeId;
            this.employeeAttendance.hrmLeaveTypeId = data.hrmLeaveTypeId;
            this.employeeAttendance.empLop = data.empLop;
            this.employeeAttendance.empOvertime = data.empOvertime;
            this.employeeAttendance.remark = data.remark;
            this.employeeAttendance.empAttendanceStatusId = data.empAttendanceStatusId;
            this.employeeAttendance.leaveTimeId = data.leaveTimeId;
            this.employeeAttendance.workDayType = data.workDayType;
            this.employeeAttendance.createdById = data.createdById;
            if (this.elements.editMode) {
                // this.$bvModal.show('leaveUpdatePopup');
                return;
            }
            else if (statusLookupId == 1 || statusLookupId == 2 || statusLookupId == 3) {
                this.employeeAttendance.isLop = this.employeeAttendance.empLop ? true : false;
                this.$bvModal.show('leaveUpdatePopup');
                return;
            }
            this.employeeAttendance.hrmLeaveTypeId = '';
            this.employeeAttendance.empLop = '';
            this.employeeAttendance.remark = '';
            this.$nextTick(() => {
                this.saveEmployeeAttendance();
            })
        },
        saveEmpOvertimeData(empOvertime) {
            this.employeeAttendance.empOvertime = empOvertime;
            // this.$v.employeeAttendance.empOvertime.$touch();
            // if (!this.$v.employeeAttendance.empOvertime.$invalid) {
                console.log(this.employeeAttendance)
                this.saveEmployeeAttendance();
                this.elements.editMode = false;
            // }
        },
        saveLeaveTypeData() {
            this.$v.employeeAttendance.hrmLeaveTypeId.$touch();
            if (!this.$v.employeeAttendance.hrmLeaveTypeId.$invalid) {
                this.saveEmployeeAttendance();
                this.$bvModal.hide('leaveUpdatePopup');
            }
        },
        saveEmployeeAttendance: function() {
            console.log(this.employeeAttendance)
            this.showOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/company/dailyemployeeattendance/add', this.employeeAttendance)
                .then((response) => {
                    this.showOverlay = false;
                    let result = response.data;
                    if (result.status == "CREATED") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.getEmployees();
                        this.clearData();
                    }
                })
                .catch(error => {
                    this.showOverlay = false;
                    iziToast.error({
                       title: 'Error',
                       message: 'Error',
                       position: 'topRight'
                   });
                })
        },
        onFilteredEmp(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },
        onRowSelected: function(items) {
            if (items != null && items.length > 0) {
                let data = items[0];
                this.elements.showDetail = true;
            }
        },
        openWorkingDay(workShiftId) {
            this.selWorkShiftId=workShiftId;

            this.$nextTick(() => {
                this.$refs.setWorkingDayPopupRef.getWorkingDayData(this.workingDate);
            })

            
        },
        clearData() {
            // this.employeeAttendanceList.map(data => {
            //     if (data.id == this.humanResourceId) {
            //         data.statusLookupId = null;
            //     }
            //     return data;
            // });
            this.editId = '';
            this.elements.editMode = false;
            this.employeeAttendance.hrmLeaveTypeId = '';
            this.employeeAttendance.empLop = '';
            this.employeeAttendance.empOvertime = '';
            this.employeeAttendance.remark = '';
            this.employeeAttendance.isLop = false;
            this.$v.employeeAttendance.$reset();
            this.getEmployees();
        },
        onChangeIsLop(value) {
            if (this.employeeAttendance.statusLookupId == 1 || this.employeeAttendance.statusLookupId == 2) {
                this.employeeAttendance.empLop = value ? 0.5 : '';
            } else if (this.employeeAttendance.statusLookupId == 3) {
                this.employeeAttendance.empLop = value ? 1 : '';
            }
        },
        onChangeLop() {
            if (!this.employeeAttendance.empLop || this.employeeAttendance.empLop == '') {
                this.employeeAttendance.isLop = false;
            }
        },
        showTimelineListPopup(employeeAttendanceId) {
            this.showDialog = true;
            this.$nextTick(() => {
                this.$refs.empAttendanceTimelinePopupRef.getDetails(employeeAttendanceId);
            })
        },
        viewItemDetails(leaveTimeId) {
            this.referencePopupId = leaveTimeId;
            this.attachmentData.refEntity = 'calendar_event';
            this.attachmentData.refRecordId = leaveTimeId;
            this.showDialog = true;
            this.$nextTick(() => {
                this.$refs.leaveTimeDetailPopupRef.getDetails(this.referencePopupId, 'leave_time');
            })
        },
    }
}
</script>