<template>
    <div>
        <div id="frame">
            <div id="sidepanel">
                <div id="chat-profile">
                    <div class="wrap">
                        <img id="profile-img" :src="chatUserImage" class="online my-2" alt="" />
                        <!-- <div id="profile-img"  class="profile-img online"><label>{{userinfo.name | getAcronym}}</label></div> -->
                        <h8 class="pl-2">{{userinfo.name}}</h8>

                        <div id="status-options">
                            <ul>
                                <li id="status-online" class="active"><span class="status-circle"></span> <p>Online</p></li>
                                <li id="status-away"><span class="status-circle"></span> <p>Away</p></li>
                                <li id="status-busy"><span class="status-circle"></span> <p>Busy</p></li>
                                <li id="status-offline"><span class="status-circle"></span> <p>Offline</p></li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div id="search">
                    <label for=""><i class="fa fa-search" aria-hidden="true"></i></label>
                    <input type="search" v-model="search" placeholder="Search contacts..."/>
                </div>
                <div id="contacts">
                    <ul v-if="isLandingPage" class="Members active">
                        <li class="no-chats-text" v-if="chatUsers.length === 0">
                            <p class="text-primary">No Members Found</p>
                        </li>
                        <li class="contact" v-for="memberChat in filteredUsers" @click="userChatClick(memberChat); selectedChatId = memberChat.id">
                            <div class="row">
                                <div class="wrap px-2">
                                    <span class="contact-status online"></span>
                                    <div class="user-img"><img :src="showPhoto(memberChat.photo)" alt="" />
                                        
                                    </div>
                                     <!-- <div class="user-img"><label>{{ memberChat.memberName | getAcronym }}</label></div> -->
                                    <div class="meta">
                                        <h7>{{ memberChat.memberName }} <strong v-if="memberChat.unReadChatCount > 0" class="badge badge-success fs-11 float-right text-center">{{ memberChat.unReadChatCount }} Unread</strong></h7>
                                        <p class="preview">{{memberChat.position}}</p>
                                        <!-- <p class="preview">{{memberChat.unReadChatCount}}</p> -->
                                    </div>

                                </div>
<!--                                 <div class="pr-2 mx-2" v-if="memberChat.unReadChatCount > 0">
                                    <span class="badge badge-success" title="Unread">{{ memberChat.unReadChatCount }}</span>
                                </div>                                                         -->
                            </div>

                        </li>
                    </ul>
                    <template v-else>
                        <ul class="Members active">
                            <li class="no-chats-text" v-if="projectMembers.length === 0">
                                <p>No Members Found</p>
                            </li>
                            <li class="contact" v-for="memberChat in filteredMembers" @click="memberChatClick(memberChat); selectedChatId = memberChat.id" >
                                <div class="wrap">
                                    <span class="contact-status online"></span>
                                    <div class="user-img"><img :src="showPhoto(memberChat.photo)" alt="" /></div>
                                     <!-- <div class="user-img"><label>{{ memberChat.memberName | getAcronym }}</label></div> -->
                                    <div class="meta">
                                        <p class="name">{{ memberChat.memberName }}</p>
                                        <p class="preview">{{memberChat.position}}</p>
                                    </div>
                                </div>
                            </li>
                        </ul>
                        <ul class="Teams">
                            <li class="no-chats-text" v-if="entityRecordMasterList.length === 0">
                                <p>No Teams Found</p>
                            </li>
                            <li class="contact" v-for="teamChat in filteredTeams" @click="teamChatClick(teamChat)" >
                                <div class="wrap">
                                    <span class="contact-status online"></span>
                                    <div class="user-img"><img src="@/assets/img/defaultuser.png" alt="" /></div>
                                    <!-- <div class="user-img"><label>{{ teamChat.title | getAcronym }}</label></div> -->
                                    <div class="meta">
                                        <p class="name">{{ teamChat.title }}</p>
                                        <!-- <p class="preview">{{teamChat.createdBy}}</p> -->
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </template>
                </div>
                <div id="bottom-bar">
                    <div v-if="isLandingPage" class="title-block py-3 font-weight-normal">Users</div>
                    <template v-else>
                        <button id="Members" @click="showMemberChat = true;" class="active"><i class="fa fa-user-plus fa-fw" aria-hidden="true"></i> <span>Members</span></button>
                        <button id="Teams" @click="showMemberChat = false;"><i class="fa fa-cog fa-fw" aria-hidden="true"></i> <span>Teams</span></button>
                    </template>
                </div>
            </div>
            <div class="content" ref="messagesContainer">
                <div class="contact-profile d-flex align-items-center">
                    <div v-if="memberDetail.chatTitle" class="">
                        <!-- <img src="http://emilcarlsson.se/assets/harveyspecter.png" alt="" /> -->
                        <img class="user-img p-1" :src="showPhoto(memberDetail.photo)" alt="" />
                        <p class="">{{ memberDetail.chatTitle }}</p>
                    </div>
                    <!-- <div class="social-media">
                        <i class="fa fa-facebook" aria-hidden="true"></i>
                        <i class="fa fa-twitter" aria-hidden="true"></i>
                        <i class="fa fa-instagram" aria-hidden="true"></i>
                    </div> -->
                </div>
                <div class="messages">
                    <div class="chats-dispaly">
                        <ul v-if="chats.length === 0">
                            <li class="no-chats-text" >
                                <p v-if="selectedChatId">No Conversation Found</p>
                            </li>
                        </ul>
                        <div v-for="loadChat,index in chats" class="chats-dispaly-list">
                            <ul >
                                <li :class="userId == loadChat.chatById ? 'sent' : 'replies'">
                                    <!-- <img src="http://emilcarlsson.se/assets/harveyspecter.png" alt="" /> -->
                                    <!-- <div class="chat-icon-img"><label>{{loadChat.chatToId == loadChat.chatById ? loadChat.chatToUser : loadChat.chatByUser | getAcronym }}</label></div> -->
                                    <div class="chat-icon-img"><label>{{ loadChat.chatByUser | getAcronym  }}</label></div>
                                    <p>
                                        <ul class="chat-user-details">
                                            <li><span class="">{{ loadChat.chatByUser }}</span></li>
                                            <li><span class="">{{ loadChat.lastUpdatedDt | formatDateTime }}</span></li>
                                        </ul>
                                        <span :data-id="'moreactions-options-'+loadChat.id" class="User-avtar chatMoreActions" >
                                            <i class="fa fa-ellipsis-h action-link" aria-hidden="true"></i>
                                        </span>
                                        <ul class="User-Dropdown" :id="'moreactions-options-'+loadChat.id">
                                            <li v-if="userId == loadChat.chatById">
                                                <a href="#"><i class="fa fa-pencil pl-1" aria-hidden="true"></i> Edit</a>
                                            </li>
                                            <li v-if="userId == loadChat.chatById">
                                                <a href="#" title="Delete" @click="deleteChat(loadChat.id)" v-b-modal.deleteChat><i class="fa fa-trash-o pl-1" aria-hidden="true"></i> Delete</a>
                                            </li>
                                            <li>
                                                <a href="javascript:" title="Assign Task" @click="assignTask(loadChat)" v-b-modal.ermAssignments><i class="fa fa-user-plus pl-1"></i> Assign Task</a>
                                            </li>                                            
                                            <li v-if="userId != loadChat.chatById">
                                                <a href="#" title="Deactivate Chat" @click="deactivateChat(loadChat.id)" v-b-modal.deactivateChat><i class="fa fa-trash-o pl-1" aria-hidden="true"></i> Deactivate</a>
                                            </li>
                                        </ul>
                                        <span class="chat-msg">
                                            {{ loadChat.chatBody }}
                                        </span>
                                    </p>
                                </li>
                                <li v-if="index == (chats.length-1)" id="default-chat-load"></li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div v-if="selectedChatId" id="commentsBox" class="message-input comments-cox">
                    <div class="wrap publisher">
                        <input type="text" v-model="chatMessage" class="chat-input" @keyup.enter="addChat" placeholder="Write your message..." />
                        <AttachmentComponent class="attachment"  :attachmentInfo="attachmentInfo" :attachments="attachments" />
                        <a class="msgSubmit" href="#" data-abc="true" type="submit" @click="addChat"><i class="fa fa-paper-plane"></i></a> 
                    </div>
                </div>
            </div>
        </div>
        <DialogComponent id="deleteChat" :onYes="onDeleteChatConfirmation" :returnParams="deleteParam" title="Delete Chat Confirmation" message="Are you sure you want to delete the chat?" />   
        <DialogComponent id="deactivateChat" :onYes="onDeactivateChatConfirmation" :returnParams="deactivateParam" title="Deactivate Chat Confirmation" message="Are you sure you want to deactivate the chat?" />   
        <QuickTaskPopup ref="ermAssignmentsRef" :masterData="masterData" />
    </div>
</template>

<script>
import iziToast from 'izitoast';
import moment from 'moment';
import Multiselect from 'vue-multiselect';
import AttachmentComponent from '@/components/common/AttachmentComponent.vue';
import QuickTaskPopup from '@/components/popup/action/QuickTaskPopup.vue';
import defaultImage from '@/assets/img/defaultuser.png';
export default {
    name: 'app',
    components: {
        Multiselect,
        AttachmentComponent,
        QuickTaskPopup,
    },
    data() {
        return {

            vhextAccountId: localStorage.getItem("vhextAccountId"),
            projectId: localStorage.getItem("projectId"),
            projId: localStorage.getItem("projId"),
            projectName: localStorage.getItem("projectName"),
            userId: localStorage.getItem("userId"),
            userImage: localStorage.getItem("userImage"),
            isArchive: false,
            entity: 'project_team_master',
            ermId: '',

            timer: '',
            chatTitle: 'xyz',
            showOverlay: false,
            showMemberChat: true,
            isLandingPage: false,


            chatBox: {
                id: '',
                chatBoxId: '',
                projectId: localStorage.getItem("projectId"),
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                chatBody: '',
                chatById: localStorage.getItem("userId"),
                chatByUser: localStorage.getItem("userName"),
                hasAttachment: '',
                isRead: '',
                active: 1,
                chatToId: '',
                chatToUser: '',
                teamChatId: '',
                createdDt: '',
                lastUpdatedDt: '',
                editId: '',

            },
            memberDetail: {
                chatTitle: '',
                chatName: '',
                photo: '',
            },

            masterData: {
                refErmId: 0,
                refErdId: 0,
                refEntityId: 0,
                refErdTitle: '',
                refErdDescription: '',
                refEntity: '',
                statusId: 1,
            },

            chatMessage:'',
            chats: [],

            currentDate: new Date().toString().substr(0, 10),
            tabIndex: 0,
            visibleChatContent: false,
            selectedChatId: null,
            editMode: false,

            ermMasterInfo: {
                title: "Team",
                entity: 'project_team_master',
                refEntity: 'project_team',
            },
            attachmentInfo:{
                title:''
            },
            attachments: [],

            allTeamMembers: [],
            memberList: [],
            users: [],
            teamChat: [],
            entityRecordMasterList: [],
            search: '',
            deleteParam: {
                id: 0
            },
            deactivateParam: {
                id: 0
            },
            userinfo: this.$store.getters.currentUser,
        }
    },
    watch: {
        tabIndex() {
            this.visibleChatContent = false;
            this.selectedChatId = null;
        },
        '$route.path': {
            handler: function(path) {
                let routeName = this.$route.name;

                this.isLandingPage = false;
                if (path.includes('landing')) {
                    this.isLandingPage = true;
                }
            },
            deep: true,
            immediate: true
        }
    },
    computed: {
        chatUserImage() {
            return localStorage.getItem("userImage") ? localStorage.getItem("userImage") : defaultImage;
        },
        chatUsers() {
            return this.users.filter(data => data.id != this.userId);
        },
        projectMembers() {
            return this.memberList.filter(data => data.userId != this.userId);
        },
        filteredTeams() {
            return this.entityRecordMasterList.filter(data => data.title && data.title.toLowerCase().includes(this.search.toLowerCase()));
        },
        filteredMembers() {
            return this.projectMembers.filter(data => data.memberName && data.memberName.toLowerCase().includes(this.search.toLowerCase()));
        },
        filteredUsers() {
            return this.chatUsers.filter(data => data.memberName && (data.memberName.toLowerCase().includes(this.search.toLowerCase())));
        },
    },
    mounted() {
        if (this.isLandingPage) {
            this.getChatUsers();
        } else {
            this.getProjectMembers();
            this.getProjectTeams();
        }
        this.vhextAccountId = localStorage.getItem("vhextAccountId");
        this.projectId = localStorage.getItem("projectId");
    },
    created () {
        // this.getProjectMembers();
        // this.timer = setInterval(this.getMemberChat(), 300000);
    },    
    methods: {
        backgroundImage(img) {
            return 'background: url(' + img + ') no-repeat center; background-size: cover;';
        },
        getProjectMembers: function() {
            let data = {
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                projectId: localStorage.getItem("projectId"),
            }
            axios.post(this.$store.getters.getAPIBasePath + '/resource/projectmembers', data)
                .then((response) => {
                    let result = response.data;
                    console.log(result)
                    this.memberList = result.projectMembers;
                    // this.selectedChatId = this.projectMembers[0].id;
                    // this.allTeamMembers = this.projectMembers;
                    // this.$nextTick(() => {
                    //     this.requirementMasterFilter(this.requirementFilterId);
                    // })
                })
        },
        getProjectTeams: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
                projectId: this.projectId,
                userId: this.userId,
                entity: this.entity,
                isArchive: this.isArchive,
            }
            axios.post(this.$store.getters.getAPIBasePath + '/common/entityrecordmaster/list', data)
                .then((response) => {
                    let result = response.data;
                    console.log(result);
                    
                    if (result.entityRecordMasterList && result.entityRecordMasterList.length > 0) {     
                        this.isEmpty = result.entityRecordMasterList.length == 0 ? true : false;                   
                        this.entityRecordMasterList = result.entityRecordMasterList;
                        this.ermId = this.entityRecordMasterList[0].id;
                        console.log(this.entityRecordMasterList.length)
                    }
                })
        },
        getChatUsers: function() {
            let data = {
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                userId: localStorage.getItem("userId"),
            }
            axios.post(this.$store.getters.getAPIBasePath + '/communication/chat/userlist', data)
                .then((response) => {
                    let result = response.data;
                    this.users = result.chatUserList;
                    // this.selectedChatId = this.chatUsers[0].id;
                    // this.allTeamMembers = this.projectMembers;
                    // this.$nextTick(() => {
                    //     this.requirementMasterFilter(this.requirementFilterId);
                    // })
                })
        },
        projectTeamMembers(ermId) {
            return this.projectMembers.filter(projectMembers => projectMembers.entityRecordMasterId == ermId);
        },
        getTeamsByErmId(ermId) {
            return this.projectMembers.filter(member => member.entityRecordMasterId == ermId);
        },
        memberChatClick: function(memberChat) {
            // this.getAttachment(mail.sendMailId);
            console.log(memberChat);
            this.memberDetail.chatTitle = memberChat.memberName;
            this.memberDetail.chatName = memberChat.memberName;
            this.memberDetail.photo = memberChat.photo;
            this.chatBox.chatToId = memberChat.userId;
            this.visibleChatContent = true;
            this.selectedChatId = memberChat.id;
            // this.timer = setInterval(this.getMemberChat(), 3000);            
            this.getMemberChat(true);


        },
        userChatClick: function(memberChat) {
            this.memberDetail.chatTitle = memberChat.memberName;
            this.memberDetail.chatName = memberChat.memberName;
            this.memberDetail.photo = memberChat.photo;
            this.chatBox.chatToId = memberChat.id;
            this.visibleChatContent = true;
            this.selectedChatId = memberChat.id;
            this.getMemberChat(true);
            console.log("Read",memberChat);

            if(memberChat.unReadChatCount > 0){
                this.updateUserChatRead(memberChat);
            }
                        
        },
        teamChatClick: function(teamChat) {
            // this.getAttachment(mail.sendMailId);
            console.log(teamChat);
            this.memberDetail.chatTitle = teamChat.title;
            this.memberDetail.chatName = teamChat.title;
            this.memberDetail.photo = memberChat.photo;
            this.chatBox.teamChatId = teamChat.entityRecordMasterId;
            this.visibleChatContent = true;
            this.selectedChatId = teamChat.id;
            // this.getTeamChat(true);

        },
        getFileExtension: function(name) {
            var re = /(?:\.([^.]+))?$/;

            return re.exec(name)[1];
        },
        getAttachment: function(sendMailId) {
            let data = {
                vhextAccountId: this.vhextAccountId,
                projectId: this.projectId,
                refEntity: 'send_mail',
                refRecordId: sendMailId
            }
            axios.post(this.$store.getters.getAPIBasePath + '/attachment/list', data)
                .then((response) => {
                    let result = response.data;
                    console.log(result);
                    this.attachments = result.attachmentList;
                })
        },
        imageFormats(content) {
            var contentExtension = this.getFileExtension(content);
            if ( contentExtension == 'png' || contentExtension == 'jpg' || contentExtension == 'jpeg' || contentExtension == 'tiff' || contentExtension == 'tif') {
                return true;
            }
            else return false;
        },
        getMemberChat: function(scrollToEnd) {
            let data = {
               chatToId: this.chatBox.chatToId,
               chatById: this.userId,
               chatToId: this.chatBox.chatToId,
            }
            console.log(this.chatBox);
            // this.chats = [];
            this.showOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/communication/memberchat/list', data)
                .then((response) => {
                    this.showOverlay = false;
                    let result = response.data;
                    this.chats = result.memberChatList;
                    if (this.chats.length>0 && scrollToEnd) {
                        this.$nextTick(() => {
                            this.scrollToEnd();
                        })
                    }
                    this.timer = setTimeout(() => {
                        this.getMemberChat(false)
                    }, 10000);
                })
                .catch(error => {
                    this.showOverlay = false;
                    // iziToast.error({
                    //    title: 'Error',
                    //    message: 'Error',
                    //    position: 'topRight'
                    // });

                })
        },
        getTeamChat: function(scrollToEnd) {
            let data = {
                teamChatId: this.chatBox.teamChatId
            }
            this.chats = [];
            this.showOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/communication/teamchat/list', data)
                .then((response) => {
                    this.showOverlay = false;
                    let result = response.data;
                    this.chats = result.teamChatList;
                    if (this.chats.length>0 && scrollToEnd) {
                        this.$nextTick(() => {
                            this.scrollToEnd();
                        })
                    }
                    this.timer = setTimeout(() => {
                        this.getMemberChat(false)
                    }, 10000);
                })
                .catch(error => {
                    this.showOverlay = false;
                   //  iziToast.error({
                   //     title: 'Error',
                   //     message: 'Error',
                   //     position: 'topRight'
                   // });
                })
        },
        addChat: function() {
            this.chatBox.chatBody = this.chatMessage;
            axios.post(this.$store.getters.getAPIBasePath + '/communication/chatbox/add', this.chatBox)
                .then((response) => {
                    let result = response.data;
                    if (result.status == "CREATED") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        // this.scrollToEnd();
                        this.getMemberChat(true);
                        // this.getTeamChat(true);
                        this.clearChat();
                        
                    }
                })
                
        },
        scrollToEnd: function () {
            let elmnt = document.getElementById('default-chat-load');
            if (elmnt) {
                elmnt.scrollIntoView(false);
            }
        },
        inputChat(e) {
            this.chatBox.chatBody = e.target.innerText;
        },
        updateChat: function() {
            axios.post(this.$store.getters.getAPIBasePath + '/communication/chatbox/update', this.chatBox)
                .then((response) => {
                    let result = response.data;
                    if (result.status == "CREATED") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.getMemberChat(false);
                        // this.getTeamChat(false);
                        this.clearChat();
                        this.editMode = false;
                    }
                })
        },
        editChat: function(chatData) {
            this.editMode = true;
            this.chatBox.id = chatData.id;
            this.chatBox.editId = chatData.id;
            this.chatBox.chatBoxId = chatData.chatBoxId;
            this.chatBox.projectId = chatData.projectId;
            this.chatBox.vhextAccountId = chatData.vhextAccountId;
            this.chatBox.chatBody = chatData.chatBody;
            this.chatBox.chatById = chatData.chatById;
            this.chatBox.hasAttachment = chatData.hasAttachment;
            this.chatBox.isRead = chatData.isRead;
            this.chatBox.chatToId = chatData.chatToId;
            this.chatBox.teamChatId = chatData.teamChatId;
        },
        updateUserChatRead: function(memberChat) {
            let data = {
               vhextAccountId: this.vhextAccountId,
               chatById: this.chatBox.chatToId,
               chatToId: this.chatBox.chatById,
            }       
            console.log("Read",this.chatBox);
            axios.post(this.$store.getters.getAPIBasePath + '/communication/chatbox/userchatread/update', data)
                .then((response) => {
                    let result = response.data;
                    // if (result.status == "CREATED") {
                    //     iziToast.success({
                    //         title: 'Success',
                    //         message: result.message,
                    //         position: 'topRight'
                    //     });
                    // }
                })
        },
        assignTask: function(data) {
            console.log("CHAT",data);
            this.masterData.refErmId = '';
            this.masterData.refErdId = data.id;
            this.masterData.refEntityId = '';
            this.masterData.refEntity = 'chat_box';
            this.masterData.refErdTitle = data.chatBody.substring(0, 50);
            this.masterData.refErdDescription = data.chatBody;
            this.$refs.ermAssignmentsRef.onRefDataChange();
            console.log("CHAT", this.masterData);
        },        
        deactivateChat: function(id) {
            console.log(id)
            this.deactivateParam.id = id;
        },
        onDeactivateChatConfirmation: function() {
            axios.delete(this.$store.getters.getAPIBasePath + '/communication/chat/deactivate/' + this.deactivateParam.id)
                .then(response => {
                    var result = response.data;
                    if (result.status == "OK") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.getMemberChat(false);
                        // this.getTeamChat(false);
                    }
                })
                .catch(error => {
                    console.log(error);
                });
        },

        deleteChat: function(id) {
            console.log(id)
            this.deleteParam.id = id;
        },
        onDeleteChatConfirmation: function() {
            axios.delete(this.$store.getters.getAPIBasePath + '/communication/chat/delete/' + this.deleteParam.id)
                .then(response => {
                    var result = response.data;
                    if (result.status == "OK") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.getMemberChat(false);
                        // this.getTeamChat(false);
                    }
                })
                .catch(error => {
                    console.log(error);
                });
        },
        clearChat: function() {
            this.editMode = false;
            this.chatBox.id = '';
            this.chatBox.editId = '';
            this.chatBox.commentId = '';
            this.chatBox.chatBody = '';
            this.chatMessage='';
        },
        uploadFile: function() {
            this.attachments.push(this.$refs.add_attachment.files[0]);
            console.log(this.attachments);
        },
        showPhoto(photo){
            if(photo != null && photo.length>0) {
              return photo;  
            } else {
                return defaultImage;
            }
        },
        cancelAutoUpdate () {
            clearTimeout(this.timer);
        }        
    },
    beforeDestroy () {
      this.cancelAutoUpdate();

    }    

    
}
</script>
